import {
  FormError,
  isRequiredValidator,
  SimpleValidator,
} from "sections/billing-model-common/BillingModelValidation";
import { BillingModelRatePlanType_bamRatePlanType as RatePlanType } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlanType";

const ratePlanTypeValidators = [
  new SimpleValidator(/^name$/, (value: unknown | null) =>
    isRequiredValidator("Please enter a name.", value as string)
  ),
];

const validateRatePlanTypeField = (
  name: string,
  update: RatePlanType,
  errors: FormError
): [boolean, FormError] =>
  SimpleValidator.validate<RatePlanType>(
    ratePlanTypeValidators,
    name,
    update,
    errors,
    false,
    undefined,
    (name: string, update: RatePlanType) => {
      let value: unknown | null = null;
      switch (name) {
        default:
          value = update[name as keyof RatePlanType]?.toString() ?? null;
          break;
      }
      return value;
    }
  );

const validateRatePlanType = (update: RatePlanType): [boolean, FormError] => {
  let validationErrors = {};
  const props = Object.keys(update);
  props.forEach((p) => {
    const results = validateRatePlanTypeField(p, update, validationErrors);
    validationErrors = results[1];
  });

  const hasErrors = Object.values(validationErrors).some((a) => a != null);
  return [hasErrors, validationErrors];
};

export { validateRatePlanTypeField, validateRatePlanType };
