import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Grid, TextField } from "@mui/material";
import type { OfscActivityTicketTypes } from "../../../../../../__generated__/globalTypes";
import { upperCaseFirstLetterOnly } from "../../screens/helpers";
import { Ticket, TicketType } from "../../screens/types";

export type Props = {
  setTicket: (str: any) => void;
  ticket?: Ticket | undefined;
  ticketType: TicketType;
};

type TicketInput = {
  ticketType: OfscActivityTicketTypes;
  ticketNumber?: string;
};

const TicketInput: React.FC<Props> = ({
  setTicket,
  ticket,
  ticketType,
}: Props) => {
  const [ticketTemp, setTicketTemp] = useState<Ticket | undefined>();

  React.useEffect(() => {
    setTicketTemp(undefined);
  }, []);

  React.useEffect(() => {
    setTicketTemp(ticket);
  }, [ticket]);

  return (
    <Grid container spacing={1} justifyContent="flex-start" marginBottom="1em">
      <Grid item md={4} xs={12}>
        <TextField
          id="ticket"
          value={ticketTemp?.ticketNumber || ""}
          onChange={(e) => {
            const input = e.target.value.replace(/\D/g, "");
            setTicketTemp({
              ticketType: (ticketType as string) as TicketType,
              ticketNumber: input,
            });
          }}
          onBlur={(e) => setTicket(ticketTemp ?? null)}
          label={`${upperCaseFirstLetterOnly(ticketType)} Ticket`}
          variant="outlined"
          type="text"
          style={{ display: "flex" }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TicketInput);
