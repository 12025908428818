import { Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { RcdGroup } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { getDisplayText } from "sections/billing-model-common/BillingModelUtilities";
import Toggle from "sections/_global/components/Toggle";
import { colors } from "styles/colors";

export interface Props {
  rcdGroup: RcdGroup;
  isChecked?: boolean;
  onChecked?: (checked: boolean) => void;
}

const RcdGroupView: React.FC<Props> = ({
  rcdGroup,
  isChecked,
  onChecked,
}: Props) => {
  const inlineField = (
    label: string | null,
    value: string | undefined | null,
    editable: boolean,
    hidden: boolean
  ) => {
    return (
      <Grid
        container
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: colors.gray11,
          borderRadius: "0.1em",
          "& .field_label": {
            borderRight: `1px solid ${colors.gray10}`,
            paddingLeft: "0.3em",
            paddingRight: "0.3em",
            backgroundColor: colors.gray12,
            color: colors.gray1,
            fontWeight: "bold",
            alignSelf: "center",
          },
          "& .field_value": {
            paddingLeft: "0.3em",
            paddingRight: "0.3em",
            alignSelf: "center",
          },
          backgroundColor: () =>
            !editable || hidden ? colors.gray12 : undefined,
        }}
      >
        <Grid item className="field_label" xs={6}>
          <Typography variant="overline">
            {label ?? "Unknown Element"}
          </Typography>
        </Grid>
        <Grid item className="field_value" xs={6}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getTransactionInfo = () => {
    if (rcdGroup.transactionType) {
      return (
        <>
          {`Transaction Type - ${
            rcdGroup.periodType?.name ?? "No Period Type"
          }`}{" "}
          -{" "}
          {getDisplayText(
            rcdGroup.transactionType?.transactionTypeId,
            rcdGroup.transactionType?.name,
            "No Transaction Type"
          )}
        </>
      );
    }

    return "No Transaction Type";
  };

  return (
    <Paper
      elevation={1}
      sx={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.gray11,
        padding: "0.4em",
        maxWidth: "100%",
        margin: "0.8em",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={4}>
          {isChecked !== undefined && (
            <Toggle
              label="Copy"
              isChecked={isChecked}
              onChange={(event) => {
                if (onChecked) {
                  onChecked(event.target.checked);
                }
              }}
            />
          )}
          <Typography variant="h6">
            {getDisplayText(
              rcdGroup.ratingControlGroupId,
              rcdGroup.ratingControlGroupType?.name,
              "Unknown RCD Group Type"
            )}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="overline">{getTransactionInfo()}</Typography>
      <Divider />
      <Typography variant="overline">Elements</Typography>
      <Grid container spacing={1}>
        {rcdGroup &&
          rcdGroup.ratingControlElements?.map((e, i) => (
            <Grid item key={i} xs={12} md={6}>
              {inlineField(
                e.ratingControlElementType.displayLabel ?? null,
                e.value,
                e.ratingControlElementType.editable ?? false,
                e.ratingControlElementType.hidden ?? false
              )}
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

export default RcdGroupView;
