import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(styles);

const LightLevelsLegend: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.mb_1em} wrap="nowrap">
      <Grid item container xs sm={3} lg={2} spacing={2} wrap="nowrap">
        <Grid item>
          <Icon className={classes.flashlightIcon}>flashlight_on</Icon>
        </Grid>
        <Grid item>
          <Typography>Light Level</Typography>
        </Grid>
      </Grid>
      <Grid item container xs sm spacing={2}>
        <Grid item>
          <Icon color="primary">timelapse</Icon>
        </Grid>
        <Grid item>
          <Typography>Status</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LightLevelsLegend;
