import React, { FC, Dispatch, SetStateAction } from "react";
import { FormControl } from "@mui/material";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import ChannelSelector from "../../components/ChannelSelector/ChannelSelector";
import ContactField from "../../components/ContactField/ContactField";
import PreferencesContainer from "../../components/PreferencesContainer/PreferencesContainer";
import PreferencesFooter from "./PreferencesFooter/PreferencesFooter";
import {
  Contact,
  SubPreference,
} from "sections/communication-preference/contacts/types";
import { useContact } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import { Box } from "@mui/system";
import componentStyles from "./styles";

type Props = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  contact: Contact;
  screenIsSmall: boolean;
};

const PreferencesForm: FC<Props> = ({
  setCurrentStep,
  currentStep,
  contact,
  screenIsSmall,
}: Props) => {
  const dispatch = useMessageDispatch();
  const [{ contacts }, contactDispatch] = useContact();
  const { value, type, key } = contact;

  /**
   * Function which checks the provided subPreferences list is empty, if it is this means the user wants all of their preference alarams set to ON, so
   * we set all of the checkboxes to true
   */
  const selectAllCheckboxes = (
    subPreferences: SubPreference[],
    key: string
  ): void => {
    if (subPreferences.length === 0) {
      contactDispatch({
        type: "UPDATE_CONTACT",
        key,
        update: { type: "SUBPREFERENCES", action: { type: "SELECT_ALL" } },
      });
    }
  };

  /**
   * Function that is run before pressing the next, skip, or previous button, if errors are present will prevent the user from continuing
   * @returns True if no errors and false along with error messages otherwise
   */
  const validate = (skip: boolean): boolean => {
    const unfilledPreferences: string[] = [];
    const { subPreferences } = contact;

    // If the skip button is pressed, then we go through this if statement checking to see if each contact has an empty subpreferences list and fills those, otherwise just check this contact
    if (skip) {
      contacts.forEach((contact) => {
        const { subPreferences, key } = contact;
        selectAllCheckboxes(subPreferences, key);
      });
    } else selectAllCheckboxes(subPreferences, key);

    // Loop through our checkboxes, if all are unchecked for a preference, then check if there are subpreferences. If none are checked there, then throw an error
    for (const subPreference of subPreferences) {
      const { channelOptions, channelName } = subPreference;
      if (channelOptions.every((channelOption) => !channelOption.checked)) {
        if (
          channelOptions.every((channelOption) => !channelOption.subOptions)
        ) {
          unfilledPreferences.push(channelName);
          continue;
        } else {
          let allUnchecked = true;
          for (const channelOption of channelOptions) {
            const { subOptions } = channelOption;
            if (subOptions)
              if (!subOptions.every((subOption) => !subOption.checked))
                allUnchecked = false;
          }
          if (allUnchecked) {
            unfilledPreferences.push(channelName);
          }
        }
      }
    }
    if (unfilledPreferences.length === 0) return true;
    unfilledPreferences.forEach((preferenceName) =>
      dispatch({
        message: {
          description: `Either remove ${preferenceName}, or check at least one of its checkboxes`,
          id: preferenceName,
          isOpen: true,
          type: "error",
        },
        type: "ADD_MESSAGE",
      })
    );
    return false;
  };

  return (
    <FormControl sx={componentStyles.formContainer}>
      <Box sx={componentStyles.form}>
        <ContactField contactValue={value} contactType={type} />
        <ChannelSelector
          chosenChannels={contact.channelNames}
          contactKey={contact.key}
        />
        <PreferencesContainer contact={contact} />
      </Box>
      <PreferencesFooter
        contactsLength={contacts.length}
        contactKey={contact.key}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        validation={(skip: boolean) => validate(skip)}
        screenIsSmall={screenIsSmall}
      />
    </FormControl>
  );
};

export default PreferencesForm;
