import { makeStyles } from "@mui/styles";
import { colors } from "styles/colors";

export default makeStyles({
  title: {
    margin: "1em 0",
  },
  backButton: {
    paddingLeft: "0.5em",
  },
  switches: {
    margin: "1em 0",
  },
  addEditButton: {
    marginTop: "0.5em",
  },
  affectedAreasError: {
    color: colors.errorRed,
    fontSize: "0.9em",
    marginBottom: "0.5em",
  },
  affectedAreasTitle: {
    marginBottom: "0.5em",
  },
});
