import React, { FC } from "react";
import {
  Divider,
  Icon,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Theme,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import componentStyles from "./styles";

/**
 * Function which takes in a string array, and returns a new line separated component array of JSX elements
 * @param stringArr Array of string
 * @returns Tab separated strings
 */
export const displayOnSeparateLines = (
  stringArr: string[],
  classes: SxProps<Theme>
): JSX.Element[] => {
  const returnStr = stringArr.map((str, index) => (
    <Typography key={`${str}${index}`} variant="h6" sx={classes}>
      {str}
    </Typography>
  ));
  return returnStr;
};

export type Props = {
  headerInfo?: string;
  mainHeader: string | string[];
  subHeader?: string;
  icon?: number;
  classes?: SxProps;
};

const SummaryCard: FC<Props> = ({
  headerInfo,
  mainHeader,
  subHeader,
  icon,
  classes,
}: Props) => {
  return (
    <Box sx={classes}>
      <ListItem>
        <ListItemIcon>
          <Icon>{icon || "bookmark"}</Icon>
        </ListItemIcon>
        <ListItemText
          primary={
            <Box>
              <Typography variant="h6" sx={componentStyles.headerInfo}>
                {headerInfo}
              </Typography>
              <Typography variant="h6" sx={componentStyles.mainHeader}>
                {Array.isArray(mainHeader)
                  ? displayOnSeparateLines(
                      mainHeader,
                      componentStyles.separateLineString
                    )
                  : mainHeader}
              </Typography>
              <Typography variant="h6" sx={componentStyles.subHeader}>
                {subHeader}
              </Typography>
            </Box>
          }
        />
      </ListItem>
      <Divider />
    </Box>
  );
};

export default SummaryCard;
