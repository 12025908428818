import * as React from "react";
import { ActivityType } from "../../activityTypes";
import AppSelect, { SelectOption } from "sections/_global/components/AppSelect";
import styles from "./styles";
import { withStyles } from "@mui/styles";

export type Props = {
  handleChange: (e: any) => void;
  options: ActivityType[];
  selected: string;
  useFullWidth?: boolean;
  required?: boolean;
  classes: {
    menuPaper: string;
  };
};

const ActivityTypeSelect: React.FC<Props> = ({
  handleChange,
  options = [],
  selected,
  useFullWidth,
  required,
  classes,
}: Props) => {
  const menuOptions: SelectOption[] =
    options.map((type) => ({
      value: type.id,
      label: type.displayName,
    })) ?? [];

  return (
    <AppSelect
      options={menuOptions}
      required={required}
      label="Activity Type"
      value={selected}
      onChange={handleChange}
      useFullWidth={useFullWidth}
      classes={classes.menuPaper}
    />
  );
};

export default withStyles(styles)(ActivityTypeSelect);
