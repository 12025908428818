import { colors } from "styles/colors";

export default {
  backdrop: {
    zIndex: 10000,
  },
  progressIndicator: {
    color: colors.brandGreen,
  },
};
