import React, {
  FC,
  useContext,
  createContext,
  useReducer,
  ReactNode,
} from "react";
import { Contact } from "../../types";
import contactReducer from "./reducer";

const initialValues = {
  contacts: [
    {
      key: "1",
      value: "",
      type: 1,
      subPreferences: [],
      channelNames: [],
      isBeingEdited: false,
    },
  ],
  externalRefTypeId: 1,
  externalRefId: "",
};

export type State = {
  contacts: Contact[];
  externalRefTypeId: number;
  externalRefId: string;
};

export type ContactAction =
  | { type: "REMOVE_CONTACT"; key: string }
  | { type: "ADD_CONTACT"; contact: Contact }
  | {
      type: "UPDATE_CONTACT";
      key: string;
      update: UpdateContact;
    }
  | { type: "SET_CONTACTS"; contacts: Contact[] }
  | { type: "EDIT_CONTACT"; key: string; edit: boolean }
  | { type: "EDIT_EXTERNAL_REF_TYPE_ID"; newValue: number }
  | { type: "EDIT_EXTERNAL_REF_VALUE"; newValue: string };

export type UpdateContact =
  | { type: "TEXT"; text: string }
  | { type: "TYPE"; text: string }
  | {
      type: "SUBPREFERENCES";
      action: UpdateSubPreference;
    }
  | { type: "CHANNELS"; channels: string[] };

export type UpdateSubPreference =
  | { type: "SELECT_ALL" }
  | {
      type: "SET_OUTER_CHECKBOX";
      columnIndex: number;
      rowIndex: number;
    }
  | {
      type: "SET_INNER_CHECKBOX";
      columnIndex: number;
      rowIndex: number;
      nestedIndex: number;
    };

type Dispatch = (action: ContactAction) => void;

const ContactStateContext = createContext<State | undefined>(initialValues);
const ContactDispatchContext = createContext<Dispatch | undefined>(undefined);

const useContactState = (): State => {
  const context = useContext(ContactStateContext);

  if (context === undefined)
    throw new Error("useContact must be used with ContactProvider");

  return context;
};

const useContactDispatch = (): Dispatch => {
  const context = useContext(ContactDispatchContext);

  if (context === undefined)
    throw new Error("useContact must be used with ContactProvider");

  return context;
};

type Props = {
  children: ReactNode;
  initialState?: State;
};

const useContact = (): [State, Dispatch] => {
  return [useContactState(), useContactDispatch()];
};

const ContactProvider: FC<Props> = ({ children, initialState }: Props) => {
  const [state, dispatch] = useReducer(
    contactReducer,
    initialState ?? initialValues
  );

  return (
    <ContactDispatchContext.Provider value={dispatch}>
      <ContactStateContext.Provider value={state}>
        {children}
      </ContactStateContext.Provider>
    </ContactDispatchContext.Provider>
  );
};

export { ContactProvider, useContactDispatch, useContactState, useContact };
