import React from "react";
import { Container, Drawer } from "@mui/material";

import Routes from "routes/Routes";
import NavMenuList from "sections/_global/components/NavMenuList";
import getNavListDefinitions from "sections/_global/components/NavMenuList/navListDefinitions";

type Props = {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
};

const Main: React.FC<Props> = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const navMenuDefinitions = getNavListDefinitions();

  return (
    <div>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        variant="temporary"
        onClose={toggleDrawer}
      >
        {
          <NavMenuList
            navigations={navMenuDefinitions}
            toggleDrawer={toggleDrawer}
          />
        }
      </Drawer>
      <Container sx={{ marginTop: "1.5em", marginBottom: "7em" }}>
        <Routes />
      </Container>
    </div>
  );
};

export default Main;
