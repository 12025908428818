import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import {
  RatePlan,
  RatePlanViewInfo,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import RcdGroupView from "sections/billing-model-rateplans/components/RcdGroupView";
import { getDisplayText } from "sections/billing-model-common/BillingModelUtilities";
import { colors } from "styles/colors";

export interface Props {
  summary: RatePlanViewInfo;
  details: RatePlan | null;
  selected?: boolean;
  loading?: boolean;
  onSelected: (ratePlan: RatePlanViewInfo | null) => void;
}

const RatePlanView: React.FC<Props> = ({
  summary,
  details,
  selected,
  loading,
  onSelected,
}: Props) => {
  const open = selected && !loading;
  const rootStyle = {
    borderWidth: selected ? "3px" : undefined,
    borderStyle: selected ? "solid" : undefined,
    borderColor: selected ? colors.brandGreen : undefined,
    "& h4": {
      fontSize: "1.6em",
      width: "100%",
    },
    "& h5": {
      fontSize: "1.4em",
      marginBottom: "0.3em",
    },
    "& h6": {
      fontSize: "1em",
      fontWeight: "550",
    },
    "&:hover": {
      color: !selected ? colors.white : colors.black,
      backgroundColor: !selected ? colors.brandGreenDark : colors.white,
    },
  };

  const renderDetails = () => {
    if (loading) return null;

    if (!selected || !details)
      return (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h5">Rate Plan Details not available.</Typography>
        </Box>
      );

    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="overline">
          Rate Plan Type -{" "}
          {getDisplayText(
            details.ratePlanType?.ratePlanTypeId,
            details.ratePlanType?.name,
            "No rate plan type."
          )}
        </Typography>
        <Typography variant="overline">Description</Typography>
        <Typography variant="body1">
          {getDisplayText(
            null,
            details.description,
            "No description available."
          )}
        </Typography>
        <br />
        <Typography variant="h5">RCD Groups</Typography>
        <Paper elevation={0} sx={{ backgroundColor: colors.gray12 }}>
          {details.ratingControlGroups
            ?.filter((rg) => rg.active)
            .map((rg, i) => (
              <RcdGroupView rcdGroup={rg} key={i} />
            ))}
        </Paper>
      </Box>
    );
  };

  return (
    <Accordion
      sx={{ rootStyle }}
      expanded={open}
      onChange={(event: any, expanded: boolean) => {
        const newSelection = !selected ? summary : null;
        if (onSelected) {
          onSelected(newSelection);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon>expand_more</Icon>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h4">
          {getDisplayText(summary.ratePlanId, summary.name, null)}
          {loading && selected && <LinearProgress />}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{renderDetails()}</AccordionDetails>
    </Accordion>
  );
};

export default RatePlanView;
