// import React, { useState } from "react";
import { getFields } from "./data";

export type PendingItemType = {
  serialNumber: string;
  pending: boolean;
  toBeRemoved: boolean;
  show: boolean;
  errorMessage?: string;
};

export type StateType = {
  error?: any;
  loading?: boolean | null;
  itemList: Record<string, any>;
  serialNumbers: string[];
  pendingList: PendingItemType[];
  notes: Record<string, any>[];
  currTechName?: string;
  currSubId?: string | null;
  currSourceID?: string | null;
  updatedSubscriberId?: string | null;
  currSubscriber?: string | null;
  currSubEmail?: any;
  emailMessage?: string;
  returnData: Record<string, any>[];
  ancillaryReturns: Record<string, any>;
  addendum?: string;
  useSubInv?: boolean;
  resourceID?: string;
  techName?: string;
  marketID?: string;
  market?: string;
  sourceType: string;
  forcePanelClose: boolean;
};

export type EquipmentType = {
  serialNumber: string;
  category: string;
  partNumber: string;
  subInventory: string;
  techName?: string;
  resourceID?: string;
  location: string;
  sourceType: string;
  currentStatus: number;
  isAssociatedToSubscriber: boolean;
  marketID?: string;
  market?: string;
  email?: string;
  subscriber?: string;
};

export const initState: StateType = {
  error: null,
  loading: null,
  itemList: {},
  serialNumbers: [],
  pendingList: [],
  notes: [],
  currTechName: "",
  currSubId: null, // Tracks last value for !allowMultipleSubscribers
  currSourceID: null, // Tracks last value for !allowMultipleSubscribers
  updatedSubscriberId: null, // Tracks last value for !allowMultipleSubscribers
  currSubscriber: null,
  currSubEmail: "",
  emailMessage: "",
  returnData: [],
  ancillaryReturns: {},
  addendum: "",
  useSubInv: false,
  resourceID: "",
  techName: "",
  marketID: "",
  market: "",
  sourceType: "",
  forcePanelClose: false,
};

export const prepareData = (list: any[]): Record<string, any> => {
  const itemListGroupedBySubId: any = groupBy(list, "subInventory");
  const fields = getFields("ASSIGN");
  return Object.keys(itemListGroupedBySubId).reduce(
    (acc: any, subId: string) => {
      const subscriberGroupedItems = _getSubList(
        itemListGroupedBySubId[subId],
        fields
      );
      const categoryGroupedItems = listToGroups(
        subscriberGroupedItems,
        "category"
      );
      const itemsWithSummary = _addSummaryData(categoryGroupedItems) || [];
      acc[subId] = { categories: itemsWithSummary, itemFields: fields.item };
      return acc;
    },
    {}
  );
};

const _getSubList = (subIdListGroup: any[], fields: any) =>
  subIdListGroup
    .filter((v) => v)
    .map((item) => {
      const itemKeys = Object.keys(item);
      itemKeys.forEach((k) => {
        const hItem = fields.item.find((item: any) => item.name === k);
        if (!hItem) {
          delete item[k];
        }
      });
      fields.item.forEach((v: any) => {
        if (itemKeys.indexOf(v.name) === -1) {
          item[v.name] = null;
        }
      });
      return item;
    });

const _addSummaryData = (groups: any[]) =>
  groups.map((group) => {
    const fields = getFields("ASSIGN");
    const goodCount = group.items.filter((v: any) => v.isAvailable).length;
    const badCount = group.items.filter((v: any) => !v.isAvailable).length;
    group.summaryData = {
      count: [
        { icon: "check_circle", value: goodCount },
        { icon: "warning", value: badCount },
      ],
    };
    fields.group.forEach((v: any) => {
      if (group[v]) {
        group.summaryData[v] = group[v];
        delete group[v];
      }
    });
    return group;
  });

export const groupBy = (
  list: Record<string, any>[],
  key: string
): Record<string, unknown> =>
  list.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const objectToArray = (obj: Record<string, unknown>): any[] =>
  Object.keys(obj).map((k) => obj[k]);

export const isObjectEmpty = (obj: Record<string, unknown>): boolean =>
  Boolean(Object.keys(obj)[0]);

export const listToGroups = (
  list: Record<string, any>[],
  key = "name"
): any[] => {
  if (!list?.length) return [];
  const grouped = list.reduce((result: any[], item) => {
    if (!item) return result;
    const foundItem = result.find((itm: any) => itm[key] === item[key]);
    if (!foundItem) {
      const newItem = {
        [key]: item[key],
        items: [item],
      };
      result.push(newItem);
      return result;
    }
    foundItem.items.push(item);
    return result;
  }, []);
  return grouped;
};

// Extract the categories from the data
export const flattenItemList = (subscribers: Record<string, any>): any[] => {
  const result = Object.keys(subscribers).reduce((items: any[], k: string) => {
    const categories = subscribers[k].categories || [];
    return [...items, ...flattenCategories(categories)];
  }, []);
  return result;
};

// Returns all items from each category as a single array
export const flattenCategories = (
  categories: Record<string, any>
): Record<string, any>[] => {
  const result = categories
    .reduce(
      (items: any[], category: { items?: any } = {}) => [
        ...items,
        ...category.items,
      ],
      []
    )
    .filter((v: any) => v);
  return result;
};

export const utils = {
  // addNote: (notes: Record<string, any>[], subId: string, state: StateType): Record<string, any>[] => {
  //   const newNotes = [...state.notes];
  //   const found = newNotes.find((v) => v.subId === subId);
  //   if (found) {
  //     found.value = notes;
  //   } else newNotes.push({ value: notes, subId });
  //   return newNotes;
  // },
  // clearEmailMessage: () => setState({ emailMessage: "" }),
  // resetFlash: () => {
  //   if (state.updatedSubscriberId) {
  //     setState({ updatedSubscriberId: null })
  //   }
  // },
  // submitEmail: async filePath => {
  //   // this.setStoreMessages('postEmailSubmit');
  //   try {
  //     const { currSubEmail, emailMessage } = state;
  //     await this.service(
  //       PostEquipmentEmailSubmit,
  //       [
  //         {
  //           currSubEmail,
  //           emailMessage,
  //           addendum: filePath,
  //         },
  //       ],
  //       {
  //         setState: true,
  //         showSuccess: false,
  //       }
  //     );
  //     MessageStore.removeAddendum(filePath);
  //     // this.setStoreMessages(null);
  //   } catch (err) {
  //     this.setStoreMessages(null);
  //     this.log(err);
  //   }
  // },
  // submitReturn: async (marketId, appName = 'TRUCK') => {
  //   const messageKey = appName === 'MOVE' ? 'postMoveEquipment' : 'postReturnEquipment';
  //   this.setStoreMessages(messageKey, appName);
  //   const returnType = `${appEnum[appName].name.toLowerCase()}_${appEnum[
  //     appName
  //   ].group.name.toLowerCase()}`;
  //   try {
  //     const serialNumbers = this.getSerials();
  //     const { itemList, ancillaryReturns, notes, currSubscriber, currSubId } = state;
  //     const res = await this.service(
  //       PostEquipmentReturn,
  //       [
  //         {
  //           marketId,
  //           serialNumbers,
  //           itemList,
  //           ancillaryReturns,
  //           notes: appName === 'TRUCK' ? notes[0] ?? '' : notes,
  //           returnType,
  //           subscriber: currSubscriber,
  //           subId: currSubId,
  //           appName, // TODO: remove when refactored to a single runmode
  //         },
  //       ],
  //       {
  //         setState: true,
  //         showSuccess: true,
  //       }
  //     );
  //     this.removeItem(serialNumbers, appName)();
  //     setState({
  //       returnData: res.data,
  //       currSubId: null,
  //       currSourceID: null,
  //       notes: appName === 'TRUCK' ? '' : [],
  //       market: appName === 'MOVE' ? res.data[0]?.equipment?.market : state.market,
  //     });
  //     this.setStoreMessages(null);
  //   } catch (err) {
  //     this.setStoreMessages(null);
  //     this.log(err);
  //     setState({ currSubId: null, currSourceID: null }),
  //   }
  // },
};
