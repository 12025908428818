import React, { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Card, CardContent, Link } from "@mui/material";
import { Typography } from "@mui/material";
import { LinkType } from "routes/routeDefinitions";
import LinkCardIcon from "../LinkCardIcon";
import componentStyles from "./styles";

export type Props = {
  title: string;
  icon?: React.ReactElement | string;
  url: string;
  active?: boolean;
  linkType: LinkType;
  titleProps?: {
    fontSize?: string;
  };
};

const LinkCard: FC<Props> = ({
  title,
  icon,
  url,
  active = true,
  linkType,
  titleProps,
}: Props) => {
  const [hovered, setHovered] = useState(false);

  const renderCard = () => (
    <Card
      sx={{
        ...componentStyles.card,
        ...(!active && componentStyles.disabledCard),
      }}
      aria-disabled={!active}
      raised={hovered}
      onMouseEnter={() => setHovered(active)}
      onMouseLeave={() => setHovered(false)}
    >
      <CardContent>
        <Grid container direction={"column"} alignItems="center">
          {icon && (
            <Grid item>
              <LinkCardIcon disabled={!active} icon={icon} />
            </Grid>
          )}
          <Grid item>
            <Typography
              sx={{
                ...(active
                  ? componentStyles.activeLabel
                  : componentStyles.disabledLabel),
              }}
              fontSize={titleProps?.fontSize}
              variant="h5"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return active ? (
    <Link
      to={linkType == "external" ? { pathname: url } : url}
      underline="none"
      component={RouterLink}
      target={linkType == "external" ? "_blank" : undefined}
      rel="noopener noreferrer"
    >
      {renderCard()}
    </Link>
  ) : (
    renderCard()
  );
};

export default LinkCard;
