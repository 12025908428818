export default {
  expansionPanel: {
    background: "none",
    boxShadow: "none",
    borderBottom: "1px solid lightgrey",
  },
  expansionPanelDetails: {
    marginLeft: "2em",
    flexDirection: "column" as const,
  },
  lcp: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
    "&:first-child": {
      marginTop: 0,
    },
    display: "flex",
  },
};
