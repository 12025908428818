import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox, Select } from "@mui/material";
import styles from "./styles";
import { withStyles } from "@mui/styles";

import {
  getSubscriberData_metasolvNetworkInventory_MetasolvOntSuccess_inventory as MetasolvInventory,
  getSubscriberData_billingServices_BillingServicesSuccess_services as Service,
} from "../../../__generated__/getSubscriberData";

export type Props = {
  equipment?: MetasolvInventory[];
  services?: Service[];
  selected?: string[];
  handleChange: (e: any) => void;
};

function equipmentToLabel(equipment: string) {
  const ontString = "ONT - Equipment (" + equipment + ")";
  return ontString;
}

const Services: React.FC<Props> = ({
  equipment = [],
  services = [],
  selected = [],
  handleChange,
}: Props) => {
  const allServices = services.map(function (item) {
    return item["id"].toString();
  });

  const allEquipment = equipment
    .filter((entry) => {
      return entry.serialNumber !== null;
    })
    .map(function (entry) {
      return entry["serialNumber"] as string;
    });

  const options = allServices.concat(allEquipment);

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="select-services-label" color="secondary">
          Services Included In Activity
        </InputLabel>
        <Select
          labelId="select-services-label"
          id="select-services"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Services Included In Activity" />}
          renderValue={(selected) => selected.join(", ")}
          color="secondary"
        >
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
            <ListItemText primary="Check All Services" />
          </MenuItem>
          {services.map((service) => (
            <MenuItem key={"" + service.id} value={"" + service.id}>
              <Checkbox checked={selected.indexOf("" + service.id) > -1} />
              <ListItemText
                primary={`${service.id} - ${service.package.name} (${service.connection.number})`}
              />
            </MenuItem>
          ))}
          {allEquipment.map((entry) => (
            <MenuItem key={entry} value={entry}>
              <Checkbox checked={selected.indexOf(entry) > -1} />
              <ListItemText primary={equipmentToLabel(entry)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(Services);
