import * as React from "react";
import styles from "./styles";
import { withStyles } from "@mui/styles";
import AppSelect, { SelectOption } from "sections/_global/components/AppSelect";

export type Props = {
  handleChange: (e: any) => void;
  options: number[];
  selected: string;
  useFullWidth?: boolean;
  required?: boolean;
  classes: {
    menuPaper: string;
  };
};

function displayTime(num: number) {
  const hours = num / 60;
  const displayHours = Math.floor(hours);
  const minutes = (hours - displayHours) * 60;
  const displayMinutes = Math.round(minutes);
  return (
    displayHours.toString().padStart(2, "0") +
    ":" +
    displayMinutes.toString().padStart(2, "0")
  );
}

const ActivityDuration: React.FC<Props> = ({
  handleChange,
  options,
  selected,
  required,
  classes,
}: Props) => {
  const menuOptions: SelectOption[] =
    options.map((duration) => ({
      value: duration,
      label: displayTime(duration),
    })) ?? [];

  return (
    <AppSelect
      options={menuOptions}
      required={required}
      label="Duration"
      value={selected}
      onChange={handleChange}
      useFullWidth
      classes={classes.menuPaper}
    />
  );
};

export default withStyles(styles)(ActivityDuration);
