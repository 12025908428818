import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import { withStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import CollapsibleNavMenuItem from "./CollapsibleNavMenuItem";
import styles from "./styles";
import { navList } from "./navListDefinitions";

const getRootUrl = (url: string): string => {
  return url;
};

export type Props = {
  classes: {
    list: string;
  };
  navigations: navList[];
  toggleDrawer: () => void;
};

const NavMenuList: React.FC<Props> = ({
  classes,
  toggleDrawer,
  navigations,
}: Props) => {
  const location = useLocation();

  const groups = navigations.reduce((acc, val) => {
    const group = val.name.split(";")[0];
    return acc.includes(group) ? acc : [...acc, group];
  }, [] as string[]);

  return (
    <List classes={{ root: classes.list }}>
      {location.pathname !== "/" && (
        <>
          <ListItem button component={Link} to="/" onClick={toggleDrawer}>
            <ListItemIcon>
              <Icon>arrow_back</Icon>
            </ListItemIcon>
            <ListItemText>Back to Dashboard</ListItemText>
          </ListItem>
          <Divider />
        </>
      )}
      {groups.map((group) => {
        const items = navigations.filter(
          (nav) => nav.name.split(";")[0] === group
        );
        return (
          <CollapsibleNavMenuItem
            key={group}
            toggleDrawer={toggleDrawer}
            title={group}
            items={items}
            isActive={
              getRootUrl(items?.[0]?.url) === getRootUrl(location.pathname)
            }
          />
        );
      })}
    </List>
  );
};

const StyledComponent = withStyles(styles)(NavMenuList);

export { NavMenuList };

export default StyledComponent;
