import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import styles from "./styles";

const useStyles = makeStyles(styles);

export interface Props {
  label: string;
  isChecked?: boolean;
  isDefaultChecked?: boolean;
  onClick?: () => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const Toggle: React.FC<Props> = ({
  label,
  isChecked,
  isDefaultChecked,
  onClick,
  onChange,
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.display}>
      <FormControlLabel
        control={
          <Switch
            onClick={onClick}
            onChange={onChange}
            checked={isChecked}
            defaultChecked={isDefaultChecked}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default Toggle;
