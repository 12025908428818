import { createTheme } from "@mui/material/styles";

import { colors } from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.brandBlue,
    },
    secondary: {
      main: colors.brandGreen,
    },
    error: {
      main: colors.errorRed,
    },
    warning: {
      light: colors.warningOrange,
      main: colors.warningOrange,
      dark: colors.warningOrange,
    },
    info: {
      light: colors.brandBlue,
      main: colors.brandBlue,
      dark: colors.brandBlue,
    },
    success: {
      contrastText: colors.white,
      light: colors.brandGreen,
      main: colors.brandGreen,
      dark: colors.brandGreen,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        position: "static",
      },
      styleOverrides: {
        colorPrimary: colors.brandBlueDark,
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.brandBlueDark,
          color: colors.white,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        color: "secondary",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          position: "fixed" as const,
          right: "2em",
          bottom: "2em",
          color: "white",
          background: colors.brandGreen,
          "&:hover": {
            background: colors.brandGreenDark,
          },
        },
      },
    },
  },
});
export default theme;
