import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import BackButton from "sections/_global/components/BackButton";
// import LinkCards from "sections/_global/components/LinkCards";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import { LinkType } from "routes/routeDefinitions";
import styles from "./styles";

export type CardType = {
  title: string;
  icon?: React.ReactElement | string;
  url: string;
  active?: boolean;
  linkType: LinkType;
};

export type SectionType = {
  name: string;
  cards: CardType[];
};

export type Props = {
  classes: {
    title: string;
    subTitle: string;
    section: string;
    group: string;
  };
  title: string;
  cards?: CardType[];
  sections?: SectionType[];
};

const Overview: FC<Props> = ({ classes, title, cards, sections }: Props) => (
  <>
    <BackButton url="/" />
    <Typography
      marginTop="0.5em"
      marginBottom="1em"
      variant="h4"
      className={classes.title}
    >
      {title}
    </Typography>
    {sections && (
      <div className={classes.group}>
        {sections?.map((section, i) => (
          <div key={i} className={classes.section}>
            <Typography variant="h5" className={classes.subTitle}>
              {section.name}
            </Typography>
            <LinkCardsProvider cards={section.cards} />
          </div>
        ))}
      </div>
    )}
    {cards && <LinkCardsProvider cards={cards} />}
  </>
);

const StyledComponent = withStyles(styles)(Overview);

export { Overview };

export default StyledComponent;
