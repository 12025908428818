import React from "react";
import { useMutation } from "@apollo/client";

import AddAffectedAreaDialog from "../AddAffectedAreaDialog";
import { UpdateOutageInput } from "../../../../../__generated__/globalTypes";
import { AffectedArea, Outage, LCP } from "../../ServiceDisruptionTypes";
import {
  UpdateOutage,
  UpdateOutageVariables,
} from "../../__generated__/UpdateOutage";
import { MUTATIONS } from "../../ServiceDisruptionQueries";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  initialAffectedAreas?: AffectedArea[];
  outage: Outage;
};

const EditOutageAffectedAreaDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  initialAffectedAreas,
  outage,
}: Props) => {
  const [updateOutage, { loading }] = useMutation<
    UpdateOutage,
    UpdateOutageVariables
  >(MUTATIONS.updateOutage);

  const saveOutage = async (selectedLCPs: LCP[]) => {
    const affectedAreas = [];
    for (const lcp of selectedLCPs) {
      affectedAreas.push({
        city: lcp.city,
        state: lcp.state,
        lcp: lcp.name,
      });
    }

    const updatedOutage: UpdateOutageInput = {
      id: outage.id,
      startAt: outage.startAt,
      endAt: outage.endAt,
      description: outage.description,
      affectedAreas,
      serviceType: outage.serviceType,
      status: outage.status as string,
      affectedResidentialCustomers: outage.affectedResidentialCustomers,
      affectedBusinessCustomers: outage.affectedBusinessCustomers,
    };

    await updateOutage({
      variables: {
        outage: updatedOutage,
      },
    });
  };

  return (
    <div>
      <AddAffectedAreaDialog
        loading={loading}
        isOpen={isOpen}
        initialAffectedAreas={initialAffectedAreas}
        save={saveOutage}
        onClose={onClose}
      />
    </div>
  );
};

export default EditOutageAffectedAreaDialog;
