import React, { Dispatch, FC, SetStateAction } from "react";
import { styled } from "@mui/styles";
import { Grid } from "@mui/material";
import NextOrFinishButton from "sections/communication-preference/components/buttons/NextOrFinishButton";
import PreviousButton from "sections/communication-preference/components/buttons/PreviousButton";
import SkipButton from "sections/communication-preference/components/buttons/SkipButton";
import StepsFooter from "sections/communication-preference/components/StepsFooter/StepsFooter";
import { useContactDispatch } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import componentStyles from "./styles";

const StyledSkipButton = styled(SkipButton)(({ theme }) => ({
  marginRight: "1rem",
}));

type Props = {
  contactsLength: number;
  contactKey: string;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  /**
   * The validation prop is important, before the user can move onto the next page, they must provide a condition such as all the inputs being filled in
   */
  validation: (skip: boolean) => boolean;
  screenIsSmall: boolean;
};

const PreferencesFooter: FC<Props> = ({
  contactsLength,
  currentStep,
  setCurrentStep,
  validation,
  contactKey,
  screenIsSmall,
}: Props) => {
  const contactDispatch = useContactDispatch();

  const closePreferencesTable = () => {
    contactDispatch({ type: "EDIT_CONTACT", edit: false, key: contactKey });
    return true;
  };

  return (
    <Grid container sx={componentStyles.container}>
      <Grid item xs={6} sm={4}>
        <PreviousButton
          onClick={() =>
            validation(false) &&
            closePreferencesTable() &&
            setCurrentStep((oldStep) => oldStep - 1)
          }
        />
      </Grid>
      {!screenIsSmall && (
        <Grid item sm={4}>
          <StepsFooter currentStep={currentStep} totalSteps={contactsLength} />
        </Grid>
      )}
      <Grid item sx={componentStyles.navigation} xs={6} sm={4}>
        <StyledSkipButton
          onClick={() =>
            validation(true) &&
            closePreferencesTable() &&
            setCurrentStep(contactsLength + 2)
          }
        />
        <NextOrFinishButton
          next={currentStep !== contactsLength + 2}
          onClick={() =>
            validation(false) &&
            closePreferencesTable() &&
            setCurrentStep((oldStep) => oldStep + 1)
          }
        />
      </Grid>
    </Grid>
  );
};

export default PreferencesFooter;
