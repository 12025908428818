export default {
  contactFieldContainer: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "95%", sm: "100%" },
    margin: { xs: "1rem 0 1rem 0", sm: "1rem 0 2rem 0" },
  },
  textField: {
    width: { xs: "95%", sm: "75%" },
  },
} as const;
