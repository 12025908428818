import React, { FC } from "react";
import { LinearProgress, Typography } from "@mui/material";
import componentStyles from "./styles";
import { Box } from "@mui/system";

type Props = {
  currentStep: number;
  headerText: string;
  maxSteps: number;
};

const getHeaderText = (
  steps: number,
  maxSteps: number,
  headerText: string
): string => {
  switch (steps) {
    case 1:
      return `${headerText} Contact`;
    case maxSteps:
      return "Summary";
    default:
      return `${headerText} Preferences`;
  }
};

const ContactHeader: FC<Props> = ({
  currentStep,
  maxSteps,
  headerText,
}: Props) => {
  const increment = 100 / maxSteps;
  const header = getHeaderText(currentStep, maxSteps, headerText);
  return (
    <Box sx={componentStyles.cardHeader}>
      <Typography sx={componentStyles.cardHeaderTitle} variant="h4">
        {header}
      </Typography>
      <LinearProgress
        variant="determinate"
        title="Add Contact"
        value={currentStep * increment}
        sx={componentStyles.progressBar}
        color="secondary"
      />
    </Box>
  );
};

export default ContactHeader;
