import { makeStyles } from "@mui/styles";
import { colors } from "styles/colors";

export default makeStyles({
  imageContainer: {
    margin: "1em 0",
    textAlign: "center" as const,
  },
  image: {
    width: "100%",
    border: `1px solid ${colors.borderGray}`,
  },
});
