import React from "react";
import { Switch, Route, Link } from "react-router-dom";

import { useUserState } from "sections/_global/contexts/UserContext";
import getRoutes, { Route as RouteType } from "routes/routeDefinitions";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { userCan } from "lib/Authorization";
import PermissionDenied from "sections/permission-denied/screens/PermissionDenied";

const getRouteComponent = ({
  route,
  user,
}: {
  route: RouteType;
  user?: any;
}) => {
  if (
    route.rbacAction &&
    user &&
    !userCan({ user, performAction: route.rbacAction })
  ) {
    return PermissionDenied;
  }

  return route.component;
};

const Routes: React.FC = () => {
  const user = useUserState();
  const routesList = getRoutes().map((route) =>
    route.linkType == "internal" ? (
      <Route
        key={route.url}
        exact
        path={route.url}
        component={getRouteComponent({ route, user })}
      />
    ) : (
      <Link
        key={route.url}
        to={{ pathname: route.url }}
        target="_blank"
        rel="noopener noreferrer"
      />
    )
  );

  if (user?.error) {
    return null;
  } else if (user?.needsAuthVerification || !user.username) {
    return <LoadingBackdrop isOpen={true} />;
  }

  return <Switch>{routesList}</Switch>;
};

export default Routes;
