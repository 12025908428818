import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import LightLevelsLegend from "../LightLevelsLegend/LightLevelsLegend";
import LightLevelsEntry from "../LightLevelsEntry/LightLevelsEntry";
import { GetLightLevel } from "../../__generated__/GetLightLevel";

const useStyles = makeStyles(styles);

export interface Props {
  data?: GetLightLevel | null;
}

const LightLevelsCard: FC<Props> = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <>
      {data?.lightLevel ? (
        <>
          <Card className={classes.mt_1em}>
            <CardContent>
              <LightLevelsLegend />
              <Grid container direction="column" spacing={3}>
                <LightLevelsEntry
                  name="ONT Rx"
                  lightLevel={data.lightLevel.ontRxLightLevel}
                  status={data.lightLevel.ontLightLevelStatus}
                />
                <LightLevelsEntry
                  name="OLT Rx"
                  lightLevel={data.lightLevel.oltRxLightLevel}
                  status={data.lightLevel.oltLightLevelStatus}
                />
              </Grid>
            </CardContent>
          </Card>
          <Grid container className={classes.mt_1em}>
            <Typography color="textSecondary" variant="h6">
              Platform: {data.lightLevel.platform ?? "Not found"}
            </Typography>
          </Grid>
        </>
      ) : (
        <Card className={classes.mt_1em}>
          <CardContent>
            <Typography className={classes.centerText}>
              No data to display
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default LightLevelsCard;
