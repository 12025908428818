import React from "react";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import UserIcon from "../../../_global/components/UserIcon";
import { colors } from "../../../../styles/colors";

const styles = {
  root: {
    padding: "2em 3em",
    width: "100%",
  },
  userIcon: {
    width: "5em",
    height: "5em",
  },
  userIconInitials: {
    fontSize: "2em",
  },
  userIconGrid: {
    marginBottom: "3em",
  },
  input: {
    background: colors.gray12,
  },
  textField: {
    width: "100%",
  },
};

export type Props = {
  classes: {
    root: string;
    userIcon: string;
    userIconInitials: string;
    userIconGrid: string;
    textField: string;
    input: string;
  };
  displayName?: string | null;
  email?: string | null;
};

const ProfilePanelContent: React.FC<Props> = ({
  classes,
  displayName,
  email,
}: Props) => {
  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12} className={classes.userIconGrid}>
        <UserIcon
          styles={{
            root: classes.userIcon,
            initials: classes.userIconInitials,
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          variant="outlined"
          value={displayName}
          label="Display Name"
          InputProps={{ readOnly: true, classes: { root: classes.input } }}
          className={classes.textField}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          variant="outlined"
          value={email}
          label="Email"
          InputProps={{ readOnly: true, classes: { root: classes.input } }}
          classes={{ root: classes.textField }}
          className={classes.textField}
        />
      </Grid>
    </Grid>
  );
};

const StyledComponent = withStyles(styles)(ProfilePanelContent);

export { ProfilePanelContent };

export default StyledComponent;
