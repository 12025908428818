import { createStyles } from "@mui/styles";

import { colors } from "styles/colors";
import { luminance, saturate } from "styles/colors";

export default createStyles({
  icon: {
    fontSize: "5rem",
    background: luminance(colors.brandBlue, -0.2),
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    color: "transparent",
    fontWeight: "bold",
    textShadow: "0.5px 0.5px 0 rgba(255, 255, 255, 0.7)",
  },

  mainTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "0.2rem",
    textTransform: "uppercase",
    fontSize: "1rem",
    color: saturate(luminance(colors.brandBlue, 0.1), 0.3),
    fontWeight: "bold",
    textShadow: "0.5px 0.5px 0 hsl(0deg 0% 100% / 70%)",
  },
});
