import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

import styles from "./styles";

import { getSubscriberData_ofscActivitiesBySubId_OfscActivitiesSuccess_activities as Activity } from "../../../__generated__/getSubscriberData";

type ActivityType = {
  id: string;
  displayName: string;
};

export type Props = {
  classes: {
    formControl: string;
  };
  handleChange: (e: any) => void;
  selected: string;
  options?: Activity[];
  activityTypes: ActivityType[];
};

const ExistingActivities: React.FC<Props> = ({
  classes,
  handleChange,
  selected,
  options = [],
  activityTypes = [],
}: Props) => {
  return (
    <FormControl classes={{ root: classes.formControl }}>
      {options.length == 0 ? (
        <></>
      ) : (
        <>
          <TextField
            select
            id="existing-activities"
            value={selected}
            sx={{ minWidth: 200 }}
            onChange={handleChange}
            label="Scheduled Activities"
          >
            <MenuItem key="" value="">
              <em>None</em>
            </MenuItem>
            {options
              .filter(function (activity) {
                return (
                  activityTypes.filter(function (activityType) {
                    return activityType.id == activity.activityType;
                  }).length > 0
                );
              })
              .map((activity) => (
                <MenuItem
                  key={activity.ofscActivityId?.toString()}
                  value={activity.ofscActivityId?.toString()}
                >
                  <em>{`${activity.ofscActivityId} (${
                    activity.activityType
                  }) - ${
                    activity.date == null ? "Unscheduled" : activity.date
                  } ${activity.timeSlot} ${
                    activity.ticket == null
                      ? ""
                      : `(${activity.ticket.ticketType} # ${activity.ticket.ticketNumber})`
                  }`}</em>
                </MenuItem>
              ))}
          </TextField>
        </>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(ExistingActivities);
