import React from "react";
import { withStyles } from "@mui/styles";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import PageHeader from "sections/_global/components/PageHeader";
import styles from "./styles";

type Props = {
  classes: {
    title: string;
    button: string;
  };
};

const Overview: React.FC<Props> = ({ classes }: Props) => {
  return (
    <div>
      <PageHeader title="Service Disruptions" url="/" />
      <LinkCardsProvider
        cards={[
          {
            title: "Outages",
            icon: "warning",
            url: "/service-disruptions/outages",
            rbacAction: "outages:get",
            linkType: "internal",
          },
          {
            title: "Maintenances",
            icon: "event",
            url: "/service-disruptions/maintenances",
            rbacAction: "maintenances:get",
            linkType: "internal",
          },
        ]}
      />
    </div>
  );
};

export default withStyles(styles)(Overview);
