import { makeStyles } from "@mui/styles";
import * as colors from "styles/colors";

const styles = makeStyles({
  root: {
    position: "relative" as const,
  },
  backdrop: {
    zIndex: 10000,
  },
  progressIndicator: {
    color: colors.colors.brandGreen,
  },
});

export default styles;
