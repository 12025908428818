export default {
  subPreferencesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: {
      xs: "center",
      sm: "inherit",
    },
  },
  tableHeader: {
    marginTop: "1rem",
    width: "100%",
    textAlign: {
      xs: "center",
      sm: "inherit",
    },
  },
  fullWidth: { width: "100%" },
} as const;
