import { useQuery } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { BillingModelSearch } from "sections/billing-model-common/BillingModelSearch";
import {
  BamSortBy,
  BamSortOrder,
} from "sections/billing-model-common/BillingModelTypes";
import {
  RatePlan,
  RatePlanViewInfo,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import {
  BillingModelRatePlan,
  BillingModelRatePlanVariables,
} from "sections/billing-model-rateplans/__generated__/BillingModelRatePlan";
import {
  BillingModelRatePlansList,
  BillingModelRatePlansListVariables,
} from "sections/billing-model-rateplans/__generated__/BillingModelRatePlansList";
import { QUERIES } from "sections/billing-model-rateplans/BillingModelRatePlanQueries";
import SelectRatePlan from "./SelectRatePlan";

export interface Props {
  selectedRatePlan: RatePlan | null;
  onChange: (ratePlan: RatePlan | null) => void;
}

const SelectRatePlanProvider = ({
  selectedRatePlan,
  onChange,
}: Props): JSX.Element => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [ratePlans, setRatePlans] = useState<RatePlanViewInfo[]>([]);
  const [
    ratePlanDetails,
    setRatePlanDetails,
  ] = useState<RatePlanViewInfo | null>(null);

  const [ratePlanQuery, setRatePlanQuery] = useState<
    BillingModelRatePlansListVariables
  >({
    input: {
      search: undefined,
      sortBy: BamSortBy.name,
      sortOrder: BamSortOrder.ascending,
      pageSize: 10,
      page: 1,
    },
  });

  const { loading } = useQuery<
    BillingModelRatePlansList,
    BillingModelRatePlansListVariables
  >(QUERIES.getRatePlansList, {
    variables: { input: ratePlanQuery.input },
    onCompleted: (data) => {
      setRatePlans(data.bamRatePlans?.data ?? []);
      setTotalPages(data.bamRatePlans?.pagination?.totalPages ?? 1);
      onChange(null);
    },
  });

  const { loading: loadingDetails, refetch: getDetails } = useQuery<
    BillingModelRatePlan,
    BillingModelRatePlanVariables
  >(QUERIES.getRatePlan, {
    skip: !ratePlanDetails || ratePlanDetails.ratePlanId === "",
    fetchPolicy: "no-cache",
    variables: { id: ratePlanDetails?.ratePlanId ?? "" },
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setRatePlanQuery({ input: { ...ratePlanQuery.input, page: page } });
    },
    [ratePlanQuery]
  );

  const getSortBy = (sort: string | undefined) => {
    if (sort === "Name") return BamSortBy.name;
    if (sort === "Newest") return BamSortBy.createdAt;
    if (sort === "Recent Changes") return BamSortBy.modifiedAt;
    return undefined;
  };

  const handleSearch = useCallback((s: BillingModelSearch) => {
    setRatePlanQuery((rq) => {
      const request: BillingModelRatePlansListVariables = {
        ...rq,
        input: {
          ...rq.input,
          search: s.terms,
          sortBy: getSortBy(s.sort) ?? BamSortBy.name,
          sortOrder: BamSortOrder.ascending,
        },
      };

      return request;
    });
  }, []);

  const handleSelection = async (rps: RatePlanViewInfo | null) => {
    setRatePlanDetails(rps);
    if (rps) {
      const response = await getDetails({ id: rps.ratePlanId });
      if (response && response.data && response.data.bamRatePlan)
        onChange(response.data.bamRatePlan as RatePlan);
      else onChange(null);
    }
  };

  return (
    <SelectRatePlan
      ratePlans={ratePlans}
      selectedRatePlan={selectedRatePlan}
      ratePlanDetails={ratePlanDetails}
      page={ratePlanQuery.input.page}
      totalPages={totalPages}
      isLoadingList={loading}
      isLoadingDetails={loadingDetails}
      onPageChange={handlePageChange}
      onRatePlanSelected={handleSelection}
      onSearch={handleSearch}
    />
  );
};

export default SelectRatePlanProvider;
