import React, { FC } from "react";
import PageHeader from "sections/_global/components/PageHeader";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";

const Overview: FC = () => {
  return (
    <div>
      <PageHeader url="/" title={"Provisioning Tools"} />
      <LinkCardsProvider
        cards={[
          {
            title: "Light Level Lookup",
            icon: "bolt",
            url: "/provisioning-tools/light-levels-lookup",
            rbacAction: "outages:get",
            linkType: "internal",
          },
          {
            title: "Provisioning Details",
            icon: "apps",
            url: "/provisioning-tools/provisioning-details",
            linkType: "internal",
          },
        ]}
      />
    </div>
  );
};

export default Overview;
