import { MockData } from "../../../types";

const mockData: MockData = {
  Outage: {
    subPreferences: [
      {
        channelName: "Outage",
        channelDescription: "List of channel options related to outage",
        channelId: 1,
        channelOptions: [
          {
            channelOptionId: 1,
            optionName: "Severity",
            optionIcon: "flash_on",
            checked: true,
            subOptions: [
              {
                optionTypeValueId: 1,
                checked: true,
                subOptionName: "Level 1",
                subOptionIcon: "exposure",
              },
              {
                optionTypeValueId: 2,
                checked: true,
                subOptionName: "Level 2",
                subOptionIcon: "exposure2",
              },
              {
                optionTypeValueId: 3,
                checked: true,
                subOptionName: "Level 3",
                subOptionIcon: "exposure3",
              },
            ],
          },
          // {
          //   optionName: "Customer Class",
          //   optionIcon: "person",
          //   checked: true,
          //   subOptions: [
          //     {
          //       checked: true,
          //       subOptionName: "Business",
          //       subOptionIcon: "business",
          //     },
          //     {
          //       checked: true,
          //       subOptionName: "Residential",
          //       subOptionIcon: "home",
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  // Billing: {
  //   subPreferences: [
  //     {
  //       channelName: "Billing",
  //       channelDescription: "List of channel options related to billing",
  //       channelOptions: [
  //         {
  //           optionName: "Invoices",
  //           checked: true,
  //           optionIcon: "money",
  //         },
  //         {
  //           optionName: "Credit Reports",
  //           checked: true,
  //           optionIcon: "receipt",
  //         },
  //         {
  //           optionName: "Payment",
  //           checked: true,
  //           optionIcon: "money",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // Marketing: {
  //   subPreferences: [
  //     {
  //       channelName: "Marketing",
  //       channelDescription: "List of channel options related to Marketing",
  //       channelOptions: [
  //         {
  //           optionName: "Invoices",
  //           checked: true,
  //           optionIcon: "money",
  //         },
  //         {
  //           optionName: "Credit Reports",
  //           checked: true,
  //           optionIcon: "receipt",
  //         },
  //         {
  //           optionName: "Payment",
  //           checked: true,
  //           optionIcon: "money",
  //         },
  //       ],
  //     },
  // ],
  // },
};

/**
 * Used to get a value of the mockData rather than using a reference to the data
 * @param mockData The mock data from the mock data file
 * @returns A value of the mockData
 */
const getMockData = (): MockData => {
  return JSON.parse(JSON.stringify(mockData));
};

export const mockOptions = ["Outage"];

export default getMockData;
