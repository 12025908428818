export default {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  heading: { fontSize: "1.4rem" },
  subHeading: { fontSize: "0.9rem" },
} as const;
