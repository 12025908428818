import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";

import LoginForm from "sections/login/components/LoginForm";
import { useUserDispatch } from "sections/_global/contexts/UserContext";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import useIsUnmounted from "hooks/useIsUnmounted";
import styles from "./styles";

type FormFields = {
  username: string;
  password: string;
};

type Props = {
  classes: {
    inputContainer: string;
    input: string;
    paper: string;
    image: string;
    imageContainer: string;
    title: string;
    button: string;
    buttonContainer: string;
  };
};

const initialFormFields = {
  username: "",
  password: "",
};

const Login: React.FC<Props> = ({ classes }: Props) => {
  const isUnmounted = useIsUnmounted();
  const [loginIsLoading, setLoginIsLoading] = useState<boolean>(false);
  const userDispatch = useUserDispatch();
  const messageDispatch = useMessageDispatch();
  const [formFields, setFormFields] = useState<FormFields>(initialFormFields);

  const setFormField = (name: string) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFields({ ...formFields, [name]: e.target.value });
  };

  const submitForm = async () => {
    setLoginIsLoading(true);

    userDispatch({
      type: "LOG_IN",
      credentials: {
        username: formFields.username,
        password: formFields.password,
      },
      onError: () => {
        setLoginIsLoading(false);
        messageDispatch({
          type: "ADD_MESSAGE",
          message: {
            description: "Invalid credentials.",
            type: "error",
            isOpen: true,
            id: "login",
          },
        });
      },
      onSuccess: () => {
        if (!isUnmounted) {
          setLoginIsLoading(false);
        }
        messageDispatch({
          type: "CLOSE_MESSAGE",
          message: "login",
        });
      },
    });
  };

  return (
    <div>
      <LoadingBackdrop isOpen={loginIsLoading} />
      <Paper elevation={1} classes={{ root: classes.paper }}>
        <LoginForm
          setFormField={setFormField}
          formFields={formFields}
          submitForm={submitForm}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Login);
