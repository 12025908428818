import React from "react";
import Fade from "@mui/material/Fade";

import CreateOutageForm from "../components/CreateOutageForm";

const CreateMaintenance: React.FC = () => {
  return (
    <Fade in={true}>
      <div>
        <CreateOutageForm isPlanned={true} />
      </div>
    </Fade>
  );
};

export default CreateMaintenance;
