export type Subscriber = null | {
  id: string;
  name: string;
  marketClass: string;
};

export type DateTime = {
  date?: string;
  timeSlot?: string;
  dateTime?: string;
  formattedDate?: Date;
};

export interface Project {
  projectId: number;
  projectName: string;
  clliCode: string;
}

export interface Ticket {
  ticketType: TicketType;
  ticketNumber: string;
  prefix?: string;
}

export enum TicketType {
  FOOTPRINTS = "FOOTPRINTS",
  ZENDESK = "ZENDESK",
}

export type Coordinates = {
  latitude?: number;
  longitude?: number;
};

export type Address = null | {
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export enum EntryType {
  Location = "LOCATION",
  Subscriber = "SUBSCRIBER",
}

export type SLA = {
  start?: Date | null;
  end?: Date | null;
};

export type OspInfo = {
  cableSize?: string;
  emailsToNotify?: string[];
  hutCabinet?: string;
  isCablePlaced?: boolean;
  links?: string[];
  ring?: string;
  splicePoint?: string;
  splicingRequestTeam?: string;
  typeOfWork?: string;
};

export const splicingTeams: string[] = [
  "City Build",
  "Field Operations",
  "NOC",
  "Special Projects",
];

export const typesOfWork: string[] = [
  "Butt Splice",
  "Drop Request",
  "Tie Splice",
];

export const cableSizes: string[] = [
  "4",
  "12",
  "24",
  "48",
  "72",
  "84",
  "96",
  "144",
  "288",
  "432",
  "864",
  "ROC drop fiber",
];

export type ResourceOption = {
  resourceId?: string;
  name?: string;
};

export enum ResourceType {
  BUCKET = "Bucket",
  TECHNICIAN = "Technician",
}

export enum DispatchType {
  SPLICING = "Splicing",
  FIELD_OPS = "Field Ops",
  NOC = "NOC",
}
