import { userCan } from "lib/Authorization";
import { useMemo } from "react";
import { getDefaultStartDate } from "sections/billing-model-common/BillingModelUtilities";
import { useUserState } from "sections/_global/contexts/UserContext";
import { BillingModelPeriodTypes_bamPeriodTypes as PeriodType } from "../__generated__/BillingModelPeriodTypes";
import { BillingModelTransactionTypes_bamTransactionTypes as TransactionType } from "../__generated__/BillingModelTransactionTypes";

interface RatePlanDefaultValues {
  ratePlanAdmin: boolean;
  canWriteRatePlans: boolean;
  ratePlanStartDate: Date;
  ratePlanTypeAdmin: boolean;
  canWriteRatePlanTypes: boolean;
  ratePlanTypeStartDate: Date;
  periodType: PeriodType | null;
  transactionType: TransactionType | null;
}

export const useRatePlanDefaultValues = (): RatePlanDefaultValues => {
  const user = useUserState();

  return useMemo(() => {
    const ratePlanAdmin = userCan({
      user,
      performAction: "billing-model-rateplans:admin",
    });

    const canWriteRatePlans =
      userCan({
        user,
        performAction: "billing-model-rateplans:write",
      }) || ratePlanAdmin;

    const ratePlanTypeAdmin = userCan({
      user,
      performAction: "billing-model-rateplans:admin",
    });

    const canWriteRatePlanTypes =
      userCan({
        user,
        performAction: "billing-model-rateplans:write",
      }) || ratePlanTypeAdmin;

    const ratePlanStartDate = getDefaultStartDate(ratePlanAdmin);
    const ratePlanTypeStartDate = getDefaultStartDate(ratePlanTypeAdmin);

    return {
      ratePlanAdmin,
      canWriteRatePlans,
      ratePlanTypeAdmin,
      canWriteRatePlanTypes,
      ratePlanStartDate,
      ratePlanTypeStartDate,
      periodType: {
        __typename: "BamPeriodType",
        periodTypeId: "103",
        name: "Monthly",
      },
      transactionType: null,
    };
  }, [user]);
};
