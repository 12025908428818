import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERIES } from "../BillingModelRatePlanQueries";
import {
  BillingModelRatePlanTypes,
  BillingModelRatePlanTypesVariables,
} from "../__generated__/BillingModelRatePlanTypes";

export interface RatePlanTypeSelectData {
  getRatePlanTypes: (search: string) => void;
  ratePlanTypesResponse: BillingModelRatePlanTypes | undefined;
  loading: boolean;
}

function useRatePlanTypeSelectQuery(): RatePlanTypeSelectData {
  const [getData, { data, loading }] = useLazyQuery<
    BillingModelRatePlanTypes,
    BillingModelRatePlanTypesVariables
  >(QUERIES.getRatePlanTypes, { fetchPolicy: "cache-first" });

  return useMemo(
    () => ({
      getRatePlanTypes: (search) =>
        getData({
          variables: {
            input: {
              search: search,
              page: 1,
              pageSize: 10,
            },
          },
        }),
      ratePlanTypesResponse: data,
      loading,
    }),
    [data, getData, loading]
  );
}

export default useRatePlanTypeSelectQuery;
