import { ClientAdminConfig, ClientAdminConfigField } from "../types";
import config, { getEntrypointValue } from "../../../config";
import Watchable from "../../../lib/Watchable";

export const getValueFromLocalStorageConfig = ({
  name,
  localStorageConfigJSON,
}: {
  name: ClientAdminConfigField;
  localStorageConfigJSON: string;
}): any => {
  let parsedConfig;
  try {
    parsedConfig = JSON.parse(localStorageConfigJSON) as ClientAdminConfig;
  } catch (err) {
    if (process.env.NODE_ENV !== "test") {
      console.error("Unable to parse local config.");
    }
    return null;
  }

  if (!parsedConfig[name]) return null;

  return parsedConfig[name];
};

export const getConfigValue = (name: ClientAdminConfigField): any => {
  const localStorageConfig = localStorage.getItem("clientAdminConfig");
  if (localStorageConfig && config.configBarVisibility === "visible") {
    return getValueFromLocalStorageConfig({
      name,
      localStorageConfigJSON: localStorageConfig,
    });
  }

  return getEntrypointValue(name);
};

const initialValues = {
  databaseInstance: getConfigValue("databaseInstance"),
};

export const clientAdminConfig = new Watchable<ClientAdminConfig>(
  initialValues
);

export const isProductionConfigValue = (
  name: ClientAdminConfigField,
  value: string
): boolean => value === config.productionConstants[name];

export const currentClientAdminConfigHasProductionValues = (): boolean =>
  isProductionConfigValue(
    "databaseInstance",
    clientAdminConfig.get().databaseInstance
  );

export const saveConfig = (config: ClientAdminConfig): void => {
  clientAdminConfig.set(config);
  localStorage.setItem("clientAdminConfig", JSON.stringify(config));
};
