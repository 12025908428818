import { useEffect, useState } from "react";

import Watchable from "../lib/Watchable";

/**
 * Custom hook which allows watching a Watchable in a React component.
 *
 * Triggers a state update when the Watchable value changes and automatically
 * handles the setup and cleanup of the watcher.
 *
 * @param  {Watchable<T>} watchable The Watchable to watch
 * @returns {T} Returns the current value of the watchable
 */
const useWatchable = <T>(watchable: Watchable<T>): T => {
  const [value, setValue] = useState(watchable.get());

  useEffect(() => {
    const watcherId = watchable.watch(setValue);

    return () => watchable.unwatch(watcherId);
  }, [watchable]);

  return value;
};

export default useWatchable;
