import React, { FC } from "react";
import { Button, Icon } from "@mui/material";

export type Props = {
  onClick: () => void;
  children: any;
  className?: string;
};

const SubtractButton: FC<Props> = ({ children, onClick, className }: Props) => {
  return (
    <Button
      sx={{ marginBottom: "1rem" as const }}
      onClick={onClick}
      color="error"
      startIcon={<Icon>remove</Icon>}
      className={className}
    >
      {children}
    </Button>
  );
};

export default SubtractButton;
