import getMockData, {
  mockOptions,
} from "../../add-contact/components/SubPreferencesTable/mockData";
import { Contact } from "../../types";
import {
  State,
  ContactAction,
  UpdateSubPreference,
  UpdateContact,
} from "./ContactContext";

const updateSubPreferences = (
  action: UpdateSubPreference,
  foundContact: Contact
): Contact => {
  switch (action.type) {
    case "SELECT_ALL": {
      foundContact.subPreferences = mockOptions.map(
        (mockOption) => getMockData()[mockOption].subPreferences[0]
      );
      foundContact.channelNames = mockOptions;
      break;
    }
    case "SET_OUTER_CHECKBOX": {
      const { columnIndex, rowIndex } = action;
      const outerCheckBox =
        foundContact.subPreferences[columnIndex].channelOptions[rowIndex];
      outerCheckBox.checked = !outerCheckBox.checked;

      // Set all of the checkboxes under this subPreference to be the same as it
      if (outerCheckBox.subOptions)
        outerCheckBox.subOptions.forEach(
          (subOption) => (subOption.checked = outerCheckBox.checked)
        );
      break;
    }
    case "SET_INNER_CHECKBOX": {
      const { columnIndex, rowIndex, nestedIndex } = action;
      const outerOption =
        foundContact.subPreferences[columnIndex].channelOptions[rowIndex];

      if (outerOption.subOptions) {
        outerOption.subOptions[nestedIndex].checked = !outerOption.subOptions[
          nestedIndex
        ].checked;
        // If the parent checkbox is checked, then uncheck it
        if (!outerOption.subOptions[nestedIndex].checked)
          outerOption.checked = false;

        // If all children checkboxes are filled, fill parent with check
        if (outerOption.subOptions.every((subOption) => subOption.checked))
          outerOption.checked = true;
      }
      break;
    }
  }
  return foundContact;
};

const updateChannels = (channels: string[], foundContact: Contact): Contact => {
  foundContact.channelNames = channels;
  // For each channel name, get the fields for that channel name in our mockData
  channels.forEach((channelName) => {
    const mockDataSubPreferences = getMockData()[channelName].subPreferences;
    if (
      // This if statement is to add subPreferences based on the values added to our MultipleSelect component
      !foundContact.subPreferences.find(
        (preference) => preference.channelName === channelName
      )
    )
      foundContact.subPreferences = [
        ...foundContact.subPreferences,
        ...mockDataSubPreferences,
      ];
    // This else statement is to remove subPreferences that are removed from our MultipleSelect component
    else
      foundContact.subPreferences = foundContact.subPreferences.filter(
        (preference) => preference.channelName === channelName
      );
  });
  if (channels.length === 0) foundContact.subPreferences = [];
  return foundContact;
};

const updateContact = (
  update: UpdateContact,
  foundContact: Contact
): Contact => {
  switch (update.type) {
    case "TEXT":
      foundContact.value = update.text;
      break;
    case "TYPE":
      foundContact.type = parseInt(update.text);
      foundContact.value = "";
      break;
    case "SUBPREFERENCES":
      foundContact = updateSubPreferences(update.action, foundContact);
      break;
    case "CHANNELS": {
      const updatedContact = updateChannels(update.channels, foundContact);
      foundContact.channelNames = updatedContact.channelNames;
      foundContact.subPreferences = updatedContact.subPreferences;
      break;
    }
  }
  return foundContact;
};

const contactReducer = (state: State, action: ContactAction): State => {
  const { contacts, externalRefId, externalRefTypeId } = state;
  switch (action.type) {
    case "REMOVE_CONTACT":
      return {
        contacts: contacts.filter((contact) => contact.key !== action.key),
        externalRefId,
        externalRefTypeId,
      };
    case "ADD_CONTACT":
      return {
        contacts: [...contacts, action.contact],
        externalRefId,
        externalRefTypeId,
      };
    case "UPDATE_CONTACT": {
      const { key, update } = action;
      // Get the contact we are updating, and modify this in the below switch statement
      let foundContact = contacts.find((oldContact) => oldContact.key === key);
      if (foundContact) {
        foundContact = updateContact(update, foundContact);
      }
      return { contacts, externalRefId, externalRefTypeId };
    }
    case "SET_CONTACTS": {
      const { contacts } = action;
      return { contacts, externalRefId, externalRefTypeId };
    }
    case "EDIT_CONTACT": {
      const { edit, key } = action;
      const foundContact = contacts.find(
        (oldContact) => oldContact.key === key
      );
      if (foundContact) foundContact.isBeingEdited = edit;
      return { contacts, externalRefId, externalRefTypeId };
    }
    case "EDIT_EXTERNAL_REF_VALUE": {
      const { newValue } = action;
      return { contacts, externalRefId: newValue, externalRefTypeId };
    }
    case "EDIT_EXTERNAL_REF_TYPE_ID": {
      const { newValue } = action;
      return { contacts, externalRefId, externalRefTypeId: newValue };
    }
  }
};

export default contactReducer;
