import React from "react";

import config from "config";
import PermissionDenied from "./PermissionDenied";

const PermissionDeniedProvider: React.FC = () => (
  <PermissionDenied email={config.supportEmail} />
);

export default PermissionDeniedProvider;
