import React, { Dispatch, FC, SetStateAction } from "react";
import {
  useContactDispatch,
  useContactState,
} from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import SummaryContainer, {
  SummaryContainer as SummaryContainerType,
} from "../../components/SummaryContainer/SummaryContainer";
import SummaryFooter from "./SummaryFooter/SummaryFooter";

type Props = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  screenIsSmall: boolean;
};

const SummaryForm: FC<Props> = ({
  currentStep,
  setCurrentStep,
  screenIsSmall,
}: Props) => {
  const contactDispatch = useContactDispatch();
  const { contacts } = useContactState();

  /**
   * Formatted contacts for the summaryContainer
   */
  const contactsSummaryDisplay: SummaryContainerType[] = contacts.map(
    (currentContact) => {
      const { channelNames, subPreferences, type, value, key } = currentContact;
      const header = {
        infoHeader: "Contact Value",
        mainHeader: value,
        subHeader: `Channels: ${channelNames.join(", ")}`,
        iconHeader: type,
      };

      const content: {
        infoContent: string;
        mainContent: string | string[];
      }[] = subPreferences.map((subPreference) => {
        const { channelName, channelOptions } = subPreference; // ChannelName refers to outage, billing, marketing
        const options = channelOptions.every((option) => option.checked)
          ? `All Options`
          : channelOptions.reduce((previousOptions, channelOption) => {
              const { checked, subOptions, optionName } = channelOption;
              if (subOptions) {
                const checkedOptions = subOptions.filter(
                  (subOption) => subOption.checked
                );

                if (checkedOptions.length === 0 && !checked)
                  return previousOptions;

                if (checked)
                  return [
                    ...previousOptions,
                    `${optionName}: ${subOptions
                      .map((subOption) => subOption.subOptionName)
                      .join(", ")}`,
                  ];
                return [
                  ...previousOptions,
                  `${optionName}: ${checkedOptions
                    .map((subOption) => subOption.subOptionName)
                    .join(", ")}`,
                ];
              } else
                return checked
                  ? [...previousOptions, optionName]
                  : previousOptions;
            }, [] as string[]);
        return { infoContent: channelName, mainContent: options };
      });
      return {
        content,
        header,
        onClick: (index: number) => {
          setCurrentStep(index + 2);
          contactDispatch({ key, edit: true, type: "EDIT_CONTACT" });
        },
      };
    }
  );

  return (
    <>
      <SummaryContainer summaryColumns={contactsSummaryDisplay} />
      <SummaryFooter
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        screenIsSmall={screenIsSmall}
        contactsLength={contacts.length}
      />
    </>
  );
};

export default SummaryForm;
