import { getActivity_ofscActivity_OfscActivitiesSuccess_activities_tickets as OfscTicket } from "sections/scheduling-tools/__generated__/getActivity";
import { getSubscriberData_billingCustomer_BillingCustomerSuccess_individual_geographicAddress as GeographicAddress } from "sections/scheduling-tools/__generated__/getSubscriberData";
import {
  OfscActivityTicketTypes,
  OfscTicketInput,
} from "../../../../../__generated__/globalTypes";
import { Ticket, TicketType } from "./types";

export function unescapeString(input: string): string {
  return input
    .replace(/&lt;/gi, "<")
    .replace(/&gt;/gi, ">")
    .replace(/&quot;/gi, '"')
    .replace(/&#39;/gi, "'")
    .replace(/&amp;/gi, "&")
    .replace(/&nbsp;/gi, " ");
}

export function formatDescription(str: string): string {
  if (str === null || str === "") return "";
  else
    return unescapeString(str)
      .replace(/<br[ ]?[/]?>/gi, "\n")
      .replace(/(<([^>]+)>)/gi, "")
      .replace(";", ".")
      .trim();
}

export function getPriorityAddress(
  addresses: (GeographicAddress | null)[]
): GeographicAddress | undefined {
  if (addresses.length === 0) return;
  const filteredAddresses = addresses.filter(
    (address) => address !== null
  ) as GeographicAddress[];
  return filteredAddresses.reduce(function (a, b) {
    return statusPriorityEnum[a.status] < statusPriorityEnum[b.status] ? a : b;
  });
}

const statusPriorityEnum: Record<string, number> = { A: 0, P: 1, I: 2 };

export function upperCaseFirstLetterOnly(input: string): string {
  return `${input.slice(0, 1).toUpperCase()}${input.slice(1).toLowerCase()}`;
}

export function convertTicketsForActivity(
  ticket1?: Ticket,
  ticket2?: Ticket
): (OfscTicketInput | null)[] {
  return [
    convertTicketForActivity(ticket1),
    convertTicketForActivity(ticket2),
  ].filter((t) => t !== null);
}

function convertTicketForActivity(ticket?: Ticket) {
  const ofscTicket: OfscTicketInput | null = ticket
    ? {
        ticketType: (ticket.ticketType as string) as OfscActivityTicketTypes,
        ticketNumber: (ticket.prefix ?? "") + ticket.ticketNumber,
      }
    : null;

  return ofscTicket;
}

export function convertActivityToTicket(
  ofscTicket?: OfscTicket | null
): Ticket | undefined {
  const ticket: Ticket | undefined = ofscTicket
    ? {
        ticketType: (ofscTicket.ticketType as string) as TicketType,
        ticketNumber: ofscTicket.ticketNumber.includes("-")
          ? ofscTicket.ticketNumber.split("-")[1]
          : ofscTicket.ticketNumber,
        prefix: ofscTicket.ticketNumber.includes("-")
          ? ofscTicket.ticketNumber.split("-")[0] + "-"
          : "",
      }
    : undefined;

  return ticket;
}
