import React from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import classnames from "classnames";

import styles from "./styles";

export type Props = {
  databaseInstance: string;
  isProduction: boolean;
  onSettingsClick: () => void;
  classes: {
    configBar: string;
    configBarFields: string;
    configBarText: string;
    configValueName: string;
    configBarMore: string;
    configBarProd: string;
    dialogContent: string;
    button: string;
    dialogInput: string;
  };
};

const ConfigBar: React.FC<Props> = ({
  databaseInstance,
  isProduction,
  classes,
  onSettingsClick,
}: Props) => {
  const configBarClasses = classnames(classes.configBar, {
    [classes.configBarProd]: isProduction,
  });

  return (
    <div className={configBarClasses}>
      <div className={classes.configBarFields}>
        <Typography classes={{ root: classes.configBarText }}>
          <span className={classes.configValueName}>Database Instance:</span>{" "}
          {databaseInstance || "Default"}
        </Typography>
      </div>
      <IconButton
        size="small"
        aria-label="settings"
        classes={{
          root: classes.configBarMore,
        }}
        onClick={onSettingsClick}
      >
        <Icon>settings</Icon>
      </IconButton>
    </div>
  );
};

const StyledComponent = withStyles(styles)(ConfigBar);

export { ConfigBar };

export default StyledComponent;
