import React from "react";
import { Paper, Typography, Icon } from "@mui/material";

import { colors } from "styles/colors";

import useStyles from "./styles";

export type Props = {
  message: string | React.ReactElement;
  subMessage?: string | React.ReactElement;
  /**
   * A Material Icons icon name or any React component.
   */
  icon: string | React.ReactNode;
  color?: string;
};

const AppNotice: React.FC<Props> = ({
  message,
  subMessage,
  icon,
  color,
}: Props) => {
  const classes = useStyles();

  const IconComponent =
    typeof icon === "string" ? (
      <Icon
        sx={{
          color: color ?? colors.brandGreen,
          fontSize: "10em",
          opacity: 0.5,
        }}
      >
        {icon}
      </Icon>
    ) : (
      icon
    );

  return (
    <Paper
      sx={{
        textAlign: "center" as const,
        maxWidth: "40em",
        margin: "auto" as const,
        padding: "5em",
      }}
      elevation={0}
    >
      {IconComponent}
      <Typography variant="h6" classes={{ root: classes.message }}>
        {message}
      </Typography>
      {subMessage && (
        <Typography classes={{ root: classes.subMessage }}>
          {subMessage}
        </Typography>
      )}
    </Paper>
  );
};

export default AppNotice;
