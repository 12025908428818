import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Icon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export type Props = {
  setMonthsPast: (monthsPast: number) => void;
  currentMonthsPast: number;
};

const OutageFilterMenu: React.FC<Props> = ({
  setMonthsPast,
  currentMonthsPast,
}: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [filterMenuIsOpen, setFilterMenuIsOpen] = useState<boolean>(false);

  const openFilterMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setMenuAnchor(event.currentTarget);
    setFilterMenuIsOpen(true);
  };

  const onClick = (monthsPast: number) => () => {
    setMonthsPast(monthsPast);
    setFilterMenuIsOpen(false);
  };

  return (
    <div>
      <Menu
        open={filterMenuIsOpen}
        anchorEl={menuAnchor}
        onClose={() => setFilterMenuIsOpen(false)}
      >
        <MenuItem selected={currentMonthsPast === 1} onClick={onClick(1)}>
          <Typography>Past month</Typography>
        </MenuItem>
        <MenuItem selected={currentMonthsPast === 3} onClick={onClick(3)}>
          <Typography>Past 3 months</Typography>
        </MenuItem>
        <MenuItem selected={currentMonthsPast === 6} onClick={onClick(6)}>
          <Typography>Past 6 months</Typography>
        </MenuItem>
        <MenuItem selected={currentMonthsPast === 12} onClick={onClick(12)}>
          <Typography>Past 12 months</Typography>
        </MenuItem>
      </Menu>
      <Tooltip title="Filter" aria-label="filter">
        <IconButton onClick={openFilterMenu}>
          <Icon>filter_list</Icon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default OutageFilterMenu;
