import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Fade from "@mui/material/Fade";

import { GetOutage, GetOutageVariables } from "../__generated__/GetOutage";
import EditOutageDialog from "../components/EditOutageDialog";
import EditOutageAffectedAreaDialog from "../components/EditOutageAffectedAreaDialog";
import OutageDetails from "../components/OutageDetails";
import BackButton from "sections/_global/components/BackButton";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import NotifyDialog from "../components/NotifyDialog";
import { QUERIES } from "../ServiceDisruptionQueries";
import NoResults from "sections/_global/components/NoResults";

type Dialog = "notify" | "edit" | "affectedAreas" | "";

type Params = {
  id: string;
};

const Outage: React.FC = () => {
  const [currentDialog, setOpenDialog] = useState<Dialog>("");
  const { id } = useParams<Params>();
  const [getOutage, { data: outageData, loading }] = useLazyQuery<
    GetOutage,
    GetOutageVariables
  >(QUERIES.getOutage, {
    variables: { id },
  });
  const outage = outageData?.outage;

  const openDialog = (name: Dialog) => () => {
    setOpenDialog(name);
  };

  const closeDialogs = () => {
    setOpenDialog("");
  };

  const parsedOutage = (() => {
    if (!outage) return;
    const updatedOutage = JSON.parse(JSON.stringify(outage));

    for (const affectedArea of updatedOutage.affectedAreas) {
      delete affectedArea.__typename;
    }

    delete updatedOutage.__typename;

    const startAt = new Date(updatedOutage.startAt);
    const endAt = updatedOutage.endAt ? new Date(updatedOutage.endAt) : null;

    return { ...updatedOutage, startAt, endAt };
  })();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOutage();
    }

    return () => {
      isMounted = false;
    };
  }, [getOutage]);

  return (
    <Fade in={true}>
      <div>
        <LoadingBackdrop isOpen={loading} />
        <BackButton
          url={`/service-disruptions/${
            outage?.isPlanned ? "maintenances" : "outages"
          }`}
        />
        {outage && (
          <>
            <OutageDetails
              id={outage.id}
              serviceType={outage.serviceType}
              serviceTypeName={outage.serviceTypeName}
              startAt={new Date(outage.startAt)}
              endAt={outage.endAt ? new Date(outage.endAt) : null}
              affectedAreas={outage.affectedAreas}
              openEditDialog={openDialog("edit")}
              openNotifyDialog={openDialog("notify")}
              description={outage.description}
              totalCallbacks={outage.totalCallbacks}
              totalCalls={outage.totalCalls}
              openAffectedAreasDialog={openDialog("affectedAreas")}
              affectedBusinessCustomers={outage.affectedBusinessCustomers}
              affectedResidentialCustomers={outage.affectedResidentialCustomers}
              externalNotificationHasBeenSent={
                outage.externalNotificationHasBeenSent
              }
              internalNotificationHasBeenSent={
                outage.internalNotificationHasBeenSent
              }
              isPlanned={outage.isPlanned}
            />
            <EditOutageDialog
              isOpen={currentDialog === "edit"}
              onClose={closeDialogs}
              outage={parsedOutage}
            />
            <NotifyDialog
              isOpen={currentDialog === "notify"}
              onClose={closeDialogs}
              id={id}
              externalNotificationHasBeenSent={
                outage.externalNotificationHasBeenSent
              }
              internalNotificationHasBeenSent={
                outage.internalNotificationHasBeenSent
              }
            />
            <EditOutageAffectedAreaDialog
              isOpen={currentDialog === "affectedAreas"}
              onClose={closeDialogs}
              initialAffectedAreas={outage.affectedAreas}
              outage={parsedOutage}
            />
          </>
        )}
        {!loading && !outage && <NoResults message="Outage not found." />}
      </div>
    </Fade>
  );
};

export default Outage;
