import { gql } from "@apollo/client";

export const QUERIES = {
  getLightLevel: gql`
    query GetLightLevel($subId: ID!) {
      lightLevel(subscriberId: $subId) {
        ontRxLightLevel
        oltRxLightLevel
        ontLightLevelStatus
        oltLightLevelStatus
        platform
      }
    }
  `,
};
