import { gql } from "@apollo/client";

export const QUERIES = {
  getTokenUser: gql`
    query GetTokenUser {
      user {
        userId
        permissions {
          name
          action
          category
        }
      }
    }
  `,
};
