import React from "react";
import { IconButton, Icon, Button } from "@mui/material";
import classnames from "classnames";

import useStyles from "./styles";

export type Props = {
  text?: string;
  component?: React.ReactElement;
  icon?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  edge?: "start" | "end";
  label?: string;
};

const Action: React.FC<Props> = ({
  icon,
  text,
  component,
  onClick,
  edge,
  label,
}: Props) => {
  const classes = useStyles();

  if (!icon && !text && !component) {
    throw new Error(
      "One of 'icon', 'component', or 'text' is required by action"
    );
  }

  if (icon && !label) {
    throw new Error("'label' is required when using 'icon'");
  }

  if (icon) {
    return (
      <IconButton aria-label={label} onClick={onClick} edge={edge}>
        <Icon classes={{ root: classes.menuIcon }}>{icon}</Icon>
      </IconButton>
    );
  }

  return (
    <Button
      variant="text"
      classes={{
        root: classnames(classes.textButton, {
          [classes.leftEdge]: edge === "start",
          [classes.rightEdge]: edge === "end",
        }),
      }}
      onClick={onClick}
    >
      {text ?? component}
    </Button>
  );
};

export default Action;
