import { colors } from "styles/colors";

export default {
  root: {
    padding: "1em",
    borderBottom: `1px solid ${colors.borderGray}`,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    fontWeight: "bold" as const,
    color: colors.brandBlue,
  },
  search: {
    width: "100%",
    marginBottom: "1em",
  },
  selectAllButton: {
    fontWeight: "bold" as const,
    color: colors.brandBlue,
  },
  selectAllContainer: {
    textAlign: "right" as const,
    padding: "1em",
  },
  container: {
    marginTop: "1.5em",
    paddingBottom: "1.5em",
    marginBottom: "3em",
    overflowX: "hidden" as const,
  },
  market: {
    padding: "1em",
    borderBottom: `1px solid ${colors.borderGray}`,
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  addButton: {
    fontWeight: "bold" as const,
    color: colors.brandBlue,
  },
  stepperButtons: {
    color: colors.white,
    fontWeight: 400,
    "&.Mui-disabled": {
      color: "#678ba8",
    },
  },
};
