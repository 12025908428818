import React from "react";
import { useHistory } from "react-router-dom";
import { History } from "history";

import BackButton from "sections/_global/components/BackButton";
import PermissionDeniedComponent from "sections/_global/components/PermissionDenied";

const onGoBack = (history: History<unknown>) => () => {
  history.goBack();
};

const PermissionDenied: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <BackButton onClick={onGoBack(history)} />
      <PermissionDeniedComponent />
    </>
  );
};

export default PermissionDenied;
