import React, {
  useContext,
  createContext,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import firebase from "firebase/app";
import "firebase/auth";

import client from "apolloClient";
import {
  logInWithFirebase,
  logInWithUsername,
  getNewToken,
} from "lib/Authentication";
import { User } from "../types";
import config from "config";
import { setCachedAccessToken } from "token";
import Login from "../../login/screens/Login";
import { Post } from "services/fetch";
import { QUERIES } from "../queries";
import { registerOnFirebaseAuthStateChanged } from "./UserContextUtils";

type Credentials = {
  username?: string;
  password?: string;
};
type Action =
  | {
      type: "SET_USER_FIELDS";
      user: Partial<User>;
    }
  | {
      type: "LOG_IN";
      credentials?: Credentials;
      onError?: () => void;
      onSuccess?: () => void;
    }
  | {
      type: "LOG_OUT";
    }
  | {
      type: "VERIFY_TOKEN_AUTH";
    };
export type Dispatch = (action: Action) => void;
type State = User;
type Props = {
  children: React.ReactNode;
  initialState?: State;
};

const UserDispatchContext = createContext<Dispatch | undefined>(undefined);
const UserStateContext = createContext<State | undefined>(undefined);

const initialValues: User = {
  error: null,
  isAuthenticated: false,
  needsAuthVerification: true,
  needsReauthentication: false,
  permissions: [],
  username: null,
  displayName: null,
  email: null,
};

export const updateLoginStatus = (
  dispatch: Dispatch,
  accessToken?: string | null
): void => {
  dispatch({
    type: "SET_USER_FIELDS",
    user: {
      isAuthenticated: Boolean(accessToken),
      needsAuthVerification: !accessToken,
      needsReauthentication: !accessToken,
    },
  });
};

const setUserDataFromToken = async (dispatch: Dispatch) => {
  const result = await client.query({
    query: QUERIES.getTokenUser,
  });

  dispatch({
    type: "SET_USER_FIELDS",
    user: {
      username: result.data.user?.userId || null,
      displayName: result.data.user?.userId || null,
      permissions: result.data.user?.permissions || [],
    },
  });
};

type logInArgs = {
  dispatch: Dispatch;
  credentials?: Credentials;
  onSuccess?: () => void;
  onError?: () => void;
};
const logIn = async ({
  dispatch,
  credentials,
  onSuccess,
  onError,
}: logInArgs): Promise<boolean> => {
  const token =
    config.authenticationType === "TOKEN" &&
    credentials?.username &&
    credentials?.password
      ? await logInWithUsername({
          username: credentials.username,
          password: credentials.password,
        })
      : config.authenticationType === "SAML"
      ? await logInWithFirebase()
      : null;

  if (token) {
    setCachedAccessToken(token);
    if (config.authenticationType === "TOKEN") {
      await setUserDataFromToken(dispatch);
    }
    updateLoginStatus(dispatch, token);

    if (onSuccess) onSuccess();
  } else {
    if (onError) onError();
  }

  return Boolean(token);
};

const logOut = async (dispatch: Dispatch): Promise<void> => {
  if (config.authenticationType === "TOKEN") {
    await Post({
      uri: `${config.backendEndpoint}/logout`,
    });
  }
  await firebase.auth().signOut();

  updateLoginStatus(dispatch, "");
  setCachedAccessToken(null);
  await client.clearStore();
};

const verifyTokenAuthentication = async (dispatch: Dispatch): Promise<void> => {
  try {
    const token = await getNewToken();

    if (token) {
      setCachedAccessToken(token);
      if (config.authenticationType === "TOKEN") {
        await setUserDataFromToken(dispatch);
      }
      updateLoginStatus(dispatch, token);
    } else {
      dispatch({
        type: "SET_USER_FIELDS",
        user: {
          needsAuthVerification: false,
          needsReauthentication: true,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: "SET_USER_FIELDS",
      user: {
        error: "Could not reach server.",
      },
    });
    throw new Error(err);
  }
};

const userReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER_FIELDS":
      return { ...state, ...action.user };
    default:
      throw new Error("Unhandled action type in UserContext reducer.");
  }
};

const asyncMiddleware = (dispatch: Dispatch): Dispatch => {
  return async (action) => {
    switch (action.type) {
      case "LOG_IN": {
        const { credentials, onSuccess, onError } = action;
        logIn({ dispatch, credentials, onSuccess, onError });
        break;
      }
      case "LOG_OUT":
        await logOut(dispatch);
        break;
      case "VERIFY_TOKEN_AUTH":
        await verifyTokenAuthentication(dispatch);
        break;
      default:
        return dispatch(action);
    }
  };
};

const useUserDispatch = (): Dispatch => {
  const context = useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error("useUserDispatch must be used with a UserProvider.");
  }

  return context;
};

const useUserState = (): State => {
  const context = useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUserState must be used with a UserProvider.");
  }

  return context;
};

const useUser = (): [State, Dispatch] => {
  return [useUserState(), useUserDispatch()];
};

const UserProvider: React.FC<Props> = ({ children, initialState }: Props) => {
  const [state, dispatch] = useReducer(
    userReducer,
    initialState ?? initialValues
  );

  const userDispatch = useCallback(
    (action: Action) => {
      return asyncMiddleware(dispatch)(action);
    },
    [dispatch]
  );

  useEffect(() => {
    if (config.authenticationType === "SAML" && state.needsAuthVerification) {
      registerOnFirebaseAuthStateChanged(userDispatch);
    }
  }, [userDispatch, state.needsAuthVerification]);

  useEffect(() => {
    if (
      config.authenticationType === "TOKEN" &&
      state.needsAuthVerification &&
      !state.error
    ) {
      userDispatch({ type: "VERIFY_TOKEN_AUTH" });
    }
  }, [state.needsAuthVerification, state.error, userDispatch]);

  return (
    <UserDispatchContext.Provider value={asyncMiddleware(dispatch)}>
      {state.needsReauthentication ? (
        <Login />
      ) : (
        <UserStateContext.Provider value={state}>
          {children}
        </UserStateContext.Provider>
      )}
    </UserDispatchContext.Provider>
  );
};

export {
  UserStateContext,
  UserProvider,
  useUser,
  useUserState,
  useUserDispatch,
  verifyTokenAuthentication,
};
