import React, { FC, SetStateAction } from "react";
import { Grid } from "@mui/material";
import { Dispatch } from "react";
import CancelButton from "sections/communication-preference/components/buttons/CancelButton";
import NextOrFinishButton from "sections/communication-preference/components/buttons/NextOrFinishButton";
import StepsFooter from "sections/communication-preference/components/StepsFooter/StepsFooter";
import { Contact } from "sections/communication-preference/contacts/types";
import { useHistory } from "react-router";
import componentStyles from "./styles";

type Props = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  contacts: Contact[];
  validation: () => boolean;
  screenIsSmall: boolean;
};

const ContactFooter: FC<Props> = ({
  setCurrentStep,
  currentStep,
  contacts,
  validation,
  screenIsSmall,
}: Props) => {
  const history = useHistory();
  return (
    <Grid container sx={componentStyles.navigation} item>
      <Grid item xs={4}>
        <CancelButton
          onClick={() => history.push("/communication-preference/contacts")}
        />
      </Grid>
      {!screenIsSmall && (
        <Grid item>
          <StepsFooter currentStep={currentStep} totalSteps={contacts.length} />
        </Grid>
      )}
      <Grid sx={componentStyles.next} item xs={4}>
        <NextOrFinishButton
          next={currentStep !== contacts.length + 2}
          onClick={() => {
            validation() && setCurrentStep((oldStep) => oldStep + 1);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ContactFooter;
