import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Grid } from "@mui/material";
import { Coordinates } from "../../screens/types";
import ValidatedTextField from "sections/_global/components/ValidatedTextField";
import {
  latitudeValidation,
  longitudeValidation,
} from "sections/_global/RegexValidation";

type Props = {
  coordinates: Coordinates;
  required?: boolean;
  onChange: (str: any) => void;
};

const CoordinatesInput: FC<Props> = ({
  coordinates,
  required,
  onChange,
}: Props) => {
  return (
    <Grid container spacing={1} justifyItems="start">
      <Grid item xs={6}>
        <ValidatedTextField
          id="latitude"
          required={required}
          value={((coordinates.latitude ?? "") as unknown) as string}
          onChange={(e) => {
            onChange({
              ...coordinates,
              latitude: e.target.value,
            });
          }}
          label="Latitude"
          variant="outlined"
          errorText="between -90 and 90"
          regex={latitudeValidation}
          type="text"
          classes={styles}
        />
      </Grid>
      <Grid item xs={6}>
        <ValidatedTextField
          id="longitude"
          required={required}
          value={((coordinates.longitude ?? "") as unknown) as string}
          onChange={(e) =>
            onChange({
              ...coordinates,
              longitude: e.target.value,
            })
          }
          label="Longitude"
          variant="outlined"
          errorText="between -90 and 90"
          regex={longitudeValidation}
          type="text"
          classes={styles}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CoordinatesInput);
