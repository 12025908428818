import { makeStyles } from "@mui/styles";

import { colors } from "styles/colors";

const styles = makeStyles({
  toolbar: {
    color: colors.white,
  },
  menuIcon: {
    color: colors.white,
  },
  titleContainer: { flex: 1, display: "flex", alignItems: "center" },
  textButton: {
    color: colors.white,
    fontWeight: 400,
    lineHeight: "unset",
  },
  leftEdge: {
    marginLeft: "-0.5em",
  },
  rightEdge: {
    marginRight: "-0.5em",
  },
  clickable: {
    cursor: "pointer",
  },
});

export default styles;
