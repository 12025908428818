import React from "react";
import Dashboard from "sections/dashboard/screens/Dashboard";
import { AssignProvided } from "sections/_global/contexts/AssignContext";
import Login from "sections/login/screens/Login";
import ServiceDisruptionsOverview from "sections/service-disruptions/screens/Overview";
import Outages from "sections/service-disruptions/screens/Outages";
import Outage from "sections/service-disruptions/screens/Outage";
import CreateOutage from "sections/service-disruptions/screens/CreateOutage";
import Maintenances from "sections/service-disruptions/screens/Maintenances";
import LightLevelsLookup from "sections/provisioning-tools/screens/LightLevelsLookup";
import CreateMaintenance from "sections/service-disruptions/screens/CreateMaintenance";
import BillingDataAdminOverview from "sections/billing-model-common/screens/Overview";
import BillingModelMaintainRatePlans from "sections/billing-model-rateplans/screens/MaintainRatePlans";
import BillingModelEditRatePlan from "sections/billing-model-rateplans/screens/EditRatePlan";
import BillingModelCreateRatePlan from "sections/billing-model-rateplans/screens/CreateRatePlan";
import BillingModelMaintainRatePlanTypes from "sections/billing-model-rateplans/screens/MaintainRatePlanTypes";
import BillingModelEditRatePlanType from "sections/billing-model-rateplans/screens/EditRatePlanType";
import BillingModelCreateRatePlanType from "sections/billing-model-rateplans/screens/CreateRatePlanType";
import Settings from "sections/settings/screens/Settings";
import IMDashboard from "sections/inventory-manager/Dashboard";
import ProvisioningToolsOverview from "sections/provisioning-tools/screens/Overview";
import CommunicationPreferenceDashboard from "sections/communication-preference/screens/Dashboard";
import SchedulingToolsOverview from "sections/scheduling-tools/screens/Overview";
import ScheduleActivity from "sections/scheduling-tools/ofsc-schedule-activity/screens/ScheduleActivity";
import ScheduleActivityForLocation from "sections/scheduling-tools/ofsc-schedule-activity/screens/ScheduleActivityForLocation";
import ProvisioningDetails from "sections/provisioning-tools/provisioning-details/screens/ProvisioningDetails";
import ExternalLinksOverview from "sections/external-links/screens/Overview";
import CommunicationPreferenceContacts from "sections/communication-preference/contacts/screens";
import AddContactProvider from "sections/communication-preference/contacts/add-contact/AddContactProvider";
import { User } from "sections/_global/types";

export type LinkType = "internal" | "external";

export type Route = {
  requiresValidSession: boolean;
  /**
   * The component that will be rendered at the give URL.
   */
  component?: React.ComponentType;
  /**
   * The icon to be used for the route's link card.
   */
  icon?: React.ReactElement | string;
  /**
   * Whether this route should create a new link card on the main dashboard page.
   */
  showInDashboard: boolean;
  /**
   * The title to be used for link card and nav menu item.
   */
  title: string;
  url: string;
  /**
   * An internal link will link to another page in the app; an external link will open a new tab and
   * direct the user to an external site.
   */
  linkType: LinkType;
  /**
   * Used to determine whether or not the user has permission to access the route. If the user does
   * not, it will not show the link card or nav menu item, and will show the PermissionDenied
   * component when trying to access that route.
   */
  rbacAction?: string | string[] | ((user: User) => boolean);
};

const routeDefinitions = (): Route[] => [
  {
    requiresValidSession: true,
    component: Dashboard,
    showInDashboard: false,
    title: "Dashboard",
    url: "/",
    linkType: "internal",
  },
  {
    requiresValidSession: false,
    component: Login,
    showInDashboard: false,
    title: "Login",
    url: "/login",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: ServiceDisruptionsOverview,
    icon: "bolt",
    showInDashboard: true,
    title: "Service Disruptions",
    url: "/service-disruptions",
    rbacAction: ["outages:get", "maintenances:get"],
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: LightLevelsLookup,
    showInDashboard: false,
    title: "Light Levels Lookup",
    url: "/provisioning-tools/light-levels-lookup",
    rbacAction: "provisioning:get",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: Outages,
    showInDashboard: false,
    title: "Outages",
    url: "/service-disruptions/outages",
    rbacAction: "outages:get",
    linkType: "internal",
  },

  {
    requiresValidSession: true,
    component: Outage,
    showInDashboard: false,
    title: "Outage",
    url: "/service-disruptions/outages/:id",
    rbacAction: "outages:get",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: CreateOutage,
    showInDashboard: false,
    title: "Create Outage",
    url: "/service-disruptions/create-outage",
    rbacAction: "outages:manage",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: Maintenances,
    showInDashboard: false,
    title: "Maintenances",
    url: "/service-disruptions/maintenances",
    rbacAction: "maintenances:get",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: Outage,
    showInDashboard: false,
    title: "Maintenance",
    url: "/service-disruptions/maintenances/:id",
    rbacAction: "maintenances:get",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: CreateMaintenance,
    showInDashboard: false,
    title: "Create Maintenance",
    url: "/service-disruptions/create-maintenance",
    rbacAction: "maintenances:manage",
    linkType: "internal",
  },

  {
    requiresValidSession: true,
    component: IMDashboard,
    icon: "storage",
    showInDashboard: true,
    title: "Inventory Manager",
    url: "/inventory-manager",
    rbacAction: "equipment:manage",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: ProvisioningToolsOverview,
    icon: "device_hub",
    showInDashboard: true,
    title: "Provisioning Tools",
    url: "/provisioning-tools",
    linkType: "internal",
    rbacAction: "provisioning:get",
  },
  {
    requiresValidSession: true,
    component: ProvisioningDetails,
    icon: "app",
    showInDashboard: false,
    title: "Provisioning Details",
    url: "/provisioning-tools/provisioning-details",
    linkType: "internal",
    rbacAction: "provisioning:get",
  },
  {
    requiresValidSession: true,
    component: AssignProvided,
    title: "Transfer To ETA Technician",
    showInDashboard: false,
    icon: "trending_flat",
    url: "/inventory-manager/assign",
    linkType: "internal",
  },

  {
    requiresValidSession: true,
    component: Settings,
    showInDashboard: false,
    title: "Settings",
    url: "/settings",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    component: BillingDataAdminOverview,
    icon: "tune",
    showInDashboard: true,
    title: "Billing Data Admin",
    url: "/billing-model",
    linkType: "internal",
    rbacAction: ["billing-model-rateplans:read"],
  },
  {
    requiresValidSession: true,
    component: BillingModelMaintainRatePlans,
    showInDashboard: false,
    title: "Maintain Rate Plans",
    url: "/billing-model/rates/list",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelCreateRatePlan,
    showInDashboard: false,
    title: "Create Rate Plan",
    url: "/billing-model/rates/create",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelCreateRatePlan,
    showInDashboard: false,
    title: "Create Rate Plan",
    url: "/billing-model/rates/create/:id",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelEditRatePlan,
    showInDashboard: false,
    title: "Edit Rate Plan",
    url: "/billing-model/rates/edit/:id",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelMaintainRatePlanTypes,
    showInDashboard: false,
    title: "Maintain Rate Plan Types",
    url: "/billing-model/rate-types/list",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelCreateRatePlanType,
    showInDashboard: false,
    title: "Create Rate Plan Type",
    url: "/billing-model/rate-types/create",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelCreateRatePlanType,
    showInDashboard: false,
    title: "Create Rate Plan Type",
    url: "/billing-model/rate-types/create/:id",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: BillingModelEditRatePlanType,
    showInDashboard: false,
    title: "Edit Rate Plan Type",
    url: "/billing-model/rate-types/edit/:id",
    linkType: "internal",
    rbacAction: "billing-model-rateplans:read",
  },
  {
    requiresValidSession: true,
    component: CommunicationPreferenceDashboard,
    icon: "tune",
    showInDashboard: true,
    title: "Communication Preference",
    url: "/communication-preference",
    linkType: "internal",
    rbacAction: "communication-preference:manage",
  },
  {
    requiresValidSession: true,
    component: SchedulingToolsOverview,
    icon: "schedule",
    showInDashboard: true,
    title: "Scheduling Tools",
    url: "/scheduling-tools",
    linkType: "internal",
    rbacAction: "scheduling:manage",
  },
  {
    requiresValidSession: true,
    component: ScheduleActivity,
    icon: "schedule",
    showInDashboard: false,
    title: "Scheduling Tools",
    url: "/scheduling-tools/ofsc-schedule-activity",
    linkType: "internal",
    rbacAction: "scheduling:manage",
  },
  {
    requiresValidSession: true,
    component: ScheduleActivityForLocation,
    icon: "schedule",
    showInDashboard: false,
    title: "Scheduling Tools",
    url: "/scheduling-tools/ofsc-schedule-activity-location",
    linkType: "internal",
    rbacAction: "scheduling:manage",
  },
  {
    requiresValidSession: true,
    component: CommunicationPreferenceContacts,
    icon: "person",
    showInDashboard: false,
    title: "Contacts",
    url: "/communication-preference/contacts",
    linkType: "internal",
    rbacAction: "communication-preference:manage",
  },
  {
    requiresValidSession: true,
    component: AddContactProvider,
    showInDashboard: false,
    title: "Add Contacts",
    url: "/communication-preference/contacts/add",
    linkType: "internal",
    rbacAction: "communication-preference:manage",
  },
  {
    requiresValidSession: true,
    component: ExternalLinksOverview,
    icon: "link",
    showInDashboard: true,
    title: "External Links",
    url: "/external-links",
    linkType: "internal",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Oracle E-Business Suite (EBS)",
    url: "/external-links/ebs1.in.qservco.com:8000",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "SharePoint",
    url: "/external-links/sharepoint.ad.qservco.com",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "UltiPro (UKG)",
    url: "/external-links/qservices.ultipro.com/",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Personal IP Address Lookup",
    url: "/external-links/webapps.metronetinc.com/cgi-bin/ip.cgi",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Oracle Field Services Cloud (OFSC)",
    url: "/external-links/metronet.etadirect.com/",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Field Tech Admin (FTA)",
    url: "/external-links/webapps.metronetinc.com/fta",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Construction Communication Tool (CCT)",
    url: "/external-links/webapps.metronetinc.com/cct",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Construction Tracker",
    url: "/external-links/webapps.metronetinc.com/ospcal",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Inventory Manager",
    url: "/external-links/apps.metronetinc.com/inventory-manager",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Inventory Scanner",
    url:
      "/external-links/qmanager.cinergymetronet.com/EquipMgr/ajax/inventory.cgi",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Intelligent Support Resolution (ISR)",
    url: "/external-links/webapps.metronetinc.com/isr",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Q-Manager",
    url: "/external-links/qmanager.cinergymetronet.com",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Quote Tool",
    url: "/external-links/webapps.metronetinc.com/quote",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "Self Sign-Up (SSU)",
    url: "/external-links/signup.metronetinc.com",
    linkType: "external",
  },
  {
    requiresValidSession: true,
    showInDashboard: false,
    title: "View/Edit Subscriber (VES)",
    url: "/external-links/webapps.metronetinc.com/ves",
    linkType: "external",
  },
];

export default routeDefinitions;
