import React from "react";
import { Button, Icon } from "@mui/material";

import { linkComponent } from "../../ui";

export type Props = {
  url?: string;
  onClick?: () => void;
};

const BackButton: React.FC<Props> = ({ url, onClick }: Props) => {
  const props = url ? { component: linkComponent({ url }) } : {};

  return (
    <Button
      variant="contained"
      onClick={onClick}
      {...props}
      startIcon={<Icon>chevron_left</Icon>}
    >
      Back
    </Button>
  );
};

export default BackButton;
