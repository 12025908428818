import React, { FC } from "react";
import { Box } from "@mui/system";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import PageHeader from "sections/_global/components/PageHeader";

const Dashboard: FC = () => {
  return (
    <Box>
      <PageHeader title="Communication Preference" url="/" />
      <LinkCardsProvider
        cards={[
          {
            title: "Contacts",
            icon: "contacts",
            url: "/communication-preference/contacts",
            linkType: "internal",
          },
          {
            title: "Channels",
            icon: "settings_input_component",
            url: "/communication-preference/channels",
            linkType: "internal",
          },
        ]}
      />
    </Box>
  );
};

export default Dashboard;
