import React, { FC, ReactNode } from "react";
import { Card } from "@mui/material";

type Props = {
  children?: ReactNode;
};

const ContactContainer: FC<Props> = ({ children }: Props) => {
  return <Card raised={true}>{children}</Card>;
};

export default ContactContainer;
