import { Box, Grid, Icon, Paper, Typography, IconButton } from "@mui/material";
import { compareAsc } from "date-fns";
import React from "react";
import { ObjectStatus } from "sections/billing-model-common/BillingModelTypes";
import { colors } from "styles/colors";
import BillingModelDateDisplay from "../BillingModelDateDisplay/BillingModelDateDisplay";
import StatusIndicator, { CustomStatus } from "../StatusIndicator";

export interface Props {
  typeId?: string;
  typeName?: string;
  startDate?: string | null;
  endDate?: string | null;
  /**
   * Controls which status indicator is displayed.
   */
  status?: ObjectStatus | CustomStatus;
  /**
   * If true, displays a `X` icon button to allow the user to click to trigger a remove action.
   * If false or undefined, the button is not visible.
   */
  removeable?: boolean;
  cloneable?: boolean;
  createdAt?: string | null;
  modifiedAt?: string | null;
  onClick?: () => void;
  /**
   * Event that is called when the user clicks the clone button.
   */
  onClone?: () => void;
  /**
   * Event that is called when the user clicks the remove button.
   */
  onRemove?: () => void;
  /**
   * Event that is called when the user clicks the restore button for an object that has a 'deleted' status.
   */
  onRestore?: () => void;
}

/**
 Provides standard view for billing model data types.
 */
const BillingModelTypeCard = ({
  typeId,
  typeName,
  startDate,
  endDate,
  createdAt,
  modifiedAt,
  removeable = false,
  cloneable = false,
  status,
  onClick,
  onClone,
  onRemove,
  onRestore,
}: Props): JSX.Element => {
  const end = endDate ? new Date(endDate) : null;
  const active = end ? compareAsc(new Date(), end) === -1 : true;

  const buttonContainerStyle = {
    display: "flex",
    minWidth: "3em",
    paddingRight: "0.3em",
  };

  const statusIndicator = status
    ? status
    : !active && (startDate || endDate)
    ? "expired"
    : null;

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleCloneClick = (event: React.MouseEvent) => {
    if (onClone) onClone();
  };

  const handleRemoveClick = (event: React.MouseEvent) => {
    if (onRemove) onRemove();
  };

  const handleRestoreClick = (event: React.MouseEvent) => {
    if (onRestore) onRestore();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Paper
          sx={{
            flexGrow: 1,
            padding: "0.7em",
            margin: "0.4em",
            cursor: "pointer",
            "&:hover": {
              color: colors.white,
              backgroundColor: colors.brandGreenDark,
            },
            backgroundColor: () => (!active ? "#0404041a" : undefined),
          }}
          elevation={3}
          role="button"
          onClick={handleOnClick}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} md={7}>
              {status !== "new" && (
                <Typography variant="subtitle1" component="span">
                  {typeId}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={5}>
              <StatusIndicator status={statusIndicator} />
            </Grid>

            <Grid item xs={12} md={7}>
              <Typography variant="body1">{typeName}</Typography>
            </Grid>

            <Grid item xs={12} md={5}>
              <Grid container row-spacing="1" column-spacing="2">
                <Grid item xs={12} sm={6}>
                  <BillingModelDateDisplay
                    isVisible={typeof startDate === "string"}
                    title="Start"
                    value={startDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BillingModelDateDisplay
                    isVisible={
                      typeof startDate === "string" ||
                      typeof endDate === "string"
                    }
                    title="End"
                    value={endDate}
                    missingDateText="No End Date"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BillingModelDateDisplay title="Created" value={createdAt} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BillingModelDateDisplay
                    isVisible={typeof createdAt === "string"}
                    title="Modified"
                    value={modifiedAt}
                    missingDateText="No Date"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {cloneable && !removeable && (
        <Box sx={buttonContainerStyle}>
          <IconButton
            sx={{
              maxHeight: "2em",
              alignSelf: "center",
              color: colors.gray8,
            }}
            aria-label="Clone"
            onClick={handleCloneClick}
          >
            <Icon>file_copy</Icon>
          </IconButton>
        </Box>
      )}
      {removeable && status !== "deleted" && (
        <Box sx={buttonContainerStyle}>
          <IconButton
            sx={{ maxHeight: "2em", alignSelf: "center", color: colors.gray8 }}
            aria-label="Remove"
            onClick={handleRemoveClick}
          >
            <Icon>delete</Icon>
          </IconButton>
        </Box>
      )}
      {removeable && status === "deleted" && (
        <IconButton
          sx={{
            maxHeight: "2em",
            alignSelf: "center",
            color: colors.warningOrange,
          }}
          aria-label="Restore"
          onClick={handleRestoreClick}
        >
          <Icon>undo</Icon>
        </IconButton>
      )}
    </Box>
  );
};

export default BillingModelTypeCard;
