import React, { FC } from "react";
import { Accordion, AccordionSummary, List, Icon } from "@mui/material";
import CheckBoxListItem from "sections/communication-preference/contacts/add-contact/components/CheckBoxListItem";
import ColumnHeader from "../ColumnHeader/ColumnHeader";
import componentStyles from "./styles";
import { Box } from "@mui/system";

export type Props = {
  setInnerCheckBox: (
    columnIndex: number,
    outerIndex: number,
    innerIndex: number
  ) => void;
  setOuterCheckBox: (columnIndex: number, outerIndex: number) => void;
  data: {
    columnTitle: string;
    columnDescription: string;
    options: {
      icon: string;
      optionName: string;
      optionChecked: boolean;
      subOptions?: {
        subOptionIcon: string;
        subOptionName: string;
        subOptionChecked: boolean;
      }[];
    }[];
  }[];
};

const CheckBoxTable: FC<Props> = ({
  data,
  setOuterCheckBox,
  setInnerCheckBox,
}: Props) => {
  return (
    <List sx={componentStyles.fullWidth}>
      <Box sx={componentStyles.container}>
        {data.map((column, columnIndex) => {
          const { columnTitle, columnDescription, options } = column;
          return (
            <Accordion
              defaultExpanded
              sx={{
                root: componentStyles.default,
                expanded: componentStyles.default,
                ...componentStyles.column,
              }}
              key={`${columnTitle}${columnIndex}`}
              elevation={0}
            >
              <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <ColumnHeader
                  title={columnTitle}
                  description={columnDescription}
                />
              </AccordionSummary>
              {options.map((option, outerCheckBoxIndex) => {
                const { icon, optionChecked, optionName, subOptions } = option;
                return (
                  <div key={`${optionName}${outerCheckBoxIndex}`}>
                    <CheckBoxListItem
                      labelId={optionName}
                      indeterminate={
                        subOptions?.find(
                          (subOption) => subOption.subOptionChecked
                        ) !== undefined &&
                        !subOptions.every(
                          (subOption) => subOption.subOptionChecked
                        )
                      }
                      icon={icon}
                      checked={optionChecked}
                      title={optionName}
                      setChecked={() =>
                        setOuterCheckBox(columnIndex, outerCheckBoxIndex)
                      }
                    />
                    {subOptions &&
                      subOptions.map((subOption, innerCheckBoxIndex) => {
                        const {
                          subOptionChecked,
                          subOptionIcon,
                          subOptionName,
                        } = subOption;
                        return (
                          <div key={`${subOptionName}${innerCheckBoxIndex}`}>
                            <CheckBoxListItem
                              classes={componentStyles.shift}
                              labelId={subOptionName}
                              icon={subOptionIcon}
                              checked={subOptionChecked}
                              title={subOptionName}
                              setChecked={() =>
                                setInnerCheckBox(
                                  columnIndex,
                                  outerCheckBoxIndex,
                                  innerCheckBoxIndex
                                )
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </Accordion>
          );
        })}
      </Box>
    </List>
  );
};

export default CheckBoxTable;
