import { Typography } from "@mui/material";
import React from "react";
import { colors } from "styles/colors";

export interface Props {
  value?: string;
}

/**
 Provides standard display for type IDs.
 */
const BillingModelTextAdornment = ({ value }: Props): JSX.Element | null => {
  if (value) {
    return (
      <Typography
        variant="overline"
        sx={{
          fontSize: "0.8em",
          backgroundColor: colors.gray8,
          color: colors.white,
          paddingLeft: "0.5em",
          paddingRight: "0.5em",
          borderRadius: "0.3em",
          marginRight: "0.3em",
        }}
      >
        {value}
      </Typography>
    );
  }

  return null;
};

export default BillingModelTextAdornment;
