import React from "react";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";

import LinkCard from "./LinkCard";
import { growDelay } from "../../ui";
import { LinkType } from "routes/routeDefinitions";

type Card = {
  title: string;
  icon?: React.ReactElement | string;
  url: string;
  active?: boolean;
  linkType: LinkType;
  titleProps?: {
    fontSize?: string;
  };
};

export type Props = {
  cards: Card[];
};

const LinkCards: React.FC<Props> = ({ cards }: Props) => (
  <Grid container spacing={3}>
    {cards.map((card, i) => (
      <Grow
        key={i}
        in={true}
        timeout={growDelay({
          componentNumber: i,
          maxNumberOfComponents: 4,
          milliseconds: 200,
        })}
      >
        <Grid item xs={12} sm={6} md={3}>
          <LinkCard
            title={card.title}
            icon={card.icon || "help"}
            url={card.url}
            active={card.active}
            linkType={card.linkType}
            titleProps={card.titleProps}
          />
        </Grid>
      </Grow>
    ))}
  </Grid>
);

export default LinkCards;
