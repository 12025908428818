import React, { useState, FC } from "react";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import styles from "./styles";

export interface Props {
  /**
   * The color of the tab underline and text
   */
  variant?: "blue" | "green" | undefined;

  /**
   * The size of the tab holder
   */
  size: "fullWidth" | "standard";

  /**
   * This prop takes in a list of objects, which all should have the property tabName, signifying the name of the tab corresponding to that object. The
   * second attribute required is the component that will be rendered upon that tab being selected.
   */
  tabs: { tabName: string; display: JSX.Element | undefined }[];

  /**
   * This is an optional prop that comes from useState. The purpose of setTabSelected is to send back a signal to the higher component which tab is selected.
   */
  setTabSelected?: (tab: number) => void;

  /**
   * An optional prop that renders the tab at this index in the tabs array.
   */
  startValue?: number;
}

const useStyles = makeStyles(styles);

const TabHolder: FC<Props> = ({
  variant = "blue",
  tabs,
  setTabSelected,
  size,
  startValue,
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected?.(newValue);
    setValue(newValue);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={startValue ?? value}
          onChange={handleChange}
          indicatorColor={variant === "blue" ? "primary" : "secondary"}
          textColor={variant === "blue" ? "primary" : "secondary"}
          centered
          variant={size}
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.tabName} value={index} key={tab.tabName} />
          ))}
        </Tabs>
      </Paper>
      <Paper>{tabs[value].display}</Paper>
    </>
  );
};

export { TabHolder };
