import { Chip } from "@mui/material";
import React from "react";
import { ObjectStatus } from "sections/billing-model-common/BillingModelTypes";
import { colors } from "styles/colors";

export interface CustomStatus {
  label: string;
  backgroundColor: string;
}

export interface Props {
  /**
   If 'active', then the indicator displays green with the 'Active' text.  
   If 'expired', then the indicator displays red with the 'Expired' text.  
   If 'new', then the indicator displays green with the 'New' text.  
   If 'changed', then the indicator displays yellow-orange with the 'Changed' text.
   If CustomStatus object is passed, then the label and background color will come from it.
   */
  status?: ObjectStatus | CustomStatus;
  onDelete?: () => void;
}

/**
 Simple component that provides a standardized way of displaying various status messages for billing model types.
 */
const StatusIndicator = ({ status, onDelete }: Props): JSX.Element | null => {
  const baseStyle = {
    color: colors.white,
  };

  const getColor = () => {
    if (status) {
      if (typeof status === "string") {
        switch (status) {
          case "active":
            return colors.brandGreen;
          case "expired":
            return colors.errorRed;
          case "changed":
            return colors.warningOrange;
          case "new":
            return colors.brandGreen;
          case "deleted":
            return colors.errorRed;
        }
      } else {
        return status.backgroundColor;
      }
    }

    return null;
  };

  const getProps = () => {
    if (status) {
      if (typeof status === "string") {
        return {
          label: `${status[0].toLocaleUpperCase()}${status.substring(1)}`,
          sx: {
            ...baseStyle,
            backgroundColor: getColor(),
          },
          onDelete: onDelete,
        };
      } else {
        return {
          label: status.label,
          sx: {
            ...baseStyle,
            backgroundColor: status.backgroundColor ?? colors.gray12,
          },
          onDelete: onDelete,
        };
      }
    }

    return null;
  };

  const props = getProps();
  if (props) {
    return <Chip size="small" {...props} />;
  }

  return null;
};

export default StatusIndicator;
