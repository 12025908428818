import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DispatchType, ResourceType } from "../../screens/types";

export type Props = {
  label: string;
  dispatchType?: DispatchType;
  setResourceType: (val: ResourceType) => void;
  clearResource: () => void;
};

const ResourceTypeSelection: React.FC<Props> = ({
  label,
  dispatchType,
  setResourceType,
  clearResource,
}: Props) => {
  return (
    <FormControl>
      <FormLabel id="resource-type-radio-group-label">{label}</FormLabel>
      <RadioGroup
        row
        name="resource-type-radio-group"
        defaultValue={
          dispatchType === DispatchType.NOC ? "Technician" : "Bucket"
        }
      >
        <FormControlLabel
          value="Bucket"
          control={<Radio />}
          label={ResourceType.BUCKET.toString()}
          onClick={() => {
            clearResource();
            setResourceType(ResourceType.BUCKET);
          }}
        />
        <FormControlLabel
          value="Technician"
          control={<Radio />}
          label={ResourceType.TECHNICIAN.toString()}
          onClick={() => {
            clearResource();
            setResourceType(ResourceType.TECHNICIAN);
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(ResourceTypeSelection);
