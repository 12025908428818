import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { GetTechnicians } from "./__generated__/GetTechnicians";

import { Technician } from "../../InventoryManagerTypes";
import Typeahead, {
  TypeaheadOption,
} from "sections/_global/components/Typeahead";

export type Props = {
  onChange: (value: TypeaheadOption | null | string) => void;
  currTech?: TypeaheadOption | null | string;
  style?: string;
};

export const GET_TECH = gql`
  query GetTechnicians {
    technicians {
      name
      id
    }
  }
`;

const TechnicianSelect: FC<Props> = ({ currTech, onChange, style }: Props) => {
  const { data, loading } = useQuery<GetTechnicians>(GET_TECH);
  const options: TypeaheadOption[] =
    data?.technicians.map((v: Technician) => ({
      value: v.id,
      label: v.name,
    })) ?? [];

  return options ? (
    <Grid container spacing={2} justifyContent="center" marginTop="3rem">
      <Grid item xs={12} sm={6}>
        <>
          <Typography color="secondary" variant="h6">
            Choose A Technician
          </Typography>
          <Typeahead
            label="Technician"
            isDisabled={loading || options?.length === 0}
            options={options}
            value={currTech}
            isLoading={loading}
            onChange={onChange}
          />
        </>
      </Grid>
    </Grid>
  ) : null;
};

export default TechnicianSelect;
