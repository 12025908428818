import React, { FC } from "react";
import { Button, Icon } from "@mui/material";
import componentStyles from "./styles";

type Props = {
  onClick: () => void;
};

const AddContactButton: FC<Props> = ({ onClick }: Props) => {
  return (
    <Button
      startIcon={<Icon>person_add</Icon>}
      onClick={onClick}
      sx={componentStyles.addContactButton}
    >
      Add Contact
    </Button>
  );
};

export default AddContactButton;
