import React, { Dispatch, FC, SetStateAction } from "react";
import { useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import NextOrFinishButton from "sections/communication-preference/components/buttons/NextOrFinishButton";
import PreviousButton from "sections/communication-preference/components/buttons/PreviousButton";
import StepsFooter from "sections/communication-preference/components/StepsFooter/StepsFooter";
import { useContactState } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import { CreateContact } from "./ContactQueries";
import {
  CreateContactVariables,
  CreateContact_createContacts,
} from "./__generated__/CreateContact";
import componentStyles from "./styles";
import { formatContacts } from "sections/communication-preference/contacts/screens/CommunicationPreferenceContacts";

type Props = {
  contactsLength: number;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  /**
   * The validation prop is important, before the user can move onto the next page, they must provide a condition such as all the inputs being filled in.
   */
  screenIsSmall: boolean;
};

const SummaryFooter: FC<Props> = ({
  contactsLength,
  currentStep,
  setCurrentStep,

  screenIsSmall,
}: Props) => {
  const { contacts, externalRefId, externalRefTypeId } = useContactState();
  const [createContact, { loading }] = useMutation<
    CreateContact_createContacts,
    CreateContactVariables
  >(CreateContact);

  const createContacts = () => {
    const formattedContacts = formatContacts(
      contacts,
      externalRefId,
      externalRefTypeId
    );
    createContact({
      variables: {
        input: formattedContacts,
      },
    });
  };

  return (
    <>
      <LoadingBackdrop isOpen={loading} />
      <Grid container sx={componentStyles.container}>
        <Grid xs={6} sm={4}>
          <PreviousButton
            onClick={() => setCurrentStep((oldStep) => oldStep - 1)}
          />
        </Grid>
        {!screenIsSmall && (
          <Grid sm={4} item>
            <StepsFooter
              currentStep={currentStep}
              totalSteps={contactsLength}
            />
          </Grid>
        )}
        <Grid sx={componentStyles.navigation} xs={6} sm={4}>
          <NextOrFinishButton
            next={currentStep !== contactsLength + 2}
            onClick={createContacts}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryFooter;
