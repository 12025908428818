import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { FetchResult } from "@apollo/client";

import { AffectedArea, Status } from "../../ServiceDisruptionTypes";
import { splitLCPs, serviceTypeMapping } from "../../data-model";
import {
  UpdateOutage,
  UpdateOutageVariables,
} from "../../__generated__/UpdateOutage";

import styles from "./styles";

export type Props = {
  affectedAreas: AffectedArea[];
  classes: {
    active: string;
    buttonRoot: string;
    cancelled: string;
    cardActionsRoot: string;
    chipIcon: string;
    complete: string;
    header: string;
    cardRoot: string;
  };
  endAt?: Date | null;
  id: string;
  isPlanned: boolean;
  affectedResidentialCustomers?: number | null;
  affectedBusinessCustomers?: number | null;
  serviceType: string;
  serviceTypeName: string;
  startAt: Date;
  status: Status;
  refetchOutages: () => void;
  /**
   * This is a mutation hook using Apollo Client's `useMutation` hook.
   */
  updateOutage: ({
    variables,
  }: {
    variables: UpdateOutageVariables;
  }) => Promise<FetchResult<UpdateOutage>>;
};

const OutageCard: React.FC<Props> = ({
  id,
  serviceType,
  serviceTypeName,
  startAt,
  endAt,
  affectedAreas,
  isPlanned,
  classes,
  status,
  affectedResidentialCustomers,
  affectedBusinessCustomers,
  refetchOutages,
  updateOutage,
}: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const splitAffectedAreas = splitLCPs(affectedAreas);
  const url = `/service-disruptions/${
    isPlanned ? "maintenances" : "outages"
  }/${id}`;
  const maxAffectedAreas = 4;

  const parsedStatus = (() => {
    switch (status) {
      case "A":
        return "Active";
      case "X":
        return "Cancelled";
      case "C":
        return "Complete";
    }
  })();

  const chipColor = (() => {
    if (status === "A") {
      return "primary";
    }
    if (status === "X") {
      return "default";
    }
    if (status === "C") {
      return "success";
    }
  })();

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuIsOpen(true);
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  const updateOutageStatus = (status: Status) => {
    const parsedAffectedAreas = affectedAreas.map((affectedArea) => ({
      city: affectedArea.city,
      state: affectedArea.state,
      lcp: affectedArea.lcp,
    }));

    const outage = {
      id,
      serviceType,
      startAt: startAt,
      endAt: endAt,
      affectedAreas: parsedAffectedAreas,
      status,
      affectedResidentialCustomers,
      affectedBusinessCustomers,
    };
    updateOutage({ variables: { outage } }).then(refetchOutages);
    closeMenu();
  };

  const cancelOutage = () => updateOutageStatus("X");
  const completeOutage = () => updateOutageStatus("C");

  let ChipIcon = <></>;
  switch (status) {
    case "A":
      ChipIcon = <Icon>info_outlined</Icon>;
      break;
    case "X":
      ChipIcon = <Icon>close</Icon>;
      break;
    case "C":
      ChipIcon = <Icon>done</Icon>;
      break;
  }

  return (
    <div className={classes.cardRoot}>
      <Card>
        <Menu open={menuIsOpen} anchorEl={menuAnchor} onClose={closeMenu}>
          <MenuItem onClick={completeOutage}>Complete Outage</MenuItem>
          <MenuItem onClick={cancelOutage}>Cancel Outage</MenuItem>
        </Menu>
        <CardHeader
          classes={{ root: classes.header }}
          title={`${serviceTypeMapping[serviceTypeName]} ${
            isPlanned ? "Maintenance" : "Outage"
          }`}
          action={
            status === "A" && (
              <IconButton onClick={openMenu}>
                <Icon>more_vert</Icon>
              </IconButton>
            )
          }
          subheader={
            <>
              <Chip
                size="small"
                icon={ChipIcon}
                color={chipColor}
                label={parsedStatus}
                sx={{
                  marginTop: "0.5em",
                  marginBottom: "0.5em",
                }}
              />
              <Typography color="textSecondary">
                {format(startAt, "M/d/yyyy")}{" "}
                {endAt ? <>to {format(endAt, "M/d/yyyy")}</> : null}
              </Typography>
            </>
          }
        />
        <CardContent>
          {splitAffectedAreas
            .slice(0, maxAffectedAreas)
            .map((affectedArea, i) => (
              <Typography key={i}>
                {affectedArea.city}, {affectedArea.state}
              </Typography>
            ))}
          {splitAffectedAreas.length > maxAffectedAreas && (
            <Typography>
              ...and {splitAffectedAreas.length - maxAffectedAreas} more
            </Typography>
          )}
        </CardContent>
        <CardActions classes={{ root: classes.cardActionsRoot }}>
          <Button
            variant="text"
            classes={{ root: classes.buttonRoot }}
            component={Link}
            to={url}
          >
            View
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const StyledComponent = withStyles(styles)(OutageCard);

export { OutageCard };

export default StyledComponent;
