import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERIES } from "../BillingModelRatePlanQueries";
import {
  BillingModelRcdGroupTypes,
  BillingModelRcdGroupTypesVariables,
} from "../__generated__/BillingModelRcdGroupTypes";

export interface RcdGroupTypeSelectData {
  getRcdGroupTypes: (search: string) => void;
  rcdGroupTypesResponse: BillingModelRcdGroupTypes | undefined;
  loading: boolean;
}

function useRcdGroupTypeSelectQuery(search: string): RcdGroupTypeSelectData {
  const [getRcdGroupTypes, { data, loading }] = useLazyQuery<
    BillingModelRcdGroupTypes,
    BillingModelRcdGroupTypesVariables
  >(QUERIES.getRcdGroupTypes, {
    fetchPolicy: "cache-first",
  });

  return useMemo(
    () => ({
      getRcdGroupTypes: (search: string) =>
        getRcdGroupTypes({ variables: { search } }),
      rcdGroupTypesResponse: data,
      loading,
    }),
    [data, getRcdGroupTypes, loading]
  );
}

export default useRcdGroupTypeSelectQuery;
