import { colors } from "styles/colors";

export default {
  previous: {
    marginBottom: "1rem",
    backgroundColor: colors.gray9,
    "&:hover": {
      backgroundColor: colors.gray5,
    },
  },
} as const;
