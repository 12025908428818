import createStyles from "@mui/styles/createStyles";

const styles = createStyles({
  mt_1em: {
    marginTop: "1em",
  },
  centerText: {
    textAlign: "center",
  },
});

export default styles;
