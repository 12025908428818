import { gql } from "@apollo/client";

export const QUERIES = {
  getActivityDates: gql`
    query quotaActivityDates(
      $subId: ID!
      $activityType: String!
      $duration: Int!
      $dayOffset: Int!
      $isEmergency: Boolean
    ) {
      quotaActivityDates(
        subscriberId: $subId
        activityType: $activityType
        duration: $duration
        userName: "MetronetConsole"
        applicationId: "MetronetConsole"
        daysToLookOut: 6
        dayOffset: $dayOffset
        isEmergency: $isEmergency
      ) {
        isFrozen
        isPresale
        isTerminalReady
        schedulingMessage
        dates {
          date
          day
          timeSlots {
            name
            label
            availableQuota
            quotaLimit
          }
        }
      }
    }
  `,
  getTicket: gql`
    query ticket(
      $ticketNumber: String
      $ticketId: String
      $workSpace: String
      $ticketType: String
    ) {
      ticket(
        ticketNumber: $ticketNumber
        ticketId: $ticketId
        workSpace: $workSpace
        ticketType: $ticketType
      ) {
        ticketDetails {
          ticketNumber
          fields {
            key
            value
          }
        }
      }
    }
  `,
  getProjectMetadata: gql`
    query getProjectMetadata {
      ebsProjectTypes(orgId: 508) {
        projectTypes {
          projectType
          description
        }
      }

      ebsProjectMarkets {
        projectMarkets {
          market
        }
      }

      ebsProjectStates {
        projectStates {
          stateName
          stateAcronym
        }
      }
    }
  `,
  getProjects: gql`
    query ebsProjects(
      $state: String
      $market: String
      $projectType: String
      $projectName: String
    ) {
      ebsProjects(
        input: {
          state: $state
          market: $market
          projectType: $projectType
          projectName: $projectName
        }
      ) {
        projects {
          projectId
          projectName
          clliCode
        }
      }
    }
  `,
  getFootprintsTicket: gql`
    query footprintsTicket($input: FootprintsTicketInput!) {
      footprintsTicket(input: $input) {
        ticketDetails {
          ticketNumber
          fields {
            key
            value
          }
        }
      }
    }
  `,
  getBuckets: gql`
    query buckets($bucketType: String!) {
      buckets(bucketType: $bucketType) {
        resourceId
        name
      }
    }
  `,
  getLcps: gql`
    query getLcps($name: String!, $limit: Int, $includeFeederSplice: Boolean) {
      getLcps(
        name: $name
        limit: $limit
        includeFeederSplice: $includeFeederSplice
      ) {
        lcps {
          name
        }
      }
    }
  `,
  getOfscResources: gql`
    query ofscResources(
      $status: OfscResourceStatusInput
      $useTableCache: Boolean
      $resourceType: OfscResourceTypeInput
    ) {
      ofscResources(
        status: $status
        useTableCache: $useTableCache
        resourceType: $resourceType
      ) {
        resourceId
        resourceType
        name
      }
    }
  `,
  getSubscriberData: gql`
    query getSubscriberData($subId: ID!) {
      billingCustomer(subscriberId: $subId) {
        ... on BillingCustomerSuccess {
          customer {
            customerId
            market {
              marketClass
            }
          }
          individual {
            displayName
            geographicAddress {
              id
              streetAddressLine1
              streetAddressLine2
              city
              stateOrProvince
              postCode
              formattedAddress
              status
            }
          }
          organization {
            name
            tradingName
            status
            geographicAddress {
              id
              streetAddressLine1
              streetAddressLine2
              city
              stateOrProvince
              postCode
              formattedAddress
              status
            }
          }
        }
        ... on ErrorMessage {
          message
        }
      }

      masterList(subscriberId: $subId) {
        ... on MasterListSuccess {
          locations {
            active
            equipment {
              port {
                terminal
                active
              }
            }
          }
        }
        ... on ErrorMessage {
          message
        }
      }

      metasolvNetworkInventory(subscriberId: $subId) {
        ... on MetasolvOntSuccess {
          inventory {
            serialNumber
          }
        }
        ... on ErrorMessage {
          message
        }
      }

      billingServices(subscriberId: $subId) {
        ... on BillingServicesSuccess {
          services {
            id
            status
            package {
              name
            }
            connection {
              number
            }
          }
        }
        ... on ErrorMessage {
          message
        }
      }

      ticketLookup(subscriberId: $subId) {
        tickets {
          description
          id
          lastUpdateDTTM
          source
          status
          title
        }
      }

      ofscActivitiesBySubId(subscriberId: $subId) {
        ... on OfscActivitiesSuccess {
          activities {
            activityType
            date
            duration
            ofscActivityId
            timeSlot
            notes
            ticket {
              ticketType
              ticketNumber
            }
            services {
              id
            }
            equipment {
              serialNumber
            }
          }
        }
        ... on ErrorMessage {
          message
        }
      }
    }
  `,
  getActivity: gql`
    query getActivity($ofscActivityId: ID!) {
      ofscActivity(ofscActivityId: $ofscActivityId) {
        ... on OfscActivitiesSuccess {
          activities {
            activityType
            address {
              address1
              address2
              city
              state
              postalCode
            }
            coordinates {
              latitude
              longitude
            }
            date
            duration
            equipment {
              serialNumber
            }
            notes
            ofscActivityId
            ospInfo {
              cablePlaced
              cableSize
              dueDate
              emailNotifications
              hutCabinet
              invoiceTaskCode
              links
              projectCode
              projectName
              ring
              splicePoint
              splicingRequester
              splicingRequestTeam
              ticketType
              troubleType
              typeOfWork
            }
            resourceId
            services {
              id
            }
            slaWindowStart
            slaWindowEnd
            subId
            terminal
            tickets {
              ticketType
              ticketNumber
            }
            timeSlot
          }
        }
        ... on ErrorMessage {
          message
        }
      }
    }
  `,
};

export const MUTATIONS = {
  updateOfscActivity: gql`
    mutation updateOfscActivity($input: OfscUpdateActivityRequest!) {
      updateOfscActivity(input: $input) {
        activityId
        ofscId
        ofscIds
        success
        errorMessage
      }
    }
  `,
};
