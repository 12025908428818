import React from "react";
import { withStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";

import AppFab from "sections/_global/components/AppFab";
import BackButton from "sections/_global/components/BackButton";
import OutagesList from "../components/OutagesList";
import styles from "./styles";

type Props = {
  classes: { root: string };
};

const Maintenances: React.FC<Props> = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <BackButton url="/service-disruptions" />
      <Fade in={true}>
        <div>
          <OutagesList type="planned" />
        </div>
      </Fade>
      <Link to="/service-disruptions/create-maintenance">
        <AppFab accessibilityLabel="New">
          <Icon>add</Icon>
        </AppFab>
      </Link>
    </div>
  );
};
export default withStyles(styles)(Maintenances);
