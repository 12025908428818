export default {
  root: {
    position: "relative" as const,
  },
  title: {
    marginBottom: "1em",
  },
  button: {
    paddingLeft: "0.5em",
  },
};
