import React from "react";
import { useMutation, MutationUpdaterFn } from "@apollo/client";

import { MUTATIONS, FRAGMENTS } from "../../ServiceDisruptionQueries";
import {
  SendOutageNotification,
  SendOutageNotificationVariables,
} from "../../__generated__/SendOutageNotification";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import NotifyDialog from "./NotifyDialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  internalNotificationHasBeenSent: boolean;
  externalNotificationHasBeenSent: boolean;
};

const updateCache: MutationUpdaterFn<SendOutageNotification> = (
  cache,
  { data }
) => {
  if (!data?.sendOutageNotification) return;

  cache.modify({
    fields: {
      outage: () => {
        const updatedOutage = cache.writeFragment({
          data: data.sendOutageNotification?.outage,
          fragment: FRAGMENTS.outageDetails,
        });
        return updatedOutage;
      },
    },
  });
};

const NotifyDialogProvider: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  internalNotificationHasBeenSent,
  externalNotificationHasBeenSent,
}: Props) => {
  const dispatch = useMessageDispatch();
  const [sendOutageNotification, { loading }] = useMutation<
    SendOutageNotification,
    SendOutageNotificationVariables
  >(MUTATIONS.sendOutageNotification, { update: updateCache });

  const sendNotification = ({
    sendExternalNotification,
    sendInternalNotification,
  }: {
    sendExternalNotification: boolean;
    sendInternalNotification: boolean;
  }) => {
    onClose();
    sendOutageNotification({
      variables: { sendExternalNotification, sendInternalNotification, id },
    }).then((response) => {
      const outageNotificationResponse = response.data?.sendOutageNotification;
      if (outageNotificationResponse?.success) {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            description: outageNotificationResponse.message || "",
            type: "success",
            isOpen: true,
            id: "outage-notification-response-success",
          },
        });
      } else {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            description: outageNotificationResponse?.message || "",
            type: "error",
            isOpen: true,
            id: "outage-notification-response-error",
          },
        });
      }
    });
  };

  return (
    <>
      <LoadingBackdrop isOpen={loading} />
      <NotifyDialog
        externalNotificationHasBeenSent={externalNotificationHasBeenSent}
        internalNotificationHasBeenSent={internalNotificationHasBeenSent}
        sendNotification={sendNotification}
        onClose={onClose}
        isOpen={isOpen}
      />
    </>
  );
};

export default NotifyDialogProvider;
