export default {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  next: {
    display: "flex",
    justifyContent: "flex-end",
  },
} as const;
