import { colors } from "styles/colors";

export default {
  addContactButton: {
    backgroundColor: colors.brandGreen,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.brandGreenDark,
    },
    marginBottom: {
      xs: "2rem",
      sm: 0,
    },
  },
} as const;
