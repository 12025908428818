import React, { ReactNode } from "react";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  TextField,
} from "@mui/material";

import LoadingIndicator from "../LoadingIndicator";
import { colors } from "styles/colors";

export interface TypeaheadOption {
  label: string;
  value: any;
}

export interface Props {
  /**
   * The label that is displayed to the user.
   */
  label: string;
  value?: TypeaheadOption | null | string;
  isLoading?: boolean;
  defaultValue?: TypeaheadOption | null;
  isDisabled?: boolean;
  required?: boolean;
  hasError?: boolean;
  helperText?: string;
  /**
   * Array of TypeaheadOption that contain the current options for the Typeahead.
   */
  options: TypeaheadOption[];
  /**
   * Allows for an adornment on the front of the Typeahead.
   */
  startAdornment?: ReactNode;
  /**
   * Allows for an adornment on the end of the Typeahead.
   */
  endAdornment?: ReactNode;
  /**
   * Allows skipping of auto selection of menu item. By default, item is automatically selected when
   * focus is lost.
   */
  skipAutoSelect?: boolean;
  /**
   * Event that fires when the selected TypeaheadOption changes.
   */
  onChange?: (value: TypeaheadOption | null | string) => void;
  onFocus?: () => void;
  /**
   * Event that fires when the text input of the Typeahead changes, can be used for triggering searched
   */
  onInputChange?: (value: string) => void;
  /**
   * Change the MUI variant of the Typeahead.
   */

  /**
   * Specify whether this typeahead is allowed to contain values that aren't actually in the typahead list
   */
  freeSolo?: boolean;

  /**
   * Customize the filter method used to search the options based on the user input.
   */
  filterOptions?: (
    options: TypeaheadOption[],
    state: FilterOptionsState<TypeaheadOption>
  ) => TypeaheadOption[];

  /**
   * Customize how the options are rendered.
   */
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TypeaheadOption,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;

  classes?: {
    root?: string;
    input?: string;
  };

  isOptionEqualToValue?: (option: TypeaheadOption, value: any) => boolean;
}

const Typeahead: React.FC<Props> = ({
  label,
  value,
  isLoading = false,
  defaultValue,
  isDisabled,
  required,
  hasError,
  helperText,
  options,
  startAdornment,
  skipAutoSelect = false,
  onInputChange,
  onFocus,
  onChange,
  freeSolo = false,
  filterOptions,
  renderOption,
  isOptionEqualToValue,
  classes: propClasses,
}: Props) => {
  const adornment = isLoading
    ? { endAdornment: isLoading && <LoadingIndicator /> }
    : { startAdornment };

  return (
    <Autocomplete
      freeSolo={freeSolo}
      options={options}
      value={value}
      autoHighlight
      autoSelect={!skipAutoSelect}
      defaultValue={defaultValue}
      disabled={isDisabled}
      getOptionLabel={(option) =>
        typeof option === "string" ? "" : option.label
      }
      filterOptions={filterOptions}
      renderOption={renderOption}
      fullWidth
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          sx={{ input: { background: colors.white, borderRadius: "0.3rem" } }}
          {...params}
          label={isLoading ? null : label}
          placeholder={isLoading ? "Loading" : undefined}
          disabled={isDisabled || isLoading}
          required={required}
          error={hasError}
          helperText={helperText}
          color="secondary"
          InputProps={{ ...params.InputProps, ...adornment }}
        />
      )}
      onInputChange={(event, value) => {
        if (onInputChange) {
          onInputChange(value);
        }
      }}
      onFocus={() => {
        if (onFocus) onFocus();
      }}
      onChange={(event, value) => {
        if (onChange) onChange(value);
      }}
    />
  );
};

export default Typeahead;
