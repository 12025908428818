import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

import styles from "./styles";

import { getSubscriberData_ticketLookup_tickets as Ticket } from "../../../__generated__/getSubscriberData";

export type Props = {
  classes: {
    formControl: string;
  };
  handleChange: (e: any) => void;
  selected: string;
  options?: Ticket[];
  ticketType: string;
};

function dateToLocalTime(date: string) {
  const dt = new Date(date);
  return dt.toLocaleString();
}

const Tickets: React.FC<Props> = ({
  classes,
  handleChange,
  selected,
  options = [],
  ticketType,
}: Props) => {
  const optionsByType = options.filter(
    (ticket) =>
      ticket?.source?.toLowerCase() === ticketType.toLowerCase() &&
      ticket?.status?.toLowerCase() !== "closed"
  );

  return (
    <>
      {options.length == 0 ? (
        <></>
      ) : (
        <FormControl classes={{ root: classes.formControl }}>
          <TextField
            select
            id="tickets"
            value={selected}
            sx={{ minWidth: 200 }}
            onChange={handleChange}
            label={`${ticketType} Tickets`}
            disabled={optionsByType.length < 1}
          >
            <MenuItem key="" value="">
              <em>None</em>
            </MenuItem>
            {optionsByType.map((ticket, i) => (
              <MenuItem
                key={`${ticket?.source?.toUpperCase()}!${ticket.id}`}
                value={`${ticket?.source?.toUpperCase()}!${ticket.id}`}
              >
                <em>{`${ticket.id} - ${ticket.title} (Last Updated: ${
                  ticket.lastUpdateDTTM &&
                  dateToLocalTime(ticket.lastUpdateDTTM)
                })`}</em>
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      )}
    </>
  );
};

export default withStyles(styles)(Tickets);
