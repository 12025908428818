import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Alert,
} from "@mui/material";

import { isProductionConfigValue } from "../../contexts/ClientAdminConfigContext";
import { ClientAdminConfig, ClientAdminConfigField } from "../../types";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (config: ClientAdminConfig) => void;
  databaseInstance: string;
  databaseInstanceOptions: string[];
};

const ConfigDialog: React.FC<Props> = ({
  databaseInstance,
  isOpen,
  onClose,
  onSave,
  databaseInstanceOptions,
}: Props) => {
  const [formFields, setFormFields] = useState<ClientAdminConfig>({
    databaseInstance,
  });
  const databaseHelperText = isProductionConfigValue(
    "databaseInstance",
    formFields.databaseInstance
  )
    ? "Caution: This is a production instance."
    : null;

  const databaseInstanceItems = databaseInstanceOptions.map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ));

  const [reloadPromptVisibility, setReloadPromptVisibility] = useState(false);

  const onSelectChange = (name: ClientAdminConfigField) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormFields({ ...formFields, [name]: event.target.value });
    setReloadPromptVisibility(true);
  };

  const save = () => {
    onSave(formFields);
    onClose();
    setReloadPromptVisibility(false);
    window.location.reload();
  };

  const cleanup = () => {
    setFormFields({
      databaseInstance,
    });
    setReloadPromptVisibility(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        onExited: cleanup,
      }}
    >
      <DialogTitle>Update Config</DialogTitle>
      <DialogContent
        sx={{
          minWidth: "30em",
          display: "flex",
          flexDirection: "column" as const,
        }}
      >
        <>
          <TextField
            InputLabelProps={{ id: "databaseInstanceLabel" }}
            inputProps={{ "aria-labelledby": "databaseInstanceLabel" }}
            label="Database Instance"
            value={formFields.databaseInstance}
            variant="outlined"
            sx={{
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
            fullWidth
            helperText={databaseHelperText}
            select
            error={Boolean(databaseHelperText)}
            onChange={onSelectChange("databaseInstance")}
          >
            {databaseInstanceItems}
          </TextField>
          {reloadPromptVisibility && (
            <Alert severity="warning">
              Caution: changing the database instance will reload the page
            </Alert>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Close
        </Button>
        <Button onClick={save} variant="text">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigDialog;
