import React, { FC } from "react";
import { Button, Theme, Icon } from "@mui/material";
import componentStyles from "./styles";
import { SxProps } from "@mui/system";

export type Props = {
  title?: string;
  classes?: SxProps<Theme>;
  onClick: () => void;
};

const PreviousButton: FC<Props> = ({
  classes: optionalClasses,
  onClick,
  title,
}: Props) => {
  return (
    <Button
      startIcon={<Icon>chevron_left</Icon>}
      sx={{ ...componentStyles.previous, optionalClasses }}
      onClick={onClick}
    >
      {title || "Previous"}
    </Button>
  );
};

export default PreviousButton;
