import React from "react";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import styles from "./styles";
import { LinkType } from "./navListDefinitions";

type Props = {
  name: string;
  onClick: () => void;
  url: string;
  text: string;
  icon?: React.ReactElement;
  linkType: LinkType;
};

const MenuItem: React.FC<Props> = ({
  onClick,
  url,
  text,
  icon,
  linkType,
}: Props) => {
  return (
    <ListItem
      button
      color="secondary"
      component={Link}
      to={linkType == "external" ? { pathname: url } : url}
      target={linkType == "external" ? "_blank" : undefined}
      rel="noopener noreferrer"
      onClick={onClick}
      role="link"
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
};

export default withStyles(styles)(MenuItem);
