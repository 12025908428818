import { Grid, Box, Icon, Slide, Fab } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import BillingModelQueryNameField from "sections/billing-model-common/components/BillingModelQueryNameField";
import BillingModelTextAdornment from "sections/billing-model-common/components/BillingModelTextAdornment";
import ConfirmationDialog from "sections/billing-model-common/components/ConfirmationDialog";
import { RatePlanType } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { useRatePlanDefaultValues } from "sections/billing-model-rateplans/hooks/useRatePlanDefaultValues";
import AppFab from "sections/_global/components/AppFab";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import {
  validateRatePlanType,
  validateRatePlanTypeField,
} from "../../validation";

interface FormState {
  isInvalid: boolean;
  errors: FormError;
  showDeleteConfirmation: boolean;
}

export interface Props {
  ratePlanType: RatePlanType;
  nameSearchDebounceMilliseconds?: number;
  checkingName?: boolean;
  isNameInUse?: boolean;
  onChange?: (rpt: RatePlanType) => void;
  onCheckName?: (name: string) => void;
  onSubmit?: () => void;
  onDelete?: () => void;
}

const RatePlanTypeForm: React.FC<Props> = ({
  ratePlanType,
  nameSearchDebounceMilliseconds,
  checkingName = false,
  isNameInUse = false,
  onChange,
  onCheckName,
  onSubmit,
  onDelete,
  children,
}: PropsWithChildren<Props>) => {
  const isNew = ratePlanType.ratePlanTypeId === "";
  const defaultValues = useRatePlanDefaultValues();
  const dispatch = useMessageDispatch();
  const [state, setState] = useState<FormState>({
    isInvalid: false,
    errors: {},
    showDeleteConfirmation: false,
  });

  const checkName = (nameExists: boolean, errors: FormError) => {
    const errs = { ...errors };
    errs["name"] = nameExists
      ? "A rate plan type with that name already exists."
      : errs["name"];

    return errs;
  };

  useEffect(() => {
    setState((s) => ({ ...s, errors: checkName(isNameInUse, s.errors) }));
  }, [isNameInUse]);

  const handleNameChange = (name: string) => {
    const update: RatePlanType = { ...ratePlanType, name };
    const [isInvalid, errors] = validateRatePlanTypeField(
      "name",
      update,
      state.errors
    );

    setState({
      ...state,
      isInvalid,
      errors,
    });

    if (onChange) onChange({ ...ratePlanType, name });
  };

  const handleSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const [isInvalid, errors] = validateRatePlanType(ratePlanType);
    if (isInvalid || isNameInUse) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "Please fix the errors before saving.",
          type: "error",
          isOpen: true,
          id: "invalid-form-msg",
        },
      });
      setState({
        ...state,
        errors: checkName(isNameInUse, errors),
      });
    } else if (ratePlanType.ratingControlGroupTypes?.length === 0) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "Please add at least one RCD Group Type.",
          type: "error",
          isOpen: true,
          id: "invalid-form-msg",
        },
      });
    } else if (onSubmit) {
      dispatch({ type: "CLOSE_MESSAGE", message: "invalid-form-msg" });
      onSubmit();
    }
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={state.showDeleteConfirmation}
        title="Are you sure you want to delete this Rate Plan Type?"
        okButtonLabel="Yes"
        cancelButtonLabel="No"
        okClick={() => {
          if (onDelete) onDelete();
        }}
        cancelClick={() =>
          setState({ ...state, showDeleteConfirmation: false })
        }
      />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <BillingModelQueryNameField
              debounceMilliseconds={nameSearchDebounceMilliseconds}
              useFullWidth
              label="Name"
              value={ratePlanType.name}
              isDisabled={
                !defaultValues.ratePlanTypeAdmin &&
                ratePlanType.ratePlanTypeId.valueOf() !== ""
              }
              hasError={state.errors.name != null}
              helperText={state.errors?.name ?? undefined}
              isLoading={checkingName}
              exists={isNameInUse}
              startAdornment={
                ratePlanType.ratePlanTypeId !== "" ? (
                  <BillingModelTextAdornment
                    value={ratePlanType.ratePlanTypeId}
                  />
                ) : null
              }
              onChange={(value) => handleNameChange(value)}
              onSearch={onCheckName}
            />
          </Grid>
        </Grid>

        <Box marginTop="3rem">{children}</Box>

        {defaultValues.ratePlanTypeAdmin && !isNew && (
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Fab
              sx={{
                background: "red",
                ":hover": {
                  color: "white",
                  background: "red !important",
                  backgroundColor: "red !important",
                },
              }}
              onClick={() =>
                setState({ ...state, showDeleteConfirmation: true })
              }
              aria-label="Delete Rate Plan Type"
            >
              <Icon>delete</Icon>
            </Fab>
          </Slide>
        )}

        {defaultValues.canWriteRatePlanTypes && isNew && (
          <AppFab
            onClick={handleSubmit}
            accessibilityLabel="Save Rate Plan Type"
          >
            <Icon>save</Icon>
          </AppFab>
        )}
      </form>
    </>
  );
};

export default RatePlanTypeForm;
