import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Icon } from "@mui/material";

import { SplitAffectedArea } from "../../data-model";
import styles from "./styles";

export type Props = SplitAffectedArea & {
  classes: {
    expansionPanel: string;
    expansionPanelDetails: string;
    lcp: string;
  };
};

const AffectedAreaAccordion: React.FC<Props> = ({
  city,
  state,
  lcps,
  numberOfCallbacks,
  numberOfCalls,
  classes,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      setShowDetails(true);
    }
  }, [isExpanded, setShowDetails]);

  return (
    <div>
      <Accordion
        square
        sx={{
          background: "none",
          borderBottom: "1px solid lightgrey",
        }}
        elevation={0}
        expanded={isExpanded}
        onClick={toggleExpanded}
        TransitionProps={{
          onExited: () => {
            setShowDetails(false);
          },
        }}
      >
        <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
          <Typography>
            {city}, {state}{" "}
          </Typography>
          <Typography marginLeft="0.5em" color={"textSecondary"}>
            {numberOfCalls} Calls &middot; {numberOfCallbacks} Callbacks
          </Typography>
        </AccordionSummary>
        {showDetails && (
          <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
            {lcps.length > 0 &&
              lcps
                .sort((a, b) =>
                  a.name.localeCompare(b.name, "en", { numeric: true })
                )
                .map((lcp) => (
                  <div key={lcp.name} className={classes.lcp}>
                    <Typography>{lcp.name}</Typography>
                    <Typography color={"textSecondary"} marginLeft="0.5em">
                      {lcp.numberOfCalls} Calls &middot; {lcp.numberOfCallbacks}{" "}
                      Callbacks
                    </Typography>
                  </div>
                ))}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

const StyledComponent = withStyles(styles)(AffectedAreaAccordion);

export { AffectedAreaAccordion };

export default StyledComponent;
