export default {
  container: {
    width: "92.5%",
    marginTop: "2rem",
  },
  navigation: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
} as const;
