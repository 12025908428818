export type LinkType = "internal" | "external";

export type navList = {
  name: string;
  url: string;
  linkType: LinkType;
};

const navLinksDefinitions = (): navList[] => [
  {
    name: "Service Disruptions;Outages",
    url: "/service-disruptions/outages",
    linkType: "internal",
  },
  {
    name: "Service Disruptions;Maintenances",
    url: "/service-disruptions/maintenances",
    linkType: "internal",
  },
  {
    name: "Billing Model;Rate Plans",
    url: "/billing-model/rates/list",
    linkType: "internal",
  },
  {
    name: "Billing Model;Rate Plan Types",
    url: "/billing-model/rate-types/list",
    linkType: "internal",
  },
  {
    name: "External Links;Oracle E-Business Suite (EBS)",
    url: "http://ebs1.in.qservco.com:8000",
    linkType: "external",
  },
  {
    name: "External Links;SharePoint",
    url: "https://sharepoint.ad.qservco.com",
    linkType: "external",
  },
  {
    name: "External Links;UltiPro (UKG)",
    url: "https://qservices.ultipro.com/",
    linkType: "external",
  },
  {
    name: "External Links;Personal IP Address Lookup",
    url: "https://webapps.metronetinc.com/cgi-bin/ip.cgi",
    linkType: "external",
  },
  {
    name: "External Links;Field Tech Admin (FTA)",
    url: "https://webapps.metronetinc.com/fta",
    linkType: "external",
  },
  {
    name: "External Links;Oracle Field Services Cloud (OFSC)",
    url: "https://metronet.etadirect.com/",
    linkType: "external",
  },
  {
    name: "External Links;Construction Communication Tool (CCT)",
    url: "https://webapps.metronetinc.com/cct",
    linkType: "external",
  },
  {
    name: "External Links;Construction Tracker",
    url: "https://webapps.metronetinc.com/ospcal",
    linkType: "external",
  },
  {
    name: "External Links;Inventory Manager",
    url: "https://apps.metronetinc.com/inventory-manager",
    linkType: "external",
  },
  {
    name: "External Links;Inventory Scanner",
    url: "https://qmanager.cinergymetronet.com/EquipMgr/ajax/inventory.cgi",
    linkType: "external",
  },
  {
    name: "External Links;Intelligent Support Resolution (ISR)",
    url: "https://webapps.metronetinc.com/isr",
    linkType: "external",
  },
  {
    name: "External Links;Q-Manager",
    url: "https://qmanager.cinergymetronet.com",
    linkType: "external",
  },
  {
    name: "External Links;Quote Tool",
    url: "https://webapps.metronetinc.com/quote",
    linkType: "external",
  },
  {
    name: "External Links;Self Sign-Up (SSU)",
    url: "https://signup.metronetinc.com",
    linkType: "external",
  },
  {
    name: "External Links;View/Edit Subscriber (VES)",
    url: "https://webapps.metronetinc.com/ves",
    linkType: "external",
  },
];

export default navLinksDefinitions;
