import { useMutation, useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import RouteExitGuard from "sections/billing-model-common/components/RouteExitGuard";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import {
  BamUpdateRatePlanTypeInput,
  BamUpdateRcdGroupInput,
} from "../../../../__generated__/globalTypes";
import { MUTATIONS, QUERIES } from "../BillingModelRatePlanQueries";
import RatePlanTypeForm from "sections/billing-model-rateplans/components/RatePlanTypeForm";
import {
  BillingModelRatePlanType,
  BillingModelRatePlanTypeVariables,
} from "../__generated__/BillingModelRatePlanType";
import {
  BillingModelUpdateRatePlanType,
  BillingModelUpdateRatePlanTypeVariables,
} from "../__generated__/BillingModelUpdateRatePlanType";
import {
  BamSortBy,
  BamSortOrder,
} from "sections/billing-model-common/BillingModelTypes";
import { RatePlan, RatePlanType, RcdGroup } from "../BillingModelRatePlanTypes";
import {
  BillingModelRatePlansList,
  BillingModelRatePlansListVariables,
} from "../__generated__/BillingModelRatePlansList";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import AddRcdGroupTypeDialog from "../components/AddRcdGroupTypeDialog";
import useRcdGroupTypeSelectQuery from "../hooks/useRcdGroupTypeSelectQuery";
import usePeriodTypeSelectQuery from "../hooks/usePeriodTypeSelectQuery";
import useTransactionTypeSelectQuery from "../hooks/useTransactionTypeSelectQuery";

interface Params {
  id: string;
}

/*
 * NOTE: This interface does not allow updating Rate Plan Types as the billing-rate-plan-sapi does not support it.
 *       If the billing-rate-plan-sapi is ever updated to support updating rate plan types the functionality already exists in the interface
 *       It is just required that the AppFab button, Add RCD Group Type button, and delete buttons are re-enabled.
 */

const EditRatePlanType = (): JSX.Element => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useMessageDispatch();

  const showMessage = (
    id: string,
    message: string,
    type: "success" | "warning" | "error"
  ) => {
    dispatch({ type: "CLEAR_MESSAGES" });
    dispatch({
      type: "ADD_MESSAGE",
      message: {
        description: message,
        type,
        isOpen: true,
        id,
      },
    });
  };

  const showUpdateSuccessMessage = () =>
    showMessage(
      "rateplantype-update-success",
      "Rate plan type updated!",
      "success"
    );

  const showNoChangesMessage = () =>
    showMessage(
      "no-changes-message",
      "There are no changes to be saved.",
      "warning"
    );

  const showUpdateFailedMessage = () =>
    showMessage(
      "graphql-error",
      "An error occurred while updating the Rate Plan Type.",
      "error"
    );

  const showDeleteSuccessMessage = () =>
    showMessage(
      "rateplantype-delete-success",
      "Rate plan type deleted!",
      "success"
    );

  const showDeleteFailedMessage = () =>
    showMessage(
      "graphql-error",
      "Unable to delete Rate Plan type, this may be due to it being in use by Rate Plans.",
      "error"
    );

  const [haveChanges, setHaveChanges] = useState<boolean>(false);
  const [ratePlanType, setRatePlanType] = useState<RatePlanType>();
  const [ratePlans, setRatePlans] = useState<RatePlan[]>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [addingRcdGroupType, setAddingRcdGroupType] = useState<boolean>(false);

  const { loading } = useQuery<
    BillingModelRatePlanType,
    BillingModelRatePlanTypeVariables
  >(QUERIES.getRatePlanType, {
    fetchPolicy: "network-only",
    skip: id === "" || id === null || id === undefined,
    variables: { id: id },
    onCompleted: (response) => {
      if (response.bamRatePlanType) {
        setRatePlanType(response.bamRatePlanType as RatePlanType);
      }
    },
  });

  const { loading: ratePlansLoading } = useQuery<
    BillingModelRatePlansList,
    BillingModelRatePlansListVariables
  >(QUERIES.getRatePlansList, {
    skip: id === "" || id === null || id === undefined,
    variables: {
      input: {
        typeId: Number(id),
        page: 1,
        pageSize: 1000,
        sortBy: BamSortBy.name,
        sortOrder: BamSortOrder.ascending,
      },
    },
    onCompleted: (response) => {
      if (response.bamRatePlans) {
        setRatePlans(response.bamRatePlans.data as RatePlan[]);
      }
    },
  });

  const [updateRatePlanType, { loading: submitting }] = useMutation<
    BillingModelUpdateRatePlanType,
    BillingModelUpdateRatePlanTypeVariables
  >(MUTATIONS.updateRatePlanType);

  const [deleteRatePlanType, { loading: deleting }] = useMutation(
    MUTATIONS.deleteRatePlanType
  );

  const handleAddRcdGroup = async (
    ratePlanTypeUpdate: RatePlanType,
    ratePlansUpdate: RatePlan[] | undefined
  ) => {
    const convertGroup = (group: RcdGroup): BamUpdateRcdGroupInput => {
      if (!isNaN(Number(group.ratingControlGroupId))) {
        return {
          ratingControlGroupId: group.ratingControlGroupId,
        };
      }

      return {
        ratingControlGroupId: group.ratingControlGroupId,
        ratingControlGroupTypeId:
          group.ratingControlGroupType?.ratingControlGroupTypeId,
        transactionTypeId: group.transactionType?.transactionTypeId,
        periodTypeId: group.periodType?.periodTypeId,
        startDate: group.startDate,
        endDate: group.endDate,
        ratingControlElements: group.ratingControlElements.map((e) => ({
          ratingControlElementId: e.ratingControlElementId,
          ratingControlElementTypeId:
            e.ratingControlElementType.ratingControlElementTypeId,
          value: e.value,
        })),
      };
    };

    setAddingRcdGroupType(true);
    updateRatePlanType({
      variables: {
        input: {
          ratePlanType: {
            ratePlanTypeId: ratePlanTypeUpdate.ratePlanTypeId,
            name: ratePlanTypeUpdate.name ?? "",
            ratingControlGroupTypes:
              ratePlanTypeUpdate.ratingControlGroupTypes?.map((gt) => ({
                ratingControlGroupTypeId: gt.ratingControlGroupTypeId,
              })) ?? [],
          },
          ratePlans:
            ratePlansUpdate?.map((rpu) => ({
              ratePlanId: rpu.ratePlanId,
              name: rpu.name,
              description: rpu.description,
              startDate: rpu.startDate,
              endDate: rpu.endDate,
              currencyCode: rpu.currencyCode,
              ratePlanTypeId: rpu.ratePlanType?.ratePlanTypeId ?? "",
              ratingControlGroups: rpu.ratingControlGroups.map((g) =>
                convertGroup(g)
              ),
            })) ?? [],
        },
      },
    })
      .then((response) => {
        if (
          response.data?.bamUpdateRatePlanType?.__typename ===
          "BamUpdateRatePlanTypeSuccess"
        ) {
          const rpt = {
            ...response.data.bamUpdateRatePlanType.ratePlanType,
            ratingControlGroupTypes:
              response.data.bamUpdateRatePlanType.ratePlanType.ratingControlGroupTypes?.map(
                (gt) => ({ ...gt })
              ) ?? [],
          };

          const ratePlans = response.data.bamUpdateRatePlanType.ratePlans;

          setRatePlanType(rpt);

          setRatePlans(
            ratePlans?.map((rp) => ({
              ...rp,
              ratingPlanType: rpt,
              ratingControlGroups:
                rp.ratingControlGroups?.map((o) => ({
                  ...o,
                  ratingControlElements: o.ratingControlElements ?? [],
                })) ?? [],
            })) ?? []
          );

          setAddingRcdGroupType(false);
          setDialogOpen(false);
          showUpdateSuccessMessage();
        } else {
          setAddingRcdGroupType(false);
          showUpdateFailedMessage();
        }
      })
      .catch((error) => {
        setAddingRcdGroupType(false);
        showUpdateFailedMessage();
      });

    setRatePlanType(ratePlanTypeUpdate);
    setRatePlans(ratePlansUpdate);
  };

  const handleSubmit = async () => {
    if (!haveChanges) {
      showNoChangesMessage();
    } else if (ratePlanType) {
      const input: BamUpdateRatePlanTypeInput = {
        ratePlanType: {
          name: ratePlanType.name ?? "",
          ratingControlGroupTypes:
            ratePlanType.ratingControlGroupTypes?.map((rgt) => ({
              ratingControlGroupTypeId: rgt.ratingControlGroupTypeId,
            })) ?? [],
        },
        ratePlans: [],
      };

      try {
        const response = await updateRatePlanType({ variables: { input } });
        const isError =
          response.data?.bamUpdateRatePlanType?.__typename ===
          "BamUpdateRatePlanTypeError";

        setHaveChanges(isError);
        isError ? showUpdateFailedMessage() : showUpdateSuccessMessage();
        if (!isError) history.push("/billing-model/rate-types/list");
      } catch {
        showUpdateFailedMessage();
      }
    }
  };

  const handleDelete = async () => {
    if (ratePlanType && ratePlanType.ratePlanTypeId !== "") {
      deleteRatePlanType({
        variables: { id: Number(ratePlanType.ratePlanTypeId) },
      })
        .then((response) => {
          if (response.data.bamDeleteRatePlanType.success) {
            showDeleteSuccessMessage();
            history.push("/billing-model/rate-types/list");
          } else showDeleteFailedMessage();
        })
        .catch(() => showDeleteFailedMessage());
    }
  };

  if (loading || ratePlansLoading) return <LoadingBackdrop isOpen={true} />;

  return (
    <BillingModelScreen
      backUrl="/billing-model/rate-types/list"
      title="Edit Rate Plan Type"
    >
      <RouteExitGuard showPrompt={haveChanges || dialogOpen} />

      <LoadingBackdrop isOpen={addingRcdGroupType} />

      {ratePlanType && (
        <>
          <AddRcdGroupTypeDialog
            isOpen={dialogOpen}
            ratePlanType={ratePlanType}
            ratePlans={ratePlans ?? []}
            usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
            useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
            useRcdGroupTypeSelectQuery={useRcdGroupTypeSelectQuery}
            onSubmitClick={handleAddRcdGroup}
            onCloseClick={() => setDialogOpen(false)}
          />
          <RatePlanTypeForm
            loading={loading || submitting || deleting}
            ratePlanType={ratePlanType}
            ratePlans={ratePlans}
            onAddRcdGroupType={() => setDialogOpen(true)}
            onChange={(rpt, rp) => {
              setHaveChanges(true);
              setRatePlanType(rpt);
              setRatePlans(rp);
            }}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        </>
      )}
      {ratePlanType === undefined && (
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h5">Rate Plan Type not found.</Typography>
        </Box>
      )}
    </BillingModelScreen>
  );
};

export default EditRatePlanType;
