import React from "react";

import { can } from "lib/Authorization";
import { Permission } from "sections/_global/types";

type Props = {
  permissions: Permission[];
  performAction: string;
  yes: React.ReactElement;
  no: React.ReactElement;
};

const Can: React.FC<Props> = ({
  permissions,
  performAction,
  yes,
  no,
}: Props) => {
  return can({ permissions, performAction }) ? yes : no;
};

export default Can;
