import { Box, Typography } from "@mui/material";
import React from "react";
import BillingModelCardList from "../../../billing-model-common/components/BillingModelCardList";
import PaginatedList from "../../../_global/components/PaginatedList";
import RatePlanView from "../RatePlanView";
import { BillingModelSearch } from "../../../billing-model-common/BillingModelSearch";
import { RatePlan, RatePlanViewInfo } from "../../BillingModelRatePlanTypes";
import { BillingModelRatePlansList_bamRatePlans_data } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlansList";

export interface Props {
  selectedRatePlan: RatePlan | null;
  ratePlans: BillingModelRatePlansList_bamRatePlans_data[];
  ratePlanDetails: RatePlanViewInfo | null;
  page?: number | null;
  totalPages?: number | null;
  isLoadingList?: boolean;
  isLoadingDetails?: boolean;
  onPageChange: (page: number) => void;
  onRatePlanSelected: (ratePlan: RatePlanViewInfo | null) => void;
  onSearch: (search: BillingModelSearch) => void;
}

const SelectRatePlan: React.FC<Props> = ({
  selectedRatePlan,
  ratePlans,
  ratePlanDetails,
  page,
  totalPages,
  isLoadingList,
  isLoadingDetails,
  onPageChange,
  onRatePlanSelected,
  onSearch,
}: Props) => {
  const sortOptions = ["Newest", "Recent Changes", "Name"];

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        marginBottom="3em"
      >
        <Typography variant="h4">Select Rate Plan</Typography>
        <Typography variant="body1">
          To create a rate plan based on an existing one, search by name or id
          and left click to select the existing rate plan.
        </Typography>
        <Typography variant="body1">
          To create a new blank rate plan, click next.
        </Typography>
      </Box>
      <BillingModelCardList
        sort={"Name"}
        sortOptions={sortOptions}
        onSearch={onSearch}
      >
        <PaginatedList
          loading={isLoadingList ?? false}
          page={page ?? 1}
          totalPages={totalPages ?? 1}
          onPageChange={onPageChange}
        >
          {ratePlans.map((rp, i) => (
            <RatePlanView
              key={rp.ratePlanId}
              summary={rp}
              loading={isLoadingDetails ?? false}
              details={selectedRatePlan}
              selected={ratePlanDetails?.ratePlanId === rp.ratePlanId ?? false}
              onSelected={onRatePlanSelected}
            />
          ))}
        </PaginatedList>
      </BillingModelCardList>
    </>
  );
};

export default SelectRatePlan;
