import React, { useState, useCallback, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Dialog,
  Fade,
  FormControlLabel,
  Switch,
  Typography,
  Icon,
} from "@mui/material";
import { useMutation, MutationUpdaterFn } from "@apollo/client";
import { zonedTimeToUtc } from "date-fns-tz";

import AddAffectedAreaDialog from "../AddAffectedAreaDialog";
import AffectedAreaAccordions from "../AffectedAreaAccordions";
import NotificationWarning from "../NotificationWarning";
import AppFab from "sections/_global/components/AppFab";
import BackButton from "sections/_global/components/BackButton";
import OutageFormFields from "../OutageFormFields";
import { splitLCPs } from "../../data-model";
import {
  CreateOutage as CreateOutageData,
  CreateOutageVariables,
} from "../../__generated__/CreateOutage";
import { CreateOutageInput } from "../../../../../__generated__/globalTypes";
import {
  CreateOutageFormFields,
  CreateOutageFormFieldNames,
  UpdateOutageFormFields,
  LCP,
  ValidationError,
} from "../../ServiceDisruptionTypes";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { MUTATIONS, FRAGMENTS } from "../../ServiceDisruptionQueries";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";

import useStyles from "./styles";

const initialFormFields = {
  serviceType: "",
  startAt: null,
  endAt: null,
  affectedAreas: [],
  description: "",
  sendExternalNotification: false,
  sendInternalNotification: false,
  affectedResidentialCustomers: null,
  affectedBusinessCustomers: null,
};

const updateCache: MutationUpdaterFn<CreateOutageData> = (cache, { data }) => {
  if (!data?.createOutage) return;

  cache.modify({
    fields: {
      outages: (existingOutageRefs) => {
        const newOutageRef = cache.writeFragment({
          data: data.createOutage?.outage,
          fragment: FRAGMENTS.outageDetails,
        });
        return existingOutageRefs
          ? [...existingOutageRefs, newOutageRef]
          : [newOutageRef];
      },
    },
  });
};

type Props = {
  isPlanned: boolean;
};

const CreateOutageForm: React.FC<Props> = ({ isPlanned }: Props) => {
  const classes = useStyles();
  const dispatch = useMessageDispatch();
  const [showNotificationWarning, setShowNotificationWarning] = useState<
    boolean
  >(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<CreateOutageFormFields>(
    initialFormFields
  );
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );
  const [createOutage, { loading }] = useMutation<
    CreateOutageData,
    CreateOutageVariables
  >(MUTATIONS.createOutage, {
    update: updateCache,
  });
  const [affectedAreaDialogIsOpen, setAffectedAreaDialogIsOpen] = useState<
    boolean
  >(false);

  const setValidationErrorsCallback = useCallback(
    (errors: ValidationError[]) => {
      setValidationErrors(errors);
    },
    [setValidationErrors]
  );

  const setFormFieldsCallback = useCallback(
    (fields: CreateOutageFormFields | UpdateOutageFormFields) => {
      setFormFields(fields as CreateOutageFormFields);
    },
    [setFormFields]
  );

  useEffect(() => {
    if (formFields.sendExternalNotification) {
      setShowNotificationWarning(true);
    }
  }, [formFields.sendExternalNotification]);

  const closeNotificationWarningDialog = () => {
    setShowNotificationWarning(false);
  };

  const cancelSendExternalNotification = () => {
    closeNotificationWarningDialog();
    setFormFields({ ...formFields, sendExternalNotification: false });
  };

  const save = () => {
    const newValidationErrors: ValidationError[] = [];
    if (!formFields) return null;
    if (!formFields.serviceType) {
      newValidationErrors.push({
        field: "serviceType",
        error: "Service type required.",
      });
    }
    if (!formFields.startAt) {
      newValidationErrors.push({
        field: "startAt",
        error: "Start date required.",
      });
    }
    if (formFields.affectedAreas.length === 0) {
      newValidationErrors.push({
        field: "affectedAreas",
        error: "Affected areas required.",
      });
    }

    setValidationErrors(newValidationErrors);

    if (newValidationErrors.length > 0) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "Please enter all required fields.",
          type: "error",
          isOpen: true,
          id: "enter-required-fields",
        },
      });
      return null;
    }

    // The non-null assertion operator is used here because Typescript does not recognize that the
    // falsy check for startAt and serviceType above should cause their types to be non-nullable.
    const outage: CreateOutageInput = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      startAt: zonedTimeToUtc(formFields.startAt!, "America/Chicago"),
      endAt: formFields.endAt
        ? zonedTimeToUtc(formFields.endAt, "America/Chicago")
        : null,
      affectedAreas: formFields.affectedAreas,
      description: formFields.description || "",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      serviceType: formFields.serviceType!,
      isPlanned,
      sendExternalNotification: formFields.sendExternalNotification,
      sendInternalNotification: formFields.sendInternalNotification,
      affectedResidentialCustomers: formFields.affectedResidentialCustomers,
      affectedBusinessCustomers: formFields.affectedBusinessCustomers,
    };
    createOutage({
      variables: { outage },
    }).then(({ data }) => {
      if (data?.createOutage?.success) {
        dispatch({
          message: {
            description: "Outage created.",
            id: `${data?.createOutage?.outage?.id}-outage-created`,
            isOpen: true,
            type: "success",
          },
          type: "ADD_MESSAGE",
        });
        setRedirect(true);
      }
    });
  };

  const clearValidationError = (name: string) => {
    const newValidationErrors = JSON.parse(JSON.stringify(validationErrors));

    setValidationErrors(
      newValidationErrors.filter(
        (error: ValidationError) => error.field !== name
      )
    );
  };

  const setBooleanField = (name: CreateOutageFormFieldNames) => (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormFields({ ...formFields, [name]: checked });
    clearValidationError(name);
  };

  const setFormAffectedAreas = async (selectedLCPs: LCP[]) => {
    clearValidationError("affectedAreas");
    setFormFields({
      ...formFields,
      affectedAreas: selectedLCPs.map((lcp) => ({
        city: lcp.city,
        state: lcp.state,
        lcp: lcp.name,
      })),
    });
  };

  const outageLink = isPlanned
    ? "/service-disruptions/maintenances"
    : "/service-disruptions/outages";

  return (
    <Fade in={true}>
      <div>
        <Dialog
          open={showNotificationWarning}
          aria-labelledby="notification-warning-id"
        >
          <NotificationWarning
            id="notification-warning-id"
            sendingExternalNotification={formFields.sendExternalNotification}
            sendingInternalNotification={formFields.sendInternalNotification}
            internalNotificationHasBeenSent={false}
            externalNotificationHasBeenSent={false}
            onSend={closeNotificationWarningDialog}
            onClose={cancelSendExternalNotification}
          />
        </Dialog>
        <LoadingBackdrop isOpen={loading} />
        <BackButton url={outageLink} />
        <Typography classes={{ root: classes.title }} variant="h5">
          Create {isPlanned ? "Maintenance" : "Outage"}
        </Typography>
        <OutageFormFields
          setFormFields={setFormFieldsCallback}
          setValidationErrors={setValidationErrorsCallback}
          clearValidationError={clearValidationError}
          formFields={formFields}
          validationErrors={validationErrors}
        />
        <div className={classes.switches}>
          <FormControlLabel
            control={
              <Switch
                checked={formFields.sendExternalNotification}
                onChange={setBooleanField("sendExternalNotification")}
              />
            }
            label="Send External Notification"
            color="secondary"
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                value={formFields.sendInternalNotification}
                onChange={setBooleanField("sendInternalNotification")}
              />
            }
            label="Send Internal Notification"
            color="secondary"
          />
        </div>
        <Typography variant="h6" classes={{ root: classes.affectedAreasTitle }}>
          Affected Areas
        </Typography>
        <AffectedAreaAccordions
          affectedAreas={splitLCPs(formFields.affectedAreas)}
        />
        {validationErrors.find((error) => error.field === "affectedAreas") && (
          <>
            <Typography classes={{ root: classes.affectedAreasError }}>
              At least one affected area is required.
            </Typography>
          </>
        )}
        <Button
          classes={{ root: classes.addEditButton }}
          onClick={() => setAffectedAreaDialogIsOpen(true)}
        >
          Add/Edit
        </Button>
        <AppFab onClick={save} accessibilityLabel="Save">
          <Icon>save</Icon>
        </AppFab>
        <AddAffectedAreaDialog
          save={setFormAffectedAreas}
          isOpen={affectedAreaDialogIsOpen}
          onClose={() => setAffectedAreaDialogIsOpen(false)}
        />
        {redirect && <Redirect to={outageLink} />}
      </div>
    </Fade>
  );
};

export default CreateOutageForm;
