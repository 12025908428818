export default {
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "92.5%",
    display: { xs: "flex", sm: "block" },
    flexDirection: { xs: "column", sm: "column" },
    alignItems: { xs: "center", sm: "inherit" },
  },
} as const;
