import React, { FC } from "react";
import { Box } from "@mui/system";
import { List } from "@mui/material";
import EditButton from "sections/communication-preference/components/buttons/EditButton";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import SummaryCardHeader from "../SummaryCard/SummaryCardHeader";
import componentStyles from "./styles";

export type SummaryContainer = {
  /**
   * The header are displayed at the top of each column
   */
  header: {
    infoHeader?: string;
    mainHeader: string | string[];
    subHeader?: string;
    iconHeader?: number;
  };
  /**
   * The content cards
   */
  content: {
    infoContent?: string;
    mainContent: string | string[];
    subContent?: string;
    iconContent?: number;
  }[];
  /**
   * If there is an onClick property specified for the summary container, then render an edit button with the onClick function
   */
  onClick?: (index: number) => void;
};

export type Props = {
  summaryColumns: SummaryContainer[];
};

const SummaryContainer: FC<Props> = ({ summaryColumns }: Props) => {
  return (
    <List sx={componentStyles.container}>
      <Box sx={componentStyles.table}>
        {summaryColumns.map((column, index) => {
          const { content, header, onClick } = column;
          const { infoHeader, subHeader, mainHeader, iconHeader } = header;
          return (
            <Box
              key={`${mainHeader}${index}: header`}
              sx={componentStyles.column}
            >
              <SummaryCardHeader
                headerInfo={infoHeader}
                subHeader={subHeader}
                mainHeader={mainHeader}
                icon={iconHeader}
              />
              {content.map((card, index) => {
                const {
                  infoContent,
                  mainContent,
                  subContent,
                  iconContent,
                } = card;
                return (
                  <SummaryCard
                    classes={componentStyles.shift}
                    headerInfo={infoContent}
                    subHeader={subContent}
                    mainHeader={mainContent}
                    icon={iconContent}
                    key={`${mainContent}${index}`}
                  />
                );
              })}
              {onClick && (
                <Box sx={componentStyles.buttonContainer}>
                  <EditButton
                    key={`${mainHeader}${index}: edit-button`}
                    onClick={() => onClick(index)}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </List>
  );
};

export default SummaryContainer;
