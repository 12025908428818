import React, { FC } from "react";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Contact } from "sections/communication-preference/contacts/types";
import SubPreferencesTable from "../SubPreferencesTable/SubPreferencesTable";
import SubPreferenceButton from "./PreferenceButton/PreferenceButton";
import { useContactDispatch } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import componentStyles from "./styles";

type Props = {
  contact: Contact;
};

const PreferencesContainer: FC<Props> = ({ contact }: Props) => {
  const contactDispatch = useContactDispatch();

  const editTable = () => {
    contactDispatch({
      type: "EDIT_CONTACT",
      key: contact.key,
      edit: !contact.isBeingEdited,
    });
  };

  return (
    <Box sx={componentStyles.subPreferencesContainer}>
      {contact.isBeingEdited && (
        <>
          <Box sx={componentStyles.tableHeader}>
            <Typography>Sub Preferences</Typography>
            <Divider sx={componentStyles.fullWidth} />
          </Box>

          <SubPreferencesTable contact={contact} />
        </>
      )}
      <SubPreferenceButton
        subtractButtonDescription="Hide preferences"
        addButtonDescription="Show preferences"
        tableActive={contact.isBeingEdited}
        onClick={editTable}
      />
    </Box>
  );
};

export default PreferencesContainer;
