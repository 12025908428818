import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import { Icon, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Fade from "@mui/material/Fade";

import AppFab from "sections/_global/components/AppFab";
import BackButton from "sections/_global/components/BackButton";
import OutagesList from "../components/OutagesList";
import styles from "./styles";

type Props = {
  classes: {
    root: string;
  };
};

const Outages: FC<Props> = ({ classes }: Props) => {
  return (
    <Box>
      <BackButton url="/service-disruptions" />
      <Fade in={true}>
        <Box>
          <OutagesList type="unplanned" />
        </Box>
      </Fade>
      <Link to="/service-disruptions/create-outage">
        <AppFab accessibilityLabel="New">
          <Icon>add</Icon>
        </AppFab>
      </Link>
    </Box>
  );
};

export default withStyles(styles)(Outages);
