import React, { useCallback, useState } from "react";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import {
  CreateRatePlanInput,
  RatePlan,
  RcdElement,
  RcdGroup,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { BillingModelRatePlan_bamRatePlan_ratePlanType as RatePlanType } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlan";
import SelectRatePlan from "sections/billing-model-rateplans/components/SelectRatePlan";
import SelectRcdGroups from "sections/billing-model-rateplans/components/SelectRcdGroups";
import RatePlanForm from "sections/billing-model-rateplans/components/RatePlanForm";
import { MUTATIONS, QUERIES } from "../BillingModelRatePlanQueries";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getDisplayText } from "sections/billing-model-common/BillingModelUtilities";
import { v4 as uuidv4 } from "uuid";
import { BillingModelRatePlan } from "../__generated__/BillingModelRatePlan";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import { useRatePlanDefaultValues } from "../hooks/useRatePlanDefaultValues";
import AppStepper from "sections/_global/components/AppStepper";

export interface CreateRatePlanState {
  activeStep: number;
  selectedRatePlan: RatePlan | null;
  selectedRatePlanType: RatePlanType | null;
  newRatePlan: RatePlan | null;
  selectedRcdGroups: RcdGroup[];
  loading: boolean;
}

enum CreateRatePlanStep {
  Loading = 0,
  SelectRatePlan = 1,
  SelectRCDGroups = 2,
  FinalizeRatePlans = 3,
}

interface Params {
  id: string;
}

const CreateRatePlan: React.FC = () => {
  const defaultValues = useRatePlanDefaultValues();
  const { id } = useParams<Params>();
  const dispatch = useMessageDispatch();
  const [state, setState] = useState<CreateRatePlanState>({
    activeStep: id ? 0 : 1,
    selectedRatePlan: null,
    selectedRatePlanType: null,
    selectedRcdGroups: [],
    newRatePlan: null,
    loading: (id ? 0 : 1) === CreateRatePlanStep.Loading,
  });

  // *==================================== Queries ====================================* //
  const [createRatePlan] = useMutation(MUTATIONS.createRatePlan);
  useQuery<BillingModelRatePlan>(QUERIES.getRatePlan, {
    fetchPolicy: "network-only",
    skip: id === "" || id === null || id === undefined,
    variables: { id: id },
    onCompleted: (response) => {
      if (response.bamRatePlan) {
        setState({
          ...state,
          selectedRatePlan: response.bamRatePlan as RatePlan,
          selectedRatePlanType: (response.bamRatePlan as RatePlan).ratePlanType,
          activeStep: CreateRatePlanStep.SelectRCDGroups,
          selectedRcdGroups: getRcdGroups(
            response.bamRatePlan.ratingControlGroups as RcdGroup[]
          ),
          loading: false,
        });
      }
    },
  });

  // *==================================== Helpers ====================================* //
  const setTime = (name: string, value: string | null) => {
    if (value) {
      const date = new Date(value);
      if (name.toLowerCase().includes("start")) date.setUTCHours(0, 0, 0, 0);
      if (name.toLowerCase().includes("end")) date.setUTCHours(23, 59, 59, 999);
      return date;
    }

    return null;
  };

  const validateSelectRcdGroups = useCallback(() => {
    dispatch({ type: "CLOSE_MESSAGE", message: "select-rcdgroup-msg" });
    dispatch({ type: "CLOSE_MESSAGE", message: "select-rateplantype-msg" });

    if (!state.selectedRatePlanType) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "You must select a Rate Plan Type before continuing.",
          type: "warning",
          isOpen: true,
          id: "select-rateplantype-msg",
        },
      });
      return false;
    } else if (
      state.selectedRcdGroups.length === 0 &&
      state.selectedRatePlanType &&
      state.selectedRatePlanType.ratingControlGroupTypes &&
      state.selectedRatePlanType.ratingControlGroupTypes.length > 0
    ) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description:
            "You must select at least one RCD Group before continuing.",
          type: "warning",
          isOpen: true,
          id: "select-rcdgroup-msg",
        },
      });
      return false;
    } else {
      return true;
    }
  }, [dispatch, state.selectedRatePlanType, state.selectedRcdGroups.length]);

  const getNewRatePlan = useCallback((): RatePlan | null => {
    const getNewElements = (group: RcdGroup): RcdElement[] =>
      group.ratingControlElements?.map((e) => ({
        ...e,
        ratingControlElementId: uuidv4(),
        value: e.value?.trim() ?? null,
      })) ?? [];

    const getNewRcdGroups = (ratePlan: RatePlan): RcdGroup[] =>
      state.selectedRcdGroups?.map((g) => ({
        ...g,
        ratingControlGroupId: uuidv4(),
        periodType: g.periodType ?? defaultValues.periodType,
        transactionType: g.transactionType ?? defaultValues.transactionType,
        elements: getNewElements(g),
      })) ?? [];

    return state.selectedRatePlan
      ? {
          ...state.selectedRatePlan,
          ratePlanId: "",
          name: "",
          ratePlanType: state.selectedRatePlanType ?? {
            __typename: "BamRatePlanType",
            ratePlanTypeId: "",
            name: "",
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
            ratingControlGroupTypes: [],
          },
          ratingControlGroups: getNewRcdGroups(state.selectedRatePlan),
          status: "new",
        }
      : null;
  }, [
    defaultValues.periodType,
    defaultValues.transactionType,
    state.selectedRatePlan,
    state.selectedRatePlanType,
    state.selectedRcdGroups,
  ]);

  const getRcdGroups = useCallback((groups: RcdGroup[] | undefined) => {
    return groups?.filter((g) => g.active) ?? [];
  }, []);

  // *==================================== Events ====================================* //
  const handleNextStep = useCallback(() => {
    const nextStep = state.activeStep + 1;

    if (state.activeStep === CreateRatePlanStep.SelectRatePlan) {
      setState({
        ...state,
        selectedRatePlan: state.selectedRatePlan ?? {
          __typename: "BamRatePlan",
          ratePlanId: "",
          name: "",
          ratePlanType: {
            __typename: "BamRatePlanType",
            ratePlanTypeId: "",
            name: "",
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
            ratingControlGroupTypes: [],
          },
          description: "",
          startDate: defaultValues.ratePlanStartDate.toISOString(),
          endDate: null,
          createdAt: new Date().toISOString(),
          modifiedAt: new Date().toISOString(),
          currencyCode: "USD",
          ratingControlGroups: [],
          status: "new",
        },
        activeStep: nextStep,
        selectedRcdGroups: getRcdGroups(
          state.selectedRatePlan?.ratingControlGroups
        ),
      });
    } else if (state.activeStep === CreateRatePlanStep.SelectRCDGroups) {
      // going forward
      if (validateSelectRcdGroups()) {
        setState({
          ...state,
          activeStep: nextStep,
          newRatePlan: getNewRatePlan(),
        });
      }
    } else {
      setState({
        ...state,
        activeStep: nextStep,
        newRatePlan: state.newRatePlan,
      });
    }
  }, [
    defaultValues.ratePlanStartDate,
    getNewRatePlan,
    getRcdGroups,
    state,
    validateSelectRcdGroups,
  ]);

  const handlePreviousStep = useCallback(() => {
    const nextStep = state.activeStep - 1;
    if (state.activeStep === CreateRatePlanStep.SelectRCDGroups) {
      // going back
      setState({
        ...state,
        activeStep: nextStep,
        selectedRcdGroups: [],
      });
    } else if (state.activeStep === CreateRatePlanStep.FinalizeRatePlans) {
      setState({
        ...state,
        activeStep: nextStep,
        newRatePlan: null,
      });
    }
  }, [state]);

  const handleSave = useCallback(
    async (rp: RatePlan): Promise<boolean> => {
      dispatch({ type: "CLOSE_MESSAGE", message: "create-rateplan-msg" });

      const create: CreateRatePlanInput = {
        name: rp.name,
        ratePlanTypeId: rp.ratePlanType?.ratePlanTypeId ?? "",
        description: rp.description,
        startDate: setTime("startDate", rp.startDate)?.toISOString() ?? "",
        endDate: setTime("endDate", rp.endDate)?.toISOString(),
        currencyCode: rp.currencyCode,
        ratingControlGroups: rp.ratingControlGroups?.map((g) => ({
          ratingControlGroupTypeId:
            g.ratingControlGroupType?.ratingControlGroupTypeId ?? "",
          transactionTypeId: g.transactionType?.transactionTypeId ?? "",
          periodTypeId: g.periodType?.periodTypeId ?? "",
          startDate: setTime("startDate", g.startDate)?.toISOString() ?? "",
          endDate: setTime("endDate", g.endDate)?.toISOString(),
          ratingControlElements: g.ratingControlElements?.map((e) => ({
            ratingControlElementTypeId:
              e.ratingControlElementType.ratingControlElementTypeId,
            value: e.value,
          })),
        })),
      };

      setState({ ...state, loading: true });
      const request = await createRatePlan({
        variables: { input: create },
      }).then((response) => {
        setState({ ...state, loading: false });
        return response;
      });

      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description:
            request.data?.bamCreateRatePlan?.message ??
            "An error occurred while creating the Rate Plan.",
          type: request.data?.bamCreateRatePlan?.success ? "success" : "error",
          isOpen: true,
          id: "create-rateplan-msg",
        },
      });

      if (request.data?.bamCreateRatePlan?.success === true) {
        return true;
      }

      return false;
    },
    [createRatePlan, dispatch, state]
  );
  return (
    <BillingModelScreen backUrl="/billing-model/rates/list">
      {state.loading && <LoadingBackdrop isOpen />}

      {state.activeStep !== CreateRatePlanStep.Loading && (
        <AppStepper
          steps={[
            "Select Rate Plan",
            "Select RCD Groups",
            "Finalize new Rate Plan",
          ]}
          currentStep={state.activeStep - 1}
          onNextStepClick={handleNextStep}
          onPreviousStepClick={handlePreviousStep}
        >
          {state.activeStep === CreateRatePlanStep.SelectRatePlan && (
            <>
              <SelectRatePlan
                selectedRatePlan={state.selectedRatePlan}
                onChange={(rp) =>
                  setState({
                    ...state,
                    selectedRatePlan: rp,
                    selectedRatePlanType: rp ? rp.ratePlanType : null,
                  })
                }
              />
            </>
          )}

          {state.activeStep === CreateRatePlanStep.SelectRCDGroups && (
            <SelectRcdGroups
              ratePlan={state.selectedRatePlan}
              ratePlanType={state.selectedRatePlanType}
              selected={state.selectedRcdGroups}
              onChange={(rpt, g) =>
                setState({
                  ...state,
                  selectedRatePlanType: rpt,
                  selectedRcdGroups: g,
                })
              }
            />
          )}

          {state.activeStep === CreateRatePlanStep.FinalizeRatePlans &&
            state.newRatePlan && (
              <RatePlanForm
                subtitle={
                  state.selectedRatePlan?.ratePlanId !== ""
                    ? `Based on ${getDisplayText(
                        state.selectedRatePlan?.ratePlanId,
                        state.selectedRatePlan?.name,
                        "Unknown"
                      )}`
                    : "Creating new rate plan from scratch."
                }
                ratePlan={state.newRatePlan}
                onSubmit={handleSave}
              />
            )}
        </AppStepper>
      )}
    </BillingModelScreen>
  );
};

export default CreateRatePlan;
