import React, { ReactNode } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { colors } from "styles/colors";

export interface SelectOption {
  label: string;
  value: any;
}

export interface Props {
  label: string;
  value?: unknown;
  /**
   * The MUI Variant, `filled`, `outlined`, or `standard`
   */
  variant?: "filled" | "outlined" | "standard" | undefined;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  useFullWidth?: boolean;
  onChange: (value: unknown) => void;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  required?: boolean;
  /**
   * The available options for the user to pick from.
   */
  options: SelectOption[];
  classes?: string;
}

const AppSelect: React.FC<Props> = ({
  label,
  value,
  variant = "outlined",
  isDisabled,
  hasError,
  helperText,
  useFullWidth,
  onChange,
  startAdornment,
  endAdornment,
  required,
  options,
  classes: optionalClasses,
}: Props) => {
  const selectId = `app-select-${label}`;
  const labelId = `app-select-label-${label}`;

  return (
    <FormControl
      className={optionalClasses}
      sx={{
        minWidth: "8em",
      }}
      fullWidth={useFullWidth}
      variant={variant}
      error={hasError}
      required={required}
    >
      <InputLabel color="secondary" id={labelId}>
        {label}
      </InputLabel>
      <Select
        id={selectId}
        labelId={labelId}
        label={label}
        value={value ?? ""}
        disabled={isDisabled}
        error={hasError}
        onChange={(event) => onChange(event.target.value)}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        color="secondary"
        MenuProps={{ classes: { paper: optionalClasses } }}
      >
        {options.map((s) => (
          <MenuItem
            sx={{
              "&:hover": {
                backgroundColor: colors.brandGreen,
              },
            }}
            key={s.label}
            value={s.value}
          >
            {s.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AppSelect;
