import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import { RcdGroup } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import StatusIndicator from "sections/billing-model-common/components/StatusIndicator";
import { getDisplayText } from "sections/billing-model-common/BillingModelUtilities";
import RcdGroupControls from "../RcdGroupControls";
import { colors } from "styles/colors";
import { TransactionTypeSelectData } from "sections/billing-model-rateplans/hooks/useTransactionTypeSelectQuery";
import { PeriodTypeSelectData } from "sections/billing-model-rateplans/hooks/usePeriodTypeSelectQuery";

export interface Props {
  errors: FormError;
  isUserAdmin: boolean;
  rcdGroup: RcdGroup;
  usePeriodTypeSelectQuery: () => PeriodTypeSelectData;
  useTransactionTypeSelectQuery: (search: string) => TransactionTypeSelectData;
  onChange: (rcdGroup: RcdGroup, errors: FormError) => void;
}

const RatePlanRcdGroupForm: React.FC<Props> = ({
  errors,
  isUserAdmin,
  rcdGroup,
  usePeriodTypeSelectQuery,
  useTransactionTypeSelectQuery,
  onChange,
}: Props) => {
  const [original, setOriginal] = useState<RcdGroup>(rcdGroup);
  const [open, setOpen] = useState<boolean>(false);
  const handleRcdGroupChange = (update: RcdGroup, groupErrors: FormError) => {
    onChange(update, groupErrors);
  };

  return (
    <Accordion
      sx={{
        padding: "0.8em",
      }}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary
        expandIcon={<Icon>expand_more</Icon>}
        sx={{
          "& .summary": {
            marginLeft: "0.8em",
          },
          "& .active": {
            backgroundColor: colors.brandGreen,
            color: colors.white,
          },
          "& .inactive": {
            backgroundColor: colors.errorRed,
            color: colors.white,
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={1}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={2} md={12}>
                <StatusIndicator
                  status={rcdGroup.active ? "active" : "expired"}
                />
              </Grid>
              <Grid item xs={6} sm="auto" md={12}>
                <Tooltip title="Click to undo changes.">
                  <div>
                    <StatusIndicator
                      status={rcdGroup.status}
                      onDelete={() => {
                        if (onChange) onChange(original, {});
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} lg={11}>
            <Grid container sx={{ paddingLeft: "1.5rem" }}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {rcdGroup.ratingControlGroupType?.name ??
                    "RCD Group Type Not Found!"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {getDisplayText(
                    rcdGroup.transactionType?.transactionTypeId,
                    rcdGroup.transactionType?.name,
                    "No Transaction Type"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{`Group Class ${rcdGroup.class}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {open && (
          <RcdGroupControls
            original={original}
            rcdGroup={rcdGroup}
            usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
            useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
            isUserAdmin={isUserAdmin}
            errors={errors}
            onChange={handleRcdGroupChange}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default RatePlanRcdGroupForm;
