import React from "react";

import { Permission } from "../../../_global/types";
import PermissionsSection from "./PermissionsSection";

export type Props = {
  userPermissions?: Permission[] | null;
  permissions?: Permission[] | null;
};

type MappedPermission = {
  [key: string]: Permission[];
};

const reducePermissionsByCategory = (permissions: Permission[]) => {
  return permissions.reduce((acc: MappedPermission, val) => {
    const category = val.category;
    if (acc[category]) {
      acc[category].push(val);
    } else {
      acc[category] = [val];
    }

    return acc;
  }, {});
};

const PermissionsPanelContent: React.FC<Props> = ({
  userPermissions,
  permissions,
}: Props) => {
  const reducedPermissions = permissions
    ? reducePermissionsByCategory(permissions)
    : {};

  if (!Object.keys(reducedPermissions).length) return null;

  return (
    <>
      {Object.keys(reducedPermissions).map((category) => (
        <PermissionsSection
          key={category}
          category={category}
          userPermissions={userPermissions}
          permissions={reducedPermissions[category]}
        />
      ))}
    </>
  );
};

export default PermissionsPanelContent;
