import { Grid, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { ObjectStatus } from "sections/billing-model-common/BillingModelTypes";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import { validateElement, validateRcdGroupField } from "../../validation";
import {
  RcdElement,
  RcdGroup,
  RcdGroupType,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import {
  BillingModelRatePlan_bamRatePlan_ratingControlGroups_periodType as PeriodType,
  BillingModelRatePlan_bamRatePlan_ratingControlGroups_transactionType as TransactionType,
} from "sections/billing-model-rateplans/__generated__/BillingModelRatePlan";
import PeriodTypeSelect from "../PeriodTypeSelect";
import TransactionTypeSelect from "../TransactionTypeSelect";
import BillingModelDateField from "sections/billing-model-common/components/BillingModelDateField";
import RcdElementField from "../RcdElementField";
import { TransactionTypeSelectData } from "sections/billing-model-rateplans/hooks/useTransactionTypeSelectQuery";
import { PeriodTypeSelectData } from "sections/billing-model-rateplans/hooks/usePeriodTypeSelectQuery";

export interface Props {
  original: RcdGroup;
  rcdGroup: RcdGroup;
  errors: FormError;
  isUserAdmin: boolean;
  usePeriodTypeSelectQuery: () => PeriodTypeSelectData;
  useTransactionTypeSelectQuery: (search: string) => TransactionTypeSelectData;
  onChange?: (group: RcdGroup, errors: FormError) => void;
}

const RcdGroupControls = ({
  original,
  rcdGroup,
  isUserAdmin,
  errors,
  usePeriodTypeSelectQuery,
  useTransactionTypeSelectQuery,
  onChange,
}: Props): JSX.Element => {
  const getStatus = (changed: boolean): ObjectStatus | undefined => {
    if (rcdGroup.status === "new") return "new";

    return changed ? "changed" : undefined;
  };

  const getError = (name: string) =>
    errors[`rcdGroups.${rcdGroup.ratingControlGroupId}.${name}`] ?? undefined;
  const hasError = (name: string) => getError(name) != null;
  const getElementError = (element: RcdElement) =>
    getError(`elements.${element.ratingControlElementId}`);
  const elementHasError = (element: RcdElement) =>
    hasError(`elements.${element.ratingControlElementId}`);

  const handleOnChange = (
    name: string,
    update: RcdGroup,
    elementErrors?: FormError
  ) => {
    if (onChange) {
      const validationErrors: FormError = { ...errors, ...elementErrors };
      const validationResults = validateRcdGroupField(
        name,
        update,
        validationErrors,
        isUserAdmin,
        `rcdGroups.${rcdGroup.ratingControlGroupId}.`
      );

      onChange(update, validationResults[1]);
    }
  };

  const setPeriodType = (value: PeriodType | null) => {
    const update: RcdGroup = {
      ...rcdGroup,
      periodType: value,
      status: getStatus(
        value?.periodTypeId !== original.periodType?.periodTypeId
      ),
    };
    handleOnChange("periodType", update);
  };

  const setTransactionType = (value: TransactionType | null) => {
    const update: RcdGroup = {
      ...rcdGroup,
      transactionType: value,
      status: getStatus(
        value?.transactionTypeId !== original.transactionType?.transactionTypeId
      ),
    };
    handleOnChange("transactionType", update);
  };

  const setDateField = (name: string) => (value: Date | null) => {
    const update: RcdGroup = {
      ...rcdGroup,
      [name]: value,
      status: getStatus(value !== original[name as keyof RcdGroup]),
    };
    handleOnChange(name, update);
  };

  const handleElementChange = (element: RcdElement, value: string) => {
    const update = [...rcdGroup.ratingControlElements];
    const index = update.findIndex(
      (e) => e.ratingControlElementId === element.ratingControlElementId
    );

    const changed = original.ratingControlElements[index].value !== value;

    const elementUpdate: RcdElement = {
      ...element,
      value,
      status:
        element.status === "new" ? "new" : changed ? "changed" : undefined,
    };

    update.splice(index, 1, elementUpdate);

    const validationResult = validateElement(
      elementUpdate,
      update,
      errors,
      `rcdGroups.${rcdGroup.ratingControlGroupId}.elements.`
    );

    const group = {
      ...rcdGroup,
      ratingControlElements: update,
      class:
        (element.ratingControlElementType.displayLabel === "Group Class"
          ? elementUpdate.value
          : rcdGroup.class) ?? "",
      status: getStatus(changed),
    };

    handleOnChange("ratingControlElements", group, validationResult[1]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <PeriodTypeSelect
              useFullWidth
              label="Frequency"
              value={rcdGroup.periodType}
              hasError={hasError("periodType")}
              helperText={getError("periodType")}
              usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
              onChange={setPeriodType}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TransactionTypeSelect
              label="Transaction Name"
              value={rcdGroup.transactionType}
              hasError={hasError("transactionType")}
              helperText={getError("transactionType")}
              useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
              onChange={setTransactionType}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <BillingModelDateField
              useFullWidth
              limitDates={!isUserAdmin}
              label="Start Date"
              hasError={hasError("startDate")}
              helperText={getError("startDate")}
              value={rcdGroup.startDate}
              onChange={setDateField("startDate")}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <BillingModelDateField
              useFullWidth
              label="End Date"
              hasError={hasError("endDate")}
              helperText={getError("endDate")}
              value={rcdGroup.endDate}
              onChange={setDateField("endDate")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {!rcdGroup.ratingControlElements && (
            <Box
              sx={{
                display: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "2rem",
                width: "100%",
                color: "red",
              }}
            >
              <Typography variant="h6">
                Rating Control Elements are missing for this RCD Group.
              </Typography>
            </Box>
          )}
          {rcdGroup.ratingControlElements &&
            rcdGroup.ratingControlElements
              .slice()
              .sort((a: RcdElement, b: RcdElement) => {
                const aName = a.ratingControlElementType.name ?? "";
                const bName = b.ratingControlElementType.name ?? "";
                return aName.localeCompare(bName);
              })
              .map((e: RcdElement, k: number) => {
                if (e.ratingControlElementType.hidden !== true) {
                  return (
                    <Grid item key={k.toString()} xs={12} md={4}>
                      <RcdElementField
                        dataType={
                          e.ratingControlElementType?.ratingControlDataType
                            ?.name
                        }
                        label={e.ratingControlElementType.displayLabel}
                        value={e.value}
                        editable={e.ratingControlElementType?.editable ?? false}
                        hasError={elementHasError(e)}
                        helperText={getElementError(e)}
                        onChange={(value) => handleElementChange(e, value)}
                      />
                    </Grid>
                  );
                }
              })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RcdGroupControls;
