import React from "react";
import { withStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./styles";

export type Props = {
  classes: {
    progressIndicator: string;
    backdrop: string;
  };
  isOpen: boolean;
};

const LoadingBackdrop: React.FC<Props> = ({ classes, isOpen }: Props) => {
  return (
    <Backdrop classes={{ root: classes.backdrop }} open={isOpen}>
      <CircularProgress
        color="secondary"
        aria-label={"Loading"}
        classes={{ root: classes.progressIndicator }}
      />
    </Backdrop>
  );
};

const StyledComponent = withStyles(styles)(LoadingBackdrop);

export { LoadingBackdrop };

export default StyledComponent;
