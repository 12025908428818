import * as React from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";

export type Props = {
  handleChange: (str: any) => void;
  label: string;
  value?: boolean;
  useFullWidth?: boolean;
};

const BooleanSelect: React.FC<Props> = ({
  handleChange,
  label,
  value,
  useFullWidth,
}: Props) => {
  return (
    <FormControl fullWidth={useFullWidth}>
      <TextField
        select
        id="boolean-dropdown"
        value={value}
        defaultValue=""
        onChange={handleChange}
        label={label}
        sx={{ minWidth: 140 }}
      >
        <MenuItem key="false" value="false">
          No
        </MenuItem>
        <MenuItem key="true" value="true">
          Yes
        </MenuItem>
      </TextField>
    </FormControl>
  );
};

export default BooleanSelect;
