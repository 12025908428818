import { colors } from "styles/colors";

export default {
  root: {
    display: "block",
    margin: "0.3em",
    color: "transparent",
    background: colors.brandBlue,
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    textShadow: "0px 1px 2px rgb(255 255 255 / 70%)",
    fontSize: {
      xs: "2.5rem",
      sm: "4rem",
    },
  },
  iconDisabled: {
    background: colors.gray6,
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
  },
} as const;
