import { gql } from "@apollo/client";

export const QUERIES = {
  getPermissions: gql`
    query GetPermissions {
      permissions {
        name
        action
        category
      }
    }
  `,
};
