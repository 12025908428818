import { gql } from "@apollo/client";

export const FRAGMENTS = {
  rcdElementType: gql`
    fragment RcdElementType on BamRcdElementType {
      ratingControlElementTypeId
      name
      displayLabel
      ratingControlDataType {
        ratingControlDataTypeId
        name
      }
      regEx
      defaultValue
      editable
      hidden
      required
    }
  `,
  rcdElement: gql`
    fragment RcdElement on BamRcdElement {
      ratingControlElementId
      value
      ratingControlElementType {
        ...RcdElementType
      }
    }
  `,
  rcdGroupTypes: gql`
    fragment RcdGroupType on BamRcdGroupType {
      ratingControlGroupTypeId
      name
      periodClass
      ratingControlElementTypes {
        ...RcdElementType
      }
    }
  `,
  ratePlanDetails: gql`
    fragment RatePlanDetails on BamRatePlan {
      ratePlanId
      name
      ratePlanType {
        ratePlanTypeId
        name
        createdAt
        modifiedAt
        ratingControlGroupTypes {
          ...RcdGroupType
        }
      }
      description
      startDate
      endDate
      createdAt
      modifiedAt
      currencyCode
      ratingControlGroups {
        ratePlanId
        ratingControlGroupId
        startDate
        endDate
        active
        class
        ratingControlGroupType {
          ratingControlGroupTypeId
          name
        }
        transactionType {
          transactionTypeId
          name
        }
        periodType {
          periodTypeId
          name
        }
        ratingControlElements {
          ...RcdElement
        }
      }
    }
  `,
};

export const QUERIES = {
  getRatePlanNameExists: gql`
    query BillingModelRatePlanNameExists($name: String!, $editing: Boolean) {
      bamRatePlanNameExists(name: $name, editing: $editing)
    }
  `,
  getRatePlansList: gql`
    query BillingModelRatePlansList($input: BamRatePlansQuery!) {
      bamRatePlans(input: $input) {
        data {
          ratePlanId
          name
          description
          startDate
          endDate
          currencyCode
          createdAt
          modifiedAt
          ratePlanType {
            ratePlanTypeId
          }
          ratingControlGroups {
            ratingControlGroupId
          }
        }
        pagination {
          page
          totalPages
        }
      }
    }
  `,
  getRatePlan: gql`
    query BillingModelRatePlan($id: ID!) {
      bamRatePlan(input: { id: $id, deep: all }) {
        ...RatePlanDetails
      }
    }
    ${FRAGMENTS.ratePlanDetails}
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElement}
    ${FRAGMENTS.rcdElementType}
  `,
  getPeriodTypes: gql`
    query BillingModelPeriodTypes {
      bamPeriodTypes {
        periodTypeId
        name
      }
    }
  `,
  getRatePlanTypeNameExists: gql`
    query BillingModelRatePlanTypeNameExists(
      $name: String!
      $editing: Boolean
    ) {
      bamRatePlanTypeNameExists(name: $name, editing: $editing)
    }
  `,
  getRatePlanType: gql`
    query BillingModelRatePlanType($id: ID!) {
      bamRatePlanType(id: $id) {
        ratePlanTypeId
        name
        createdAt
        modifiedAt
        ratingControlGroupTypes {
          ...RcdGroupType
        }
      }
    }
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElementType}
  `,
  getRatePlanTypes: gql`
    query BillingModelRatePlanTypes($input: BamRatePlanTypesQuery!) {
      bamRatePlanTypes(input: $input) {
        data {
          ratePlanTypeId
          name
          createdAt
          modifiedAt
        }
        pagination {
          page
          totalPages
        }
      }
    }
  `,
  getTransactionTypes: gql`
    query BillingModelTransactionTypes($search: String) {
      bamTransactionTypes(search: $search) {
        transactionTypeId
        name
      }
    }
  `,
  getRcdGroupTypes: gql`
    query BillingModelRcdGroupTypes($search: String) {
      bamRcdGroupTypes(search: $search) {
        data {
          ...RcdGroupType
        }
        pagination {
          page
          totalPages
        }
      }
    }
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElementType}
  `,
};

export const MUTATIONS = {
  createRatePlan: gql`
    mutation BillingModelCreateRatePlan($input: BamCreateRatePlanInput!) {
      bamCreateRatePlan(input: $input) {
        success
        message
        ratePlan {
          ...RatePlanDetails
        }
      }
    }
    ${FRAGMENTS.ratePlanDetails}
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElement}
    ${FRAGMENTS.rcdElementType}
  `,
  updateRatePlan: gql`
    mutation BillingModelUpdateRatePlan($input: BamUpdateRatePlanInput!) {
      bamUpdateRatePlan(input: $input) {
        success
        message
        ratePlan {
          ...RatePlanDetails
        }
      }
    }
    ${FRAGMENTS.ratePlanDetails}
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElement}
    ${FRAGMENTS.rcdElementType}
  `,
  createRatePlanType: gql`
    mutation BillingModelCreateRatePlanType(
      $input: BamCreateRatePlanTypeInput!
    ) {
      bamCreateRatePlanType(input: $input) {
        success
        message
        ratePlanType {
          ratePlanTypeId
          name
          createdAt
          modifiedAt
          ratingControlGroupTypes {
            ratingControlGroupTypeId
          }
        }
      }
    }
  `,
  updateRatePlanType: gql`
    mutation BillingModelUpdateRatePlanType(
      $input: BamUpdateRatePlanTypeInput!
    ) {
      bamUpdateRatePlanType(input: $input) {
        __typename
        ... on BamUpdateRatePlanTypeSuccess {
          ratePlanType {
            ratePlanTypeId
            name
            createdAt
            modifiedAt
            ratingControlGroupTypes {
              ...RcdGroupType
            }
          }
          ratePlans {
            ratePlanId
            name
            ratePlanType {
              ratePlanTypeId
              name
              createdAt
              modifiedAt
              ratingControlGroupTypes {
                ...RcdGroupType
              }
            }
            description
            startDate
            endDate
            createdAt
            modifiedAt
            currencyCode
            ratingControlGroups {
              ratePlanId
              ratingControlGroupId
              startDate
              endDate
              active
              class
              ratingControlGroupType {
                ...RcdGroupType
              }
              transactionType {
                transactionTypeId
                name
              }
              periodType {
                periodTypeId
                name
              }
              ratingControlElements {
                ...RcdElement
              }
            }
          }
        }
        ... on BamUpdateRatePlanTypeError {
          message
          missingRatePlanUpdates
          extraRatePlanUpdates
          invalidRatePlanUpdates {
            ratePlanId
            missingRatingControlGroupTypes
            extraRatingControlGroupTypes
            extraRatingControlGroupTypes
            incorrectRatingControlGroups {
              ratingControlGroupId
              groupType
              missingElementTypes
              extraElementTypes
            }
          }
        }
      }
    }
    ${FRAGMENTS.rcdGroupTypes}
    ${FRAGMENTS.rcdElement}
    ${FRAGMENTS.rcdElementType}
  `,
  deleteRatePlanType: gql`
    mutation BillingModelDeleteRatePlanType($id: Int!) {
      bamDeleteRatePlanType(id: $id) {
        success
      }
    }
  `,
};
