import { colors } from "../../../../styles/colors";

const alertStyle = {
  color: "white",
  boxShadow:
    "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
};

export default {
  container: {
    display: "flex",
    position: "fixed",
    height: "1rem", // Keep this because otherwise we won't be able to click on anything behind the container
    top: "1rem",
    transform: { xs: "none", sm: "translateX(-50%)" },
    justifyContent: "center",
    zIndex: 1301,
    left: {
      xs: "0.5rem",
      sm: "50%",
    },
    right: {
      xs: "0.5rem",
      sm: "initial",
    },
  },
  container2: {
    width: "100%",
  },
  alert: {
    minWidth: "20vw",
    "&:first-child": { marginTop: "2rem" },
    "&:not(:first-child)": { marginTop: "0.4rem" },
  },
  closeAll: {
    position: "absolute",
    minWidth: "20vw",
    width: "100%",
    backgroundColor: colors.gray4,
    color: colors.white,
    borderRadius: ".25rem",
    padding: "0.25rem",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    cursor: "pointer",
    fontSize: "0.8rem",
    boxShadow: alertStyle.boxShadow,
    zIndex: 10000,
  },
  standardSuccess: {
    ...alertStyle,
    backgroundColor: colors.brandGreenDark,
  },
  standardWarning: {
    ...alertStyle,
    backgroundColor: colors.warningOrange,
  },
  standardInfo: {
    ...alertStyle,
    backgroundColor: colors.brandBlue,
  },
  standardError: {
    ...alertStyle,
    backgroundColor: colors.errorRed,
  },
} as const;
