export enum AppGroupEnum {
  RELOCATE = "Relocate",
  RETURN = "Return",
  INVENTORY = "Inventory",
  SEARCH = "Search",
  UTILITIES = "Utilities",
}
export enum AppEnum {
  ASSIGN,
  TRUCK,
  INSTORE,
  BILLING,
  BATCH,
  MOVE,
  SWAP,
  LIST,
  SCAN,
  HISTORY,
  INSTALL,
  PSR,
  DHCT,
  LOCATION,
  MACADDRESS,
}
export type AppEnumString = keyof typeof AppEnum;

export const dataTypeEnum = Object.freeze({
  CURRENCY: "CURRENCY",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
  STRING: "STRING",
  BILLING: "BILLING",
  CHECKBOX: "CHECKBOX",
});

export type IMRoutesType = {
  name: AppEnumString;
  title?: string;
  icon?: string;
  desc?: string;
  type?: string;
  group: AppGroupEnum;
  route: string;
  submitLabel?: string;
  active: boolean;
  allowMultipleSubscribers?: boolean;
  multiple?: {
    restrictor: string;
    warningMessage: string;
    watermark: string;
  };
  defaultClose?: boolean;
};

export const imRoutes: IMRoutesType[] = [
  {
    name: "ASSIGN",
    title: "Transfer To ETA Technician",
    icon: "trending_flat",
    group: AppGroupEnum.RELOCATE,
    route: "/inventory-manager/assign",
    submitLabel: "Choose A Technician",
    active: true,
  },

  {
    name: "TRUCK",
    title: "Truck Returns",
    type: "truck_return",
    desc: "Receive Returns From Truck",
    icon: "local_shipping",
    group: AppGroupEnum.RETURN,
    route: "/inventory-manager/truck-return",
    active: false,
  },

  {
    name: "INSTORE",
    title: "In-Store Returns",
    icon: "people",
    group: AppGroupEnum.RETURN,
    route: "/inventory-manager/instore-return",
    allowMultipleSubscribers: false,
    multiple: {
      restrictor: "subId",
      warningMessage: "Please issue a separate form for each subscriber",
      watermark: "Subscriber Deviation",
    },
    active: false,
  },

  {
    name: "BILLING",
    title: "Inactive Billing Returns",
    icon: "credit_card",
    group: AppGroupEnum.RETURN,
    route: "/inventory-manager/inactive-billing-return",
    active: false,
  },

  {
    name: "BATCH",
    title: "Batch Transfer",
    icon: "create_new_folder",
    group: AppGroupEnum.RELOCATE,
    route: "/inventory-manager/batch",
    defaultClose: false,
    active: false,
  },

  {
    name: "MOVE",
    icon: "file_copy",
    group: AppGroupEnum.RELOCATE,
    route: "/inventory-manager/move",
    submitLabel: "Current Assigned Market",
    active: false,
  },

  {
    name: "SWAP",
    icon: "swap_calls",
    group: AppGroupEnum.RETURN,
    route: "/inventory-manager/swap",
    active: false,
  },

  {
    name: "LIST",
    group: AppGroupEnum.INVENTORY,
    route: "/inventory-manager/list",
    active: false,
  },

  {
    name: "SCAN",
    group: AppGroupEnum.INVENTORY,
    route: "/inventory-manager/scan",
    active: false,
  },

  {
    name: "HISTORY",
    icon: "history",
    group: AppGroupEnum.INVENTORY,
    route: "/inventory-manager/history",
    active: false,
  },

  {
    name: "INSTALL",
    title: "One-Step Install",
    group: AppGroupEnum.SEARCH,
    route: "/inventory-manager/install",
    active: false,
  },

  {
    name: "PSR",
    title: "PSR Search",
    group: AppGroupEnum.SEARCH,
    route: "/inventory-manager/psr",
    active: false,
  },

  {
    name: "DHCT",
    title: "DHCT Tools",
    group: AppGroupEnum.UTILITIES,
    route: "/inventory-manager/dhct",
    active: false,
  },

  {
    name: "LOCATION",
    title: "Location Management",
    group: AppGroupEnum.UTILITIES,
    route: "/inventory-manager/location",
    active: false,
  },

  {
    name: "MACADDRESS",
    title: "Mac Address Import",
    group: AppGroupEnum.UTILITIES,
    route: "/inventory-manager/macaddress",
    active: false,
  },
];
