import { isAfter, isSameDay, isValid } from "date-fns";

// * Support for common form validation.
// * If we decide to use Formik or something similar for validation this can be replaced.
export type FormError = {
  [key: string]: string | null;
};

export class FormValidator {
  public readonly match: RegExp;
  public readonly relatedMatch?: RegExp;

  constructor(match: RegExp, relatedMatch?: RegExp) {
    this.match = match;
    this.relatedMatch = relatedMatch;
  }
}

export class SimpleValidator<T> extends FormValidator {
  public readonly isInvalid: (
    value: unknown | null,
    obj?: T,
    isUserAdmin?: boolean
  ) => string | null;

  constructor(
    match: RegExp,
    isInvalid: (
      value: unknown | null,
      obj?: T,
      isUserAdmin?: boolean
    ) => string | null,
    relatedMatch?: RegExp
  ) {
    super(match, relatedMatch);
    this.isInvalid = isInvalid;
  }

  public static filterValidators<T>(
    name: string | null | undefined,
    validators: SimpleValidator<T>[]
  ): SimpleValidator<T>[] {
    if (name === undefined || name === null) return [];

    let filtered: SimpleValidator<T>[] = [];
    filtered = validators.filter((v) => v.match.test(name)) ?? [];
    filtered.forEach((v) => {
      if (v.relatedMatch) {
        const related =
          validators.filter(
            (r) => v.relatedMatch?.toString() === r.match.toString()
          ) ?? [];
        if (related) {
          filtered = filtered.concat(related);
        }
      }
    });

    return filtered;
  }

  public static validate<T>(
    validators: SimpleValidator<T>[],
    name: string,
    update: T,
    errors: FormError,
    isUserAdmin: boolean,
    prefix = "",
    getConvertedValue: (name: string, obj: T) => unknown | null
  ): [boolean, FormError] {
    const checks = SimpleValidator.filterValidators<T>(name, validators);
    const validationResults: FormError = { ...errors };
    const keys = Object.keys(update);
    checks.forEach((c) => {
      const propName = keys.find((p) => c.match.test(p));
      // const entries = Object.entries(update);
      // const propEntry = entries.find((e) => e[0] === propName) ?? null;
      if (propName) {
        const checkVal = getConvertedValue(propName, update);
        validationResults[prefix + propName] = c.isInvalid(
          checkVal,
          update,
          isUserAdmin
        );
      }
    });

    const hasErrors = Object.values(validationResults).some((a) => a != null);
    return [hasErrors, validationResults];
  }
}

export const isRequiredValidator = (
  message: string,
  value: string | null
): string | null =>
  value == null || value?.trim().length === 0 ? message : null;

export const startDateValidator = (
  value: Date | string | null,
  isUserAdmin?: boolean,
  isEditing?: boolean
): string | null => {
  if (!isUserAdmin && isEditing) return null;

  let message: string | null = null;
  const date = value ? new Date(value as Date) : null;
  const valid = date ? isValid(date) : false;
  message = !valid ? "Please enter a Start Date." : null;
  if (date && valid && isUserAdmin === false) {
    const minDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const isInRange = isSameDay(date, minDate) || isAfter(date, minDate);
    message = !isInRange ? "You must pick a date 7 days from today." : null;
  }
  return message;
};

export const endDateValidator = (
  start: Date | string | null,
  end: Date | string | null
): string | null => {
  const message: string | null =
    "End date must occur on, or after, the start date.";
  const startDate = start ? new Date(start as Date) : null;
  const endDate = end ? new Date(end as Date) : null;
  const startIsValid = isValid(startDate);
  const endIsValid = isValid(endDate);
  if (!startIsValid && endIsValid) return message;
  if (
    startDate &&
    endDate &&
    startIsValid &&
    endIsValid &&
    !isSameDay(endDate, startDate) &&
    isAfter(startDate, endDate)
  )
    return message;
  return null;
};
