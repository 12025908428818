import React, { FC, useState } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  Chip,
  FormHelperText,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";
import componentStyles from "./styles";

export type Props = {
  options: string[];
  inputLabel: string;
  /**
   * setSelected is a setState function from the parent component that will send back the selected options for that parent
   */
  setSelected?: (newChosenChannels: any[]) => void;
  /**
   * Default values is an array of strings that the user has selected already
   */
  defaultValues?: string[];
  classes?: string;
  labelId: string;
  id: string;
  helperText?: string;
  fullWidth?: boolean;
  /**
   * Is displayed under the helper text in italics
   */
  emphasizedText?: string;
};

const MultipleSelect: FC<Props> = ({
  options,
  inputLabel,
  setSelected,
  classes: optionalClasses,
  defaultValues,
  labelId,
  id,
  helperText,
  emphasizedText,
  fullWidth,
}: Props) => {
  const [value, setValues] = useState<any[]>(
    defaultValues ? defaultValues : []
  );

  const handleChange = (event: any) => {
    setSelected && setSelected(event.target.value);
    setValues(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={`${optionalClasses}`}
      fullWidth={fullWidth}
    >
      <InputLabel
        id={"multiple-select-inputlabel-id" || labelId}
        color="secondary"
      >
        {inputLabel}
      </InputLabel>
      <Select
        sx={{ icon: componentStyles.shiftIcon }}
        labelId={labelId || "multiple-select-label-id"}
        id={id || "multiple-select-id"}
        color="secondary"
        value={value}
        label={inputLabel}
        onChange={handleChange}
        multiple
        renderValue={(selected: any) => (
          <Grid sx={componentStyles.chips}>
            {selected.map((value: any) => (
              <Chip key={value} label={value} sx={componentStyles.chip} />
            ))}
          </Grid>
        )}
      >
        {options.map((option, index) => (
          <MenuItem key={`${option}${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {defaultValues && defaultValues.length === 0 && (
        <FormHelperText sx={componentStyles.textContainer}>
          <Typography sx={componentStyles.helperText} variant="h6">
            {helperText}
          </Typography>
          <Typography sx={componentStyles.emphasizedText} variant="h6">
            {emphasizedText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MultipleSelect;
