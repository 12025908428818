import { colors } from "styles/colors";

export default {
  paper: {
    padding: "2em",
    maxWidth: "25em",
    margin: "auto",
  },
  image: {
    height: "2em",
    maxWidth: "100%",
  },
  imageContainer: {
    textAlign: "center" as const,
  },
  title: {
    color: colors.gray10,
    fontWeight: "lighter" as const,
    textAlign: "center" as const,
    margin: "0.5em 0 0.5em",
    textTransform: "uppercase" as const,
    letterSpacing: "0.3em",
  },
  button: {
    padding: "1em",
    width: "100%",
  },
  buttonContainer: {
    padding: "1em",
  },
};
