import React, { useCallback } from "react";
import BillingModelQueryTypeahead from "sections/billing-model-common/components/BillingModelQueryTypeahead";
import { TransactionTypeSelectData } from "sections/billing-model-rateplans/hooks/useTransactionTypeSelectQuery";
import { BillingModelTransactionTypes_bamTransactionTypes as TransactionType } from "sections/billing-model-rateplans/__generated__/BillingModelTransactionTypes";
import { TypeaheadOption } from "sections/_global/components/Typeahead";

export interface Props {
  label: string;
  value: TransactionType | null;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  debounceMilliseconds?: number;
  useTransactionTypeSelectQuery: (search: string) => TransactionTypeSelectData;
  onChange?: (tt: TransactionType | null) => void;
}

const TransactionTypeSelect: React.FC<Props> = ({
  label,
  value,
  isDisabled,
  hasError,
  helperText,
  debounceMilliseconds = 300,
  useTransactionTypeSelectQuery,
  onChange,
}: Props) => {
  const {
    getTransactionTypes,
    loading: isLoadingTransactionTypes,
    transactionTypesResponse,
  } = useTransactionTypeSelectQuery(value?.transactionTypeId?.toString() ?? "");

  const getOption = (tt: TransactionType): TypeaheadOption => ({
    label: tt.name ?? "",
    value: tt,
  });

  const selected = value ? getOption(value) : null;

  const handleChange = useCallback(
    (val: string | TypeaheadOption | null) => {
      if (onChange) {
        const opt = val as TypeaheadOption;
        if (opt) onChange(opt.value);
        else onChange(null);
      }
    },
    [onChange]
  );

  const handleSearch = useCallback(
    (search: string) => {
      getTransactionTypes(search);
    },
    [getTransactionTypes]
  );

  return (
    <BillingModelQueryTypeahead
      label={label}
      idPropertyName="transactionTypeId"
      isLoading={isLoadingTransactionTypes}
      isDisabled={isDisabled}
      hasError={hasError}
      helperText={helperText}
      value={selected}
      options={
        transactionTypesResponse?.bamTransactionTypes?.map((tt) =>
          getOption(tt)
        ) ?? []
      }
      debounceMilliseconds={debounceMilliseconds}
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};

export default TransactionTypeSelect;
