import React, { FC } from "react";
import { Typography } from "@mui/material";
import BackButton from "../BackButton";
import { Box } from "@mui/system";
import componentStyles from "./styles";

export type Props = {
  title: string;
  url: string;
  screenIsSmall?: boolean;
};

const PageHeader: FC<Props> = ({ title, url, screenIsSmall }: Props) => {
  const useSmallerFont = title.length > 25 && screenIsSmall;
  return (
    <Box>
      <BackButton url={url} />
      <Typography
        sx={
          !useSmallerFont ? componentStyles.title : componentStyles.smallTitle
        }
        variant="h4"
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageHeader;
