type Watcher<T> = {
  id: number;
  callback: (value: T) => void;
};

/**
 * Creates a watchable value
 *
 * When the value changes, all watching callbacks are executed
 */
export default class Watchable<T> {
  nextWatcherId: number;
  value: T;
  watchers: Watcher<T>[];

  /**
   * @param  {T} initialValue The initial value of watchable value
   */
  constructor(initialValue: T) {
    this.nextWatcherId = 0;
    this.value = initialValue;
    this.watchers = [];
  }

  /**
   * @returns {T} Returns the watchable value
   */
  get(): T {
    return this.value;
  }

  /**
   * Setter function for the watchable value. Will execute the callback
   * functions of each watcher if the new value is different. Note that this is
   * done using strict equality comparison (===).
   *
   * @param  {T} value The updated value to set the watchable value.
   */
  set(value: T): void {
    if (value === this.value) return;

    this.value = value;
    this.watchers.forEach((watcher) => watcher.callback(value));
  }

  /**
   * Removes the watcher specified by the provided id
   *
   * @param  {number} id The id of the watcher to remove
   */
  unwatch(id: number): void {
    this.watchers = this.watchers.filter((watcher) => watcher.id !== id);
  }

  /**
   * Creates a watcher that executes the provided callback enever the watchable
   * value changes
   *
   * @param  {(value:T)=>void} callback A callback funcion which receives the
   *  updated watched value
   * @returns {number} Returns the id of the newly generated watcher
   */
  watch(callback: (value: T) => void): number {
    const id = this.nextWatcherId++;
    this.watchers.push({ id, callback });

    return id;
  }
}
