import {
  endDateValidator,
  FormError,
  isRequiredValidator,
  SimpleValidator,
  startDateValidator,
} from "sections/billing-model-common/BillingModelValidation";
import {
  RcdElement,
  RcdGroup,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";

const rcdGroupValidators = [
  // * Require a Period Type to be selected.
  new SimpleValidator<RcdGroup>(/^periodType$/, (value: unknown | null) =>
    isRequiredValidator(
      "Please set the frequency of the transaction.",
      value as string
    )
  ),
  // * Require a transaction to be selected.
  new SimpleValidator<RcdGroup>(/^transactionType$/, (value: unknown | null) =>
    isRequiredValidator("Please choose a transaction.", value as string)
  ),
  // * Require a start date.
  new SimpleValidator(
    /^startDate$/,
    (value: unknown | null, obj?: unknown, isUserAdmin?: boolean) =>
      startDateValidator(value as Date, isUserAdmin),
    /^endDate$/
  ),
  // * Require the end date to be on or after the start date value.
  new SimpleValidator<RcdGroup>(
    /^endDate$/,
    (value: unknown | null, obj?: RcdGroup) =>
      endDateValidator(obj?.startDate ?? null, value as Date)
  ),
];

const validateRcdGroupField = (
  name: string,
  update: RcdGroup,
  errors: FormError,
  isUserAdmin: boolean,
  prefix = ""
): [boolean, FormError] =>
  SimpleValidator.validate<RcdGroup>(
    rcdGroupValidators,
    name,
    update,
    errors,
    isUserAdmin,
    prefix,
    (name: string, update: RcdGroup) => {
      switch (name) {
        case "periodType":
          return update.periodType?.name ?? null;
        case "transactionType":
          return update.transactionType?.name ?? null;
        case "startDate":
          return update.startDate;
        case "endDate":
          return update.endDate;
        default:
          return update[name as keyof RcdGroup]?.toString() ?? null;
      }
    }
  );

const validateRcdGroup = (
  update: RcdGroup,
  errors: FormError,
  isUserAdmin: boolean,
  prefix = ""
): [boolean, FormError] => {
  let validationResults = { ...errors };
  const props = Object.keys(update);
  props.forEach((p) => {
    if (p.localeCompare("ratingControlElements") === 0) {
      update.ratingControlElements.forEach((e) => {
        validationResults = validateElement(
          e,
          update.ratingControlElements,
          validationResults,
          `${prefix}elements.`
        )[1];
      });
    } else {
      validationResults = validateRcdGroupField(
        p,
        update,
        validationResults,
        isUserAdmin,
        prefix
      )[1];
    }
  });

  const hasErrors = Object.values(validationResults).some((a) => a != null);
  return [hasErrors, validationResults];
};

// * ======================================================================================
// * Element Validation
// * ======================================================================================
/*
 * This validator handles checking the value of the element against the data type for the
 * element type and any REG_EXP expressions for either the element type or the data type.
 * If the element type has a REG_EXP set, it overrides the data type REG_EXP.
 * We convert the REG_EXP value from the database to a 'real' regular expression to test the value with.
 */
const elementDataTypeValidator = (
  value: unknown,
  objs: [RcdElement, RcdElement[]] | undefined
): string | null => {
  const element = objs ? objs[0] : null;
  /* Check the value against the REG_EXP or data type */
  if (element == null || element.value?.trim() === "") return null;

  const dataType =
    element.ratingControlElementType.ratingControlDataType?.name ?? "Character";
  let matches = false;
  let regEx = element.ratingControlElementType.regEx ?? null;

  if (regEx) {
    // *| translate the 'REG_EXP' from the billing model to usable expressions for validation
    // *| This appears to be based on old win32 textbox masks.
    // *| -----------------------------------------------------------------------------------------
    // *| 0 - Any single digit: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9
    // *| 9 - Any single digit can be entered or an empty space can be left.
    // *| # - Any single digit, a "+" or a "-" sign can be entered, or an empty space can be left.
    // *| L - Any letter in either uppercase or lowercase MUST be entered an no empty space should be left.
    // *|      Digits and other non-literals are not allowed.
    // *| ? - Any letter in either upper or lower case can be entered or an empty space can be left.
    // *|      Digits and other non-literals are not allowed.
    // *| & - Any letter, digit, or symbol, or empty space can be used.
    // *|      If the AasciiOnly property is True, this placeholder follows the ? behavior.
    // *| C - Any letter, digit, or symbol, or empty space can be used.
    // *|      If the AsciiOnly property is True, this placeholder follows the & behavior.
    // *| A or a - A letter (lowercase and uppercase), a digit, or an empty space is allowed. No special characters. If the AsciiOnly property is True, only a letter is allowed.
    // *| > - A letter in lowercase entered in this placeholder, and any letter in lowercase entered on the right side of this placeholder until the next < or I character, will be converted to uppercase.
    // *|      If the letter is entered in uppercase, nothing would happen.
    // *| < - A letter in uppercase entered in this placeholder, and any letter in uppercase entered on the right side of this placeholder until the next < or I character, will be converted to lowercase.
    // *|      If the letter is entered in lowercase, nothing would happen.
    // *| | - Removes the < or > rule set on the left side of this placeholder.
    // *|      This means that, in this placeholder and others on the right side, letters will not be converted but will be kept "as is"
    // *| . - The decimal symbol must be used.
    // *| , - The character for the thousands separator, which is the coma in US English, must be used.
    // *| : - The character used to separate the sections of a time value, which is ":" in US English, must be used.
    // *| / - The character used to separate the sections of a date value, which is "/" in US English, must be used.
    // *| $ - The currency symbol, which is $ is US and Canada, will be used.
    // *| \ - Makes the character follow the escape sequence rules.
    // *| Any other character - kept as is.

    const length = regEx.length;
    // ! ignore these for now
    regEx = regEx.replace(/\|/g, ""); // strip out | characters
    regEx = regEx.replace(/>/g, ""); // strip out > characters
    regEx = regEx.replace(/</g, ""); // strip out < characters
    regEx = regEx.replace(/\$/g, ""); // strip out $ characters

    regEx = regEx.replace(/\?/g, "[A-Za-z|\\s]?");
    regEx = regEx.replace(/\./g, "\\."); // escape decimals
    regEx = regEx.replace(/0/g, "\\d"); // handle digits
    regEx = regEx.replace(/9/g, "[\\d|\\s]?"); // handle digits or space
    regEx = regEx.replace(/#/g, "[\\d|\\+|\\-|\\s]?"); // handle digits or + / -
    regEx = regEx.replace(/L/g, "[A-Za-z]"); // handle required character

    // ! ascii only flag not explicitly handled currently
    regEx = regEx.replace(/&|C/gi, ".|\\s");
    regEx = regEx.replace(/a/gi, "[a-zA-Z0-9\\s]");
    const isNumeric = RegExp(/\d*\.*\d*/).test(regEx);

    const re = new RegExp(regEx);

    const numericCheck =
      isNumeric &&
      (element.value?.valueOf() === "0" || element.value?.valueOf() === ";");
    const regExpCheck =
      element.value != null &&
      re.test(element.value ?? "") &&
      element.value.length <= length;

    matches = regExpCheck || numericCheck;
  } else {
    let re: RegExp;
    switch (dataType) {
      case "Money":
      case "Decimal":
        re = new RegExp("^\\d*\\.*\\d*$");
        break;
      case "Integer":
        re = new RegExp("^\\d+$");
        break;
      case "Time":
        re = new RegExp("^[0-1]\\d:[0-5]\\d:[0-5]\\d$");
        break;
      default:
        // character
        re = new RegExp("[A-Z]+");
        break;
    }

    matches = re.test(element.value ?? "");
  }

  return !matches ? "Invalid value." : null;
};

/*
 * Some elements come if the form of '<SOMETHING> Allowed' and are paired with another element in the form of '<SOMETHING> Parameter'
 * Allowed elements must have a 'Y' or 'N' or "" value.
 * We run the related Parameter rule every time this validator runs.
 */
const allowedElementValidator = (
  value: unknown,
  objs: [RcdElement, RcdElement[]] | undefined
): string | null => {
  const val = value as string;
  return val != null && val.valueOf() !== "Y" && val.valueOf() !== "N"
    ? "Must by Y or N."
    : null;
};

/*
 * This validator checks the Parameter element for a value if the Allowed element is set to 'Y'.
 * This validator also makes sure that if the Parameter element has non-zero value set, that the
 * associated 'Allowed' element has its value set to 'Y'.
 */
const parameterElementValidator = (
  value: unknown,
  objs: [RcdElement, RcdElement[]] | undefined
): string | null => {
  if (objs) {
    const [element, elements] = objs;
    if (element.ratingControlElementType.displayLabel) {
      const allowedElementName = element.ratingControlElementType.displayLabel.replace(
        "Parameter",
        "Allowed"
      );

      const allowedElement = elements.find(
        (e) =>
          e.ratingControlElementType.displayLabel != null &&
          e.ratingControlElementType.displayLabel.localeCompare(
            allowedElementName
          ) === 0
      );

      if (allowedElement) {
        const allowed = allowedElement.value?.valueOf() === "Y";
        const haveValidValue = /\d+/.test(element.value ?? "");

        // if 'Allowed' element == Y then we need to have a value
        if (allowed && !haveValidValue) {
          return "Please enter a parm type ID.";
        }

        // if 'Allowed element == 'N' or blank, then we should NOT have a value
        if (
          !allowed &&
          haveValidValue &&
          (element.value?.valueOf() ?? "0") !== "0"
        ) {
          return "Allowed must be set to 'Y', otherwise remove this value.";
        }
      }
    }
  }
  return null;
};

const elementValidators = [
  new SimpleValidator<[RcdElement, RcdElement[]]>(
    /.*/,
    elementDataTypeValidator
  ),
  new SimpleValidator<[RcdElement, RcdElement[]]>(
    /.*Allowed/,
    allowedElementValidator,
    /.*Parameter/
  ),
  new SimpleValidator<[RcdElement, RcdElement[]]>(
    /.*Parameter/,
    parameterElementValidator
  ),
];

const validateElement = (
  update: RcdElement,
  elements: RcdElement[],
  errors: FormError,
  prefix = ""
): [boolean, FormError] => {
  const validationResults: FormError = { ...errors };

  if (
    update.ratingControlElementType === undefined ||
    update.ratingControlElementType.name === undefined
  ) {
    validationResults[prefix + update.ratingControlElementId] =
      "Unable to determine element type.";
    return [true, validationResults];
  }

  const checks = SimpleValidator.filterValidators<[RcdElement, RcdElement[]]>(
    update.ratingControlElementType.name,
    elementValidators
  );

  checks.forEach((c) => {
    let checkElements = [];
    if (c.match === /.*/) {
      // only run on supplied element
      checkElements.push(update);
    } else {
      // run against all possible elements
      checkElements = elements.filter((e) =>
        c.match.test(e.ratingControlElementType?.name ?? "")
      );
    }

    checkElements.forEach((e) => {
      const hidden = e.ratingControlElementType.hidden ?? false;
      const editable = e.ratingControlElementType.editable ?? false;
      if (!hidden && editable) {
        validationResults[
          prefix + e.ratingControlElementId
        ] = c.isInvalid(e.value, [e, elements]);
      }
    });
  });

  const hasErrors = Object.values(validationResults).some((a) => a != null);
  return [hasErrors, validationResults];
};

export { validateRcdGroup, validateRcdGroupField, validateElement };
