import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERIES } from "../BillingModelRatePlanQueries";
import {
  BillingModelTransactionTypes,
  BillingModelTransactionTypesVariables,
} from "../__generated__/BillingModelTransactionTypes";

export interface TransactionTypeSelectData {
  getTransactionTypes: (search: string) => void;
  transactionTypesResponse: BillingModelTransactionTypes | undefined;
  loading: boolean;
}

function useTransactionTypeSelectQuery(
  search: string
): TransactionTypeSelectData {
  const { loading, data, refetch } = useQuery<
    BillingModelTransactionTypes,
    BillingModelTransactionTypesVariables
  >(QUERIES.getTransactionTypes, {
    fetchPolicy: "cache-first",
    variables: { search },
  });

  return useMemo(
    () => ({
      getTransactionTypes: (search: string) => {
        refetch({ search });
      },
      transactionTypesResponse: data,
      loading,
    }),
    [data, loading, refetch]
  );
}

export default useTransactionTypeSelectQuery;
