import Typeahead, {
  TypeaheadOption,
} from "sections/_global/components/Typeahead";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Typography, createFilterOptions, Box } from "@mui/material";
import BillingModelTextAdornment from "../BillingModelTextAdornment";

function isTypeaheadOption(
  value: string | TypeaheadOption | null
): value is TypeaheadOption {
  return Boolean(value && typeof value !== "string");
}

export interface Props {
  label: string;
  value: string | TypeaheadOption | null;
  options?: TypeaheadOption[];
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  /**
   The name of the property which contains an item's type id.
   */
  idPropertyName?: string;
  /**
   If true, a spinner is shown as a starting adornment on the textbox.
   */
  isLoading?: boolean;
  /**
   The number of milliseconds to wait before triggering the `onSearch` event.
   */
  debounceMilliseconds?: number;

  onChange: (value: string | TypeaheadOption | null) => void;
  onFocus?: () => void;
  onSearch?: (search: string) => void;
}

/**
 Component for allowing the user to perform search queries onChange.<br/>
 Debounces onSearch event to prevent multiple calls while user is typing.
 */
const BillingModelQueryTypeahead = ({
  debounceMilliseconds = 1000,
  hasError,
  helperText,
  isDisabled,
  label,
  isLoading,
  onChange,
  onFocus,
  onSearch,
  options = [],
  idPropertyName,
  value,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const t = setTimeout(() => {
      if (search.length > 0 && onSearch) onSearch(search);
    }, debounceMilliseconds);

    return () => clearTimeout(t);
  }, [debounceMilliseconds, onSearch, search]);

  const isOptionEqualToValue = useCallback(
    (option: TypeaheadOption, value: TypeaheadOption) => {
      if (idPropertyName) {
        const a = option.value[idPropertyName];
        const b = option.value[idPropertyName];
        return a === b;
      } else {
        return option.value === value.value;
      }
    },
    [idPropertyName]
  );

  const handleOnChange = (value: string | TypeaheadOption | null) => {
    if (value === null) setSearch("");
    onChange(value);
  };

  return (
    <Typeahead
      label={label}
      value={value}
      options={
        isLoading ? [] : options?.sort((a, b) => a.label.localeCompare(b.label))
      }
      isLoading={isLoading}
      isDisabled={isDisabled}
      hasError={hasError}
      helperText={helperText}
      onChange={handleOnChange}
      onFocus={onFocus}
      onInputChange={(value) => setSearch(value)}
      isOptionEqualToValue={isOptionEqualToValue}
      startAdornment={
        <BillingModelTextAdornment
          value={
            isTypeaheadOption(value) && value.value && idPropertyName
              ? value.value[idPropertyName]
              : null
          }
        />
      }
      filterOptions={createFilterOptions({
        stringify: (option: TypeaheadOption) =>
          `${idPropertyName ? option.value[idPropertyName] : ""} ${
            option.label
          }`,
      })}
      renderOption={(props, option) => {
        const label = isTypeaheadOption(option) ? option.label : option;

        if (typeof option !== "string" && idPropertyName !== undefined) {
          return (
            <span {...props}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.2rem",
                  margin: "0.1rem",
                }}
              >
                <BillingModelTextAdornment
                  value={idPropertyName && option.value[idPropertyName]}
                />
                <Typography variant="body1">{label}</Typography>
              </Box>
            </span>
          );
        } else {
          return (
            <Typography {...props} variant="body1">
              {label}
            </Typography>
          );
        }
      }}
    />
  );
};

export default BillingModelQueryTypeahead;
