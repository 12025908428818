import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";
import componentStyles from "./styles";

type Props = {
  title: string;
  description: string;
};

const ColumnHeader: FC<Props> = ({ title, description }: Props) => {
  return (
    <Grid container sx={componentStyles.container}>
      <Grid xs={9} item sx={componentStyles.textContainer}>
        <Typography sx={componentStyles.heading}>{title}</Typography>
        <Typography sx={componentStyles.subHeading}>{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default ColumnHeader;
