import { Typography, Icon } from "@mui/material";
import React from "react";
import { colors } from "styles/colors";
import ConfirmationDialog from "../ConfirmationDialog";

export interface Props {
  isOpen: boolean;
  title?: string;
  message?: string;
  cancelNavigationClick: () => void;
  confirmNavigationClick: () => void;
}

/**
 Simple dialog for asking the user if they want to continue leaving a page.
 */
const ExitPromptDialog = ({
  isOpen,
  title = "Do you want to exit without saving?",
  message = "If you leave the current page your changes will be lost.",
  cancelNavigationClick,
  confirmNavigationClick,
}: Props): JSX.Element => {
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={title}
      icon={
        <Icon
          sx={{
            color: colors.alertYellow,
            marginRight: "0.4em",
            verticalAlign: "middle",
            paddingBottom: "0.3em",
          }}
        >
          warning
        </Icon>
      }
      okButtonLabel="Continue"
      okClick={confirmNavigationClick}
      cancelClick={cancelNavigationClick}
    >
      <Typography variant="body1">{message}</Typography>
    </ConfirmationDialog>
  );
};

export default ExitPromptDialog;
