import { colors } from "styles/colors";
import { createStyles } from "@mui/styles";

export default createStyles({
  root: {
    background: colors.borderGray,
    padding: "2rem",
    marginTop: "1em",
  },
});
