import React from "react";
import { withStyles } from "@mui/styles";
import { InputLabelProps, TextField } from "@mui/material";
import {
  DesktopDateTimePicker,
  MobileDateTimePicker,
  LocalizationProvider,
  DateTimePickerProps,
} from "@mui/lab";
import { v4 as uuidv4 } from "uuid";
import DateAdapter from "@mui/lab/AdapterDateFns";

import styles from "./styles";

export type Props = Partial<DateTimePickerProps<Date>> & {
  variant: "desktop" | "mobile";
  onChange: (date: Date | null, value?: string | undefined) => void;
  label?: string;
  classes: {
    input: string;
  };
  /**
   * An undefined value is truly undefined; a null value will set the value to the current time.
   */
  value: Date | undefined | null;
  minDateTime?: Date;
  error?: boolean;
  helperText?: string;
  InputLabelProps?: InputLabelProps;
};

const DateTextField = ({
  variant,
  onChange,
  label,
  classes,
  value,
  minDateTime,
  error,
  helperText,
  InputLabelProps,
  ...materialUIProps
}: Props): JSX.Element => {
  const inputId = `${uuidv4()}-date-textfield`;

  const combinedProps = InputLabelProps
    ? { ...InputLabelProps, htmlFor: inputId }
    : { htmlFor: inputId };

  const Picker =
    variant === "desktop" ? (
      <DesktopDateTimePicker
        label={label}
        inputFormat="MM/dd/yyyy hh:mm a"
        value={value}
        minDateTime={minDateTime}
        onChange={onChange}
        renderInput={(props) => (
          <TextField
            id={inputId}
            InputLabelProps={combinedProps}
            error={error}
            helperText={helperText}
            fullWidth
            {...props}
          />
        )}
        {...materialUIProps}
      />
    ) : (
      <MobileDateTimePicker
        label={label}
        inputFormat="MM/dd/yyyy hh:mm a"
        value={value}
        toolbarTitle
        onChange={onChange}
        renderInput={(props) => (
          <TextField
            id={inputId}
            InputLabelProps={combinedProps}
            error={error}
            helperText={helperText}
            fullWidth
            {...props}
          />
        )}
        {...materialUIProps}
      />
    );

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      {Picker}
    </LocalizationProvider>
  );
};

export { DateTextField };

const StyledComponent = withStyles(styles)(DateTextField);

export default StyledComponent;
