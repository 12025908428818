import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ConfigBar from "sections/_global/components/ConfigBar";
import ProfileMenu from "sections/_global/components/ProfileMenu";
import config from "config";
import ConfigDialog from "sections/_global/components/ConfigDialog";
import {
  currentClientAdminConfigHasProductionValues,
  clientAdminConfig,
} from "sections/_global/contexts/ClientAdminConfigContext";
import { useUser } from "sections/_global/contexts/UserContext";
import UserIcon from "sections/_global/components/UserIcon";
import AppHeader from "sections/_global/components/AppHeader";

type Props = {
  title: string;
  toggleDrawer: () => void;
};

const Header: React.FC<Props> = ({ toggleDrawer, title }: Props) => {
  const [user, dispatch] = useUser();
  const [configDialogIsOpen, setConfigDialogIsOpen] = useState<boolean>(false);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
    setMenuIsOpen(true);
  };
  const { databaseInstance } = clientAdminConfig.get();

  useEffect(() => {
    setShouldRedirect(false);
  }, [setShouldRedirect, shouldRedirect]);

  return user?.isAuthenticated ? (
    <>
      {shouldRedirect && <Redirect to="/" />}
      {config.configBarVisibility === "visible" && (
        <>
          <ConfigBar
            databaseInstance={databaseInstance}
            isProduction={currentClientAdminConfigHasProductionValues()}
            onSettingsClick={() => setConfigDialogIsOpen(true)}
          />
          <ConfigDialog
            isOpen={configDialogIsOpen}
            onClose={() => setConfigDialogIsOpen(false)}
          />
        </>
      )}
      <AppHeader
        leftActions={[
          {
            icon: "menu",
            onClick: toggleDrawer,
            label: "Menu",
          },
        ]}
        title="MetroNet Console"
        titleOnClick={() => setShouldRedirect(true)}
        rightActions={[
          {
            component: <UserIcon />,
            onClick: openMenu,
          },
        ]}
      />
      <ProfileMenu
        anchor={menuAnchor}
        onClose={() => setMenuIsOpen(false)}
        isOpen={menuIsOpen}
        displayName={user?.displayName}
        email={user?.email}
        onLogout={() => dispatch({ type: "LOG_OUT" })}
      />
    </>
  ) : null;
};

export default Header;
