import { compareAsc } from "date-fns";
import { ObjectStatus } from "./BillingModelTypes";

export interface BillingModelSearch {
  terms?: string;
  filter?: "Changes" | string;
  sort?: "Name" | "Newest" | "Recent Changes" | string;
  showExpired?: boolean;
}

export const filterByExpiredDate = (
  search: BillingModelSearch,
  endDate: Date | null
): boolean =>
  search.showExpired ||
  endDate == null ||
  (endDate != null && compareAsc(new Date(), new Date(endDate)) === -1);

export const filterBySearch = (
  search: BillingModelSearch,
  id?: string | null,
  name?: string | null
): boolean => {
  const idMatch =
    search.terms != null && id != null && search.terms.includes(id);
  const nameMatch =
    search.terms != null &&
    name != null &&
    name.toLowerCase().includes(search.terms.toLowerCase());

  return search.terms == null || idMatch || nameMatch;
};

export const filterByChanges = (
  search: BillingModelSearch,
  status?: ObjectStatus
): boolean =>
  search.filter === "Changes" ? status != null : status !== "deleted";

export const paginate = <T>(
  page: number,
  pageSize: number,
  items: Array<T>
): [Array<T>, number] => {
  const start = pageSize * (page - 1);
  const end = Math.min(start + pageSize, items.length);
  const totalPages = Math.floor((items.length + pageSize - 1) / pageSize);
  const pageContent = items.slice(start, end);
  return [pageContent, totalPages];
};

export const sortById = (
  id: string | undefined,
  otherId: string | undefined
): number => {
  const numA = Number(id);
  const numB = Number(otherId);
  if (numA < numB) return -1;
  if (numA > numB) return 1;
  return 0;
};
