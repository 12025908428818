import React from "react";

import AffectedAreaAccordion from "./AffectedAreaAccordion";
import { SplitAffectedArea } from "../../data-model";

export type Props = {
  affectedAreas: SplitAffectedArea[];
};

const AffectedAreaAccordions: React.FC<Props> = ({ affectedAreas }: Props) => {
  return (
    <div>
      {affectedAreas
        .sort((a, b) => (a.city > b.city ? 1 : -1))
        .map((affectedArea) => (
          <AffectedAreaAccordion
            key={`${affectedArea.city}.${affectedArea.state}`}
            city={affectedArea.city}
            state={affectedArea.state}
            lcps={affectedArea.lcps}
            numberOfCallbacks={affectedArea.numberOfCallbacks}
            numberOfCalls={affectedArea.numberOfCalls}
          />
        ))}
    </div>
  );
};

export default AffectedAreaAccordions;
