import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Icon from "@mui/material/Icon";

export interface Props {
  name: string;
  lightLevel: string | null;
  status?: string | null;
}

const useStyles = makeStyles(styles);

const LightLevelsEntry: React.FC<Props> = ({
  name,
  lightLevel,
  status,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} sm>
        <Typography variant="h5" color="textPrimary">
          {name + ":"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm
        container
        spacing={2}
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <Icon className={classes.flashlightIcon}>flashlight_on</Icon>
        </Grid>
        <Grid item>
          <Typography align="center" variant="h5" color="textSecondary">
            {lightLevel ?? "Not found"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm
        container
        spacing={2}
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <Icon color="primary">timelapse</Icon>
        </Grid>
        <Grid item>
          <Typography align="center" variant="h5" color="textSecondary">
            {status ?? "null"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LightLevelsEntry;
