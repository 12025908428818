export default {
  heading: {
    alignItems: "flex-start",
    flexDirection: "column" as const,
    outline: "none",
  },
  displayName: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5em",
  },
} as const;
