import { colors } from "styles/colors";
import { makeStyles } from "@mui/styles";

export default makeStyles({
  emailLink: {
    color: colors.brandBlue,
  },
  appNotice: {
    marginTop: "5em",
  },
});
