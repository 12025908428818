/**
 * Each entry in window.Constants should be added to compile-index-file.js to be
 * populated.
 */
type Constants = {
  backendEndpoint: string;
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseProviderId: string;
  authenticationType: "SAML" | "TOKEN";
  configBarVisibility: "visible" | "hidden";
  databaseInstance: string;
  databaseInstanceOptions: string[];
  supportEmail: string;
};

declare global {
  interface Window {
    Constants: Constants;
  }
}

type ConstantsKey = keyof Constants;

type EntrypointConfigDefaults = {
  [key in ConstantsKey]: (value: any) => any;
};

const entrypointConfigDefaults: EntrypointConfigDefaults = {
  backendEndpoint: (value: any) => {
    if (
      !value &&
      (process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test")
    ) {
      return process.env.REACT_APP_BACKEND_ENDPOINT ?? "";
    }
  },
  firebaseAuthDomain: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? "";
    }
  },
  firebaseApiKey: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_FIREBASE_API_KEY ?? "";
    }
  },
  firebaseProviderId: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_FIREBASE_PROVIDER_ID ?? "";
    }
  },
  authenticationType: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_AUTHENTICATION_TYPE ?? "SAML";
    }
  },
  configBarVisibility: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_CONFIG_BAR_VISIBILITY ?? "";
    }
  },
  databaseInstance: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_DATABASE_INSTANCE ?? "";
    }
  },
  databaseInstanceOptions: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_DATABASE_INSTANCE_OPTIONS;
    }
  },
  supportEmail: (value: any) => {
    if (!value && process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_SUPPORT_EMAIL ?? "";
    }
  },
};

export const getEntrypointValue = (name: ConstantsKey): any => {
  let value = window.Constants?.[name];

  if (!value) {
    value = entrypointConfigDefaults[name](value);
  }

  if (!value && process.env.NODE_ENV !== "test") {
    console.error(`No value for ${name} set.`);
  }

  return value;
};

type ProductionConstants = {
  databaseInstance: string;
};

type AppConfig = {
  authenticationType: "TOKEN" | "SAML";
  backendEndpoint: string;
  firebase: {
    authDomain: string;
    providerId: string;
    apiKey: string;
  };
  configBarVisibility: "visible" | "hidden";
  productionConstants: ProductionConstants;
  databaseInstanceOptions: string[];
  supportEmail: string;
};

const config: AppConfig = {
  authenticationType: getEntrypointValue("authenticationType"),
  backendEndpoint: getEntrypointValue("backendEndpoint"),
  firebase: {
    authDomain: getEntrypointValue("firebaseAuthDomain"),
    providerId: getEntrypointValue("firebaseProviderId"),
    apiKey: getEntrypointValue("firebaseApiKey"),
  },
  configBarVisibility: getEntrypointValue("configBarVisibility"),
  productionConstants: {
    databaseInstance: "QCC1",
  },
  databaseInstanceOptions: getEntrypointValue("databaseInstanceOptions")
    ? getEntrypointValue("databaseInstanceOptions").split(" ")
    : [],
  supportEmail: getEntrypointValue("supportEmail"),
};

export default config;
