import React, { FC } from "react";
import { Button, Icon } from "@mui/material";

export type Props = {
  title?: string;
  className?: string;
  onClick: () => void;
};

const EditButton: FC<Props> = ({ className, onClick, title }: Props) => {
  return (
    <Button
      sx={{ marginBottom: "1rem" as const }}
      onClick={onClick}
      startIcon={<Icon>create</Icon>}
      className={className}
    >
      {title || "Edit"}
    </Button>
  );
};

export default EditButton;
