export default {
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: ".25rem",
    fontSize: "1rem",
  },
  noLabel: {
    fontSize: "1rem",
  },
  helperText: {
    fontSize: { xs: "0.8rem", sm: "1rem" },
    marginTop: { xs: ".4rem", sm: "1rem" },
    textAlign: { xs: "center", sm: "inherit" },
  },
  shiftIcon: {
    marginRight: "0.5rem",
  },
  emphasizedText: {
    fontSize: { xs: "0.7rem", sm: "0.8rem" },
    textAlign: { xs: "center", sm: "inherit" },
    fontStyle: "italic",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: { xs: 0, sm: "-0.75rem" },
  },
} as const;
