import React, { FC, useEffect, useState, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Typography, Box } from "@mui/material";
import { subMonths, startOfToday } from "date-fns";

import OutageCards from "./OutageCards";
import OutageStatusChip from "./OutageStatusChip";
import { GetOutages } from "../../__generated__/GetOutages";
import {
  UpdateOutage,
  UpdateOutageVariables,
} from "../../__generated__/UpdateOutage";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { QUERIES, MUTATIONS } from "../../ServiceDisruptionQueries";
import { Outage, Status } from "../../ServiceDisruptionTypes";
import OutageFilterMenu from "../../components/OutageFilterMenu";
import { getFormattedOutagesFromQueryData } from "../../data-model";
import NoResults from "sections/_global/components/NoResults";

import styles from "./styles";

type OutageType = "planned" | "unplanned";

type Props = {
  type: OutageType;
};

const OutagesList: FC<Props> = ({ type }: Props) => {
  const [statusesShown, setStatusesShown] = useState<Status[]>(["A"]);
  const [refetchIsLoading, setRefetchIsLoading] = useState<boolean>(false);
  const [monthsPast, setMonthsPast] = useState<number>(1);
  const [
    getOutages,
    { data, refetch, loading: getOutagesIsLoading },
  ] = useLazyQuery<GetOutages>(QUERIES.getOutages, {
    variables: {
      sinceDateTime: subMonths(startOfToday(), monthsPast),
      statuses: statusesShown,
      isPlanned: type === "planned",
    },
  });
  const outages = data?.outages;
  const [updateOutage, { loading: updateOutagesIsLoading }] = useMutation<
    UpdateOutage,
    UpdateOutageVariables
  >(MUTATIONS.updateOutage);

  const formattedOutages: Outage[] = getFormattedOutagesFromQueryData({
    data,
  });

  const onChipClick = (status: Status) => () => {
    let newStatusesShown: Status[] = JSON.parse(JSON.stringify(statusesShown));
    if (!statusesShown.includes(status)) {
      newStatusesShown.push(status);
    } else {
      newStatusesShown = newStatusesShown.filter((s) => s !== status);
    }

    setStatusesShown(newStatusesShown);
  };

  useEffect(() => {
    if (refetch) {
      // This is meant to be a silent refresh, so we don't want refetchIsLoading to show the overlay
      refetch();
    }
  }, [refetch]);

  const refetchOutages = useCallback(() => {
    if (refetch) {
      setRefetchIsLoading(true);
      refetch().then(() => {
        setRefetchIsLoading(false);
      });
    }
  }, [refetch]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOutages();
    }

    return () => {
      isMounted = false;
    };
  }, [getOutages]);

  useEffect(() => {
    refetchOutages();
  }, [statusesShown, monthsPast, refetchOutages]);

  const isLoadingOutages =
    (getOutagesIsLoading && !outages) ||
    refetchIsLoading ||
    updateOutagesIsLoading;
  const renderOutageCards = isLoadingOutages || formattedOutages.length > 0;

  return (
    <Box>
      <LoadingBackdrop isOpen={isLoadingOutages} />
      <Box sx={styles.titleContainer}>
        <Typography variant="h4" sx={styles.title}>
          {type === "planned" ? "Maintenances" : "Outages"}
        </Typography>
        <OutageFilterMenu
          setMonthsPast={setMonthsPast}
          currentMonthsPast={monthsPast}
        />
      </Box>
      <OutageStatusChip
        label="Active"
        selected={statusesShown.includes("A")}
        onClick={onChipClick("A")}
      />
      <OutageStatusChip
        label="Completed"
        selected={statusesShown.includes("C")}
        onClick={onChipClick("C")}
      />
      <OutageStatusChip
        label="Cancelled"
        selected={statusesShown.includes("X")}
        onClick={onChipClick("X")}
      />
      <Box marginTop="1em">
        {renderOutageCards ? (
          <OutageCards
            outages={formattedOutages}
            refetchOutages={refetchOutages}
            updateOutage={updateOutage}
          />
        ) : (
          <NoResults
            message={`No ${
              type === "planned" ? "maintenances" : "outages"
            } found.`}
          />
        )}
      </Box>
    </Box>
  );
};

export default OutagesList;
