import firebase from "firebase/app";
import "firebase/auth";

import config from "../config";
import { Post } from "../services/fetch";
import firebaseProvider from "firebaseProvider";

type Login = {
  username?: string;
  password?: string;
};

if (config.firebase.apiKey) {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: config.firebase.apiKey,
      authDomain: config.firebase.authDomain,
    });
  }
}

export const logInWithUsername = async ({
  username,
  password,
}: Login): Promise<string> => {
  const rawResponse = await Post({
    uri: `${config.backendEndpoint}/login`,
    data: { username, password },
  });
  const response = await rawResponse.json();

  return response.accessToken;
};

export const logInWithFirebase = async (): Promise<string | undefined> => {
  let result;
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    if (!firebaseProvider) {
      console.error("No Firebase provider found.");
      return "";
    }

    result = await firebase.auth().signInWithPopup(firebaseProvider);
  } catch (err) {
    console.error("Failed to login.");
  }

  return result?.user?.getIdToken();
};

export const getNewToken = async (): Promise<string> => {
  if (config.authenticationType === "TOKEN") {
    const headers = new Headers();
    headers.append("cache-control", "no-store");
    const rawResponse = await Post({
      uri: `${config.backendEndpoint}/refreshToken`,
      headers,
    });

    const response = await rawResponse.json();

    return response.accessToken;
  } else if (config.authenticationType === "SAML") {
    return firebase.auth().currentUser?.getIdToken(true) || "";
  }

  return "";
};
