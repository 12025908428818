import React from "react";

import UserIcon from "./UserIcon";
import { useUserState } from "../../contexts/UserContext";

type Props = {
  styles?: {
    root?: string;
    initials?: string;
  };
};

const UserIconProvider: React.FC<Props> = ({ styles }: Props) => {
  const user = useUserState();

  return <UserIcon styles={styles} displayName={user.displayName} />;
};

export default UserIconProvider;
