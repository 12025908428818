import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Grid, TextField } from "@mui/material";
import { Address } from "../../screens/types";
import ValidatedTextField from "sections/_global/components/ValidatedTextField";
import {
  postalCodeValidation,
  stateAbbrevValidation,
} from "sections/_global/RegexValidation";

type Props = {
  address: Address;
  onChange: (str: any) => void;
  required?: boolean;
};

const AddressInput: FC<Props> = ({ address, onChange, required }: Props) => {
  return (
    <Grid container spacing={1} justifyItems="start">
      <Grid item md={4} xs={8}>
        <TextField
          id="address1"
          value={address?.streetAddress1 ?? ""}
          onChange={(e) => {
            onChange({
              ...address,
              streetAddress1: e.target.value,
            });
          }}
          label="Address 1"
          variant="outlined"
          required={required}
          type="text"
          style={{ display: "flex" }}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <TextField
          id="address2"
          value={address?.streetAddress2 ?? ""}
          onChange={(e) =>
            onChange({
              ...address,
              streetAddress2: e.target.value,
            })
          }
          label="Address 2"
          variant="outlined"
          type="text"
          style={{ display: "flex" }}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <TextField
          id="city"
          value={address?.city ?? ""}
          onChange={(e) => {
            onChange({
              ...address,
              city: e.target.value,
            });
          }}
          label="City"
          variant="outlined"
          required={required}
          type="text"
          style={{ display: "flex" }}
        />
      </Grid>
      <Grid item md={1} xs={2}>
        <ValidatedTextField
          id="state"
          value={address?.state ?? ""}
          onChange={(e) =>
            onChange({
              ...address,
              state: e.target.value,
            })
          }
          label="State"
          variant="outlined"
          required={required}
          type="text"
          errorText="Provide 2 letter state abbreviation."
          regex={stateAbbrevValidation}
          classes={styles}
        />
      </Grid>
      <Grid item md={2} xs={4}>
        <ValidatedTextField
          id="postalcode"
          value={address?.postalCode ?? ""}
          onChange={(e) =>
            onChange({
              ...address,
              postalCode: e.target.value,
            })
          }
          label="Postal Code"
          variant="outlined"
          required={required}
          type="text"
          errorText="Incorrect postal code format"
          regex={postalCodeValidation}
          classes={styles}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddressInput);
