import React, { FC } from "react";
import { Icon, Chip } from "@mui/material";

export type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

const OutageStatusChip: FC<Props> = ({ label, selected, onClick }: Props) => {
  return (
    <Chip
      label={label}
      icon={selected ? <Icon>check</Icon> : <></>}
      clickable
      color={selected ? "primary" : "default"}
      onClick={onClick}
      sx={{
        marginRight: "0.4em",
      }}
    />
  );
};

export default OutageStatusChip;
