import React from "react";
import { Typography, MenuItem, Menu, Box } from "@mui/material";

import UserIcon from "sections/_global/components/UserIcon";
import { linkComponent } from "sections/_global/ui";
import styles from "./styles";

export type Props = {
  /**
   * This is the element the menu is attached to.
   */
  anchor: Element | null;
  isOpen: boolean;
  /**
   * Closing occurs when clicking away from the menu.
   */
  onClose: () => void;
  email?: string | null;
  displayName?: string | null;
  onLogout: () => void;
};

const ProfileMenu: React.FC<Props> = ({
  anchor,
  isOpen,
  onClose,
  email,
  displayName,
  onLogout,
}: Props) => {
  const logout = () => {
    onLogout();
    onClose();
  };

  return (
    <Menu anchorEl={anchor} keepMounted open={isOpen} onClose={onClose}>
      <MenuItem sx={styles.heading} autoFocus={false} divider>
        <Box sx={styles.displayName}>
          <UserIcon />
          <Typography variant="h6" component="h1" marginLeft="0.5em">
            {displayName}
          </Typography>
        </Box>
        <Typography variant="body2" component="h2">
          {email}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={onClose}
        component={linkComponent({ url: "/settings" })}
      >
        Settings
      </MenuItem>
      <MenuItem onClick={logout}>Log out</MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
