import {
  endDateValidator,
  FormError,
  isRequiredValidator,
  SimpleValidator,
  startDateValidator,
} from "sections/billing-model-common/BillingModelValidation";
import { RatePlan } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { validateElement, validateRcdGroupField } from "./RcdGroupValidation";

// * ==================================================================
// * Validators for the RatePlan
// * ==================================================================
const ratePlanValidators = [
  new SimpleValidator(/^name$/, (value: unknown | null) =>
    isRequiredValidator("Please enter a name.", value as string)
  ),
  new SimpleValidator(/^ratePlanType$/, (value: unknown | null) =>
    isRequiredValidator("Please select a Rate Plan Type.", value as string)
  ),
  new SimpleValidator<RatePlan>(
    /^startDate$/,
    (value: unknown | null, obj?: RatePlan, isUserAdmin?: boolean) =>
      startDateValidator(
        value as Date,
        isUserAdmin,
        obj?.ratePlanId ? true : false
      ),
    /^endDate$/
  ),
  new SimpleValidator<RatePlan>(
    /^endDate$/,
    (value: unknown | null, obj?: RatePlan) =>
      endDateValidator(obj?.startDate ?? null, value as Date)
  ),
];

const validateRatePlanField = (
  name: string,
  update: RatePlan,
  errors: FormError,
  isUserAdmin: boolean,
  prefix = ""
): [boolean, FormError] =>
  SimpleValidator.validate<RatePlan>(
    ratePlanValidators,
    name,
    update,
    errors,
    isUserAdmin,
    prefix,
    (name: string, update: RatePlan) => {
      let value: unknown | null = null;
      switch (name) {
        case "ratePlanType":
          value = update.ratePlanType?.ratePlanTypeId;
          break;
        case "startDate":
          value = update.startDate;
          break;
        case "endDate":
          value = update.endDate;
          break;
        default:
          value = update[name as keyof RatePlan]?.toString() ?? null;
          break;
      }

      return value;
    }
  );

const validateRatePlan = (
  update: RatePlan,
  isUserAdmin: boolean
): [boolean, FormError] => {
  let validationErrors = {};
  const ratePlanProps = Object.keys(update);
  ratePlanProps.forEach((k) => {
    if (!(k.localeCompare("rcdGroups") === 0)) {
      const ratePlanResult = validateRatePlanField(
        k,
        update,
        validationErrors,
        isUserAdmin
      );
      validationErrors = ratePlanResult[1];
    } else {
      // * validate rcd groups
      for (const group of update.ratingControlGroups) {
        const rcdGroupProps = Object.keys(group);

        rcdGroupProps.forEach((gk) => {
          const groupErrorPrefix = `rcdGroups.${group.ratingControlGroupId}.`;

          if (!(gk.localeCompare("elements") === 0)) {
            const rcdGroupResult = validateRcdGroupField(
              gk,
              group,
              validationErrors,
              isUserAdmin,
              groupErrorPrefix
            );
            validationErrors = rcdGroupResult[1];
          } else {
            // * validate rcd group elements
            for (const element of group.ratingControlElements) {
              const elementErrorPrefix = groupErrorPrefix + `elements.`;
              const elementResult = validateElement(
                element,
                group.ratingControlElements,
                validationErrors,
                elementErrorPrefix
              );
              validationErrors = elementResult[1];
            }
          }
        });
      }
    }
  });

  const hasErrors = Object.values(validationErrors).some((a) => a != null);
  return [hasErrors, validationErrors];
};

export { validateRatePlan, validateRatePlanField };
