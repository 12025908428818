import { Box, CircularProgress, Pagination, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { colors } from "styles/colors";

export interface Props {
  page?: number;
  totalPages?: number;
  loading?: boolean;
  isEmptyMessage?: string;
  variant?: "top" | "bottom" | "both";
  onPageChange?: (newPage: number) => void;
}

const PaginatedList: React.FC<Props> = ({
  page = 0,
  totalPages = 0,
  onPageChange,
  loading,
  isEmptyMessage = "No search results found",
  variant = "bottom",
  children,
}: PropsWithChildren<Props>) => {
  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const renderPagination = (location: string) => {
    if (
      !loading &&
      children &&
      onPageChange &&
      page > 0 &&
      totalPages > 0 &&
      (variant === location || variant === "both")
    ) {
      return (
        <Pagination
          page={page}
          count={totalPages}
          sx={{
            marginTop: "0.3em",
            marginLeft: "auto",
            marginBottom: "0.3em",
            "& button.Mui-selected": {
              color: colors.white,
            },
          }}
          color="secondary"
          onChange={handleChangePage}
        />
      );
    }

    return null;
  };

  const renderChildren = () => {
    if (!loading && children) return children;
    if (loading)
      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          height="100px"
        >
          <CircularProgress />
        </Box>
      );
    return (
      <Typography variant="h6" align="center">
        {isEmptyMessage}
      </Typography>
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      {renderPagination("top")}
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: "100%",
          overflow: "auto",
          marginTop: "0.5rem",
        }}
      >
        {renderChildren()}
      </Box>
      {renderPagination("bottom")}
    </Box>
  );
};

export default PaginatedList;
