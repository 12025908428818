import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import styles from "../../screens/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box, Button, Grid, Typography, Card } from "@mui/material";

import { quotaActivityDates_quotaActivityDates_dates as QuotaActivityDate } from "../../../__generated__/quotaActivityDates";

export type Props = {
  classes: {
    title: string;
  };
  quota?: QuotaActivityDate[];
  quotaOffset: number;
  selected: string;
  required?: boolean;
  handleChange: (e: any) => void;
  decrementQuotaOffset: (e: any) => void;
  incrementQuotaOffset: (e: any) => void;
};

function formatDate(date: string | null) {
  if (date === null) return "";
  if (!date.includes("T")) return date;
  return date.substring(0, date.indexOf("T"));
}

const QuotaPicker: FC<Props> = ({
  classes,
  quota = [],
  quotaOffset,
  selected,
  required,
  handleChange,
  decrementQuotaOffset,
  incrementQuotaOffset,
}: Props) => {
  return (
    <>
      {quota.length != 0 && (
        <Card>
          <FormControl component="fieldset">
            <Box margin="0.5em">
              <Box marginBottom="1em">
                <FormLabel
                  component="legend"
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                  required={required}
                >
                  Time Slots
                </FormLabel>
              </Box>
              <RadioGroup
                name="radio-buttons-group"
                onChange={handleChange}
                value={selected}
              >
                <Grid container rowSpacing={2}>
                  {quota.map((entry) => (
                    <Grid item key={entry.date}>
                      {entry?.timeSlots?.length != 0 && (
                        <>
                          <Typography style={{ fontWeight: 600 }}>
                            {formatDate(entry?.date) + " - " + entry?.day}
                          </Typography>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                          >
                            {entry?.timeSlots?.map((timeSlot: any) => (
                              <FormControlLabel
                                key={`${formatDate(entry.date)}!${
                                  timeSlot.label
                                }`}
                                value={`${formatDate(entry.date)}!${
                                  timeSlot.label
                                }`}
                                control={
                                  <Radio
                                    color="secondary"
                                    required={required}
                                  />
                                }
                                label={`${timeSlot.name} (${timeSlot.availableQuota}/${timeSlot.quotaLimit})`}
                              />
                            ))}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  columnSpacing={1}
                  marginTop="1em"
                  marginBottom="0.5em"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={quotaOffset <= 0}
                      onClick={decrementQuotaOffset}
                    >
                      Previous 7 Days
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={quotaOffset >= 14}
                      onClick={incrementQuotaOffset}
                    >
                      Next 7 Days
                    </Button>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Box>
          </FormControl>
        </Card>
      )}
    </>
  );
};

export default withStyles(styles)(QuotaPicker);
