export type NameTypeSort = "Name" | "Type";
export type NoneNewFilter = "None" | "New";
export type ObjectStatus =
  | "active"
  | "expired"
  | "new"
  | "changed"
  | "deleted"
  | null;

export enum BamSortBy {
  createdAt = "createdAt",
  modifiedAt = "modifiedAt",
  name = "name",
}

export enum BamSortOrder {
  ascending = "ascending",
  descending = "descending",
}
