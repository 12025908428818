import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
} from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import firebase from "firebase/app";
import { graphQLErrorMessage } from "./sections/_global/contexts/MessageContext";
import { clientAdminConfig } from "./sections/_global/contexts/ClientAdminConfigContext";
import { getCachedAccessToken, setCachedAccessToken } from "./token";
import config from "./config";
import { getNewToken } from "./lib/Authentication";

const httpLink = new HttpLink({
  uri: `${config.backendEndpoint}/graphql`,
});

const getServerToken = async (): Promise<string> => {
  let accessToken = getCachedAccessToken();
  if (!accessToken) {
    accessToken = await getNewToken();
    setCachedAccessToken(accessToken);
  }

  return accessToken;
};

const getFirebaseToken = () => firebase.auth().currentUser?.getIdToken();

const getCurrentAccessToken = async () => {
  return config.authenticationType === "TOKEN"
    ? getServerToken()
    : config.authenticationType === "SAML"
    ? getFirebaseToken()
    : null;
};

const withCurrentAccessToken = setContext(async (_, { headers }) => {
  const token = await getCurrentAccessToken();

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const withConfigHeaders = setContext(async (_, { headers }) => {
  const { databaseInstance } = clientAdminConfig.get();
  if (!databaseInstance) return { headers };

  return {
    headers: {
      ...headers,
      "metronet-db": databaseInstance,
    },
  };
});

const handleExpiredAccessToken = async () => {
  const newAccessToken = await getNewToken();
  setCachedAccessToken(newAccessToken);
  return newAccessToken;
};

const handleError = onError(
  ({ operation, forward, networkError, graphQLErrors }): any => {
    if (
      networkError &&
      networkError.name === "ServerError" &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      return fromPromise(handleExpiredAccessToken())
        .filter((token) => Boolean(token))
        .flatMap(() => forward(operation));
    } else if (
      graphQLErrors &&
      graphQLErrors.some((error) => error.message === "Permission denied.")
    ) {
      graphQLErrorMessage.set("Permission denied.");
    } else {
      graphQLErrorMessage.set("Error processing request.");
    }
  }
);

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => /^5\d{2}$/.test(error.statusCode), // only retry 5xx errors
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([
    handleError,
    withCurrentAccessToken,
    withConfigHeaders,
    retryLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  name: "MetroNet Console",
});

export default client;
