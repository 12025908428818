import React from "react";

import ConfigDialog from "./ConfigDialog";
import config from "config";
import {
  saveConfig,
  clientAdminConfig,
} from "../../contexts/ClientAdminConfigContext";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ConfigDialogProvider: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { databaseInstance } = clientAdminConfig.get();
  return (
    <ConfigDialog
      databaseInstance={databaseInstance}
      isOpen={isOpen}
      onClose={onClose}
      onSave={saveConfig}
      databaseInstanceOptions={config.databaseInstanceOptions}
    />
  );
};

export default ConfigDialogProvider;
