import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import RatePlanForm from "sections/billing-model-rateplans/components/RatePlanForm";
import {
  MUTATIONS,
  QUERIES,
} from "sections/billing-model-rateplans/BillingModelRatePlanQueries";
import { Paper } from "@mui/material";
import {
  RatePlan,
  RcdElement,
  UpdateRatePlanInput,
  UpdateRcdElementInput,
  UpdateRcdGroupInput,
} from "../BillingModelRatePlanTypes";
import {
  BillingModelRatePlan,
  BillingModelRatePlanVariables,
} from "../__generated__/BillingModelRatePlan";
import { BillingModelUpdateRatePlan } from "../__generated__/BillingModelUpdateRatePlan";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";

type Params = {
  id: string;
};

const EditRatePlan: React.FC = () => {
  const { id } = useParams<Params>();

  const { data: ratePlanData, loading: ratePlanLoading } = useQuery<
    BillingModelRatePlan,
    BillingModelRatePlanVariables
  >(QUERIES.getRatePlan, {
    fetchPolicy: "network-only",
    variables: { id: id },
  });

  const dispatch = useMessageDispatch();
  const [updateRatePlan, { loading: updatingRatePlan }] = useMutation<
    BillingModelUpdateRatePlan
  >(MUTATIONS.updateRatePlan);

  const handleSubmit = async (ratePlan: RatePlan): Promise<boolean> => {
    const input: UpdateRatePlanInput = {
      ratePlanId: ratePlan.ratePlanId,
      name: ratePlan.name,
      ratePlanTypeId: ratePlan.ratePlanType?.ratePlanTypeId ?? "",
      description: ratePlan.description,
      startDate: ratePlan.startDate,
      endDate: ratePlan.endDate,
      currencyCode: ratePlan.currencyCode,
      ratingControlGroups:
        ratePlan.ratingControlGroups?.map((g) => {
          const group: UpdateRcdGroupInput = {
            ratingControlGroupId: g.ratingControlGroupId,
            ratingControlGroupTypeId:
              g.ratingControlGroupType?.ratingControlGroupTypeId,
            transactionTypeId: g.transactionType?.transactionTypeId ?? "",
            periodTypeId: g.periodType?.periodTypeId ?? "",
            startDate: g.startDate,
            endDate: g.endDate,
            ratingControlElements: g.ratingControlElements?.map(
              (e: RcdElement) => {
                const element: UpdateRcdElementInput = {
                  ratingControlElementId: e.ratingControlElementId,
                  ratingControlElementTypeId:
                    e.ratingControlElementType.ratingControlElementTypeId,
                  value: e.value,
                };

                return element;
              }
            ),
          };

          return group;
        }) ?? [],
    };

    const request = await updateRatePlan({ variables: { input } });

    dispatch({
      type: "ADD_MESSAGE",
      message: {
        description:
          request.data?.bamUpdateRatePlan?.message ??
          "An error occurred while updating the Rate Plan.",
        type: request.data?.bamUpdateRatePlan?.success ? "success" : "error",
        isOpen: true,
        id: "edit-rateplan-msg",
      },
    });

    if (request.data?.bamUpdateRatePlan?.success === true) {
      return true;
    }

    return false;
  };

  return (
    <BillingModelScreen
      backUrl="/billing-model/rates/list"
      onBackBtnClick={() =>
        dispatch({ type: "CLOSE_MESSAGE", message: "edit-rateplan-msg" })
      }
    >
      <Paper>
        <LoadingBackdrop isOpen={ratePlanLoading || updatingRatePlan} />
        {!ratePlanLoading && ratePlanData && ratePlanData.bamRatePlan && (
          <RatePlanForm
            ratePlan={ratePlanData.bamRatePlan as RatePlan}
            onSubmit={(ratePlan) => handleSubmit(ratePlan)}
          />
        )}
      </Paper>
    </BillingModelScreen>
  );
};

export default EditRatePlan;
