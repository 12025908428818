import { useQuery } from "@apollo/client";
import { Icon } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import AppFab from "sections/_global/components/AppFab";
import PaginatedList from "sections/_global/components/PaginatedList";
import { BillingModelSearch } from "sections/billing-model-common/BillingModelSearch";
import BillingModelCardList from "sections/billing-model-common/components/BillingModelCardList";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import BillingModelTypeCard from "sections/billing-model-common/components/BillingModelTypeCard";
import {
  BamSortBy,
  BamSortOrder,
} from "sections/billing-model-common/BillingModelTypes";
import { QUERIES } from "sections/billing-model-rateplans/BillingModelRatePlanQueries";

import {
  BillingModelRatePlanTypes,
  BillingModelRatePlanTypesVariables,
} from "../__generated__/BillingModelRatePlanTypes";
import { BamRatePlanTypesQuery } from "../../../../__generated__/globalTypes";
import { useRatePlanDefaultValues } from "../hooks/useRatePlanDefaultValues";

const MaintainRatePlanTypes = (): JSX.Element => {
  const defaultValues = useRatePlanDefaultValues();
  const history = useHistory();
  const sortOptions = ["Newest", "Recent Changes", "Name"];
  const [ratePlanTypeQuery, setRatePlanTypeQuery] = useState<
    BamRatePlanTypesQuery
  >({
    search: "",
    sortBy: BamSortBy.name,
    sortOrder: BamSortOrder.ascending,
    pageSize: 10,
    page: 1,
  });

  const { loading, data, refetch } = useQuery<
    BillingModelRatePlanTypes,
    BillingModelRatePlanTypesVariables
  >(QUERIES.getRatePlanTypes, {
    fetchPolicy: "network-only",
    variables: { input: ratePlanTypeQuery },
  });

  const handlePageChange = (page: number) => {
    setRatePlanTypeQuery({ ...ratePlanTypeQuery, page });
  };

  const getSortBy = (sort: string | undefined) => {
    if (sort === "Name") return BamSortBy.name;
    if (sort === "Newest") return BamSortBy.createdAt;
    if (sort === "Recent Changes") return BamSortBy.modifiedAt;
    return undefined;
  };

  const getSortOption = (sort: BamSortBy | null | undefined) => {
    if (sort === "name") return "Name";
    if (sort === "createdAt") return "Newest";
    if (sort === "modifiedAt") return "Recent Changes";
    return "Name";
  };

  const handleSearchChange = useCallback((search: BillingModelSearch) => {
    const sortBy = getSortBy(search.sort);
    setRatePlanTypeQuery((q) => ({
      ...q,
      search: search.terms,
      sortBy,
      sortOrder:
        sortBy === BamSortBy.name
          ? BamSortOrder.ascending
          : BamSortOrder.descending,
      page: 1,
    }));
  }, []);

  useEffect(() => {
    refetch();
  }, [ratePlanTypeQuery, refetch]);

  return (
    <BillingModelScreen
      backUrl="/billing-model"
      title="Rate Plan Types"
      subtitle="Search for Rate Plan Type by name or ID."
    >
      <BillingModelCardList
        sort={getSortOption(ratePlanTypeQuery.sortBy)}
        sortOptions={sortOptions}
        onSearch={handleSearchChange}
      >
        <PaginatedList
          loading={loading}
          page={ratePlanTypeQuery.page ?? 1}
          totalPages={data?.bamRatePlanTypes?.pagination.totalPages}
          onPageChange={handlePageChange}
          isEmptyMessage="No Rate Plan Types Found"
        >
          {data &&
            data.bamRatePlanTypes &&
            data.bamRatePlanTypes.data &&
            data.bamRatePlanTypes.data.length > 0 &&
            data.bamRatePlanTypes.data.map((rpt) => (
              <BillingModelTypeCard
                key={rpt.ratePlanTypeId}
                typeId={rpt.ratePlanTypeId}
                typeName={rpt.name ?? ""}
                createdAt={rpt.createdAt}
                modifiedAt={rpt.modifiedAt}
                cloneable={defaultValues.canWriteRatePlanTypes}
                onClone={() =>
                  history.push(
                    `/billing-model/rate-types/create/${rpt.ratePlanTypeId}`
                  )
                }
                onClick={() =>
                  history.push(
                    `/billing-model/rate-types/edit/${rpt.ratePlanTypeId}`
                  )
                }
              />
            ))}
        </PaginatedList>
      </BillingModelCardList>
      {defaultValues.canWriteRatePlanTypes && (
        <AppFab
          accessibilityLabel="Add Rate Plan Type"
          onClick={() => history.push(`/billing-model/rate-types/create`)}
        >
          <Icon>add</Icon>
        </AppFab>
      )}
    </BillingModelScreen>
  );
};

export default MaintainRatePlanTypes;
