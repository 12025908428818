import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import BillingModelCardList from "sections/billing-model-common/components/BillingModelCardList";
import BillingModelTypeCard from "sections/billing-model-common/components/BillingModelTypeCard";
import PaginatedList from "sections/_global/components/PaginatedList";
import { QUERIES } from "sections/billing-model-rateplans/BillingModelRatePlanQueries";
import AppFab from "sections/_global/components/AppFab";
import {
  BillingModelRatePlansList,
  BillingModelRatePlansListVariables,
} from "../__generated__/BillingModelRatePlansList";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import { BillingModelSearch } from "sections/billing-model-common/BillingModelSearch";
import {
  BamSortBy,
  BamSortOrder,
} from "sections/billing-model-common/BillingModelTypes";
import { Icon } from "@mui/material";
import { BamRatePlansQuery } from "../../../../__generated__/globalTypes";
import { useRatePlanDefaultValues } from "../hooks/useRatePlanDefaultValues";

const MaintainRatePlans: React.FC = () => {
  const defaultValues = useRatePlanDefaultValues();
  const history = useHistory();
  const sortOptions = ["Newest", "Recent Changes", "Name"];
  const [ratePlanQuery, setRatePlanQuery] = useState<BamRatePlansQuery>({
    search: "",
    sortBy: BamSortBy.name,
    pageSize: 10,
    page: 1,
  });

  const { loading, data, refetch } = useQuery<
    BillingModelRatePlansList,
    BillingModelRatePlansListVariables
  >(QUERIES.getRatePlansList, {
    variables: { input: ratePlanQuery },
    fetchPolicy: "network-only",
  });

  const handlePageChange = (page: number) => {
    setRatePlanQuery({ ...ratePlanQuery, page });
  };

  const getSortBy = (sort: string | undefined) => {
    if (sort === "Name") return BamSortBy.name;
    if (sort === "Newest") return BamSortBy.createdAt;
    if (sort === "Recent Changes") return BamSortBy.modifiedAt;
    return undefined;
  };

  const getSortOption = (sort: BamSortBy | null | undefined) => {
    if (sort === "name") return "Name";
    if (sort === "createdAt") return "Newest";
    if (sort === "modifiedAt") return "Recent Changes";
    return "Name";
  };

  const handleSearchChange = useCallback((search: BillingModelSearch) => {
    const sortBy = getSortBy(search.sort);
    setRatePlanQuery((rq) => ({
      ...rq,
      search: search.terms,
      sortBy,
      sortOrder:
        sortBy === BamSortBy.name
          ? BamSortOrder.ascending
          : BamSortOrder.descending,
      page: 1,
    }));
  }, []);

  useEffect(() => {
    refetch();
  }, [ratePlanQuery, refetch]);

  return (
    <BillingModelScreen
      backUrl="/billing-model"
      title="Rate Plans"
      subtitle="Search for Rate Plans by name or ID."
    >
      <BillingModelCardList
        sort={getSortOption(ratePlanQuery.sortBy)}
        sortOptions={sortOptions}
        onSearch={handleSearchChange}
      >
        <PaginatedList
          loading={loading}
          page={ratePlanQuery.page ?? 1}
          totalPages={data?.bamRatePlans.pagination.totalPages}
          onPageChange={handlePageChange}
          isEmptyMessage="No Rate Plans Found"
        >
          {data &&
            data.bamRatePlans &&
            data.bamRatePlans.data &&
            data.bamRatePlans.data.length > 0 &&
            data.bamRatePlans.data.map((rp) => (
              <BillingModelTypeCard
                key={rp.ratePlanId}
                typeId={rp.ratePlanId}
                typeName={rp.name ?? undefined}
                startDate={rp.startDate}
                endDate={rp.endDate}
                createdAt={rp.createdAt}
                modifiedAt={rp.modifiedAt}
                cloneable={defaultValues.canWriteRatePlans}
                onClick={() =>
                  history.push(`/billing-model/rates/edit/${rp.ratePlanId}`)
                }
                onClone={() =>
                  history.push(`/billing-model/rates/create/${rp.ratePlanId}`)
                }
              />
            ))}
        </PaginatedList>
      </BillingModelCardList>

      {defaultValues.canWriteRatePlans && (
        <AppFab
          accessibilityLabel="Add Rate Plan"
          onClick={() => history.push(`/billing-model/rates/create`)}
        >
          <Icon>add</Icon>
        </AppFab>
      )}
    </BillingModelScreen>
  );
};

export default MaintainRatePlans;
