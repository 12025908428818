import React, { FC } from "react";
import { Icon, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import componentStyles from "./styles";

type Props = {
  contactType: number;
  contactValue: string;
};

export const getContactIcon = (type: number | undefined): JSX.Element => {
  switch (type) {
    case 1:
      return <Icon>email</Icon>;
    case 2:
      return <Icon>phone</Icon>;
    default:
      return <Icon>help</Icon>;
  }
};

const ContactField: FC<Props> = ({ contactType, contactValue }: Props) => {
  const icon = getContactIcon(contactType);

  return (
    <Box sx={componentStyles.contactFieldContainer}>
      <TextField
        variant="filled"
        label="Contact Value"
        InputProps={{
          endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        }}
        value={contactValue}
        sx={componentStyles.textField}
        helperText="Specify preferences for contact value"
        disabled
      />
    </Box>
  );
};

export default ContactField;
