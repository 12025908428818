import React, { useState } from "react";
import BillingModelQueryTypeahead from "sections/billing-model-common/components/BillingModelQueryTypeahead";
import { BillingModelRatePlanTypes_bamRatePlanTypes_data } from "../../__generated__/BillingModelRatePlanTypes";
import { TypeaheadOption } from "sections/_global/components/Typeahead";
import { RatePlanTypeSelectData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeSelectQuery";
import { RatePlanTypeData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeDetailsQuery";
import { RatePlanType } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";

export interface Props {
  label: string;
  value: RatePlanType | null;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  debounceMilliseconds?: number;
  useRatePlanTypeDetailsQuery: (id?: string | null) => RatePlanTypeData;
  useRatePlanTypeSelectQuery: (search: string) => RatePlanTypeSelectData;
  onFocus?: () => void;
  onChange?: (rpt: RatePlanType | null) => void;
}

function isRatePlanTypeSelect(
  obj: unknown
): obj is BillingModelRatePlanTypes_bamRatePlanTypes_data {
  const val = obj as any;
  return val && "ratePlanTypeId" in val && "name" in val;
}

function isTypeaheadOption(obj: unknown): obj is TypeaheadOption {
  const val = obj as any;
  return val && "label" in val && "value" in val;
}

const RatePlanTypeSelect: React.FC<Props> = ({
  label,
  value,
  isDisabled,
  hasError,
  helperText,
  debounceMilliseconds = 300,
  useRatePlanTypeDetailsQuery,
  useRatePlanTypeSelectQuery,
  onFocus,
  onChange,
}: Props) => {
  const { getRatePlanType } = useRatePlanTypeDetailsQuery();

  const {
    getRatePlanTypes,
    loading: isLoadingRatePlanTypes,
    ratePlanTypesResponse,
  } = useRatePlanTypeSelectQuery(value?.ratePlanTypeId?.toString() ?? "");

  const getOption = (
    rpt: RatePlanType | BillingModelRatePlanTypes_bamRatePlanTypes_data
  ): TypeaheadOption => ({
    label: rpt.name ?? "",
    value: rpt,
  });

  const [selected, setSelected] = useState<TypeaheadOption | string | null>(
    value ? getOption(value) : null
  );

  const handleChange = async (val: string | TypeaheadOption | null) => {
    if (isTypeaheadOption(val)) {
      const rpt = isRatePlanTypeSelect(val.value) ? val.value : null;
      const details = rpt ? await getRatePlanType(rpt.ratePlanTypeId) : null;
      setSelected({
        label: val.label,
        value:
          details && details.bamRatePlanType
            ? {
                ratePlanTypeId: details.bamRatePlanType?.ratePlanTypeId,
                name: details.bamRatePlanType?.name,
              }
            : val.value,
      });

      if (onChange) {
        onChange(
          details && details.bamRatePlanType
            ? {
                ...details.bamRatePlanType,
                ratingControlGroupTypes:
                  details.bamRatePlanType?.ratingControlGroupTypes ?? [],
              }
            : null
        );
      }
    } else {
      setSelected(null);
      if (onChange) onChange(null);
    }
  };

  return (
    <BillingModelQueryTypeahead
      label={label}
      idPropertyName="ratePlanTypeId"
      isLoading={isLoadingRatePlanTypes}
      hasError={hasError}
      isDisabled={isDisabled}
      helperText={helperText}
      value={selected}
      options={
        ratePlanTypesResponse?.bamRatePlanTypes?.data?.map((rpt) =>
          getOption(rpt)
        ) ?? []
      }
      debounceMilliseconds={debounceMilliseconds}
      onSearch={getRatePlanTypes}
      onFocus={onFocus}
      onChange={handleChange}
    />
  );
};

export default RatePlanTypeSelect;
