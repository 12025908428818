import { colors } from "styles/colors";
import { createStyles } from "@mui/styles";

export default createStyles({
  root: {
    marginTop: "2rem",
  },
  form: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  error: {
    color: colors.errorRed,
  },
  h4: {
    margin: 0,
    color: colors.brandGreen,
  },
  fields: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "0.2rem",
    background: colors.white,
    padding: "1rem !important",
    boxShadow: "0 1px 3px -1px rgba(0, 0, 0, 0.7)",
    alignItems: "center",
  },
  fieldDescription: {
    fontSize: "0.8rem",
  },
});
