import React, { FC, useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Grid, Typography, Button } from "@mui/material";

import styles from "./styles";
const useStyles = makeStyles(styles);

export type Props = {
  getSerial: (serialNumber: string) => void;
  loading: boolean;
  error?: { message: string };
};

const SerialNumberForm: FC<Props> = ({ getSerial, error, loading }: Props) => {
  const classes = useStyles();
  const [serialNumber, setSerialNumber] = useState<string | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSerialNumber(e.target.value);
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!serialNumber) return;
    getSerial(serialNumber);
  };

  const field = {
    description: "Scan in serial numbers",
    name: "serialNumber",
    label: "Serial Number",
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.h4} variant="h4">
            Find Equipment By Serial Number
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.form} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className={classes.fields}>
            <Typography className={classes.fieldDescription}>
              {field.description}
            </Typography>
            <TextField
              id={field.name}
              label={field.label}
              placeholder={field.label}
              value={serialNumber || ""}
              onChange={onChange}
              fullWidth
              error={!!error}
              helperText={error?.message}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button color="secondary" fullWidth onClick={onSubmit}>
            {loading ? "Loading . . ." : "Find"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SerialNumberForm;
