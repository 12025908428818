import React from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import styles from "./styles";

type Props = {
  classes: {
    root: string;
    button: string;
  };
  label: React.ReactNode;
  isSelected: boolean;
  onSelect: (args: any) => void;
};

const SelectableItem: React.FC<Props> = ({
  classes,
  label,
  isSelected,
  onSelect,
}: Props) => {
  return (
    <div className={classes.root}>
      <Typography>{label}</Typography>
      <Button
        aria-label={`${isSelected ? "Remove" : "Add"} ${label}`}
        color={isSelected ? "error" : "primary"}
        onClick={onSelect}
        variant="text"
      >
        {isSelected ? "Remove" : "Add"}
      </Button>
    </div>
  );
};

export default withStyles(styles)(SelectableItem);
