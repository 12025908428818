import React, { FC } from "react";
import { Box } from "@mui/system";
import { useContactDispatch } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import MultipleSelect from "sections/_global/components/MultipleSelect";
import { mockOptions } from "../../components/SubPreferencesTable/mockData";
import componentStyles from "./styles";

type Props = {
  chosenChannels: string[];
  contactKey: string;
};

const ChannelSelector: FC<Props> = ({ chosenChannels, contactKey }: Props) => {
  const contactDispatch = useContactDispatch();

  /**
   * This method is triggered whenever we are editing the Channels MultipleSelect component
   * @param newChannels The channels that are currently showing the channels component
   * @returns The contact we are currently editing's new state
   */
  const updateContactsWithChannels = (newChannels: string[]) =>
    contactDispatch({
      type: "UPDATE_CONTACT",
      key: contactKey,
      update: { type: "CHANNELS", channels: newChannels },
    });

  return (
    <Box sx={componentStyles.channelSelectorContainer}>
      <MultipleSelect
        inputLabel="Channels"
        setSelected={updateContactsWithChannels}
        defaultValues={chosenChannels}
        fullWidth={true}
        options={mockOptions}
        helperText="Select channels subscriptions for the contact value"
        emphasizedText="Leave this field blank to subscribe to all communication channels"
        id="channel-selector"
        labelId="channel-selector"
      />
    </Box>
  );
};

export default ChannelSelector;
