import React, { ReactNode } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

import styles from "./styles";
import { ExpandMore } from "@mui/icons-material";
import { colors } from "styles/colors";

type Props = {
  title: string;
  expanded?: boolean;
  defaultExpanded?: boolean;
  disabled?: boolean;
  children: ReactNode;
  hideExpandIcon?: boolean;
};

const AppAccordion: React.FC<Props> = ({
  title,
  expanded,
  defaultExpanded,
  disabled,
  children,
  hideExpandIcon,
}: Props) => {
  return (
    <Accordion
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      disabled={disabled}
    >
      <AccordionSummary
        style={{ backgroundColor: colors.gray12 }}
        expandIcon={!hideExpandIcon && <ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(AppAccordion);
