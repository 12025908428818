// Brand Colors
export const colors = {
  brandBlue: "#246ba1",
  brandBlueLight: "#539fd8",
  brandBlueDark: "#003767",
  brandGreen: "#7ac142",
  brandGreenDark: "#659d2d",

  // Additional Colors
  alertYellow: "#f9edbe",
  warningOrange: "#d66944",
  errorRed: "#ac3931",
  darkUIGray1: "#191919",
  darkUIGray2: "#2d2d2d",
  borderGray: "#e4e4e4",

  // Base Colors
  black: "#000",
  white: "#fff",
  gray1: "#111",
  gray2: "#222",
  gray3: "#333",
  gray4: "#484848",
  gray5: "#555",
  gray6: "#767676",
  gray7: "#777",
  gray8: "#888",
  gray9: "#929292",
  gray10: "#aaa",
  gray11: "#d1d1d1",
  gray12: "#f0f0f0",
};

export const luminance = (hex: string, lum: number): string => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = "#",
    c: number | string,
    i: number;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
};

export const saturate = (hex: string, saturation: number): string => {
  if (!/^#([0-9a-f]{6})$/i.test(hex)) {
    throw "Unexpected color format";
  }

  if (saturation < 0 || saturation > 1) {
    throw "Unexpected color format";
  }

  const rgbIntensityFloat = [
    parseInt(hex.substr(1, 2), 16) / 255,
    parseInt(hex.substr(3, 2), 16) / 255,
    parseInt(hex.substr(5, 2), 16) / 255,
  ];

  const rgbIntensityFloatSorted = rgbIntensityFloat
      .slice(0)
      .sort(function (a, b) {
        return a - b;
      }),
    maxIntensityFloat = rgbIntensityFloatSorted[2],
    mediumIntensityFloat = rgbIntensityFloatSorted[1],
    minIntensityFloat = rgbIntensityFloatSorted[0];

  if (maxIntensityFloat === minIntensityFloat) {
    // All colors have same intensity, which means
    // the original color is gray, so we can't change saturation.
    return hex;
  }

  // New color max intensity wont change. Lets find medium and weak intensities.
  let newMediumIntensityFloat;
  const newMinIntensityFloat = maxIntensityFloat * (1 - saturation);

  if (mediumIntensityFloat === minIntensityFloat) {
    // Weak colors have equal intensity.
    newMediumIntensityFloat = newMinIntensityFloat;
  } else {
    // Calculate medium intensity with respect to original intensity proportion.
    const intensityProportion =
      (maxIntensityFloat - mediumIntensityFloat) /
      (mediumIntensityFloat - minIntensityFloat);
    newMediumIntensityFloat =
      (intensityProportion * newMinIntensityFloat + maxIntensityFloat) /
      (intensityProportion + 1);
  }

  const newRgbIntensityFloat: number[] = [],
    newRgbIntensityFloatSorted = [
      newMinIntensityFloat,
      newMediumIntensityFloat,
      maxIntensityFloat,
    ];

  // We've found new intensities, but we have then sorted from min to max.
  // Now we have to restore original order.
  rgbIntensityFloat.forEach(function (originalRgb) {
    const rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
    newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
  });

  const floatToHex = (val: number) =>
    ("0" + Math.round(val * 255).toString(16)).substr(-2);
  const rgb2hex = (rgb: number[]) =>
    "#" + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]);

  const newHex = rgb2hex(newRgbIntensityFloat);

  return newHex;
};

export function colorIsDark(hex: string): boolean {
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const toDecimal = (hex: string) => parseInt(hex, 16);

  // This formula is based off of the simple version of the formula to calculate relative
  // luminance.
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_Colors_and_Luminance#calculating_relative_luminance
  const brightness =
    0.2126 * Math.pow(toDecimal(`${hex[0]}${hex[1]}`) / 255, 2.2) +
    0.7152 * Math.pow(toDecimal(`${hex[2]}${hex[3]}`) / 255, 2.2) +
    0.0722 * Math.pow(toDecimal(`${hex[4]}${hex[5]}`) / 255, 2.2);

  // A relative luminance of 50% and above denotes that a color is bright rather than dark.
  if (brightness >= 0.5) {
    return false;
  }

  return true;
}
