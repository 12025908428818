import React, { FC } from "react";
import { useContactDispatch } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import { Contact } from "sections/communication-preference/contacts/types";
import CheckBoxTable, {
  Props as CheckBoxTableProps,
} from "../CheckBoxTable/CheckBoxTable";

type Props = {
  contact: Contact;
};

const SubPreferencesTable: FC<Props> = ({ contact }: Props) => {
  const contactDispatch = useContactDispatch();

  const reformatData = (): Pick<CheckBoxTableProps, "data"> => {
    const reformattedData: Pick<CheckBoxTableProps, "data"> = { data: [] };
    const { data } = reformattedData;
    contact.subPreferences.forEach((subPreference) => {
      const { channelDescription, channelName, channelOptions } = subPreference;

      const options = channelOptions.map((channelOption) => {
        return {
          icon: channelOption.optionIcon,
          optionName: channelOption.optionName,
          optionChecked: channelOption.checked,
          subOptions:
            channelOption.subOptions &&
            channelOption.subOptions.map((subOption) => ({
              subOptionName: subOption.subOptionName,
              subOptionChecked: subOption.checked,
              subOptionIcon: subOption.subOptionIcon,
            })),
        };
      });

      data.push({
        columnTitle: channelName,
        columnDescription: channelDescription,
        options,
      });
    });
    return reformattedData;
  };

  const setOuterCheckBox = (columnIndex: number, rowIndex: number) =>
    contactDispatch({
      type: "UPDATE_CONTACT",
      key: contact.key,
      update: {
        type: "SUBPREFERENCES",
        action: { type: "SET_OUTER_CHECKBOX", columnIndex, rowIndex },
      },
    });

  const setInnerCheckBox = (
    columnIndex: number,
    rowIndex: number,
    nestedIndex: number
  ) =>
    contactDispatch({
      type: "UPDATE_CONTACT",
      key: contact.key,
      update: {
        type: "SUBPREFERENCES",
        action: {
          type: "SET_INNER_CHECKBOX",
          columnIndex,
          rowIndex,
          nestedIndex,
        },
      },
    });

  const data = reformatData();

  return (
    <CheckBoxTable
      data={data.data}
      setInnerCheckBox={setInnerCheckBox}
      setOuterCheckBox={setOuterCheckBox}
    />
  );
};

export default SubPreferencesTable;
