import { colors } from "styles/colors";

export default {
  cardHeader: {
    padding: { xs: 0, sm: "2rem 3em 1.5rem 3rem" },
    margin: { xs: "1rem", sm: 0 },
  },
  cardHeaderTitle: { marginBottom: "1rem" },
  progressBar: {
    color: colors.brandGreen,
    height: "0.4rem",
  },
} as const;
