import React, { FC } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import styles from "./styles";

const useStyles = makeStyles(styles);

export type Props = {
  formName: string;
  icon?: string;
};

const IMAppHeader: FC<Props> = ({ formName, icon }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.mainTitle}>
      <i className={classnames("material-icons", classes.icon)}>{icon}</i>
      <Typography>{formName}</Typography>
    </div>
  );
};

export default IMAppHeader;
