import React, { FC } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchBox from "sections/_global/components/SearchBox";
import { TabHolder } from "sections/_global/components/TabHolder";
import MaterialTable from "sections/_global/components/MaterialTable";
import MiniTable from "sections/_global/components/MiniTable";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import PageHeader from "sections/_global/components/PageHeader";
import { getProvisioningServices } from "./__generated__/getProvisioningServices";
import useStyles from "./styles";

export type ServiceAttributes = string[][];

type ProvisioningData = {
  ont: ServiceAttributes;
  voice: ServiceAttributes;
  data: ServiceAttributes;
  box: ServiceAttributes;
  video: ServiceAttributes;
};

const GET_PROVISIONING_DETAILS = gql`
  query getProvisioningServices($subscriberId: ID!) {
    provisioningStatus(subscriberId: $subscriberId) {
      serviceType
      serviceAttributes {
        expected
        actual
        heading
      }
    }
  }
`;

/**
 * Function which goes through the json data returned from a search in the provisioning details search bar, and formats it to be displayed on the page.
 * @param data the data from returned from the database
 * @returns a formatted object containing the fields ont, voice, data, box, and video with respective attributes corresponding to the fields
 */
const partitionData = (data: getProvisioningServices): ProvisioningData => {
  const { provisioningStatus } = data;
  if (provisioningStatus === null)
    return { ont: [[]], voice: [[]], data: [[]], box: [[]], video: [[]] };
  return provisioningStatus.reduce((acc, service) => {
    if (!service?.serviceType) return acc;
    return {
      ...acc,
      [service.serviceType]: service.serviceAttributes.map((attribute) => {
        if (attribute === null) return [];
        return [attribute.heading, attribute.expected, attribute.actual];
      }),
    };
  }, {} as ProvisioningData);
};

const ProvisioningDetails: FC = () => {
  const [getServices, { loading, data }] = useLazyQuery<
    getProvisioningServices
  >(GET_PROVISIONING_DETAILS);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const headers = ["System", "Expected", "Actual"];
  const queryData = data ? partitionData(data) : data;

  return (
    <div className={classes.root}>
      <LoadingBackdrop isOpen={loading} />
      <PageHeader url="/provisioning-tools" title="Provisioning Details" />

      <SearchBox
        label="Subscriber ID"
        useFullWidth
        onSearch={(search) =>
          getServices({ variables: { subscriberId: search } })
        }
      />
      {data && (
        <TabHolder
          variant="blue"
          size="fullWidth"
          tabs={[
            {
              tabName: "ont",
              display: smallScreen ? (
                <MiniTable
                  ariaLabel="ont-mini-table"
                  headers={headers}
                  data={queryData?.ont ?? [[]]}
                />
              ) : (
                <MaterialTable
                  ariaLabel="ont-table"
                  headers={headers}
                  data={queryData?.ont ?? [[]]}
                />
              ),
            },
            {
              tabName: "voice",
              display: smallScreen ? (
                <MiniTable
                  ariaLabel="voice-mini-table"
                  headers={headers}
                  data={queryData?.voice ?? [[]]}
                />
              ) : (
                <MaterialTable
                  ariaLabel="voice-table"
                  headers={headers}
                  data={queryData?.voice ?? [[]]}
                />
              ),
            },
            {
              tabName: "data",
              display: smallScreen ? (
                <MiniTable
                  ariaLabel="data-mini-table"
                  headers={headers}
                  data={queryData?.data ?? [[]]}
                />
              ) : (
                <MaterialTable
                  ariaLabel="data-table"
                  headers={headers}
                  data={queryData?.data ?? [[]]}
                />
              ),
            },
            {
              tabName: "box",
              display: smallScreen ? (
                <MiniTable
                  ariaLabel="box-mini-table"
                  headers={headers}
                  data={queryData?.box ?? [[]]}
                />
              ) : (
                <MaterialTable
                  ariaLabel="box-table"
                  headers={headers}
                  data={queryData?.box ?? [[]]}
                />
              ),
            },
            {
              tabName: "video",
              display: smallScreen ? (
                <MiniTable
                  ariaLabel="video-mini-table"
                  headers={headers}
                  data={queryData?.video ?? [[]]}
                />
              ) : (
                <MaterialTable
                  ariaLabel="video-table"
                  headers={headers}
                  data={queryData?.video ?? [[]]}
                />
              ),
            },
          ].filter(
            (tab) => queryData && Object.keys(queryData).includes(tab.tabName)
          )}
        />
      )}
    </div>
  );
};

export default ProvisioningDetails;
