import React, { FC } from "react";
import { Props } from "../MaterialTable/MaterialTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import InvalidTable, {
  checkData,
} from "../MaterialTable/InvalidTable/InvalidTable";
import useStyles from "./styles";
import { Grid } from "@mui/material";

const MiniTable: FC<Props> = ({ data, headers, ariaLabel }: Props) => {
  const classes = useStyles();
  let smallScreenTable: any[] = [];
  const invalidTable = checkData(data);

  invalidTable
    ? smallScreenTable.push(
        <InvalidTable message={"No data to be displayed"} />
      )
    : (smallScreenTable = data.map((outer, index) => (
        <Table aria-label={ariaLabel} key={index}>
          <TableBody className={classes.smallScreenContainer}>
            {headers.map((header, index) => (
              <TableCell
                key={`${header}:${outer[index]}`}
                sx={{
                  display: "flex",
                  padding: "0.6em",
                  alignItems: "center",
                  border: "none",
                }}
              >
                <Grid justifyContent="space-around" container>
                  <Grid
                    item
                    xs={4}
                    className={classes.headerText}
                    role="columnheader"
                  >
                    {header}
                  </Grid>
                  <Grid item xs={6} className={classes.regularText}>
                    {outer[index]}
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableBody>
        </Table>
      )));

  return <TableContainer component={Paper}>{smallScreenTable} </TableContainer>;
};

export default MiniTable;
