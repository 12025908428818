import React, { FC, useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import theme from "styles/theme";
import ContactContainer from "../components/ContactContainer/ContactContainer";
import ContactHeader from "../components/ContactHeader/ContactHeader";
import SummaryForm from "./screens/SummaryForm/SummaryForm";
import ContactForm from "./screens/ContactForm/ContactForm";
import PreferencesForm from "./screens/PreferencesForm/PreferencesForm";
import PageHeader from "sections/_global/components/PageHeader";
import { useContact } from "../contexts/ContactContext/ContactContext";

const AddContact: FC = () => {
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = useState(1);
  const [maxSteps, setMaxSteps] = useState(-1);
  const [{ contacts }, contactDispatch] = useContact();

  /**
   * Get our current page based on the step we are on
   * @param step the step we are currently on
   * @returns Rendered JSX
   */
  const getCurrentPage = (step: number): JSX.Element => {
    if (step === 1) {
      return (
        <ContactForm
          screenIsSmall={screenIsSmall}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setMaxSteps={setMaxSteps}
        />
      );
    }

    // Render the final page once we have run through all the contacts
    if (step === contacts.length + 2) {
      return (
        <SummaryForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          screenIsSmall={screenIsSmall}
        />
      );
    }

    // For each contact, we render a separate page with different information depending on the contact type and value
    const contactSteps = contacts.map((contact) => (
      <PreferencesForm
        key={contact.key}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        contact={contact}
        screenIsSmall={screenIsSmall}
      />
    ));
    return contactSteps[step - 2];
  };

  // This useEffect is used to remove the existing contacts when we navigate out of the addContacts page
  useEffect(() => {
    contactDispatch({
      type: "SET_CONTACTS",
      contacts: [
        {
          key: "1",
          value: "",
          type: 1,
          subPreferences: [],
          channelNames: [],
          isBeingEdited: false,
        },
      ],
    });
  }, [contactDispatch]);

  const currentPage = getCurrentPage(currentStep);

  return (
    <>
      <PageHeader
        url="/communication-preference/contacts"
        title="Communication Preferences > Contacts > Add"
        screenIsSmall={screenIsSmall}
      />
      <ContactContainer>
        <ContactHeader
          headerText="Add"
          maxSteps={maxSteps}
          currentStep={currentStep}
        />
        {currentPage}
      </ContactContainer>
    </>
  );
};

export default AddContact;
