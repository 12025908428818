import { createStyles } from "@mui/styles";

export default createStyles({
  dot: {
    backgroundColor: "currentColor",
    borderRadius: "0.4rem",
    display: "inline-block",
    height: "0.4rem",
    verticalAlign: "middle",
    width: "0.4rem",
  },
});
