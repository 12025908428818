import React from "react";
import { useParams } from "react-router-dom";
import {
  GetLightLevel,
  GetLightLevelVariables,
} from "../__generated__/GetLightLevel";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "../LightLevelsQueries";
import SearchBox from "sections/_global/components/SearchBox";
import LightLevelsCard from "../components/LightLevelsCard/LightLevelsCard";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import PageHeader from "sections/_global/components/PageHeader";
import useStyles from "./styles";

const LightLevelsLookup: React.FC = () => {
  const classes = useStyles();
  const { subId } = useParams<GetLightLevelVariables>();
  const [getLightLevel, { data, loading }] = useLazyQuery<
    GetLightLevel,
    GetLightLevelVariables
  >(QUERIES.getLightLevel, {
    variables: { subId },
  });

  return (
    <div className={classes.root}>
      <PageHeader url="/provisioning-tools" title={"Light Levels Lookup"} />
      <SearchBox
        searchOn="click"
        useFullWidth={true}
        label="Subscriber ID"
        onSearch={(subId) => {
          getLightLevel({ variables: { subId: subId } });
        }}
      />
      <LoadingBackdrop isOpen={loading} />
      {!loading && <LightLevelsCard data={data} />}
    </div>
  );
};
export default LightLevelsLookup;
