import React, { FC } from "react";
import { Button, Icon } from "@mui/material";

export type Props = {
  title?: string;
  onClick: () => void;
  className?: string;
};

const CancelButton: FC<Props> = ({ onClick, className, title }: Props) => {
  return (
    <Button
      color="error"
      sx={{ marginBottom: "1rem" as const }}
      onClick={onClick}
      startIcon={<Icon>highlight_off</Icon>}
      className={className}
    >
      {title || "Cancel"}
    </Button>
  );
};

export default CancelButton;
