import React, { FC, ReactElement } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InvalidTable, { checkData } from "./InvalidTable/InvalidTable";

export type Props = {
  /**
   * The data to be displayed in the table
   */
  data: any[][];
  /**
   * The name of each column header
   */
  headers: string[];

  /**
   * A prop specifying the aria-label for this component, useful for tests
   */
  ariaLabel: string;
};

const MaterialTable: FC<Props> = ({ data, headers, ariaLabel }: Props) => {
  let table: ReactElement[] = [];

  const invalidTable = checkData(data);

  invalidTable
    ? table.push(
        <InvalidTable key={"invalid"} message={"No data to be displayed"} />
      )
    : (table = data.map((outer, index) => (
        <TableRow key={`${outer}${index}`}>
          {outer.map((inner, index) => (
            <TableCell sx={{ textAlign: "center" }} key={`${inner}${index}`}>
              {inner}
            </TableCell>
          ))}
        </TableRow>
      )));

  return (
    <TableContainer
      sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
      component={Paper}
    >
      <Table aria-label={ariaLabel}>
        <TableHead>
          <TableRow>
            {!invalidTable &&
              headers.map((header) => (
                <TableCell
                  sx={{ textAlign: "center" }}
                  key={header}
                  role="columnheader"
                >
                  {header}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>{table}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default MaterialTable;
