import { Box, Icon, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingIndicator from "sections/_global/components/LoadingIndicator";
import { colors } from "styles/colors";

export interface Props {
  id?: string;
  label: string;
  value: string | null;
  useFullWidth?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  /**
   If true, a spinner is shown as a starting adornment on the textbox.
   */
  isLoading?: boolean;
  /**
   If true, a red X is shown as a starting adornment. 
   If false a green check mark is displayed unless a `startAdornment` value is supplied.
   If left undefined, no adornments display.
   */
  exists?: boolean;
  /**
   An adornment to display at the start of the text box.
   */
  startAdornment?: React.ReactNode;
  /**
   The number of milliseconds to wait before triggering the `onSearch` event.
   */
  debounceMilliseconds?: number;
  onChange?: (value: string) => void;
  onSearch?: (search: string) => void;
}

/**
 Component for displaying results of name checks with debounced change events.
 */
const BillingModelQueryNameField = ({
  id,
  isLoading,
  label,
  value,
  exists,
  useFullWidth,
  isDisabled,
  hasError,
  helperText,
  startAdornment,
  debounceMilliseconds = 1000,
  onChange,
  onSearch,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>(value ?? "");

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (onSearch && search.length > 0) onSearch(search.trim());
    }, debounceMilliseconds);

    return () => clearTimeout(delayDebounce);
  }, [debounceMilliseconds, onSearch, search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  const renderAdornment = (): JSX.Element => {
    if (isLoading) {
      return (
        <Box data-testid="loading" sx={{ width: "42px", textAlign: "center" }}>
          <LoadingIndicator />
        </Box>
      );
    }

    return (
      <>
        {!exists && value !== "" && (
          <Box sx={{ width: "42px", textAlign: "center" }}>
            <Icon data-testid="success" sx={{ color: colors.brandGreen }}>
              check
            </Icon>
          </Box>
        )}
        {exists && (
          <Box sx={{ width: "42px", textAlign: "center" }}>
            <Icon data-testid="error" sx={{ color: colors.errorRed }}>
              error
            </Icon>
          </Box>
        )}
      </>
    );
  };

  return (
    <TextField
      id={id}
      sx={{ "& input": { paddingLeft: "0.4em" } }}
      label={label}
      value={value ?? ""}
      fullWidth={useFullWidth}
      disabled={isDisabled}
      error={hasError}
      helperText={helperText}
      onChange={handleChange}
      inputProps={{ "aria-label": label }}
      InputProps={{
        startAdornment,
        endAdornment: renderAdornment(),
      }}
    />
  );
};

export default BillingModelQueryNameField;
