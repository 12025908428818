import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import StatusIndicator from "sections/billing-model-common/components/StatusIndicator";
import { RcdGroupType } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { BillingModelRatePlanType_bamRatePlanType_ratingControlGroupTypes_ratingControlElementTypes as RcdElementType } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlanType";
import { colors } from "styles/colors";

export interface Props {
  rcdGroupType: RcdGroupType;
  isDeleteAllowed?: boolean;
  onDeleteClick: (rgt: RcdGroupType) => void;
}

const RcdGroupTypeView = ({
  rcdGroupType,
  isDeleteAllowed = true,
  onDeleteClick,
}: Props): JSX.Element => {
  const inlineField = (elementType: RcdElementType) => {
    return (
      <Grid
        container
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: colors.gray11,
          borderRadius: "0.1em",
          "& .field_label": {
            borderRight: `1px solid ${colors.gray10}`,
            paddingLeft: "0.3em",
            paddingRight: "0.3em",
            backgroundColor: colors.gray12,
            color: colors.gray1,
            fontWeight: "bold",
            alignSelf: "center",
          },
          "& .field_value": {
            paddingLeft: "0.3em",
            paddingRight: "0.3em",
            alignSelf: "center",
          },
          backgroundColor: () =>
            !elementType.editable || elementType.hidden
              ? colors.gray12
              : undefined,
        }}
      >
        <Grid item className="field_label" xs={6}>
          <Typography variant="overline">
            {elementType.displayLabel ?? "Unknown Element"}
          </Typography>
        </Grid>
        <Grid item className="field_value" xs={6}>
          <Typography variant="body2">{elementType.defaultValue}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container sx={{ marginTop: "0.3em", marginBottom: "0.3em" }}>
      <Grid item xs={isDeleteAllowed ? 11 : 12}>
        <Accordion>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  {rcdGroupType.ratingControlGroupTypeId}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <StatusIndicator status={rcdGroupType.status} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{rcdGroupType.name}</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{ borderTop: `1px solid ${colors.gray12}`, paddingTop: "1rem" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Period Class - {rcdGroupType.periodClass}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Element Types</Typography>
              </Grid>
              {rcdGroupType.ratingControlElementTypes?.map((e, i) => (
                <Grid item key={i} xs={12} md={6}>
                  {inlineField(e)}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {isDeleteAllowed && (
        <Grid item>
          <Box padding="1rem">
            <IconButton
              data-testid={
                rcdGroupType.status === "deleted"
                  ? "restore button"
                  : "delete button"
              }
              onClick={() => onDeleteClick(rcdGroupType)}
            >
              <Icon>
                {rcdGroupType.status === "deleted" ? "undo" : "delete"}
              </Icon>
            </IconButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RcdGroupTypeView;
