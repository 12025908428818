import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { useMutation } from "@apollo/client";
import { isValid } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

import AppFullscreenDialog from "sections/_global/components/AppFullscreenDialog";
import OutageFormFields from "../OutageFormFields";
import {
  CreateOutageFormFields,
  UpdateOutageFormFields,
  Outage,
  ValidationError,
  NewAffectedArea,
} from "../../ServiceDisruptionTypes";
import { UpdateOutageInput } from "../../../../../__generated__/globalTypes";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import {
  UpdateOutage,
  UpdateOutageVariables,
} from "../../__generated__/UpdateOutage";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { MUTATIONS } from "../../ServiceDisruptionQueries";

import styles from "./styles";

const initialFormFields = {
  id: "",
  serviceType: "",
  startAt: null,
  endAt: null,
  affectedAreas: [],
  description: "",
  affectedResidentialCustomers: null,
  affectedBusinessCustomers: null,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  classes: {
    appbar: string;
    icon: string;
    iconButton: string;
    container: string;
  };
  outage: Outage;
};

const EditOutageDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  classes,
  outage,
}: Props) => {
  const dispatch = useMessageDispatch();
  const [formFields, setFormFields] = useState<UpdateOutageFormFields>(
    initialFormFields
  );
  const [updateOutage, { loading }] = useMutation<
    UpdateOutage,
    UpdateOutageVariables
  >(MUTATIONS.updateOutage);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
    []
  );

  const setValidationErrorsCallback = useCallback(
    (errors: ValidationError[]) => {
      setValidationErrors(errors);
    },
    [setValidationErrors]
  );

  const setFormFieldsCallback = useCallback(
    (fields: CreateOutageFormFields | UpdateOutageFormFields) => {
      setFormFields(fields as UpdateOutageFormFields);
    },
    [setFormFields]
  );

  useEffect(() => {
    setFormFields({
      id: outage.id,
      serviceType: outage.serviceType,
      startAt: utcToZonedTime(outage.startAt, "America/Chicago"),
      endAt: outage.endAt
        ? utcToZonedTime(outage.endAt, "America/Chicago")
        : null,
      affectedAreas: outage.affectedAreas.map((area) => ({
        city: area.city,
        state: area.state,
        lcp: area.lcp,
      })) as NewAffectedArea[],
      description: outage.description,
      affectedResidentialCustomers: outage.affectedResidentialCustomers,
      affectedBusinessCustomers: outage.affectedBusinessCustomers,
    });
  }, [outage, setFormFields]);

  const close = () => {
    onClose();
    setValidationErrors([]);
  };

  const save = () => {
    const newValidationErrors: ValidationError[] = [];
    if (!formFields) return false;
    if (!formFields.startAt) {
      newValidationErrors.push({
        field: "startAt",
        error: "Start date required.",
      });
    }
    if (formFields.startAt && !isValid(formFields.startAt)) {
      newValidationErrors.push({
        field: "startAt",
        error: "Invalid start date.",
      });
    }
    if (formFields.endAt && !isValid(formFields.endAt)) {
      newValidationErrors.push({
        field: "endAt",
        error: "Invalid end date.",
      });
    }
    if (!formFields.serviceType) {
      newValidationErrors.push({
        field: "serviceType",
        error: "Service type required.",
      });
    }

    setValidationErrors(newValidationErrors);

    if (newValidationErrors.length > 0) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "Please enter all required fields.",
          type: "error",
          isOpen: true,
          id: "edit-outage-required-fields",
        },
      });
      return null;
    }

    // The non-null assertion operator is used here because Typescript does not recognize that the
    // falsy check for startAt and serviceType above should cause their types to be non-nullable.
    const updatedOutage: UpdateOutageInput = {
      id: formFields.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      startAt: zonedTimeToUtc(formFields.startAt!, "America/Chicago"),
      endAt: formFields.endAt
        ? zonedTimeToUtc(formFields.endAt, "America/Chicago")
        : null,
      description: formFields.description || "",
      affectedAreas: formFields.affectedAreas as NewAffectedArea[],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      serviceType: formFields.serviceType!,
      status: outage.status,
      affectedResidentialCustomers: formFields.affectedResidentialCustomers,
      affectedBusinessCustomers: formFields.affectedBusinessCustomers,
    };

    updateOutage({
      variables: {
        outage: updatedOutage,
      },
    }).then((response) => {
      if (response.data?.updateOutage?.success) {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            description: "Outage saved.",
            type: "success",
            isOpen: true,
            id: "outage-saved",
          },
        });
        close();
      } else {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            description:
              response.data?.updateOutage?.message ||
              "Error processing request.",
            type: "error",
            isOpen: true,
            id: "error-processing-request",
          },
        });
      }
    });
  };

  const clearValidationError = (name: string) => {
    const newValidationErrors = JSON.parse(JSON.stringify(validationErrors));

    setValidationErrors(
      newValidationErrors.filter(
        (error: ValidationError) => error.field !== name
      )
    );
  };

  return (
    <div>
      <LoadingBackdrop isOpen={loading} />
      <AppFullscreenDialog
        title="Edit Outage"
        onClose={close}
        isOpen={isOpen}
        action={save}
        actionText="Save"
      >
        <Container classes={{ root: classes.container }}>
          <OutageFormFields
            setFormFields={setFormFieldsCallback}
            setValidationErrors={setValidationErrorsCallback}
            clearValidationError={clearValidationError}
            formFields={formFields}
            validationErrors={validationErrors}
          />
        </Container>
      </AppFullscreenDialog>
    </div>
  );
};

export default withStyles(styles)(EditOutageDialog);
