import React from "react";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import BillingModelScreen from "../components/BillingModelScreen";

const Overview: React.FC = () => {
  return (
    <BillingModelScreen backUrl="/" title="Billing Data Admin">
      <LinkCardsProvider
        cards={[
          {
            title: "Market Attributes",
            icon: "list",
            linkType: "external",
            url: "https://market-setup.metronetinc.com/",
          },
          {
            title: "Rate Plans",
            icon: "attach_money",
            linkType: "internal",
            url: "/billing-model/rates/list",
            // rbacAction: "outages:get",
          },
          {
            title: "Rate Plan Types",
            icon: "local_atm",
            linkType: "internal",
            url: "/billing-model/rate-types/list",
          },
        ]}
      />
    </BillingModelScreen>
  );
};

export default Overview;
