import { Location } from "history";
import React, { useCallback, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import ExitPromptDialog from "sections/billing-model-common/components/ExitPrompt";

export interface Props {
  /**
   Determines when the prompt should be activated and displayed to the user.
   */
  showPrompt?: boolean;
  /**
   If a handler is provided, it will be called in order to allow selective navigation blocking.
   */
  shouldBlockNavigation?: (location: Location) => boolean;
}

const RouteExitGuard = ({
  showPrompt,
  shouldBlockNavigation,
}: Props): JSX.Element => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleShowPrompt = useCallback(
    (e: BeforeUnloadEvent) => {
      if (showPrompt) {
        e.preventDefault();
        e.returnValue = ""; // return value required by Chrome
      }
    },
    [showPrompt]
  );

  useEffect(() => {
    addEventListener("beforeunload", handleShowPrompt);
    return () => removeEventListener("beforeunload", handleShowPrompt);
  }, [handleShowPrompt]);

  const shouldAllowNavigation = (nextLocation: Location<unknown>): boolean => {
    const blockNavigation =
      (!confirmedNavigation && shouldBlockNavigation?.(nextLocation)) ?? true;

    if (blockNavigation) {
      setLastLocation(nextLocation);
      setIsOpen(true);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    setIsOpen(false);
    setConfirmedNavigation(true);
  };

  const handleCancelNavigationClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  return (
    <>
      <Prompt
        when={showPrompt}
        message={(location) => shouldAllowNavigation(location)}
      />

      <ExitPromptDialog
        isOpen={isOpen}
        confirmNavigationClick={handleConfirmNavigationClick}
        cancelNavigationClick={handleCancelNavigationClick}
      />
    </>
  );
};

export default RouteExitGuard;
