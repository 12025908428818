import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Icon } from "@mui/material";
import { QueryLazyOptions } from "@apollo/client";

import { LCP, Market } from "../../ServiceDisruptionTypes";
import SelectableItem from "./SelectableItem";
import styles from "./styles";

export type Props = {
  classes: {
    search: string;
    selectAllButton: string;
    selectAllContainer: string;
  };
  selectedMarkets: Market[];
  selectedLCPs: LCP[];
  setSelectedLCPs: (lcps: LCP[]) => void;
  getLCPs: (
    options?: QueryLazyOptions<Record<string, any>> | undefined
  ) => void;
  lcps: LCP[];
};

const cloneData = (data: unknown) => JSON.parse(JSON.stringify(data));

const AddLCPStep: React.FC<Props> = ({
  classes,
  selectedMarkets,
  selectedLCPs,
  setSelectedLCPs,
  getLCPs,
  lcps,
}: Props) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchFilter(e.target.value);
  };

  const regex = new RegExp(searchFilter, "i");

  const lcpIsSelected = ({ name }: { name: string }) =>
    selectedLCPs?.some((lcp) => lcp.name === name);

  const onSelect = ({ name, city, state }: LCP) => {
    let newSelectedLCPs = cloneData(selectedLCPs);

    if (newSelectedLCPs.some((lcp: LCP) => lcp.name === name)) {
      newSelectedLCPs = newSelectedLCPs.filter(
        (lcp: LCP) => !(lcp.name === name)
      );
    } else {
      newSelectedLCPs.push({ name, city, state });
    }
    setSelectedLCPs(newSelectedLCPs);
  };

  const onSelectAll = () => {
    let newSelectedLCPs: LCP[] = [];

    if (!allSelected) {
      newSelectedLCPs = lcps
        ? lcps.slice().map((lcp) => ({
            city: lcp.city,
            state: lcp.state,
            name: lcp.name,
          }))
        : [];
    }

    setAllSelected(!allSelected);
    setSelectedLCPs(newSelectedLCPs);
  };

  useEffect(() => {
    getLCPs({ variables: { markets: selectedMarkets } });
  }, [getLCPs, selectedMarkets]);

  return (
    <div>
      <Typography variant="h6" marginBottom="1em">
        LCPs
      </Typography>
      <TextField
        classes={{ root: classes.search }}
        label="Search"
        value={searchFilter}
        onChange={(e) => onChange(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.selectAllContainer}>
        <Button
          color={allSelected ? "error" : "primary"}
          onClick={onSelectAll}
          variant="text"
        >
          {allSelected ? "Remove All" : "Select All"}
        </Button>
      </div>
      {lcps &&
        lcps
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name, "en", { numeric: true }))
          .filter((lcp: LCP) => Boolean(!searchFilter) || lcp.name.match(regex))
          .map((lcp: LCP) => (
            <SelectableItem
              key={`${lcp.city}, ${lcp.state}, ${lcp.name}`}
              label={lcp.name}
              isSelected={lcpIsSelected({ name: lcp.name }) || false}
              onSelect={() => onSelect(lcp)}
            />
          ))}
    </div>
  );
};

const StyledComponent = withStyles(styles)(AddLCPStep);

export { AddLCPStep };

export default StyledComponent;
