import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { colors } from "../../../styles/colors";
import TabPanel from "../components/TabPanel";
import ProfilePanelContent from "../components/ProfilePanelContent";
import PermissionsPanelContent from "../components/PermissionsPanelContent";

const styles = {
  title: {
    marginBottom: "1em",
  },
  root: {
    background: colors.white,
  },
  largeScreen: {
    display: "flex",
    flexGrow: 1,
  },
};

type Props = {
  classes: {
    title: string;
    root: string;
    largeScreen: string;
  };
};

const tabProps = (index: number) => ({
  id: `settings-tab-${index}`,
  "aria-controls": `settings-tabpanel-${index}`,
});

type SettingsTabPanelProps = {
  children: React.ReactNode;
  currentTabIndex: number;
  index: number;
};
const SettingsTabPanel: React.FC<SettingsTabPanelProps> = ({
  children,
  currentTabIndex,
  index,
}: SettingsTabPanelProps) => (
  <TabPanel
    ariaControl="settings-tabpanel"
    tabId="settings-tab"
    currentTabIndex={currentTabIndex}
    index={index}
  >
    {children}
  </TabPanel>
);

const Settings: React.FC<Props> = ({ classes }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const onChange = (event: React.ChangeEvent<unknown>, value: any) => {
    setCurrentTabIndex(value);
  };

  return (
    <Paper
      className={classnames(classes.root, {
        [classes.largeScreen]: !screenIsSmall,
      })}
      elevation={0}
    >
      <Tabs
        orientation={screenIsSmall ? "horizontal" : "vertical"}
        value={currentTabIndex}
        arial-label="Settings tab panel"
        onChange={onChange}
      >
        <Tab label="Profile" {...tabProps(0)}></Tab>
        <Tab label="Permissions" {...tabProps(1)}></Tab>
      </Tabs>
      <SettingsTabPanel currentTabIndex={currentTabIndex} index={0}>
        <ProfilePanelContent />
      </SettingsTabPanel>
      <SettingsTabPanel currentTabIndex={currentTabIndex} index={1}>
        <PermissionsPanelContent />
      </SettingsTabPanel>
    </Paper>
  );
};

export default withStyles(styles)(Settings);
