export default {
  link: {
    color: "black",
    padding: 0,
  },
  expansionPanel: {
    boxShadow: "none",
  },
  expansionPanelDetails: {
    padding: 0,
    paddingLeft: "1.25em",
  },
  titleStyle: {
    fontSize: "1.2em",
  },
  list: {
    width: "100%",
    padding: 0,
  },
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
};
