import React from "react";

import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import getRoutes from "routes/routeDefinitions";
import { userCan } from "lib/Authorization";
import { useUserState } from "sections/_global/contexts/UserContext";
import PermissionDenied from "sections/_global/components/PermissionDenied";

const Dashboard: React.FC = () => {
  const user = useUserState();
  const routes = getRoutes();
  const dashboardRoutes = routes.filter((route) => {
    const action = route.rbacAction;
    if (!action) return route.showInDashboard;

    return (
      route.showInDashboard && user && userCan({ user, performAction: action })
    );
  });

  if (dashboardRoutes.length === 0) {
    return <PermissionDenied />;
  }

  return (
    <LinkCardsProvider
      cards={dashboardRoutes.map((route) => ({
        title: route.title,
        icon: route.icon,
        url: route.url,
        linkType: route.linkType,
      }))}
    />
  );
};

export default Dashboard;
