import React from "react";

import ProfilePanelContent from "./ProfilePanelContent";
import { useUserState } from "../../../_global/contexts/UserContext";

const ProfilePanelContentProvider: React.FC = () => {
  const user = useUserState();

  return (
    <ProfilePanelContent displayName={user.displayName} email={user.email} />
  );
};

export default ProfilePanelContentProvider;
