import { createStyles } from "@mui/styles";

const styles = createStyles({
  title: {
    marginBottom: "1em",
  },
  flex: { display: "flex" },
});

export default styles;
