import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import { Icon } from "@mui/material";
import { QueryLazyOptions } from "@apollo/client";
import AppFullscreenDialog from "sections/_global/components/AppFullscreenDialog";
import AddMarketStep from "./AddMarketStep";
import AddLCPStep from "./AddLCPStep";
import { getMarketsAndLCPsFromAffectedAreas } from "../../data-model";
import { Market, LCP, AffectedArea } from "../../ServiceDisruptionTypes";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { colors } from "styles/colors";

import styles from "./styles";

export type Props = {
  classes: {
    container: string;
    search: string;
    market: string;
    addButton: string;
    stepperButtons: string;
  };
  isOpen: boolean;
  onClose: () => void;
  save: (selectedLCPs: LCP[]) => Promise<void>;
  initialAffectedAreas?: AffectedArea[];
  loading?: boolean;
  getMarkets: () => void;
  getLCPs: (
    options?: QueryLazyOptions<Record<string, any>> | undefined
  ) => void;
  markets: Market[];
  lcps: LCP[];
};

const AddAffectedAreaDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onClose,
  save,
  loading,
  initialAffectedAreas,
  getMarkets,
  getLCPs,
  markets,
  lcps,
}: Props) => {
  const [selectedMarkets, setSelectedMarkets] = useState<Market[]>([]);
  const [selectedLCPs, setSelectedLCPs] = useState<LCP[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const nextStep = () => {
    if (currentStep === 1) {
      let newSelectedLCPs = JSON.parse(JSON.stringify(selectedLCPs));

      newSelectedLCPs = newSelectedLCPs.filter((lcp: LCP) => {
        return selectedMarkets.some(
          (market) => market.city === lcp.city && market.state === lcp.state
        );
      });

      setSelectedLCPs(newSelectedLCPs);
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 2) {
      save(selectedLCPs).then(() => {
        setCurrentStep(1);
        onClose();
      });
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (isOpen && initialAffectedAreas) {
      const [
        initialSelectedMarkets,
        initialSelectedLCPs,
      ] = getMarketsAndLCPsFromAffectedAreas(initialAffectedAreas);
      setSelectedLCPs(initialSelectedLCPs);
      setSelectedMarkets(initialSelectedMarkets);
    }
  }, [isOpen, initialAffectedAreas]);

  return (
    <div>
      <LoadingBackdrop isOpen={loading || false} />
      <AppFullscreenDialog
        title="Add Affected Areas"
        onClose={onClose}
        isOpen={isOpen}
      >
        <Container classes={{ root: classes.container }}>
          {currentStep === 1 && (
            <AddMarketStep
              selectedMarkets={selectedMarkets}
              setSelectedMarkets={setSelectedMarkets}
              getMarkets={getMarkets}
              markets={markets}
            />
          )}
          {currentStep === 2 && (
            <AddLCPStep
              selectedMarkets={selectedMarkets}
              selectedLCPs={selectedLCPs}
              setSelectedLCPs={setSelectedLCPs}
              getLCPs={getLCPs}
              lcps={lcps}
            />
          )}
          <MobileStepper
            variant="progress"
            steps={3}
            position="bottom"
            activeStep={currentStep}
            sx={{
              backgroundColor: colors.brandBlueDark,
            }}
            nextButton={
              <Button
                size="small"
                variant="text"
                onClick={() => nextStep()}
                sx={{
                  color: colors.white,
                  ":disabled": {
                    color: colors.brandBlueLight,
                  },
                }}
                disabled={
                  (currentStep === 1 && selectedMarkets.length === 0) ||
                  (currentStep === 2 && selectedLCPs.length === 0)
                }
              >
                Next
                <Icon>keyboard_arrow_right</Icon>
              </Button>
            }
            backButton={
              <Button
                variant="text"
                size="small"
                onClick={() => prevStep()}
                disabled={currentStep === 1}
                sx={{
                  color: colors.white,
                  ":disabled": {
                    color: colors.brandBlueLight,
                  },
                }}
              >
                <Icon>keyboard_arrow_left</Icon>
                Back
              </Button>
            }
          />
        </Container>
      </AppFullscreenDialog>
    </div>
  );
};

const StyledComponent = withStyles(styles)(AddAffectedAreaDialog);

export { AddAffectedAreaDialog };

export default StyledComponent;
