import React, { useCallback, useState } from "react";
import BillingModelQueryTypeahead from "sections/billing-model-common/components/BillingModelQueryTypeahead";
import { TypeaheadOption } from "sections/_global/components/Typeahead";
import { RcdGroupType } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { RcdGroupTypeSelectData } from "sections/billing-model-rateplans/hooks/useRcdGroupTypeSelectQuery";

export interface Props {
  label: string;
  value: RcdGroupType | null;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  excludeRcdGroupTypes?: RcdGroupType[] | null;
  debounceMilliseconds?: number;
  useRcdGroupTypeSelectQuery: (search: string) => RcdGroupTypeSelectData;
  onFocus?: () => void;
  onChange?: (tt: RcdGroupType | null) => void;
}

const RcdGroupTypeSelect: React.FC<Props> = ({
  label,
  value,
  isDisabled,
  hasError,
  helperText,
  excludeRcdGroupTypes,
  debounceMilliseconds = 300,
  useRcdGroupTypeSelectQuery,
  onFocus,
  onChange,
}: Props) => {
  const {
    getRcdGroupTypes,
    loading: isLoadingRcdGroupTypes,
    rcdGroupTypesResponse,
  } = useRcdGroupTypeSelectQuery(
    value?.ratingControlGroupTypeId?.toString() ?? ""
  );

  const idsMatch = useCallback((id1: string, id2: string) => {
    const a = Number(id1);
    const b = Number(id2);
    return !isNaN(a) && !isNaN(b) && a === b;
  }, []);

  const getOption = useCallback(
    (rpt: RcdGroupType): TypeaheadOption => ({
      label: rpt.name ?? "",
      value: rpt,
    }),
    []
  );

  const getFiltered = useCallback(
    () =>
      rcdGroupTypesResponse?.bamRcdGroupTypes.data?.filter(
        (gt) =>
          !excludeRcdGroupTypes ||
          !excludeRcdGroupTypes?.some((f) =>
            idsMatch(f.ratingControlGroupTypeId, gt.ratingControlGroupTypeId)
          )
      ),
    [
      excludeRcdGroupTypes,
      idsMatch,
      rcdGroupTypesResponse?.bamRcdGroupTypes.data,
    ]
  );

  const getOptions = useCallback(() => {
    const options = getFiltered()?.map((rpt) => getOption({ ...rpt })) ?? [];
    return options;
  }, [getFiltered, getOption]);

  const [selected, setSelected] = useState<TypeaheadOption | string | null>(
    value ? getOption(value) : null
  );

  const handleChange = useCallback(
    (val: string | TypeaheadOption | null) => {
      if (onChange) {
        const opt = val as TypeaheadOption;
        if (opt) onChange(opt.value);
        else onChange(null);
      }

      setSelected(val);
    },
    [onChange]
  );

  return (
    <BillingModelQueryTypeahead
      label={label}
      idPropertyName="ratingControlGroupTypeId"
      isLoading={isLoadingRcdGroupTypes}
      hasError={hasError}
      isDisabled={isDisabled}
      helperText={helperText}
      value={selected}
      options={getOptions()}
      debounceMilliseconds={debounceMilliseconds}
      onSearch={getRcdGroupTypes}
      onFocus={onFocus}
      onChange={handleChange}
    />
  );
};

export default RcdGroupTypeSelect;
