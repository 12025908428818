import React from "react";
import { PeriodTypeSelectData } from "sections/billing-model-rateplans/hooks/usePeriodTypeSelectQuery";
import AppSelect, { SelectOption } from "sections/_global/components/AppSelect";
import { BillingModelPeriodTypes_bamPeriodTypes as PeriodType } from "../../__generated__/BillingModelPeriodTypes";

export interface Props {
  label: string;
  value: PeriodType | null;
  isDisabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  useFullWidth?: boolean;
  usePeriodTypeSelectQuery: () => PeriodTypeSelectData;
  onChange?: (pt: PeriodType | null) => void;
}

const PeriodTypeSelect: React.FC<Props> = ({
  label,
  value,
  isDisabled,
  hasError,
  helperText,
  useFullWidth,
  usePeriodTypeSelectQuery,
  onChange,
}: Props) => {
  const { periodTypesResponse } = usePeriodTypeSelectQuery();

  let options: SelectOption[] =
    periodTypesResponse?.bamPeriodTypes?.map((pt) => ({
      label: pt.name,
      value: pt,
    })) ?? [];

  options = options.concat([{ label: "None", value: null }]);
  const selectedValue = value
    ? options.find((pt) => pt.value?.periodTypeId === value.periodTypeId)?.value
    : null;

  return (
    <AppSelect
      label={label}
      useFullWidth={useFullWidth}
      variant="outlined"
      isDisabled={isDisabled}
      hasError={hasError}
      helperText={helperText}
      value={selectedValue}
      options={options}
      onChange={(value) => {
        if (onChange) {
          onChange((value as PeriodType) ?? null);
        }
      }}
    />
  );
};

export default PeriodTypeSelect;
