import React from "react";
import { withStyles } from "@mui/styles";

import styles from "./styles";
import MetroNetLogoIcon from "assets/MetroNetLogoIcon.svg";
import AppNotice from "sections/_global/components/AppNotice";

export type Props = {
  classes: {
    root: string;
    icon: string;
    noResults: string;
  };
  message: string;
  subMessage?: string;
};

const NoResults: React.FC<Props> = ({
  classes,
  message,
  subMessage,
}: Props) => {
  return (
    <AppNotice
      message={message}
      subMessage={subMessage}
      icon={
        <img
          className={classes.icon}
          src={MetroNetLogoIcon}
          alt="MetroNet Logo"
        />
      }
    />
  );
};

const StyledComponent = withStyles(styles)(NoResults);

export { NoResults };

export default StyledComponent;
