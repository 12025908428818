import {
  emailValidation,
  latitudeValidation,
  longitudeValidation,
  stateAbbrevValidation,
} from "sections/_global/RegexValidation";
import { activityIsType, dateRequiredTypes } from "../activityTypes";
import { Address, Coordinates, OspInfo } from "./types";

export type FormValidation = {
  isValid: boolean;
  invalidFields?: string[];
};

export type FormValidationInput = {
  activityType: string;
  date?: Date;
  coordinates?: Coordinates;
  address?: Address;
  ospInfo?: OspInfo;
};

export function handleFormValidation(
  input: FormValidationInput
): FormValidation {
  const invalidFields: string[] = [];

  if (
    input.coordinates?.latitude &&
    !valid(
      (input.coordinates?.latitude as unknown) as string,
      latitudeValidation
    )
  ) {
    invalidFields.push("latitude");
  }
  if (
    input.coordinates?.longitude &&
    !valid(
      (input.coordinates?.longitude as unknown) as string,
      longitudeValidation
    )
  ) {
    invalidFields.push("longitude");
  }
  if (
    input.address?.state &&
    !valid(input.address.state, stateAbbrevValidation)
  ) {
    invalidFields.push("state");
  }
  if (
    input.ospInfo?.emailsToNotify &&
    input.ospInfo.emailsToNotify
      .filter((email) => email !== "")
      .some((email) => !valid(email, emailValidation))
  ) {
    invalidFields.push("email");
  }
  if (activityIsType(input.activityType, dateRequiredTypes) && !input.date) {
    invalidFields.push("date");
  }

  const formValidation: FormValidation = {
    isValid: invalidFields.length == 0,
    invalidFields,
  };
  return formValidation;
}

function valid(input: string, regex: RegExp) {
  return new RegExp(regex).test(input ?? "");
}
