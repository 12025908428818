import { colors } from "styles/colors";

export default {
  card: {
    "&:hover": {
      top: -1,
    },
    height: {
      xs: "8em",
      sm: "11em",
    },
    textAlign: "center",
  },
  disabledCard: {
    background: colors.gray12,
    cursor: "default",
  },
  activeLabel: {
    color: "secondary.main",
  },
  disabledLabel: {
    color: colors.gray11,
  },
} as const;
