import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { getDisplayText } from "../../../billing-model-common/BillingModelUtilities";
import RcdGroupView from "../RcdGroupView";
import {
  RatePlan,
  RatePlanType,
  RcdElement,
  RcdGroup,
} from "../../BillingModelRatePlanTypes";
import RatePlanTypeSelect from "../RatePlanTypeSelect";
import { BillingModelPeriodTypes_bamPeriodTypes as PeriodType } from "../../__generated__/BillingModelPeriodTypes";
import { BillingModelTransactionTypes_bamTransactionTypes as TransactionType } from "../../__generated__/BillingModelTransactionTypes";
import { useRatePlanDefaultValues } from "sections/billing-model-rateplans/hooks/useRatePlanDefaultValues";
import { v4 as uuidv4 } from "uuid";
import { RatePlanTypeSelectData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeSelectQuery";
import { RatePlanTypeData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeDetailsQuery";

interface SelectableGroup
  extends Omit<RcdGroup, "periodType" | "transactionType"> {
  selected: boolean;
  periodType: PeriodType | null;
  transactionType: TransactionType | null;
}

export interface Props {
  selected: RcdGroup[];
  ratePlan: RatePlan | null;
  ratePlanType: RatePlanType | null;
  useRatePlanTypeDetailsQuery: (id?: string | null) => RatePlanTypeData;
  useRatePlanTypeSelectQuery: (search: string) => RatePlanTypeSelectData;
  onChange?: (rpt: RatePlanType | null, groups: RcdGroup[]) => void;
}

const SelectRcdGroups: React.FC<Props> = ({
  selected,
  ratePlan,
  ratePlanType,
  useRatePlanTypeDetailsQuery,
  useRatePlanTypeSelectQuery,
  onChange,
}: Props) => {
  const defaultValues = useRatePlanDefaultValues();

  const loadRcdGroups = (rpt: RatePlanType): SelectableGroup[] => {
    const ratePlanGroups =
      ratePlan?.ratingControlGroups?.filter(
        (rg) => rg.active && !selected.includes(rg)
      ) ?? [];

    const allGroups = [...selected, ...ratePlanGroups];

    if (rpt && rpt.ratingControlGroupTypes) {
      return rpt.ratingControlGroupTypes.map((gt) => {
        const group = allGroups.find(
          (g) =>
            g.ratingControlGroupType?.ratingControlGroupTypeId ===
            gt.ratingControlGroupTypeId
        );

        if (group) return { ...group, selected: true };
        else {
          const newGroup: SelectableGroup = {
            __typename: "BamRcdGroup",
            ratingControlGroupId: "",
            ratePlanId: ratePlan?.ratePlanId ?? "",
            active: true,
            class: null,
            startDate: defaultValues.ratePlanStartDate.toISOString(),
            endDate: null,
            ratingControlGroupType: { ...gt },
            periodType: defaultValues.periodType,
            transactionType: defaultValues.transactionType,
            status: "new",
            selected: true,
            ratingControlElements:
              gt.ratingControlElementTypes?.map((gte) => {
                const newElement: RcdElement = {
                  __typename: "BamRcdElement",
                  ratingControlElementId: uuidv4(),
                  ratingControlElementType: { ...gte },
                  value: gte.defaultValue?.trim() ?? null,
                  status: "new",
                };

                return newElement;
              }) ?? [],
          };

          return newGroup;
        }
      });
    }

    return [];
  };

  const [rcdGroups, setRcdGroups] = useState<SelectableGroup[]>(
    ratePlanType ? loadRcdGroups(ratePlanType) : []
  );

  const handleRatePlanTypeChange = (rpt: RatePlanType | null) => {
    if (onChange) {
      const groups = rpt ? loadRcdGroups(rpt) : [];
      setRcdGroups(groups);
      onChange(rpt, groups.filter((g) => g.selected) as RcdGroup[]);
    }
  };

  const handleGroupCheckChange = (rcd: SelectableGroup, checked: boolean) => {
    const index = rcdGroups.findIndex((r) => r === rcd);
    const update = { ...rcd, selected: checked };
    const groups = [...rcdGroups];

    if (index > -1) {
      groups.splice(index, 1, update);
      setRcdGroups(groups);
      if (onChange)
        onChange(ratePlanType, groups.filter((g) => g.selected) as RcdGroup[]);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        marginBottom="3em"
      >
        <Typography variant="h4">Select RCD Groups</Typography>
        <Typography variant="body1">
          Select RCD Groups to copy from the selected Rate Plan.
        </Typography>
        <Typography variant="body1">
          Select or deselect an RCD Group by toggling the switch next to the RCD
          Group name.
        </Typography>
      </Box>

      <Divider />

      <Grid container spacing={3}>
        {ratePlan && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">
                {ratePlan.ratePlanId !== ""
                  ? "Selected Rate Plan"
                  : "New Rate Plan"}
              </Typography>
              <Typography variant="h6">
                {getDisplayText(ratePlan.ratePlanId, ratePlan.name, null)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box maxWidth="40rem">
                <RatePlanTypeSelect
                  label="Rate Plan Type"
                  value={ratePlanType}
                  useRatePlanTypeDetailsQuery={useRatePlanTypeDetailsQuery}
                  useRatePlanTypeSelectQuery={useRatePlanTypeSelectQuery}
                  onChange={handleRatePlanTypeChange}
                />
              </Box>
            </Grid>
            {ratePlan.ratePlanId !== "" && (
              <Grid item xs={12}>
                <Typography variant="overline" component="p">
                  Description
                </Typography>
                <Typography variant="body1">
                  {getDisplayText(
                    null,
                    ratePlan.description,
                    "No description available."
                  )}
                </Typography>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <Box marginTop="1em">
            <Typography variant="h5">RCD Groups</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {rcdGroups.length === 0 && (
            <Box minHeight="10em" marginLeft="3em">
              <Typography variant="body1">No RCD Groups available</Typography>
            </Box>
          )}
          {rcdGroups
            .filter((rg) => rg.active)
            .map((rg, i) => (
              <RcdGroupView
                key={i}
                rcdGroup={rg as RcdGroup}
                isChecked={rg.selected}
                onChecked={(checked) => handleGroupCheckChange(rg, checked)}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default SelectRcdGroups;
