import * as React from "react";
import { Box, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

import styles from "./styles";

export type Props = {
  handleChange: (str: any) => void;
  value: string;
  required?: boolean;
};

const DescriptionTextBox: React.FC<Props> = ({
  handleChange,
  value,
  required,
}: Props) => {
  return (
    <Box>
      <div>
        <TextField
          fullWidth
          required={required}
          onChange={handleChange}
          id="outlined-multiline-static"
          label="Description"
          multiline
          minRows={8}
          maxRows={Infinity}
          value={value}
          type="text"
        />
      </div>
    </Box>
  );
};

export default withStyles(styles)(DescriptionTextBox);
