import { Box, TextField } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import DateTextField from "sections/_global/components/DateTextField";
import TimeTextField from "sections/_global/components/TimeTextField";
import { parseISO } from "date-fns";

export interface Props {
  dataType:
    | "Date"
    | "Time"
    | "Integer"
    | "Decimal"
    | "Money"
    | "Character"
    | "Text"
    | string
    | null
    | undefined;
  label?: string | null;
  value?: string | null;
  editable?: boolean;
  hasError?: boolean;
  helperText?: string;
  onChange?: (value: string) => void;
}

function getTimeAsDate(value: string | null | undefined): Date | null {
  const timeParts = value ? value.split(":") : [];
  if (value && timeParts.length === 3) {
    const date = new Date();
    date.setHours(Number(timeParts[0]));
    date.setMinutes(Number(timeParts[1]));
    date.setSeconds(Number(timeParts[2]));
    return date;
  }

  return null;
}

function getTimeString(time: Date | null): string {
  if (time === null) return "";
  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");
  const timeStr = `${hours}:${minutes}:${seconds}`;
  return timeStr;
}

const RcdElementField: React.FC<Props> = ({
  dataType,
  label,
  value,
  editable,
  hasError,
  helperText,
  onChange,
}: Props) => {
  const parsedDate = value ? parseISO(value.trim()) : null;
  const fieldId = `${uuidv4()}-textfield`;
  const elementDisabled = !editable ?? true;
  const handleChange = (value: string) => {
    if (onChange) onChange(value);
  };

  const getTextfield = (inputType: string) => (
    <TextField
      fullWidth
      variant="outlined"
      sx={{ marginRight: "0.8em" }}
      label={label ?? "Unknown Element"}
      value={value}
      type={inputType}
      onChange={(event) => handleChange(event.target.value ?? "")}
      error={hasError}
      helperText={helperText}
      InputProps={{ id: fieldId }}
      InputLabelProps={{
        htmlFor: fieldId,
        shrink: true,
      }}
      disabled={elementDisabled}
    />
  );

  switch (dataType ?? "None") {
    case "Date":
      return (
        <DateTextField
          variant="desktop"
          label={label ?? "Unknown Element"}
          error={hasError}
          helperText={helperText}
          onChange={(date) => handleChange(date?.toISOString() ?? "")}
          value={parsedDate}
          disabled={elementDisabled}
          InputLabelProps={{ shrink: true }}
        />
      );
    case "Time":
      return (
        <Box sx={{ "& > .MuiFormControl-root": { width: "100%" } }}>
          <TimeTextField
            ampm={false}
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            variant="inline"
            label={label ?? "Unknown Element"}
            hasError={hasError}
            helperText={helperText}
            onChange={(time: Date | null) => handleChange(getTimeString(time))}
            value={getTimeAsDate(value)}
            disabled={elementDisabled}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      );
    case "Integer":
    case "Decimal":
    case "Money":
      return getTextfield("number");
    case "Character":
      return getTextfield("text");
    default:
      return getTextfield("text");
  }
};

export default RcdElementField;
