import { useLazyQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { QUERIES } from "sections/billing-model-rateplans/BillingModelRatePlanQueries";
import {
  RatePlan,
  RatePlanType,
  RcdGroupType,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { BillingModelRatePlanTypeNameExists } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlanTypeNameExists";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import RatePlanTypeForm from "./RatePlanTypeForm";
import RcdGroupTypeList from "../RcdGroupTypeList";

export interface Props {
  ratePlanType: RatePlanType;
  ratePlans?: RatePlan[];
  loading?: boolean;
  onAddRcdGroupType: () => void;
  onChange: (ratePlanType: RatePlanType, ratePlans?: RatePlan[]) => void;
  onSubmit: () => void;
  onDelete?: () => void;
}

const RatePlanTypeFormProvider = ({
  ratePlanType,
  ratePlans,
  loading = false,
  onAddRcdGroupType,
  onChange,
  onSubmit,
  onDelete,
}: Props): JSX.Element => {
  const [checkName, { loading: checkingName, data: nameInUse }] = useLazyQuery<
    BillingModelRatePlanTypeNameExists
  >(QUERIES.getRatePlanTypeNameExists, { fetchPolicy: "network-only" });

  const handleChanges = (
    ratePlanTypeUpdate: RatePlanType,
    ratePlansUpdate: RatePlan[] | undefined
  ) => {
    onChange(ratePlanTypeUpdate, ratePlansUpdate);
  };

  const handleRemoveRcdGroupType = (gt: RcdGroupType) => {
    const update = {
      ...ratePlanType,
      ratingControlGroupTypes: [
        ...(ratePlanType.ratingControlGroupTypes ?? []),
      ],
    };

    const index =
      ratePlanType.ratingControlGroupTypes?.findIndex((o) => o === gt) ?? -1;

    if (index > -1 && ratePlanType.ratingControlGroupTypes) {
      update.ratingControlGroupTypes.splice(index, 1, {
        ...gt,
        status: "deleted",
      });
    }

    onChange({ ...update }, ratePlans);
  };

  const handleRestoreRcdGroupType = (gt: RcdGroupType) => {
    const update = {
      ...ratePlanType,
      ratingControlGroupTypes: [
        ...(ratePlanType.ratingControlGroupTypes ?? []),
      ],
    };

    const index =
      ratePlanType.ratingControlGroupTypes?.findIndex((o) => o === gt) ?? -1;

    if (index > -1 && ratePlanType.ratingControlGroupTypes) {
      update.ratingControlGroupTypes.splice(index, 1, { ...gt });
    }

    onChange({ ...update }, ratePlans);
  };

  const handleCheckName = useCallback(
    (value: string) => {
      checkName({
        variables: { name: value, editing: ratePlanType.ratePlanTypeId !== "" },
      });
    },
    [checkName, ratePlanType.ratePlanTypeId]
  );

  if (loading) return <LoadingBackdrop isOpen />;

  return (
    <RatePlanTypeForm
      checkingName={checkingName}
      isNameInUse={nameInUse?.bamRatePlanTypeNameExists}
      ratePlanType={ratePlanType}
      onChange={(rpt) => handleChanges(rpt, ratePlans)}
      onCheckName={handleCheckName}
      onSubmit={onSubmit}
      onDelete={onDelete}
    >
      <RcdGroupTypeList
        rcdGroupTypes={
          (ratePlanType.ratingControlGroupTypes as RcdGroupType[]) ?? []
        }
        isAddAllowed={ratePlanType.ratePlanTypeId === ""}
        isDeleteAllowed={
          false // ratePlanType.ratePlanTypeId === "" || (ratePlans?.length ?? 0) === 0
        }
        onAddRcdGroupType={onAddRcdGroupType}
        onRemoveRcdGroupType={handleRemoveRcdGroupType}
        onRestoreRcdGroupType={handleRestoreRcdGroupType}
      />
    </RatePlanTypeForm>
  );
};

export default RatePlanTypeFormProvider;
