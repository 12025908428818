import React, { FC } from "react";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { emailValidation } from "sections/_global/RegexValidation";
import ValidatedTextField from "../ValidatedTextField";
export interface Props {
  value: string;
  onChange: (str: string) => void;
  helperText: string;
  classes?: SxProps<Theme>;
  id: string;
}

const EmailTextField: FC<Props> = ({
  value,
  onChange,
  helperText,
  classes,
  id,
}: Props) => {
  return (
    <ValidatedTextField
      id={id}
      classes={classes}
      value={value}
      onChange={(event) => {
        event.preventDefault();
        onChange(event.target.value);
      }}
      label={"Email"}
      variant={"outlined"}
      errorText={"Incorrect email format"}
      regex={emailValidation}
      type={"text"}
      helperText={helperText}
    />
  );
};

export default EmailTextField;
