import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";

import {
  CardContent,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { SelectOption } from "sections/_global/components/AppSelect";
import { Address, Subscriber, Ticket, TicketType } from "../../screens/types";
import ExistingActivities from "../ExistingActivities/ExistingActivities";
import Tickets from "../Tickets/Tickets";
import {
  getSubscriberData_ofscActivitiesBySubId_OfscActivitiesSuccess_activities as Activity,
  getSubscriberData_ticketLookup_tickets as TicketLookup,
} from "../../../__generated__/getSubscriberData";
import { ActivityType } from "../../activityTypes";
import { formatDescription } from "../../screens/helpers";

export type Props = {
  address?: Address;
  activityTypes: ActivityType[];
  existingActivities: Activity[];
  existingActivity: string | null;
  isSplicingType: boolean;
  spatialNetInfo: string;
  subscriber: Subscriber | null;
  ticket?: Ticket;
  tickets: TicketLookup[];
  handleActivityChange: any;
  setDescription: (val: string) => void;
  setTicket: (val: Ticket | undefined) => void;
};

const numActivitiesOptions: SelectOption[] = [];

for (let i = 1; i <= 25; i++) {
  numActivitiesOptions.push({ label: "" + i, value: i });
}

const SubscriberInfo: React.FC<Props> = ({
  address,
  activityTypes,
  existingActivities,
  existingActivity,
  isSplicingType,
  spatialNetInfo,
  subscriber,
  ticket,
  tickets,
  handleActivityChange,
  setDescription,
  setTicket,
}: Props) => {
  const handleTicketChange = (e: SelectChangeEvent<string>) => {
    const ticketNum = e.target.value.substring(e.target.value.indexOf("!") + 1);
    const ticketType = e.target.value.substring(
      0,
      e.target.value.indexOf("!")
    ) as TicketType;

    if (ticketNum.length == 0) {
      setTicket(undefined);
    } else {
      setTicket({
        ticketType: ticketType,
        ticketNumber: ticketNum,
      });
    }

    const selectedTicket = tickets.find(
      (ticket) =>
        ticket.id === ticketNum &&
        ticket.source?.toLowerCase() === ticketType.toLowerCase()
    );
    setDescription(formatDescription(selectedTicket?.description ?? ""));
  };

  return (
    <>
      <CardContent>
        <Typography style={{ fontWeight: 600 }}>
          {subscriber?.name} ({subscriber?.id})
        </Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item>
            <Typography style={{ fontWeight: 500 }}>
              Physical Address
            </Typography>
            <Typography>
              {address?.streetAddress1} {address?.streetAddress2}
            </Typography>
            <Typography>
              {address?.city}, {address?.state} {address?.postalCode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 500 }}>
              Spatial Net Info
            </Typography>
            <Typography>{spatialNetInfo}</Typography>
          </Grid>
          <Grid item>
            <ExistingActivities
              handleChange={handleActivityChange}
              selected={existingActivity ?? ""}
              options={existingActivities}
              activityTypes={activityTypes}
            ></ExistingActivities>
          </Grid>
        </Grid>
      </CardContent>
      {tickets.length != 0 && !isSplicingType && (
        <CardContent>
          <Grid container spacing={1}>
            <Grid item>
              <Tickets
                handleChange={handleTicketChange}
                ticketType="Zendesk"
                selected={
                  ticket == null || ticket.ticketType != "ZENDESK"
                    ? ""
                    : `${ticket?.ticketType}!${
                        (ticket?.ticketNumber as unknown) as string
                      }`
                }
                options={tickets}
              ></Tickets>
            </Grid>
            <Grid item>
              <Tickets
                handleChange={handleTicketChange}
                ticketType="Footprints"
                selected={
                  ticket == null || ticket.ticketType != "FOOTPRINTS"
                    ? ""
                    : `${ticket?.ticketType}!${
                        (ticket?.ticketNumber as unknown) as string
                      }`
                }
                options={tickets}
              ></Tickets>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </>
  );
};

export default withStyles(styles)(SubscriberInfo);
