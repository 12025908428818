import React, { useRef, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";

import MetronetLogoBlue from "assets/MetronetLogoBlue.svg";
import UserFields from "sections/login/components/UserFields";
import config from "config";
import styles from "./styles";

type Props = {
  classes: {
    paper: string;
    image: string;
    imageContainer: string;
    title: string;
    button: string;
    buttonContainer: string;
  };
  setFormField: (
    name: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  formFields: {
    username: string;
    password: string;
  };
  submitForm: () => void;
};

const LoginForm: React.FC<Props> = ({
  classes,
  setFormField,
  submitForm,
  formFields,
}: Props) => {
  const ref = useRef(null);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  });

  return (
    <div ref={ref}>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={MetronetLogoBlue}
          alt="MetroNet Logo"
        />
      </div>
      <Typography variant="h4" component="h1" className={classes.title}>
        Console
      </Typography>
      {config.authenticationType === "TOKEN" ? (
        <UserFields setFormField={setFormField} formFields={formFields} />
      ) : (
        <Typography>
          Authenticate your account by logging into MetroNet’s single sign-on
          provider.
        </Typography>
      )}
      <div className={classes.buttonContainer}>
        <Button classes={{ root: classes.button }} onClick={submitForm}>
          Log In
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(LoginForm);
