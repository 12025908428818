import React from "react";
import { SlideProps, Dialog, Slide, Box, Icon } from "@mui/material";

import AppHeader from "../AppHeader/AppHeader";

const Slider = (props: SlideProps, ref: SlideProps["ref"]) => {
  return <Slide direction="up" ref={ref} {...props} />;
};

const Transition = React.forwardRef(Slider);

export type Props = {
  action?: () => void;
  actionText?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
  title: string;
};

const AppFullscreenDialog: React.FC<Props> = ({
  action,
  actionText,
  isOpen,
  onClose,
  children,
  title,
}: Props) => {
  const rightActions =
    action && actionText
      ? [
          {
            text: actionText,
            onClick: action,
          },
        ]
      : [];

  return (
    <div>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppHeader
          leftActions={[
            {
              component: (
                <Box display="flex" alignItems="center" paddingRight=".5rem">
                  <Icon>close</Icon>
                  Close
                </Box>
              ),
              label: "Close",
              onClick: onClose,
            },
          ]}
          title={title}
          rightActions={rightActions}
        />
        {children}
      </Dialog>
    </div>
  );
};

export default AppFullscreenDialog;
