import React, { FC } from "react";
import {
  Divider,
  Icon,
  ListItemIcon,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { Box, SxProps } from "@mui/system";
import componentStyles from "./styles";

export type Props = {
  title: string;
  icon?: string;
  labelId: string;
  checked: boolean;
  indeterminate?: boolean;
  setChecked: () => void;
  classes?: SxProps;
};

const CheckBoxListItem: FC<Props> = ({
  title,
  icon,
  checked,
  setChecked,
  labelId,
  indeterminate,
  classes: optionalClasses,
}: Props) => {
  return (
    <Box sx={optionalClasses} onClick={setChecked}>
      <Divider sx={componentStyles.full} />
      <ListItem button ContainerComponent={"div"}>
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText id={labelId} primary={title} />
        <ListItemSecondaryAction onClick={setChecked}>
          <Checkbox
            edge="end"
            checked={checked}
            indeterminate={indeterminate}
            inputProps={{ "aria-labelledby": labelId }}
            sx={{ indeterminate: componentStyles.indeterminate }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
};

export default CheckBoxListItem;
