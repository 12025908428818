import {
  AffectedArea,
  Market,
  LCP,
  Outage,
  Status,
} from "./ServiceDisruptionTypes";
import { GetOutages, GetOutages_outages } from "./__generated__/GetOutages";

export type NestedAffectedArea = {
  [key: string]: {
    [key: string]: string[];
  };
};

export type SplitLCP = {
  name: string;
  numberOfCalls: number;
  numberOfCallbacks: number;
};

export type SplitAffectedArea = {
  state: string;
  city: string;
  lcps: SplitLCP[];
  numberOfCalls?: number;
  numberOfCallbacks?: number;
};

export type MarketsAndLCPs = [Market[], LCP[]];

export const nestedAffectedAreas = (
  affectedAreas: AffectedArea[]
): NestedAffectedArea => {
  const nestedAffectedAreas: NestedAffectedArea = {};
  for (const affectedArea of affectedAreas) {
    const { city, state, lcp } = affectedArea;
    nestedAffectedAreas[state][city].push(lcp);
  }

  return nestedAffectedAreas;
};

export const splitLCPs = (
  affectedAreas: AffectedArea[]
): SplitAffectedArea[] => {
  const splitAffectedAreas: SplitAffectedArea[] = [];
  for (const affectedArea of affectedAreas) {
    const { city, state, lcp, numberOfCalls, numberOfCallbacks } = affectedArea;

    const existingAffectedArea = splitAffectedAreas.filter(
      (affectedArea) =>
        affectedArea.city === city && affectedArea.state === state
    )[0];

    if (existingAffectedArea) {
      existingAffectedArea.lcps.push({
        name: lcp,
        numberOfCalls: numberOfCalls || 0,
        numberOfCallbacks: numberOfCallbacks || 0,
      });

      if (existingAffectedArea.numberOfCalls) {
        existingAffectedArea.numberOfCalls += numberOfCalls || 0;
      }

      if (existingAffectedArea.numberOfCallbacks) {
        existingAffectedArea.numberOfCallbacks += numberOfCallbacks || 0;
      }
    } else {
      splitAffectedAreas.push({
        city,
        state,
        lcps: [
          {
            name: lcp,
            numberOfCalls: numberOfCalls || 0,
            numberOfCallbacks: numberOfCallbacks || 0,
          },
        ],
        numberOfCalls,
        numberOfCallbacks,
      });
    }
  }

  return splitAffectedAreas;
};

export const getMarketsAndLCPsFromAffectedAreas = (
  affectedAreas: AffectedArea[]
): MarketsAndLCPs => {
  const markets = [];
  const lcps = [];

  for (const affectedArea of affectedAreas) {
    const marketAlreadyAdded = Boolean(
      markets.find(
        (market) =>
          market.city === affectedArea.city &&
          market.state === affectedArea.state
      )
    );

    if (!marketAlreadyAdded) {
      markets.push({ city: affectedArea.city, state: affectedArea.state });
    }

    lcps.push({
      city: affectedArea.city,
      state: affectedArea.state,
      name: affectedArea.lcp,
    });
  }

  return [markets, lcps];
};

type getFormattedOutagesFromQueryDataArgs = {
  data: GetOutages | undefined;
};

type PartialOutage = Omit<GetOutages_outages, "__typename"> & {
  __typename?: string;
};

export const getFormattedOutagesFromQueryData = ({
  data,
}: getFormattedOutagesFromQueryDataArgs): Outage[] => {
  const outages: PartialOutage[] = JSON.parse(
    JSON.stringify(data?.outages || null)
  );
  return outages
    ? outages
        .sort(
          (a, b) =>
            new Date(b.startAt).getTime() - new Date(a.startAt).getTime()
        )
        .map((outage: PartialOutage) => {
          delete outage.__typename;
          return {
            ...outage,
            status: outage.status as Status,
            startAt: new Date(outage.startAt),
            endAt: outage.endAt ? new Date(outage.endAt) : null,
          };
        })
    : [];
};

type serviceTypeMappingType = {
  [key: string]: string;
};
export const serviceTypeMapping: serviceTypeMappingType = {
  "Service Outage - Cable": "Video Services",
  "Service Outage - Internet": "Internet Services",
  "Service Outage - All": "All Services",
  "Service Outage - Phone": "Phone Services",
};
