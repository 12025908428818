import React from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { withStyles } from "@mui/styles";

import { Permission } from "../../../_global/types";

type Props = {
  userPermissions?: Permission[] | null;
  permissions?: Permission[] | null;
  category: string;
  classes: {
    root: string;
    label: string;
    caption: string;
  };
};

const styles = {
  root: {
    padding: "2em 3em",
  },
  label: {
    cursor: "default",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  caption: {
    opacity: "0.75",
  },
};

const PermissionsSection: React.FC<Props> = ({
  classes,
  userPermissions,
  permissions,
  category,
}: Props) => (
  <div className={classes.root}>
    <Typography component="h2" variant="h5" color="primary">
      {category}
    </Typography>
    <Divider sx={{ margin: "1em 0" }} variant="middle" />
    <Grid container spacing={2}>
      {permissions?.map((permission) => (
        <Grid item xs={12} md={4} key={permission.action}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple={true}
                sx={{ cursor: "default" }}
                readOnly
                value={permission.action}
                checked={
                  userPermissions?.some(
                    (p) => permission.action === p.action
                  ) || false
                }
              />
            }
            label={
              <div className={classes.label}>
                <Typography>{permission.name}</Typography>
                <Typography
                  variant="caption"
                  classes={{ root: classes.caption }}
                >
                  {permission.action}
                </Typography>
              </div>
            }
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

export default withStyles(styles)(PermissionsSection);
