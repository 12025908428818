import React, { FC } from "react";
import AppSelect from "sections/_global/components/AppSelect";
import { Grid, TextField } from "@mui/material";
import { useContact } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";

const ExternalRefInput: FC = () => {
  const [{ externalRefId, externalRefTypeId }, contactDispatch] = useContact();
  return (
    <Grid container item spacing={5}>
      <Grid item xs={12} sm={8}>
        <TextField
          fullWidth={true}
          value={externalRefId}
          onChange={(e) =>
            contactDispatch({
              type: "EDIT_EXTERNAL_REF_VALUE",
              newValue: e.target.value,
            })
          }
          label="External Ref ID"
          helperText={
            externalRefId.length === 0 &&
            "Enter unique external reference id of the user and select appropriate type"
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <AppSelect
          options={[
            { label: "Active Directory", value: 1 },
            { label: "Customer", value: 2 },
          ]}
          label="External Ref Type"
          value={externalRefTypeId}
          onChange={(value) =>
            contactDispatch({
              type: "EDIT_EXTERNAL_REF_TYPE_ID",
              newValue: value as number,
            })
          }
          useFullWidth={true}
        />
      </Grid>
    </Grid>
  );
};

export default ExternalRefInput;
