import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import theme from "styles/theme";
import PageHeader from "sections/_global/components/PageHeader";
import { CreateContactInput } from "../../../../../__generated__/globalTypes";
import AddContactButton from "../components/AddContactButton";
import ContactContainer from "../components/ContactContainer";
import { Contact } from "../types";
import componentStyles from "./styles";
import { Box, useMediaQuery } from "@mui/material";

const formatContacts = (
  contacts: Contact[],
  externalRefId: string,
  externalRefTypeId: number
): CreateContactInput[] => {
  const formattedContacts: CreateContactInput[] = contacts.map((contact) => {
    const { subPreferences, value, type } = contact;

    const formattedPrefereces: any[] = [];

    subPreferences.forEach((option) => {
      const { channelOptions, channelId } = option;
      channelOptions.forEach((preference) => {
        const { channelOptionId, subOptions } = preference;
        const formattedSubPreferences: any[] = [];
        if (subOptions)
          subOptions.forEach((subPreference) => {
            const { optionTypeValueId } = subPreference;
            formattedSubPreferences.push({
              channelOptionId,
              isOpted: subPreference.checked,
              optionTypeValueId,
            });
          });
        formattedPrefereces.push({
          channelId,
          isOpted: preference.checked,
          subPreferences: formattedSubPreferences,
        });
      });
    });

    return {
      value: type === 2 ? `+${value}` : value,
      isActive: true,
      externalRefId,
      externalRefTypeId,
      methodId: type,
      preferences: formattedPrefereces,
    };
  });
  return formattedContacts;
};

const CommunicationPreferenceContacts: FC = () => {
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  return (
    <Box sx={componentStyles.page}>
      <Box sx={componentStyles.contactsHeader}>
        <PageHeader
          url="/communication-preference"
          title="Communication Preferences > Contacts"
          screenIsSmall={screenIsSmall}
        />
        <AddContactButton
          onClick={() => history.push("/communication-preference/contacts/add")}
        />
      </Box>
      <ContactContainer></ContactContainer>
    </Box>
  );
};

export { formatContacts };

export default CommunicationPreferenceContacts;
