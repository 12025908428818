import { colors } from "styles/colors";

export default {
  container: {
    padding: { xs: "0 0.5rem", sm: "0 2rem" },
    marginBottom: "2rem",
  },
  shift: {
    width: "90%",
    marginLeft: "auto",
  },
  table: {
    width: "100%",
    overflowX: { xs: "hidden", sm: "auto" },
    overflowY: { xs: "scroll", sm: "hidden" },
    whiteSpace: { xs: "normal", sm: "nowrap" },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem",
  },
  column: {
    padding: { xs: "0.5rem", sm: "1rem" },
    width: { xs: "95%", sm: "47.2%" }, // Is 47.2 percent to account for the padding and the borderRight
    display: { xs: "block", sm: "inline-table" },
    "&:not(:last-child)": {
      borderRight: { xs: "none", sm: `1px solid ${colors.gray11}` },
      borderBottom: { xs: `1px solid ${colors.gray11}`, sm: "none" },
      paddingBottom: { xs: "2rem", sm: "none" },
    },
  },
} as const;
