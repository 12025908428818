import React from "react";
import { useLazyQuery } from "@apollo/client";

import { LCP, AffectedArea } from "../../ServiceDisruptionTypes";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import AddAffectedAreaDialog from "./AddAffectedAreaDialog";
import { QUERIES } from "../../ServiceDisruptionQueries";
import { Markets } from "../../__generated__/Markets";
import { LCPs } from "../../__generated__/LCPs";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  save: (selectedLCPs: LCP[]) => Promise<void>;
  initialAffectedAreas?: AffectedArea[];
  loading?: boolean;
};

const AddAffectedAreaDialogProvider: React.FC<Props> = ({
  isOpen,
  onClose,
  save,
  loading,
  initialAffectedAreas,
}: Props) => {
  const [
    getMarkets,
    { data: marketData, loading: getMarketsIsLoading },
  ] = useLazyQuery<Markets>(QUERIES.getMarkets);
  const [getLCPs, { data: lcpData, loading: getLCPsIsLoading }] = useLazyQuery<
    LCPs
  >(QUERIES.getLCPs);

  return (
    <>
      <LoadingBackdrop isOpen={getMarketsIsLoading || getLCPsIsLoading} />
      <AddAffectedAreaDialog
        onClose={onClose}
        isOpen={isOpen}
        save={save}
        loading={loading}
        initialAffectedAreas={initialAffectedAreas}
        getMarkets={getMarkets}
        getLCPs={getLCPs}
        markets={marketData?.markets || []}
        lcps={lcpData?.lcps || []}
      />
    </>
  );
};

export default AddAffectedAreaDialogProvider;
