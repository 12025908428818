import { colors } from "styles/colors";

export default {
  title: {
    marginBottom: "1em",
    color: colors.brandBlue,
  },
  subTitle: {
    color: colors.gray8,
  },
  section: {
    marginBottom: "3em",
  },
  group: {
    marginBottom: "7em",
  },
};
