import React from "react";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";

import styles from "./styles";

type Props = {
  classes: { inputContainer: string; input: string };
  setFormField: (
    name: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  formFields: {
    username: string;
    password: string;
  };
};

const UserFields: React.FC<Props> = ({
  classes,
  setFormField,
  formFields,
}: Props) => {
  return (
    <div className={classes.inputContainer}>
      <TextField
        classes={{ root: classes.input }}
        value={formFields.username}
        onChange={setFormField("username")}
        label="Username"
        variant="outlined"
      />
      <TextField
        classes={{ root: classes.input }}
        value={formFields.password}
        onChange={setFormField("password")}
        label="Password"
        type="password"
        variant="outlined"
      />
    </div>
  );
};

export default withStyles(styles)(UserFields);
