import React, { ChangeEvent, useState } from "react";
import { IconButton, InputAdornment, TextField, Icon } from "@mui/material";

export interface Props {
  /**
   * If `click` then onSearch is only fired when the icon button is clicked.
   * If `change` then onSearch is only fired when the change event happens.
   * Defaults to `click`.
   */
  searchOn?: "change" | "click";
  /**
   * Sets the minimum length that the search string needs to be before the
   * onSearch event is fired when the searchOn property is set to `change`.
   * Defaults to 0 length.
   */
  minOnChangeLength?: number;
  useFullWidth?: boolean;
  variant?: "filled" | "outlined" | "standard" | undefined;
  label?: string;
  className?: string;
  hasError?: boolean;
  helperText?: string;
  /**
   * Limits the search input to only characters matching the provided regex
   * expression.
   */
  regex?: string;
  /**
   * Fires search event based on either internal onclick or onchange events.
   */
  onSearch: (search: string) => void;
}

const SearchBox: React.FC<Props> = ({
  searchOn,
  minOnChangeLength = 0,
  useFullWidth,
  variant = "outlined",
  className,
  regex,
  onSearch,
  helperText,
  hasError,
  label = "Search",
}: Props) => {
  const [search, setSearch] = useState<string>("");

  const handleOnClick = () => {
    onSearch(search);
  };

  const handleOnChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (typeof regex !== "undefined" && regex !== null) {
      if (value === "" || RegExp(regex).test(value.trim())) {
        setSearch(value.trim());
      }
    } else {
      setSearch(value);
    }

    if (searchOn === "change") {
      if (value.length >= minOnChangeLength || value === "") {
        onSearch(value);
      }
    }
  };

  return (
    <TextField
      className={className}
      fullWidth={useFullWidth}
      label={label}
      value={search}
      variant={variant}
      onChange={handleOnChange}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleOnClick();
        }
      }}
      error={hasError}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleOnClick} color="secondary">
              <Icon>search</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
