import { Box, Icon, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import BillingModelCardList from "sections/billing-model-common/components/BillingModelCardList";
import RatePlanRcdGroupForm from "sections/billing-model-rateplans/components/RatePlanRcdGroupForm";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "sections/billing-model-rateplans/BillingModelRatePlanQueries";
import RatePlanForm from "./RatePlanForm";
import AppFab from "sections/_global/components/AppFab";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import { validateRatePlan } from "../../validation";
import { BillingModelRatePlanNameExists } from "sections/billing-model-rateplans/__generated__/BillingModelRatePlanNameExists";
import RouteExitGuard from "sections/billing-model-common/components/RouteExitGuard";
import {
  BillingModelSearch,
  filterBySearch,
} from "sections/billing-model-common/BillingModelSearch";
import {
  RatePlan,
  RcdGroup,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { colors } from "styles/colors";
import { useRatePlanDefaultValues } from "sections/billing-model-rateplans/hooks/useRatePlanDefaultValues";
import useRatePlanTypeSelectQuery from "sections/billing-model-rateplans/hooks/useRatePlanTypeSelectQuery";
import usePeriodTypeSelectQuery from "sections/billing-model-rateplans/hooks/usePeriodTypeSelectQuery";
import useTransactionTypeSelectQuery from "sections/billing-model-rateplans/hooks/useTransactionTypeSelectQuery";
import { useHistory } from "react-router-dom";
import useRatePlanTypeDetailsQuery from "sections/billing-model-rateplans/hooks/useRatePlanTypeDetailsQuery";

export interface Props {
  subtitle?: string;
  ratePlan: RatePlan;
  onSubmit: (ratePlan: RatePlan) => Promise<boolean>;
}

interface RatePlanFormState {
  haveChanges: boolean;
  originalRatePlan: RatePlan;
  currentRatePlan: RatePlan;
  rcdGroupSearch: BillingModelSearch;
  nameExists?: boolean;
  errors: FormError;
}

const checkName = (nameExists: boolean, errors: FormError) => {
  const errs = { ...errors };
  errs["name"] = nameExists
    ? "A rate plan with that name already exists."
    : errs["name"];

  return errs;
};

const RatePlanFormProvider: React.FC<Props> = ({
  subtitle,
  ratePlan,
  onSubmit,
}: Props) => {
  const history = useHistory();
  const defaultValues = useRatePlanDefaultValues();
  const dispatch = useMessageDispatch();
  const [state, setState] = useState<RatePlanFormState>({
    haveChanges: false,
    originalRatePlan: ratePlan,
    currentRatePlan: ratePlan,
    rcdGroupSearch: {
      terms: "",
      sort: "Active",
      filter: "",
      showExpired: false,
    },
    nameExists: undefined,
    errors: {},
  });

  const [refetch, { loading: checkingName }] = useLazyQuery<
    BillingModelRatePlanNameExists
  >(QUERIES.getRatePlanNameExists, {
    onCompleted: (response) => {
      const errs = checkName(response.bamRatePlanNameExists, state.errors);
      setState({
        ...state,
        errors: errs,
        nameExists: response.bamRatePlanNameExists,
      });
    },
  });

  const searchSortRcdGroups = useCallback(
    (rcdGroups: RcdGroup[], search: BillingModelSearch) => {
      return rcdGroups
        .filter((rg) => {
          const filterCheck =
            search.filter === "" || rg.class === search.filter;
          const expiredCheck = rg.active || (!rg.active && search.showExpired);
          return (
            expiredCheck &&
            filterCheck &&
            filterBySearch(
              search,
              rg.ratingControlGroupId,
              rg.ratingControlGroupType?.name
            )
          );
        })
        .sort((a: RcdGroup, b: RcdGroup) => {
          if (search.sort === "Active") {
            if (a.active && !b.active) return -1;
            if (b.active && !a.active) return 1;
          }

          const aName = a.ratingControlGroupType?.name ?? "";
          const bName = b.ratingControlGroupType?.name ?? "";
          return aName.localeCompare(bName);
        });
    },
    []
  );

  const filteredRcdGroups = useMemo(
    () =>
      searchSortRcdGroups(
        state.currentRatePlan.ratingControlGroups ?? [],
        state.rcdGroupSearch
      ),
    [
      state.currentRatePlan.ratingControlGroups,
      state.rcdGroupSearch,
      searchSortRcdGroups,
    ]
  );

  const handleRcdGroupSearch = useCallback(
    (s: BillingModelSearch) =>
      setState((old) => ({ ...old, rcdGroupSearch: s })),
    []
  );

  const isRatePlanChanged = (update: RatePlan): boolean => {
    return JSON.stringify(state.originalRatePlan) !== JSON.stringify(update);
  };

  const handleRatePlanChange = async (update: RatePlan, errors: FormError) => {
    setState({
      ...state,
      currentRatePlan: update,
      haveChanges: isRatePlanChanged(update),
      errors: errors,
    });
  };

  const handleRcdGroupChange = (group: RcdGroup, groupErrors: FormError) => {
    const update = {
      ...state.currentRatePlan,
      ratingControlGroups: [...state.currentRatePlan.ratingControlGroups],
    };

    const index = update.ratingControlGroups?.findIndex(
      (r: RcdGroup) => r.ratingControlGroupId === group.ratingControlGroupId
    );

    if (index !== undefined && index > -1 && update.ratingControlGroups) {
      update.ratingControlGroups.splice(index, 1, group);

      setState({
        ...state,
        currentRatePlan: update,
        haveChanges: isRatePlanChanged(update),
        errors: { ...groupErrors },
      });
    }
  };

  const handleSave = async () => {
    const validationResults = validateRatePlan(
      state.currentRatePlan,
      defaultValues.ratePlanAdmin
    );

    const errs = checkName(state.nameExists ?? false, validationResults[1]);
    const invalid = Object.values(errs).some((a) => a != null);
    setState({ ...state, errors: errs });

    if (state.nameExists && ratePlan.status !== "new") {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description:
            "Multiple rate plans with this name exist. Please contact an admin.",
          type: "error",
          isOpen: true,
          id: "invalid-form-msg",
        },
      });
    } else if (invalid) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "Please fix the errors before saving.",
          type: "error",
          isOpen: true,
          id: "invalid-form-msg",
        },
      });
    } else if (onSubmit !== undefined && state.haveChanges) {
      dispatch({ type: "CLEAR_MESSAGES" });
      const success = await onSubmit(state.currentRatePlan);
      if (success) {
        setState({ ...state, haveChanges: false });
        history.push("/billing-model/rates/list");
      }
    } else {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "No changes to save.",
          type: "warning",
          isOpen: true,
          id: "no-changes-msg",
        },
      });
    }
  };

  return (
    <>
      <RouteExitGuard showPrompt={state.haveChanges} />

      <Box sx={{ padding: "0.5em" }}>
        <RatePlanForm
          originalRatePlan={state.originalRatePlan}
          ratePlan={state.currentRatePlan}
          subtitle={subtitle}
          isUserAdmin={defaultValues.ratePlanAdmin}
          checkingName={checkingName}
          nameInUse={state.nameExists}
          errors={state.errors}
          useRatePlanTypeDetailsQuery={useRatePlanTypeDetailsQuery}
          useRatePlanTypeSelectQuery={useRatePlanTypeSelectQuery}
          onChange={handleRatePlanChange}
          onCheckName={(name) =>
            refetch({
              variables: { name: name, editing: ratePlan.status !== "new" },
            })
          }
        />

        <Box marginTop="1em" marginBottom="1em">
          <Typography variant="h5">RCD Groups</Typography>
        </Box>
        <Box sx={{ padding: "1em", backgroundColor: colors.gray12 }}>
          <BillingModelCardList
            onSearch={handleRcdGroupSearch}
            sort="Active"
            sortOptions={["Active", "Name"]}
            filter=""
            filterLabel="Group Class"
            filterOptions={["00", "01", "02", "04", "40", "50", "52", "99"]}
            checked={state.currentRatePlan.ratePlanId ? false : undefined}
          >
            <div className="rcd-group-list">
              {filteredRcdGroups.length === 0 && (
                <Box display="flex" justifyContent="center" width="100%">
                  <Typography variant="h6">No RCD Groups found.</Typography>
                </Box>
              )}
              {filteredRcdGroups.map((rcdGroup, key) => (
                <RatePlanRcdGroupForm
                  key={key}
                  rcdGroup={rcdGroup}
                  isUserAdmin={defaultValues.ratePlanAdmin}
                  errors={state.errors}
                  usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
                  useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
                  onChange={handleRcdGroupChange}
                />
              ))}
            </div>
          </BillingModelCardList>
        </Box>

        {defaultValues.canWriteRatePlans && (
          <AppFab accessibilityLabel="Save" onClick={handleSave}>
            <Icon>save</Icon>
          </AppFab>
        )}
      </Box>
    </>
  );
};

export default RatePlanFormProvider;
