import firebase from "firebase/app";
import "firebase/auth";
import { gql } from "@apollo/client";

import client from "../../../apolloClient";
import { Dispatch } from "./UserContext";

const queryUserPermissions = async () => {
  const { data } = await client.query({
    query: gql`
      query GetUserPermissions {
        user {
          permissions {
            name
            action
          }
        }
      }
    `,
  });
  return data?.user;
};

const parseFirebaseClaims = (claims: Record<string, any>) => {
  const attributes = claims.firebase.sign_in_attributes;
  // Apollo reactive variables cannot have undefined attributes
  const username = attributes.UID || null;
  const displayName =
    attributes["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] ||
    null;
  const email =
    attributes["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn"] ||
    null;

  return { username, displayName, email };
};

export const registerOnFirebaseAuthStateChanged = (
  dispatch: Dispatch
): void => {
  const onFirebaseAuthStateChanged = async (user: firebase.User | null) => {
    if (user) {
      const result = await user.getIdTokenResult(true);

      const { username, displayName, email } = parseFirebaseClaims(
        result.claims
      );

      const clientUser = await queryUserPermissions();

      dispatch({
        type: "SET_USER_FIELDS",
        user: {
          isAuthenticated: true,
          needsAuthVerification: false,
          needsReauthentication: false,
          permissions: clientUser.permissions || [],
          username,
          displayName,
          email,
        },
      });
    } else {
      dispatch({
        type: "SET_USER_FIELDS",
        user: {
          isAuthenticated: false,
          needsAuthVerification: false,
          needsReauthentication: true,
        },
      });
    }
  };

  firebase.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
};
