import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import styles from "./styles";

const useStyles = makeStyles(styles);

const LoadingIndicator = (): JSX.Element => (
  <>
    <LoadingDot delay={0} />
    <LoadingDot delay={160} offset />
    <LoadingDot delay={320} offset />
  </>
);

type LoadingDotProps = {
  delay: number;
  offset?: boolean;
};

const LoadingDot = ({ delay, offset = false }: LoadingDotProps) => {
  const animationStyle = {
    "@keyframes loadingDot": {
      "0%": { opacity: 0 },
      "80%": { opacity: 0 },
      "100%": { opacity: 0 },
      "40%": { opacity: 1 },
    },
    animation: {
      animation: `$loadingDot 1s ease-in-out ${delay}ms infinite;`,
    },
  };
  const useLoadingStyles = makeStyles(animationStyle);
  const localClasses = useLoadingStyles();
  const classes = useStyles();

  return (
    <span
      className={classnames(classes.dot, localClasses.animation)}
      style={{
        marginLeft: offset ? "0.4em" : undefined,
      }}
    />
  );
};

export default LoadingIndicator;
