export default {
  root: {},
  noResults: {
    marginTop: "5em",
  },
  icon: {
    width: "8em",
    marginBottom: "1em",
  },
};
