/**
 * Regex used for various validations
 */

const anyValidation = /.*/;

const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const latitudeValidation = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

const longitudeValidation = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

const numberValidation = /^[0-9]*$/;

const phoneValidation = /[\d]{10}/;

const postalCodeValidation = /^\d{5}(?:[-]\d{4})?$/;

const stateAbbrevValidation = /^[a-zA-Z]{2}$/;

export {
  anyValidation,
  emailValidation,
  latitudeValidation,
  longitudeValidation,
  numberValidation,
  phoneValidation,
  postalCodeValidation,
  stateAbbrevValidation,
};
