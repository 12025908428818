import { colors } from "styles/colors";

export default {
  configBar: {
    background: colors.gray2,
    height: "2em",
    display: "flex",
    alignItems: "center",
  },
  configBarFields: {
    display: "flex",
    flex: 1,
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  configBarText: {
    "&:first-child": {
      marginLeft: "0.5em",
    },
    marginRight: "0.5em",
    fontSize: "0.8em",
    color: colors.white,
  },
  configValueName: {
    color: colors.gray10,
  },
  configBarMore: {
    color: colors.white,
    height: "100%",
  },
  configBarProd: {
    background: colors.errorRed,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column" as const,
  },
  button: {
    fontWeight: "bold" as const,
  },
  dialogInput: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
};
