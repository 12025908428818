import React, { useCallback, useState } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";

import { Grid } from "@mui/material";
import {
  DispatchType,
  ResourceOption,
  ResourceType,
} from "../../screens/types";
import Typeahead, {
  TypeaheadOption,
} from "sections/_global/components/Typeahead";
import ResourceTypeSelection from "../ResourceTypeSelection/ResourceTypeSelection";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ofscResources,
  ofscResourcesVariables,
} from "sections/scheduling-tools/__generated__/ofscResources";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { buckets } from "sections/scheduling-tools/__generated__/buckets";

export type Props = {
  required?: boolean;
  dispatchType: DispatchType;
  setResourceId: (val: string | null) => void;
};

const ResourceForm: React.FC<Props> = ({
  required,
  setResourceId,
  dispatchType,
}: Props) => {
  const defaultResourceType: {
    dispatchType: DispatchType;
    resourceType: ResourceType;
  }[] = [
    {
      dispatchType: DispatchType.SPLICING,
      resourceType: ResourceType.BUCKET,
    },
    {
      dispatchType: DispatchType.NOC,
      resourceType: ResourceType.TECHNICIAN,
    },
  ];

  const [resource, setResource] = useState<TypeaheadOption | string | null>(
    null
  );

  const [resourceType, setResourceType] = React.useState<ResourceType>(
    defaultResourceType.find((type) => type.dispatchType === dispatchType)
      ?.resourceType ?? ResourceType.TECHNICIAN
  );

  const [techOptions, setTechOptions] = React.useState<ResourceOption[]>([]);
  const [bucketOptions, setBucketOptions] = React.useState<ResourceOption[]>(
    []
  );

  const [queryTechnicians, { loading: techniciansLoading }] = useLazyQuery<
    ofscResources,
    ofscResourcesVariables
  >(QUERIES.getOfscResources, {
    onCompleted(data) {
      const technicians: ResourceOption[] = data.ofscResources
        .filter((resource) => resource.resourceId !== null)
        .map((resource) => ({
          resourceId: resource.resourceId ?? "",
          name: resource.name ?? "",
        }))
        .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
      setTechOptions(technicians);
    },
  });

  const { loading: bucketsLoading } = useQuery<buckets>(QUERIES.getBuckets, {
    variables: { bucketType: "osp_bucket" },
    onCompleted(data) {
      const newBuckets: ResourceOption[] = data.buckets
        .map((bucket) => ({
          resourceId: bucket.resourceId ?? "",
          name: bucket.name ?? "",
        }))
        .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
      setBucketOptions(newBuckets);
    },
  });

  React.useEffect(() => {
    if (dispatchType === DispatchType.SPLICING) {
      queryTechnicians({
        variables: {
          status: "active" as ofscResourcesVariables["status"],
          useTableCache: true,
          resourceType: "SPLICING_TECH" as ofscResourcesVariables["resourceType"],
        },
      });
    }
    if (dispatchType === DispatchType.FIELD_OPS) {
      queryTechnicians({
        variables: {
          status: "active" as ofscResourcesVariables["status"],
          useTableCache: true,
          resourceType: "FIELD_TECH" as ofscResourcesVariables["resourceType"],
        },
      });
    }
    if (dispatchType === DispatchType.NOC) {
      queryTechnicians({
        variables: {
          status: "active" as ofscResourcesVariables["status"],
          useTableCache: true,
          resourceType: "FIELD_TECH" as ofscResourcesVariables["resourceType"],
        },
      });
    }
  }, [dispatchType, queryTechnicians]);

  //Clear the resource whenever switching between types
  React.useEffect(() => {
    setResource(null);
  }, [resourceType]);

  //Update resourceId in parent
  React.useEffect(() => {
    if (typeof resource !== "string" && typeof resource !== null) {
      setResourceId(resource?.value);
    } else {
      setResourceId(null);
    }
  }, [resource, setResourceId]);

  const isOptionEqualToValue = useCallback(
    (option: TypeaheadOption, value: TypeaheadOption) => {
      if (!value || !option) {
        return false;
      }
      return option.value === value.value;
    },
    []
  );

  const resourcesLoading: boolean =
    (resourceType === ResourceType.BUCKET && bucketsLoading) ||
    (resourceType === ResourceType.TECHNICIAN && techniciansLoading);

  return (
    <>
      <Grid item md={4} xs={12} style={{ textAlign: "center" }}>
        <ResourceTypeSelection
          label={`${dispatchType} Resource`}
          dispatchType={dispatchType}
          setResourceType={setResourceType}
          clearResource={() => {
            setResource(null);
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Typeahead
          label={resourceType.toString()}
          options={
            resourceType === ResourceType.BUCKET
              ? bucketOptions?.length
                ? bucketOptions.map((option) => ({
                    label: option.name ?? "",
                    value: option.resourceId ?? "",
                  }))
                : []
              : techOptions.map((option) => ({
                  label: option.name ?? "",
                  value: option.resourceId ?? "",
                }))
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          value={resource}
          skipAutoSelect
          onChange={(value) => setResource(value)}
          required={required}
          isDisabled={resourcesLoading}
          isLoading={resourcesLoading}
        />
      </Grid>
    </>
  );
};

export default withStyles(styles)(ResourceForm);
