import React from "react";
import { Fab, Slide } from "@mui/material";

export type Props = {
  children: React.ReactElement;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Aria label for accessibility.
   */
  accessibilityLabel: string;
};

const AppFab: React.FC<Props> = ({
  children,
  onClick,
  accessibilityLabel,
}: Props) => (
  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
    <Fab onClick={onClick} aria-label={accessibilityLabel}>
      {children}
    </Fab>
  </Slide>
);

export default AppFab;
