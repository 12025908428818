import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";

type Props = {
  sendExternalNotification: boolean;
  sendInternalNotification: boolean;
  toggleSendExternalNotification: () => void;
  toggleSendInternalNotification: () => void;
  onClose: () => void;
  onSend: () => void;
  id: string;
};

const NotificationForm: React.FC<Props> = ({
  sendExternalNotification,
  sendInternalNotification,
  toggleSendExternalNotification,
  toggleSendInternalNotification,
  onClose,
  onSend,
  id,
}: Props) => {
  return (
    <>
      <DialogTitle id={id}>Send Notification</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              value={sendExternalNotification}
              onChange={() => toggleSendExternalNotification()}
            />
          }
          label="Send External Notification"
          color="secondary"
        />
        <br />
        <FormControlLabel
          control={
            <Switch
              value={sendInternalNotification}
              onChange={() => toggleSendInternalNotification()}
            />
          }
          label="Send Internal Notification"
          color="secondary"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          disabled={!sendInternalNotification && !sendExternalNotification}
          onClick={onSend}
          variant="text"
        >
          Send
        </Button>
      </DialogActions>
    </>
  );
};

export default NotificationForm;
