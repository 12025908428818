import React from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Link, LinkProps } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};

type growDelayArgs = {
  componentNumber: number;
  milliseconds: number;
  maxNumberOfComponents: number;
};
export const growDelay = ({
  componentNumber,
  milliseconds,
  maxNumberOfComponents,
}: growDelayArgs): number => {
  return componentNumber < maxNumberOfComponents
    ? (componentNumber + 1) * milliseconds
    : maxNumberOfComponents * milliseconds;
};

export const formatDate = (date: Date, dateFormat: string): string =>
  format(utcToZonedTime(date, "America/Chicago"), dateFormat);

export const getInitials = (name?: string | null): string => {
  if (!name) return "";

  const nameParts = name.split(" ");

  if (nameParts.length > 1) {
    const first = nameParts[0].substring(0, 1);
    const end = nameParts[1].substring(0, 1);
    return `${first}${end}`;
  } else {
    return name.substring(0, 1);
  }
};

export const linkComponent = ({
  url,
}: {
  url: string;
}): React.ForwardRefExoticComponent<Omit<LinkProps, "to">> =>
  // eslint-disable-next-line react/display-name
  React.forwardRef<any, Omit<LinkProps, "to">>((props, ref) => (
    <Link ref={ref} to={url} {...props} />
  ));
