import React, { useState, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@mui/material/styles";

import { UserProvider } from "./sections/_global/contexts/UserContext";
import { MessageProvider } from "./sections/_global/contexts/MessageContext";
import apolloClient from "./apolloClient";
import Header from "./layout/Header";
import Main from "./layout/Main";
import theme from "styles/theme";

const App: React.FC = () => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!isDrawerOpen);
  }, [setDrawerOpen, isDrawerOpen]);
  const title = "MetroNet Console";

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <MessageProvider>
          <UserProvider>
            <Router>
              <div>
                <Header toggleDrawer={toggleDrawer} title={title} />
                <Main toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
              </div>
            </Router>
          </UserProvider>
        </MessageProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
