import React from "react";
import { Grid, Icon, Tooltip, Typography } from "@mui/material";
export interface Props {
  title: string;
  value?: string | Date | null;
  missingDateText?: string;
  isVisible?: boolean;
}

const BillingModelDateDisplay = ({
  title,
  value,
  missingDateText,
  isVisible = true,
}: Props): JSX.Element => {
  if (!isVisible || (!value && !missingDateText)) return <></>;

  if (value) {
    const dateVal = new Date(value);
    return (
      <Grid container column-spacing={2}>
        <Grid item xs={9}>
          <Typography
            sx={{ verticalAlign: "middle" }}
            variant="body1"
          >{`${title}: ${dateVal.toLocaleDateString("en-US")}`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            sx={{ marginLeft: "0.5rem", marginBottom: "0.1rem" }}
            title={dateVal.toString()}
          >
            <Icon>info</Icon>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container column-spacing={2}>
        <Grid item xs={9}>
          <Typography
            sx={{ verticalAlign: "middle" }}
            variant="body1"
          >{`${title}: ${missingDateText}`}</Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    );
  }
};

export default BillingModelDateDisplay;
