import React from "react";

import LinkCards from "../LinkCards";
import { useUserState } from "../../../contexts/UserContext";
import { userCan } from "../../../../../lib/Authorization";
import { LinkType } from "routes/routeDefinitions";

type Card = {
  title: string;
  icon?: React.ReactElement | string;
  url: string;
  active?: boolean;
  rbacAction?: string;
  linkType: LinkType;
  titleProps?: {
    fontSize?: string;
  };
};

export type Props = {
  cards: Card[];
};

const LinkCardsProvider: React.FC<Props> = ({ cards }: Props) => {
  const user = useUserState();

  const filteredCards = cards.filter((card) => {
    const action = card.rbacAction;
    if (!action) return true;

    return user && userCan({ user, performAction: action });
  });

  return <LinkCards cards={filteredCards} />;
};

export default LinkCardsProvider;
