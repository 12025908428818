import React, { FC, useState, useEffect, useContext } from "react";
import { Button, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";

import IMAppHeader from "sections/inventory-manager/components/IMAppHeader";
import TechnicianForm from "sections/inventory-manager/components/TechnicianSelect";
import SerialNumberForm from "sections/inventory-manager/components/SerialNumberForm";
import { TypeaheadOption } from "sections/_global/components/Typeahead";
import { useLazyQuery, gql } from "@apollo/client";
import BackButton from "sections/_global/components/BackButton";

import { prepareData } from "./assignUtils";
import { AssignContext } from "sections/_global/contexts/AssignContext";

import { imRoutes } from "sections/inventory-manager/enums";
import styles from "./styles";

const useStyles = makeStyles(styles);

export const GET_EQUIPMENT_INFO = gql`
  query EquipmentInfo($serialNumber: String!) {
    equipmentInfo(serialNumber: $serialNumber) {
      inventoryId
      serialNumber
      category
      partNumber
      subInventory
      location
      resourceID
      techName
      marketID
      market
      sourceType
      currentStatus
      isAssociatedToSubscriber
      srvcIds
    }
  }
`;

const Assign: FC = () => {
  const classes = useStyles();

  const [equipmentInfo, { data, loading, error }] = useLazyQuery(
    GET_EQUIPMENT_INFO
  );

  const [currTech, setCurrTech] = useState<TypeaheadOption | null | string>(
    null
  );

  const useAssignContext = useContext(AssignContext);
  const {
    state,
    dispatch,
    newEquipment,
    setNewEquipment,
    flattenItemListCallback,
  } = useAssignContext ?? {};

  useEffect(() => {
    if (!newEquipment) return;

    const itemList = prepareData([
      ...flattenItemListCallback(),
      {
        ...newEquipment,
        isAvailable: true,
      },
    ]);

    const pendingList = state?.pendingList.map((item: any) => {
      const itemClone = { ...item };
      if (item.serialNumber === newEquipment.serialNumber) {
        itemClone.show = false;
      }
      return itemClone;
    });

    dispatch({
      ...state,
      ...{
        currSubId: newEquipment.subInventory,
        currSourceID: newEquipment.marketID || newEquipment.resourceID,
        updatedSubscriberId: newEquipment.subInventory,
        currSubscriber: newEquipment.subscriber,
        currSubEmail: newEquipment.email,
        resourceID: newEquipment.resourceID,
        techName: newEquipment.techName,
        marketID: newEquipment.marketID,
        market: newEquipment.market,
        sourceType: newEquipment.sourceType,
        itemList,
        pendingList,
      },
    });

    setNewEquipment(null);
  }, [
    flattenItemListCallback,
    newEquipment,
    state?.pendingList,
    state,
    dispatch,
    setNewEquipment,
  ]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const _getSerial: (serialNumber: string) => void = (serialNumber) => {
    console.log("getSerial: ", serialNumber);
    equipmentInfo({ variables: { serialNumber } });
  };

  const disabled = true;
  const icon = imRoutes.find((v) => v.name.toUpperCase() === "ASSIGN")?.icon;

  return (
    <div>
      <BackButton url="/inventory-manager" />
      <Fade in={true} timeout={500}>
        <div className={classes.root}>
          <IMAppHeader
            formName={"Find Equipment By Serial Number"}
            icon={icon}
          />
          <h3>{data?.serialNumber}</h3>
          <form onSubmit={onSubmit}>
            <SerialNumberForm
              getSerial={_getSerial}
              loading={loading}
              error={error}
            />
            <TechnicianForm onChange={setCurrTech} currTech={currTech} />
            <Button
              sx={{ margin: "auto", display: "block", fontWeight: 400 }}
              type="submit"
              disabled={disabled}
              size="large"
              color="primary"
              variant="text"
            >
              Submit
            </Button>
          </form>
        </div>
      </Fade>
    </div>
  );
};

export default Assign;
