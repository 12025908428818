import createStyles from "@mui/styles/createStyles";

const styles = createStyles({
  name: {
    textAlign: "left",
  },
  flashlightIcon: {
    color: "#e6e60d",
  },
});

export default styles;
