import React, { FC } from "react";
import { Grid } from "@mui/material";
import AddButton from "sections/communication-preference/components/buttons/AddButton/AddButton";
import ContactInput from "../../../components/ContactInput/ContactInput";
import { useContact } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import componentStyles from "./styles";

type Props = {
  screenIsSmall: boolean;
};

const ContactInputContainer: FC<Props> = ({ screenIsSmall }: Props) => {
  const [{ contacts }, contactDispatch] = useContact();

  /**
   * Function which generates a random key for this input element
   */
  const generateRandomKey = () => {
    let randomKey = Math.floor(Math.random() * 100);
    while (contacts.find((contact) => contact.key === `${randomKey}`))
      randomKey = Math.floor(Math.random() * 100);
    contactDispatch({
      type: "ADD_CONTACT",
      contact: {
        type: 1,
        value: "",
        key: `${randomKey}`,
        subPreferences: [],
        channelNames: [],
        isBeingEdited: false,
      },
    });
  };

  return (
    <Grid container item spacing={screenIsSmall ? 5 : 2}>
      {contacts.map((contact) => (
        <ContactInput
          screenIsSmall={screenIsSmall}
          contact={contact}
          key={contact.key}
          oneContact={contacts.length === 1}
        />
      ))}
      <Grid sx={componentStyles.add} xs={12} item>
        <AddButton onClick={generateRandomKey}>Add more</AddButton>
      </Grid>
    </Grid>
  );
};

export default ContactInputContainer;
