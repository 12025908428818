import { dataTypeEnum } from "sections/inventory-manager/enums";

export const responsivePanelColumns = {
  xs: ["partnumber", "subinventory", "location"],
  sm: ["partnumber", "subinventory"],
  md: ["partnumber"],
};

export const animationStyles = {
  enterLeft: {
    enter: { opacity: 1, transform: "translateX(0%)" },
    from: { opacity: 0, transform: "translateX(-100%)" },
    leave: { opacity: 0 },
  },
  enterRight: {
    enter: { opacity: 1, transform: "translateX(0%)" },
    from: { opacity: 0, transform: "translateX(100%)" },
    leave: { opacity: 0 },
  },
  enterTop: {
    enter: { opacity: 1, transform: "translateY(0%)" },
    from: { opacity: 0, transform: "translateY(-100%)" },
    leave: { opacity: 0 },
  },
  enterBottom: {
    enter: { opacity: 1, transform: "translateY(0%)" },
    from: { opacity: 0, transform: "translateY(100%)" },
    leave: { opacity: 0 },
  },
  enterFade: {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
  },
  enterHeight: {
    enter: { opacity: 1, transform: "scaleY(100%)" },
    from: { opacity: 0, transform: "scaleY(0%)" },
    leave: { opacity: 0, transform: "scaleY(0%)" },
  },
  leaveFade: {
    leave: { opacity: 0 },
  },
  leaveTop: {
    leave: { opacity: 0, transform: "translateY(-100%)" },
  },
  leaveRight: {
    leave: { opacity: 0, transform: "translateX(100%)" },
  },
  leaveHeight: {
    leave: { opacity: 0, transform: "scaleY(0%)" },
  },
};

export const getFields = (appName: string): any => {
  if (!appName) return null;
  const appNameUpper = appName.toUpperCase();
  const isNotAssignBatchApp =
    appNameUpper !== "ASSIGN" && appNameUpper !== "BATCH";
  return {
    group: ["category"],
    // We can still require a field we're hiding
    // For e.g. "category" required for grouping
    // yet not shown at the item level
    item: [
      //NO MORE THAN 2 OBJECTS WITH THE SAME GROUP NUMBER!
      //WILL BREAK OTHERWISE!
      { name: "serialNumber", display: true },
      { name: "macAddr", display: false },
      { name: "subId", display: false },
      { name: "subscriber", display: false },
      { name: "category", display: false },
      { name: "subInventory", display: true },
      { name: "location", display: true },
      { name: "currentStatus", display: false },
      { name: "isAvailable", display: false },
      { name: "watermark", display: false },
      { name: "warningMessage", display: false },
      { name: "isAssociatedToSubscriber", display: false },
      { name: "messages", display: false },
      { name: "partNumber", display: isNotAssignBatchApp },
      {
        name: "charges",
        label: "chrgs",
        display: isNotAssignBatchApp,
        group: 1,
        type: dataTypeEnum.CURRENCY,
      },
      {
        name: "credit",
        label: "crdt",
        display: isNotAssignBatchApp,
        group: 1,
        type: dataTypeEnum.CURRENCY,
      },
      {
        name: "remote",
        label: "remote?",
        display: isNotAssignBatchApp,
        group: 2,
        type: dataTypeEnum.CHECKBOX,
      },
      {
        name: "powerCord",
        label: "pcord?",
        display: isNotAssignBatchApp,
        group: 2,
        type: dataTypeEnum.CHECKBOX,
      },
    ],
  };
};
