import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Icon } from "@mui/material";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

import { navList } from "./navListDefinitions";
import NavMenuItem from "./NavMenuItem";
import styles from "./styles";

type Props = {
  classes: {
    expansionPanel: string;
    expansionPanelDetails: string;
    list: string;
    root: string;
    content: string;
    expanded: string;
    titleStyle: string;
  };
  items?: navList[];
  title: string;
  toggleDrawer: () => void;
  isActive: boolean;
};

const CollapsibleMenuItem: React.FC<Props> = ({
  title,
  items,
  toggleDrawer,
  classes,
  isActive,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  function getLastValue(input: Array<string>) {
    return input[input.length - 1];
  }

  useEffect(() => {
    if (isActive) setIsExpanded(true);
  }, [isActive]);

  return (
    <Accordion
      square
      expanded={isExpanded}
      onClick={onClick}
      classes={{ root: classes.root, expanded: classes.expanded }}
    >
      <AccordionSummary
        expandIcon={<Icon>expand_more</Icon>}
        classes={{ root: classes.expansionPanel, content: classes.content }}
      >
        <Typography classes={{ root: classes.titleStyle }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
        <List classes={{ root: classes.list }}>
          {items &&
            items.map((item) => (
              <div key="menuDiv">
                <NavMenuItem
                  name={item.name}
                  onClick={toggleDrawer}
                  text={getLastValue(item.name.split(";"))}
                  url={item.url}
                  linkType={item.linkType}
                  key={item.name}
                />
              </div>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(CollapsibleMenuItem);
