import React from "react";
import { InputLabelProps, TextField } from "@mui/material";
import {
  DesktopTimePicker,
  MobileTimePicker,
  LocalizationProvider,
  TimePickerProps,
} from "@mui/lab";
import { isValid } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import DateAdapter from "@mui/lab/AdapterDateFns";

export type Props = Partial<TimePickerProps<Date>> & {
  /**
   * When set to `dialog`, opens the picker as a dialog.
   * When set to `inline`, opens the picker inline.
   */
  hasError?: boolean;
  helperText?: string;
  label?: string;
  value?: Date | null;
  variant: "dialog" | "inline";
  InputLabelProps?: InputLabelProps;
  onChange: (date: Date | null) => void;
};

const TimeTextField = ({
  hasError,
  helperText,
  label,
  value,
  variant,
  InputLabelProps,
  onChange,
  ...materialUIProps
}: Props): JSX.Element => {
  const inputId = `${uuidv4()}-date-textfield`;
  const combinedProps = InputLabelProps
    ? { ...InputLabelProps, htmlFor: inputId }
    : { htmlFor: inputId };

  const handleChange = (date: Date | null, value: string | undefined) => {
    const val = isValid(date) ? date : null;
    onChange(val);
  };

  const Picker =
    variant === "inline" ? (
      <DesktopTimePicker
        label={label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            id={inputId}
            InputLabelProps={combinedProps}
            error={hasError}
            helperText={helperText}
            {...params}
          />
        )}
        {...materialUIProps}
      />
    ) : (
      <MobileTimePicker
        label={label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            id={inputId}
            InputLabelProps={combinedProps}
            error={hasError}
            helperText={helperText}
            {...params}
          />
        )}
      />
    );

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      {Picker}
    </LocalizationProvider>
  );
};

export default TimeTextField;
