import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  BillingModelSearch,
  filterByChanges,
  filterBySearch,
  paginate,
  sortById,
} from "sections/billing-model-common/BillingModelSearch";
import BillingModelCardList from "sections/billing-model-common/components/BillingModelCardList";
import { RcdGroupType } from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { useRatePlanDefaultValues } from "sections/billing-model-rateplans/hooks/useRatePlanDefaultValues";
import PaginatedList from "sections/_global/components/PaginatedList";
import RcdGroupTypeView from "../RcdGroupTypeView";

export interface Props {
  isAddAllowed: boolean;
  isDeleteAllowed: boolean;
  rcdGroupTypes: RcdGroupType[];
  page?: number;
  totalPages?: number;
  search?: BillingModelSearch;
  pageSize?: number;
  onAddRcdGroupType: () => void;
  onRemoveRcdGroupType: (rgt: RcdGroupType) => void;
  onRestoreRcdGroupType: (rgt: RcdGroupType) => void;
}

const RcdGroupTypeList = ({
  rcdGroupTypes,
  page: initialPage = 1,
  totalPages: initialTotalPages = 1,
  search: initialSearch,
  pageSize = 10,
  isAddAllowed,
  isDeleteAllowed,
  onAddRcdGroupType,
  onRemoveRcdGroupType,
  onRestoreRcdGroupType,
}: Props): JSX.Element => {
  const defaultValues = useRatePlanDefaultValues();
  const [filtered, setFiltered] = useState<RcdGroupType[]>([]);
  const [page, setPage] = useState<number>(initialPage);
  const [totalPages, setTotalPages] = useState<number>(initialTotalPages);
  const [search, setSearch] = useState<BillingModelSearch>(
    initialSearch ?? {
      terms: "",
      sort: "Id",
    }
  );

  const handleSearch = useCallback((s: BillingModelSearch) => setSearch(s), []);
  const handlePageChange = (newPage: number) => setPage(newPage);

  useEffect(() => {
    if (rcdGroupTypes) {
      const filtered = rcdGroupTypes
        .filter(
          (rgt) =>
            filterBySearch(search, rgt.ratingControlGroupTypeId, rgt.name) &&
            filterByChanges(search, rgt.status)
        )
        .sort((a, b) =>
          search.sort === "Id"
            ? sortById(a.ratingControlGroupTypeId, b.ratingControlGroupTypeId)
            : a.name?.localeCompare(b.name ?? "") ?? 0
        );

      const [pageContent, pageCt] = paginate(page, pageSize, filtered);

      setFiltered(pageContent);
      setTotalPages(pageCt);
    }
  }, [page, pageSize, rcdGroupTypes, search]);

  const handleDeleteRestore = (rgt: RcdGroupType) => {
    if (rgt.status === "deleted") onRestoreRcdGroupType(rgt);
    else onRemoveRcdGroupType(rgt);
  };

  return (
    <>
      <Box marginTop="1em" marginBottom="1em">
        <Typography variant="h5">RCD Group Types</Typography>
      </Box>
      <BillingModelCardList
        filter={search.filter}
        filterOptions={["Changes"]}
        sort={search.sort}
        sortOptions={["Id", "Name"]}
        buttonLabel="Add RCD Group Type"
        isButtonDisabled={!defaultValues.canWriteRatePlanTypes || !isAddAllowed}
        onButtonClick={onAddRcdGroupType}
        onSearch={handleSearch}
      >
        <PaginatedList
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        >
          {filtered.length > 0 &&
            filtered.map((rgt, k) => (
              <RcdGroupTypeView
                key={k}
                isDeleteAllowed={
                  defaultValues.canWriteRatePlanTypes &&
                  (rgt.status === "new" || isDeleteAllowed)
                }
                rcdGroupType={rgt}
                onDeleteClick={handleDeleteRestore}
              />
            ))}
        </PaginatedList>
      </BillingModelCardList>
    </>
  );
};

export default RcdGroupTypeList;
