import React from "react";
import { Chip, Button, Typography, Icon } from "@mui/material";

import { GetOutage_outage_affectedAreas } from "../../__generated__/GetOutage";
import AppFab from "sections/_global/components/AppFab";
import AffectedAreaAccordions from "../AffectedAreaAccordions";
import { splitLCPs } from "../../data-model";
import { serviceTypeMapping } from "../../data-model";
import { formatDate } from "sections/_global/ui";

import useStyles from "./styles";

export type Props = {
  id: string;
  serviceType: string;
  serviceTypeName: string;
  startAt: Date;
  endAt: Date | null;
  affectedAreas: GetOutage_outage_affectedAreas[];
  description: string | null;
  totalCalls: number;
  totalCallbacks: number;
  openEditDialog: () => void;
  openNotifyDialog: () => void;
  openAffectedAreasDialog: () => void;
  affectedResidentialCustomers: number | null;
  affectedBusinessCustomers: number | null;
  internalNotificationHasBeenSent: boolean;
  externalNotificationHasBeenSent: boolean;
  isPlanned: boolean;
};

const OutageDetails: React.FC<Props> = ({
  affectedAreas,
  description,
  endAt,
  id,
  openAffectedAreasDialog,
  openEditDialog,
  openNotifyDialog,
  serviceType,
  serviceTypeName,
  startAt,
  totalCallbacks,
  totalCalls,
  affectedResidentialCustomers,
  affectedBusinessCustomers,
  internalNotificationHasBeenSent,
  externalNotificationHasBeenSent,
  isPlanned,
}: Props) => {
  const classes = useStyles();
  const customersAffected = [];
  if (affectedResidentialCustomers) {
    customersAffected.push(`${affectedResidentialCustomers} residential`);
  }

  if (affectedBusinessCustomers) {
    customersAffected.push(`${affectedBusinessCustomers} business`);
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" margin="1em 0">
        {isPlanned ? "Maintenance" : "Outage"} {id}
      </Typography>
      <Typography marginBottom="1em">
        Affects {serviceTypeMapping[serviceTypeName]}
        {customersAffected.length > 0
          ? ` for ${customersAffected.join(" and ")} customers`
          : ""}
        .
      </Typography>
      <Chip
        label={
          externalNotificationHasBeenSent
            ? "External Notification Sent"
            : "External Notification Not Sent"
        }
        color={externalNotificationHasBeenSent ? "primary" : "default"}
        sx={{ marginBottom: "1.5em" }}
        icon={
          externalNotificationHasBeenSent ? (
            <Icon>done</Icon>
          ) : (
            <Icon>clear</Icon>
          )
        }
      />
      <br />
      <Chip
        label={
          internalNotificationHasBeenSent
            ? "Internal Notification Sent"
            : "Internal Notification Not Sent"
        }
        color={internalNotificationHasBeenSent ? "primary" : "default"}
        sx={{ marginBottom: "1.5em" }}
        icon={
          internalNotificationHasBeenSent ? (
            <Icon>done</Icon>
          ) : (
            <Icon>clear</Icon>
          )
        }
      />
      <Typography marginBottom="1em">
        Starts on {formatDate(startAt, "M/d/yyyy h:mm aaaa")}
      </Typography>
      {endAt ? (
        <Typography marginBottom="1em">
          Ends on {formatDate(endAt, "M/d/yyyy h:mm aaaa")}
        </Typography>
      ) : null}
      <Typography marginBottom="1em">
        {totalCalls} Total Calls &middot; {totalCallbacks} Total Callbacks
      </Typography>
      <Typography marginBottom="1em">{description}</Typography>
      <Typography variant="h6" marginTop="1.5em">
        Affected Areas
      </Typography>
      <AffectedAreaAccordions affectedAreas={splitLCPs(affectedAreas)} />
      <Button sx={{ marginTop: "1em" }} onClick={openAffectedAreasDialog}>
        Add/Edit
      </Button>
      <Button
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={openNotifyDialog}
      >
        Notify
      </Button>
      <AppFab onClick={openEditDialog} accessibilityLabel="Edit">
        <Icon>edit</Icon>
      </AppFab>
    </div>
  );
};

export default OutageDetails;
