import React, { FC } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import AddButton from "sections/communication-preference/components/buttons/AddButton/AddButton";
import SubtractButton from "sections/communication-preference/components/buttons/SubtractButton";
import componentStyles from "./styles";

type Props = {
  tableActive: boolean;
  addButtonDescription: string;
  subtractButtonDescription: string;
  onClick: () => void;
};

const StyledAddButton = styled(AddButton)(({ theme }) => ({
  marginLeft: "auto",
  width: "15rem",
  height: "2.5rem",
  [theme.breakpoints.down("xs")]: {
    marginBottom: "1.25rem",
    marginLeft: 0,
    padding: "1.6rem",
  },
}));

const StyledSubtractButton = styled(SubtractButton)(({ theme }) => ({
  marginLeft: "auto",
  width: "15rem",
  height: "2.5rem",
  [theme.breakpoints.down("xs")]: {
    marginBottom: "1.25rem",
    marginLeft: 0,
    padding: "1.6rem",
  },
}));

const SubPreferenceButton: FC<Props> = ({
  tableActive,
  addButtonDescription,
  subtractButtonDescription,
  onClick,
}: Props) => {
  return (
    <Grid
      sx={
        tableActive
          ? componentStyles.removeButtonContainer
          : componentStyles.addButtonContainer
      }
    >
      {tableActive ? (
        <StyledSubtractButton onClick={onClick}>
          {subtractButtonDescription}
        </StyledSubtractButton>
      ) : (
        <StyledAddButton onClick={onClick}>
          {addButtonDescription}
        </StyledAddButton>
      )}
    </Grid>
  );
};

export default SubPreferenceButton;
