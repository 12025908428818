import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";

export interface Props {
  activeIndex: number;
  count: number;
  hasError: boolean;
  onPreviousClicked: () => void;
  onNextClicked: () => void;
}

const RatePlanGroupNavigation = ({
  activeIndex,
  count,
  hasError,
  onPreviousClicked,
  onNextClicked,
}: Props): JSX.Element => {
  const maxIndex = count - 1;

  return (
    <>
      <LinearProgress
        sx={{ margin: "1em 0em 1em 0em" }}
        variant="determinate"
        value={(activeIndex / maxIndex) * 100}
      />
      <Stack direction="row" justifyContent="space-between">
        <Button
          disabled={activeIndex === 0 || hasError}
          onClick={onPreviousClicked}
        >
          Previous
        </Button>
        <Typography variant="overline">
          {activeIndex + 1} of {count}
        </Typography>
        <Button
          disabled={activeIndex >= maxIndex || hasError}
          onClick={onNextClicked}
        >
          Next
        </Button>
      </Stack>
    </>
  );
};

export default RatePlanGroupNavigation;
