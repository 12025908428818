import React from "react";
import { useQuery } from "@apollo/client";

import { QUERIES as SETTINGS_QUERIES } from "../../SettingsQueries";
import { GetPermissions } from "../../__generated__/GetPermissions";
import PermissionsPanelContent from "./PermissionsPanelContent";
import { useUserState } from "../../../_global/contexts/UserContext";

const PermissionsPanelContentProvider: React.FC = () => {
  const user = useUserState();
  const { data } = useQuery<GetPermissions>(SETTINGS_QUERIES.getPermissions);
  const permissions = data?.permissions;

  return (
    <PermissionsPanelContent
      userPermissions={user.permissions}
      permissions={permissions}
    />
  );
};

export default PermissionsPanelContentProvider;
