export default {
  separateLineString: {
    display: "flex",
    flexDirection: "column",
    fontSize: { xs: "0.85rem", sm: "1rem" },
  },

  headerInfo: {
    letterSpacing: "0.05rem",
    fontSize: { xs: ".85rem", sm: "1rem" },
    fontWeight: 600,
  },
  mainHeader: {
    letterSpacing: "0.05rem",
    fontSize: { xs: ".85rem", sm: "1rem" },
  },
  subHeader: {
    letterSpacing: "0.05rem",
    fontSize: { xs: ".85rem", sm: "1rem" },
  },
  icon: {
    textAlign: "center",
    width: {
      xs: "40%",
      sm: "0",
    },
  },
} as const;
