import { colors } from "styles/colors";

export default {
  cardRoot: {
    position: "relative",
    margin: "0 0.5em 1em 0",
    marginBottom: "1.5em",
  },
  cardActionsRoot: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  buttonRoot: {
    fontWeight: "bold",
    color: colors.brandBlue,
  },
  active: {
    backgroundColor: colors.brandBlue,
  },
  cancelled: {
    backgroundColor: colors.gray10,
  },
  complete: {
    backgroundColor: colors.brandGreen,
  },
  chipIcon: {
    color: colors.white,
  },
  header: {
    paddingBottom: "0",
  },
  titleContainer: {
    marginTop: "0.5em",
    marginBottom: "1em",
    display: "flex",
    alignItems: "center",
  },
  title: {
    flex: "1",
  },
  selected: {
    backgroundColor: colors.brandBlue,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.brandBlueLight,
    },
    "&:focus": {
      backgroundColor: colors.brandBlueLight,
    },
  },
  icon: {
    color: colors.white,
  },
} as const;
