import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative" as const,
  },
  title: {
    marginBottom: "1em",
  },

  boxText: {
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(50%)",
      transition: "0.3s all",
    },
    letterSpacing: "0.34rem",
    marginTop: "2rem",
  },
  boxTypography: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
