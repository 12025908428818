import React, { FC } from "react";
import { Button, Icon } from "@mui/material";

export type Props = {
  // If false, renders text with 'finish' instead of next
  next: boolean;
  className?: string;
  onClick: () => void;
};

const NextOrFinishButton: FC<Props> = ({ next, onClick, className }: Props) => {
  return (
    <Button
      sx={{ marginBottom: "1rem" as const }}
      color="secondary"
      className={className}
      onClick={onClick}
      endIcon={next ? <Icon>arrow_forward</Icon> : <Icon>done</Icon>}
    >
      {next ? "Next" : "Finish"}
    </Button>
  );
};

export default NextOrFinishButton;
