import React, { FC, Dispatch, SetStateAction } from "react";
import { Grid, FormControl } from "@mui/material";
import ExternalRefInput from "../../../components/ExternalRefInput/ExternalRefInput";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import ContactInputContainer from "../../components/ContactInputContainer/ContactInputContainer";
import ContactFooter from "./ContactFooter/ContactFooter";
import {
  emailValidation,
  numberValidation,
} from "sections/_global/RegexValidation";
import { useContactState } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";

type Props = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
  setMaxSteps: Dispatch<SetStateAction<number>>;
  screenIsSmall: boolean;
};

const ContactForm: FC<Props> = ({
  setCurrentStep,
  currentStep,
  setMaxSteps,
  screenIsSmall,
}: Props) => {
  const dispatch = useMessageDispatch();
  const { contacts, externalRefId } = useContactState();

  /**
   * Function which checks to see if each of the inputs are valid, and if they are returns true and false otherwise
   */
  const validate = (): boolean => {
    let formIsValid = true;
    const usedContacts: string[] = [];
    for (const contact of contacts) {
      const { value, type } = contact;
      if (usedContacts.find((usedContact) => usedContact === value)) {
        dispatch({
          message: {
            description: `There are duplicates for the contact value: ${contact.value}`,
            type: "error",
            isOpen: true,
            id: "Already been used",
          },
          type: "ADD_MESSAGE",
        });
        formIsValid = false;
      } else usedContacts.push(value);

      if (type === 1) {
        if (!emailValidation.test(value)) {
          dispatch({
            message: {
              description: "Email is incomplete or invalid",
              id: "Incomplete email",
              isOpen: true,
              type: "error",
            },
            type: "ADD_MESSAGE",
          });
          formIsValid = false;
        }
      } else if (type === 2) {
        if (value.length !== 10) {
          dispatch({
            message: {
              description: "Phone number is incomplete",
              id: "Phone number is incomplete",
              isOpen: true,
              type: "error",
            },
            type: "ADD_MESSAGE",
          });
          formIsValid = false;
        }
        if (!numberValidation.test(value)) {
          dispatch({
            message: {
              description: "Invalid characters in phone number",
              id: "Invalid characters in phone number",
              isOpen: true,
              type: "error",
            },
            type: "ADD_MESSAGE",
          });
          formIsValid = false;
        }
      }
    }

    if (externalRefId.length < 1) {
      dispatch({
        message: {
          description: "External Ref ID is empty",
          id: "External Ref ID is Empty",
          isOpen: true,
          type: "error",
        },
        type: "ADD_MESSAGE",
      });
      formIsValid = false;
    }
    if (formIsValid) setMaxSteps(contacts.length + 2); // Add 2 to account for the first window and the last window
    return formIsValid;
  };

  return (
    <FormControl
      sx={{ padding: { xs: "1rem 1rem 0rem 1rem", sm: "1rem 3rem 0rem 3rem" } }}
    >
      <Grid container spacing={5}>
        <ExternalRefInput />
        <ContactInputContainer screenIsSmall={screenIsSmall} />
        <ContactFooter
          validation={validate}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          contacts={contacts}
          screenIsSmall={screenIsSmall}
        />
      </Grid>
    </FormControl>
  );
};

export default ContactForm;
