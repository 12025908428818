import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import BillingModelIdAdornment from "sections/billing-model-common/components/BillingModelTextAdornment";
import BillingModelDateField from "sections/billing-model-common/components/BillingModelDateField";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import { validateRatePlanField } from "../../validation";
import RatePlanTypeSelect from "../RatePlanTypeSelect";
import BillingModelQueryNameField from "sections/billing-model-common/components/BillingModelQueryNameField";
import {
  RatePlan,
  RcdElement,
  RcdGroup,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import { RatePlanTypeSelectData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeSelectQuery";
import { RatePlanTypeData } from "sections/billing-model-rateplans/hooks/useRatePlanTypeDetailsQuery";

export interface Props {
  originalRatePlan: RatePlan;
  ratePlan: RatePlan;
  subtitle?: string;
  isUserAdmin: boolean;
  checkingName: boolean;
  nameInUse: boolean | undefined;
  errors: FormError;
  useRatePlanTypeDetailsQuery: (id?: string | null) => RatePlanTypeData;
  useRatePlanTypeSelectQuery: (search: string) => RatePlanTypeSelectData;
  onChange: (update: RatePlan, errors: FormError) => void;
  onCheckName: (name: string) => void;
}

const RatePlanForm: React.FC<Props> = ({
  originalRatePlan,
  ratePlan,
  subtitle,
  isUserAdmin,
  checkingName,
  nameInUse,
  errors,
  useRatePlanTypeDetailsQuery,
  useRatePlanTypeSelectQuery,
  onChange,
  onCheckName,
}: Props) => {
  const editingRatePlan = ratePlan.ratePlanId ? true : false;

  const totalGroupClassCharges = (groupClass: string) => {
    // recurring charges are group class 50.
    // non-recurring charges are group class 52.
    // charge element types are element type id 9.
    let total = 0;
    const groups = ratePlan.ratingControlGroups?.filter(
      (g) => g.class === groupClass
    );
    const chargeElements = groups
      ? groups.flatMap((g: RcdGroup) =>
          g.ratingControlElements?.filter(
            (e: RcdElement) => Number(e.ratingControlElementId) === 9
          )
        )
      : [];

    chargeElements.forEach((e) => {
      if (e) {
        const amount = Number(e.value);
        total += amount;
      }
    });

    return total.toLocaleString("en-US", {
      style: "currency",
      currency: ratePlan.currencyCode ?? "USD",
    });
  };

  const updateRatePlan = (fieldName: string, update: RatePlan) => {
    const validationResults = validateRatePlanField(
      fieldName,
      update,
      errors,
      isUserAdmin
    );

    onChange(update, validationResults[1]);
  };

  const setFormField = (name: string) => (value: string | null) => {
    const update = {
      ...ratePlan,
      [name]: value,
      changed: originalRatePlan[name as keyof RatePlan] !== value,
    };

    updateRatePlan(name, update);
  };

  const handleFieldChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormField(name)(event.target.value);
  };

  const handleDateFieldChange = (name: string) => (date: Date | null) => {
    const update = {
      ...ratePlan,
      [name]: date,
      changed: originalRatePlan[name as keyof RatePlan] !== date,
    };

    updateRatePlan(name, update);
  };

  return (
    <>
      <Box sx={{ marginBottom: "0.8em" }}>
        <Typography variant="h4">
          {editingRatePlan ? "Edit" : "Create"} Rate Plan
        </Typography>
        {subtitle ? (
          <Typography variant="subtitle2">{subtitle}</Typography>
        ) : null}
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BillingModelQueryNameField
            useFullWidth
            label="Name"
            value={ratePlan.name}
            isDisabled={editingRatePlan && !isUserAdmin}
            hasError={errors.name != null}
            helperText={errors.name ?? undefined}
            isLoading={checkingName}
            exists={nameInUse}
            startAdornment={
              ratePlan.ratePlanId ? (
                <BillingModelIdAdornment value={ratePlan.ratePlanId} />
              ) : null
            }
            onChange={(value) => setFormField("name")(value)}
            onSearch={onCheckName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RatePlanTypeSelect
            label="Rate Plan Type"
            isDisabled
            useRatePlanTypeDetailsQuery={useRatePlanTypeDetailsQuery}
            useRatePlanTypeSelectQuery={useRatePlanTypeSelectQuery}
            hasError={(errors["ratePlanType"] ?? null) !== null}
            helperText={errors["ratePlanType"] ?? undefined}
            value={ratePlan.ratePlanType}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <BillingModelDateField
            limitDates={!isUserAdmin && !editingRatePlan}
            isDisabled={editingRatePlan && !isUserAdmin}
            label="Start Date"
            hasError={errors["startDate"] ? true : undefined}
            helperText={errors["startDate"] ?? undefined}
            value={ratePlan.startDate}
            onChange={handleDateFieldChange("startDate")}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <BillingModelDateField
            label="End Date"
            hasError={errors["endDate"] ? true : undefined}
            helperText={errors["endDate"] ?? undefined}
            value={ratePlan.endDate}
            onChange={handleDateFieldChange("endDate")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Description"
            inputProps={{ "aria-label": "description" }}
            fullWidth
            multiline
            variant="outlined"
            rows={3}
            defaultValue={ratePlan.description}
            error={(errors["description"] ?? null) !== null}
            helperText={errors["description"]}
            onChange={handleFieldChange("description")}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body1">Non-Recurring Charges:</Typography>
          <Typography variant="overline" sx={{ fontSize: "1em" }}>
            {totalGroupClassCharges("52")}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="body1">Recurring Charges:</Typography>
          <Typography variant="overline" sx={{ fontSize: "1em" }}>
            {totalGroupClassCharges("50")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RatePlanForm;
