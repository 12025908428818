import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERIES } from "../BillingModelRatePlanQueries";
import {
  BillingModelRatePlanType,
  BillingModelRatePlanTypeVariables,
} from "../__generated__/BillingModelRatePlanType";

export interface RatePlanTypeData {
  getRatePlanType: (id: string) => Promise<BillingModelRatePlanType>;
  ratePlanTypeResponse: BillingModelRatePlanType | undefined;
  loading: boolean;
}

function useRatePlanTypeDetailsQuery(id?: string | null): RatePlanTypeData {
  const { data, loading, refetch } = useQuery<
    BillingModelRatePlanType,
    BillingModelRatePlanTypeVariables
  >(QUERIES.getRatePlanType, {
    skip: id === "" || id === null || id === undefined,
    variables: { id: id ?? "" },
    fetchPolicy: "network-only",
  });

  return useMemo(
    () => ({
      getRatePlanType: async (id) => {
        const response = await refetch({
          id,
        });

        return response.data;
      },
      ratePlanTypeResponse: data,
      loading,
    }),
    [data, refetch, loading]
  );
}

export default useRatePlanTypeDetailsQuery;
