import React, { FC, createContext, useState, useCallback } from "react";

import {
  StateType,
  EquipmentType,
  initState,
  prepareData,
} from "sections/inventory-manager/screens/Assign/assignUtils";
import Assign from "sections/inventory-manager/screens/Assign";
import { flattenItemList } from "sections/inventory-manager/screens/Assign/assignUtils";

const AssignContext = createContext<any>(null);
const Provider = AssignContext.Provider;

const AssignProvided: FC = () => {
  const [state, dispatch] = useState<StateType>(initState);
  const [newEquipment, setNewEquipment] = useState<EquipmentType | null>(null);
  const [expanded, _setExpanded] = useState<number | null>(-1);

  const flattenItemListCallback = useCallback(
    () => flattenItemList(state.itemList),
    [state.itemList]
  );

  const _setState = useCallback(
    (data: Record<string, any>) => {
      dispatch({ ...state, ...data });
    },
    [state]
  );

  const removeItem = (id: string) => () => {
    _setState({
      itemList: prepareData(
        flattenItemListCallback().filter((v: any) => v.serialNumber !== id)
      ),
    });
  };

  const removePending = useCallback(
    (serialNumber: string) => () => {
      if (!serialNumber) return;
      const pendingList = state.pendingList.filter((item: any) => {
        if (item.serialNumber === serialNumber) {
          item.toBeRemoved = true;
          return false;
        }
        return true;
      });
      dispatch({ ...state, pendingList });
    },
    [dispatch, state]
  );

  const isAlreadyPending = useCallback(
    (serialNumber: string) =>
      state.pendingList.find(
        (item: any) => item.serialNumber === serialNumber && !item.toBeRemoved
      ),
    [state.pendingList]
  );

  const isAlreadyLoaded = useCallback(
    (serialNumber: string) =>
      flattenItemListCallback().find(
        (item: any) => item.serialNumber === serialNumber
      ),
    [flattenItemListCallback]
  );

  const _isDuplicate = useCallback(
    (serialNumber: string) => {
      const dup =
        isAlreadyPending(serialNumber) || isAlreadyLoaded(serialNumber);
      return dup;
    },
    [isAlreadyLoaded, isAlreadyPending]
  );

  const onPanelChange = (panel: number) => (
    e: React.ChangeEvent<any>,
    isExpanded: boolean
  ) => _setExpanded(isExpanded ? panel : null);

  const _giveUp = useCallback((serialNumber: string) => {
    setTimeout(() => {
      dispatch((currState) => {
        const found = currState.pendingList.find(
          (v: any) => v.serialNumber === serialNumber
        );
        if (!found) return currState;
        found.pending = false;
        return { ...currState };
      });
    }, 10000);
  }, []);

  const getSerial = useCallback(
    (serialNumber: string) => {
      if (_isDuplicate(serialNumber)) return;
      _setState({
        pendingList: [
          ...state.pendingList,
          { serialNumber, pending: true, toBeRemoved: false, show: true },
        ],
      });
      _giveUp(serialNumber);
    },
    [_giveUp, _isDuplicate, _setState, state.pendingList]
  );

  const getSerials = () =>
    flattenItemList(state.itemList)
      .filter((v: any) => v.isAvailable)
      .map((v: any) => v.serialNumber);

  return (
    <Provider
      value={{
        getSerial,
        getSerials,
        state,
        dispatch,
        newEquipment,
        setNewEquipment,
        expanded,
        onPanelChange,
        flattenItemListCallback,
        isAlreadyLoaded,
        removeItem,
        removePending,
        isAlreadyPending,
      }}
    >
      <Assign />
    </Provider>
  );
};

export { AssignProvided, AssignContext };
