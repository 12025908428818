import { Typography } from "@mui/material";
import React, { FC } from "react";
import componentStyles from "./styles";

type Props = {
  currentStep: number;
  totalSteps: number | string;
};

const StepsFooter: FC<Props> = ({ currentStep, totalSteps }: Props) => {
  return (
    <Typography sx={componentStyles.steps}>
      STEP {currentStep}/
      {typeof totalSteps === "number" ? totalSteps + 2 : totalSteps}
    </Typography>
  );
};

export default StepsFooter;
