import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

import image from "assets/ExternalNotificationTemplate.png";

import useStyles from "./styles";

export type Props = {
  onSend: () => void;
  onClose: () => void;
  id: string;
  sendingExternalNotification: boolean;
  sendingInternalNotification: boolean;
  internalNotificationHasBeenSent: boolean;
  externalNotificationHasBeenSent: boolean;
};

const NotificationWarning: React.FC<Props> = ({
  onSend,
  onClose,
  id,
  sendingExternalNotification,
  sendingInternalNotification,
  internalNotificationHasBeenSent,
  externalNotificationHasBeenSent,
}: Props) => {
  const classes = useStyles();
  const notificationsSent = [];
  if (sendingInternalNotification && internalNotificationHasBeenSent)
    notificationsSent.push("internal");
  if (sendingExternalNotification && externalNotificationHasBeenSent)
    notificationsSent.push("external");

  return (
    <>
      <DialogTitle id={id}>Send Notification?</DialogTitle>
      <DialogContent>
        {sendingExternalNotification && (
          <>
            <DialogContentText>
              The notification you are sending includes an external
              notification. This will send text messages and emails to customers
              notifying them of the outage. The email template is as follows:
            </DialogContentText>
            <div className={classes.imageContainer}>
              <a href={image} target="_blank" rel="noreferrer noopener">
                <img
                  alt="External Notification Template"
                  className={classes.image}
                  src={image}
                />
              </a>
            </div>
          </>
        )}
        {(internalNotificationHasBeenSent ||
          externalNotificationHasBeenSent) && (
          <DialogContentText>
            An {notificationsSent.join(" and ")} notification has already been
            sent for this outage.
          </DialogContentText>
        )}
        <DialogContentText>Do you wish to send?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="text" onClick={onSend}>
          Send
        </Button>
      </DialogActions>
    </>
  );
};

export default NotificationWarning;
