import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";

import { Grid } from "@mui/material";
import BooleanSelect from "../BooleanSelect/BooleanSelect";
import ActivityTypeSelect from "../ActivityType/ActivityTypeSelect";
import ActivityDuration from "../ActivityDuration/ActivityDuration";
import Can from "sections/_global/components/Can";
import BasicDatePicker from "../BasicDatePicker/BasicDatePicker";
import { durationOptions } from "../../screens/constants";
import {
  activityIsType,
  ActivityType,
  dateAllowedTypes,
  dateRequiredTypes,
  splicingActivityTypes,
  nocActivityTypes,
} from "../../activityTypes";
import { User } from "sections/_global/types";
import { DispatchType } from "../../screens/types";
import ResourceForm from "../ResourceForm/ResourceForm";
import { Box } from "@mui/system";

export type Props = {
  activityType: string;
  activityTypes: ActivityType[];
  date: Date | null;
  duration: string;
  isEmergency: boolean;
  setActivityType: (val: string) => void;
  setResourceId: (val: string | null) => void;
  setDate: (val: Date | null) => void;
  setDuration: (val: string) => void;
  setEmergency: (val: boolean) => void;
  usesQuota: boolean;
  user: User;
};

const GeneralDataForm: React.FC<Props> = ({
  activityType,
  activityTypes,
  date,
  duration,
  isEmergency,
  setActivityType,
  setResourceId,
  setDate,
  setDuration,
  setEmergency,
  user,
  usesQuota,
}: Props) => {
  return (
    <Grid container spacing={1} justifyContent="flex-start">
      <Grid item md={4} xs={6}>
        <ActivityTypeSelect
          handleChange={(value) => {
            setActivityType(value);
          }}
          options={activityTypes}
          selected={activityType}
          useFullWidth
          required
        />
      </Grid>
      <Grid item md={4} xs={6}>
        <ActivityDuration
          handleChange={(value) => setDuration(value)}
          options={durationOptions}
          selected={duration}
          useFullWidth
          required={usesQuota}
        />
      </Grid>
      {usesQuota && (
        <Grid item md={4} xs={6}>
          <Can
            permissions={user.permissions}
            performAction="scheduling:override-emergency"
            yes={
              <BooleanSelect
                label="Is Emergency"
                value={isEmergency}
                useFullWidth
                handleChange={(e) => {
                  setEmergency(e.target.value == "true");
                }}
              ></BooleanSelect>
            }
            no={<></>}
          />
        </Grid>
      )}
      {!usesQuota && (
        <>
          <Grid item md={4} xs={12}>
            <BasicDatePicker
              disabled={!activityIsType(activityType, dateAllowedTypes)}
              value={date ?? null}
              label={
                activityIsType(activityType, dateRequiredTypes)
                  ? "Date *"
                  : "Date"
              }
              minDate={new Date()}
              handleChange={(newValue) => {
                setDate(newValue);
              }}
              handleClear={(e) => {
                setDate(null);
              }}
            ></BasicDatePicker>
          </Grid>
          <Box width="100%" />
          {activityIsType(activityType, splicingActivityTypes) && (
            <>
              <ResourceForm
                dispatchType={DispatchType.SPLICING}
                required
                setResourceId={setResourceId}
              />
              <Box width="100%" />
            </>
          )}
          {activityIsType(activityType, nocActivityTypes) && (
            <>
              <ResourceForm
                dispatchType={DispatchType.NOC}
                required
                setResourceId={setResourceId}
              />
              <Box width="100%" />
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(GeneralDataForm);
