import { gql } from "@apollo/client";

export const FRAGMENTS = {
  outageDetails: gql`
    fragment OutageDetails on Outage {
      id
      serviceType
      serviceTypeName
      startAt
      endAt
      description
      totalCallbacks
      totalCalls
      affectedAreas {
        city
        state
        lcp
        numberOfCallbacks
        numberOfCalls
      }
      affectedResidentialCustomers
      affectedBusinessCustomers
      externalNotificationHasBeenSent
      internalNotificationHasBeenSent
      isPlanned
      status
    }
  `,
};

export const QUERIES = {
  getOutage: gql`
    query GetOutage($id: ID!) {
      outage(id: $id) {
        ...OutageDetails
      }
    }
    ${FRAGMENTS.outageDetails}
  `,
  getOutages: gql`
    query GetOutages(
      $sinceDateTime: DateTime
      $statuses: [Status!]
      $isPlanned: Boolean
    ) {
      outages(
        sinceDateTime: $sinceDateTime
        statuses: $statuses
        isPlanned: $isPlanned
      ) @connection(key: "outages") {
        id
        serviceType
        serviceTypeName
        startAt
        endAt
        affectedAreas {
          city
          state
          lcp
          numberOfCallbacks
          numberOfCalls
        }
        affectedResidentialCustomers
        affectedBusinessCustomers
        isPlanned
        status
      }
    }
  `,
  getLCPs: gql`
    query LCPs($markets: [MarketInput!]!) {
      lcps(markets: $markets) {
        city
        state
        name
      }
    }
  `,
  getMarkets: gql`
    query Markets {
      markets {
        city
        state
      }
    }
  `,
  getServiceTypes: gql`
    query ServiceTypes {
      serviceTypes {
        type
        name
      }
    }
  `,
};

export const MUTATIONS = {
  createOutage: gql`
    mutation CreateOutage($outage: CreateOutageInput!) {
      createOutage(outage: $outage) {
        success
        message
        outage {
          ...OutageDetails
        }
      }
    }
    ${FRAGMENTS.outageDetails}
  `,
  updateOutage: gql`
    mutation UpdateOutage($outage: UpdateOutageInput!) {
      updateOutage(outage: $outage) {
        success
        message
        outage {
          ...OutageDetails
        }
      }
    }
    ${FRAGMENTS.outageDetails}
  `,
  sendOutageNotification: gql`
    mutation SendOutageNotification(
      $sendExternalNotification: Boolean!
      $sendInternalNotification: Boolean!
      $id: NumericString!
    ) {
      sendOutageNotification(
        sendExternalNotification: $sendExternalNotification
        sendInternalNotification: $sendInternalNotification
        id: $id
      ) {
        success
        message
        outage {
          ...OutageDetails
        }
      }
    }
    ${FRAGMENTS.outageDetails}
  `,
};
