import { useEffect, useRef } from "react";

export default (): (() => boolean) => {
  const isUnmounted = useRef(false);

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    []
  );

  return () => isUnmounted.current;
};
