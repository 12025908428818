import React from "react";
import { TextField, Theme } from "@mui/material";
import componentStyles from "./styles";
import { SxProps } from "@mui/system";

export interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  regex: RegExp;
  variant?: "filled" | "standard" | "outlined" | undefined;
  /**
   * This function is one that prevents the errorText from showing up after the textField is formatted in some way, such that it would cause the regex test to fail.
   */
  deformatter?: (str: string) => string;
  type?: string;
  label?: string;
  errorText: string;
  helperText?: string;
  classes?: SxProps<Theme>;
  id: string;
  required?: boolean;
}

const ValidatedTextField: React.FC<Props> = ({
  value,
  onChange,
  regex,
  variant,
  label,
  type,
  errorText,
  helperText,
  id,
  required,
  classes,
  deformatter,
}: Props) => {
  const getValidationErrorText = (value: string) => {
    if (deformatter) {
      if (!regex.test(deformatter(value)) && value !== "") return errorText;
    } else if (!regex.test(value) && value !== "") return errorText;
    return "";
  };

  const validationResult = getValidationErrorText(value);

  return (
    <TextField
      sx={classes ? classes : componentStyles.root}
      value={value}
      onChange={onChange}
      label={label === undefined ? "" : label}
      variant={variant === undefined ? "outlined" : variant}
      error={validationResult !== ""}
      type={type === undefined ? "text" : type}
      id={id}
      required={required}
      helperText={
        helperText ? (value.length === 0 && helperText) || validationResult : ""
      }
    />
  );
};

export default ValidatedTextField;
