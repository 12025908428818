import React, { useCallback } from "react";

import { withStyles } from "@mui/styles";

import styles from "./styles";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { createFilterOptions, Grid } from "@mui/material";
import Typeahead, {
  TypeaheadOption,
} from "sections/_global/components/Typeahead";
import {
  getLcps,
  getLcpsVariables,
} from "sections/scheduling-tools/__generated__/getLcps";

export type Props = {
  required?: boolean;
  setLcp: (str: string) => void;
  lcp?: string;
};

const LCPSearch: React.FC<Props> = ({ required, setLcp, lcp }: Props) => {
  const [inputVal, setInputVal] = React.useState<string>("");
  const [lcpOptions, setLcpOptions] = React.useState<TypeaheadOption[]>([]);
  const [lcpSelected, setLcpSelected] = React.useState<
    string | TypeaheadOption | null
  >();

  const [queryLcps, { loading }] = useLazyQuery<getLcps, getLcpsVariables>(
    QUERIES.getLcps,
    {
      variables: {
        name: inputVal,
        limit: 100,
        includeFeederSplice: true,
      },
      onCompleted(data) {
        const options: TypeaheadOption[] =
          data.getLcps.lcps
            ?.map((lcp) => ({
              value: lcp.name,
              label: lcp.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) ?? [];
        setLcpOptions(options);
        if (lcp) {
          setLcpSelected(options.find((o) => o.value === lcp));
        }
      },
    }
  );

  React.useEffect(() => {
    setInputVal(lcp ?? "");
  }, [lcp]);

  React.useEffect(() => {
    queryLcps();
  }, [inputVal, queryLcps]);

  const isOptionEqualToValue = useCallback(
    (option: TypeaheadOption, value: TypeaheadOption) => {
      if (!value || !option) {
        return false;
      }
      return option.value === value.value;
    },
    []
  );

  return (
    <Grid container spacing={0} justifyItems="start">
      <Grid item xs={12}>
        <Typeahead
          label="LCP/Feeder"
          options={loading ? [] : lcpOptions}
          value={lcpSelected || null}
          isLoading={loading}
          isOptionEqualToValue={isOptionEqualToValue}
          skipAutoSelect
          required={required}
          filterOptions={createFilterOptions({
            matchFrom: "start",
          })}
          onInputChange={(value) => {
            setInputVal(value);
          }}
          onChange={(value) => {
            setLcpSelected(value);
            const lcp: string =
              value !== null && typeof value !== "string" ? value.value : "";
            setLcp(lcp);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LCPSearch);
