import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { isValid } from "date-fns";

import DateTextField from "sections/_global/components/DateTextField";
import {
  CreateOutageFormFields,
  CreateOutageFormFieldNames,
  UpdateOutageFormFields,
  UpdateOutageFormFieldNames,
  ValidationError,
} from "../../ServiceDisruptionTypes";
import { serviceTypeMapping } from "../../data-model";
import { safeCharacters } from "lib/Validators";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { ServiceTypes_serviceTypes } from "../../__generated__/ServiceTypes";

export type Props = {
  formFields: CreateOutageFormFields | UpdateOutageFormFields;
  setFormFields: (
    fields: CreateOutageFormFields | UpdateOutageFormFields
  ) => void;
  setValidationErrors: (errors: ValidationError[]) => void;
  clearValidationError: (name: string) => void;
  validationErrors: ValidationError[];
  serviceTypes: ServiceTypes_serviceTypes[];
  loading: boolean;
  screenIsSmall: boolean;
};

const OutageFormFields: React.FC<Props> = ({
  formFields,
  setFormFields,
  setValidationErrors,
  clearValidationError,
  validationErrors,
  serviceTypes,
  loading,
  screenIsSmall,
}: Props) => {
  const setDateField = (
    name: CreateOutageFormFieldNames | UpdateOutageFormFieldNames
  ) => (date: Date | null) => {
    setFormFields({
      ...formFields,
      [name]: date ?? null,
    });
    clearValidationError(name);

    if (!isValid(date)) {
      const newErrors = validationErrors;
      newErrors.push({
        field: name,
        error: `Invalid date.`,
      });
      setValidationErrors(newErrors);
    }
  };

  const setField = (
    name: CreateOutageFormFieldNames | UpdateOutageFormFieldNames
  ) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormFields({ ...formFields, [name]: event.target.value });
    clearValidationError(name);
  };

  const setSelectField = (
    name: CreateOutageFormFieldNames | UpdateOutageFormFieldNames
  ) => (event: any, child: any) => {
    setFormFields({ ...formFields, [name]: event.target.value });
    clearValidationError(name);
  };

  const setIntegerField = (
    name: CreateOutageFormFieldNames | UpdateOutageFormFieldNames
  ) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormFields({ ...formFields, [name]: parseInt(event.target.value) });
    clearValidationError(name);
  };

  const invalidDescription = !safeCharacters.test(formFields.description || "");

  const serviceTypeMenuItems = serviceTypes
    ? serviceTypes.map((serviceType) => (
        <MenuItem key={serviceType.type} value={serviceType.type}>
          {serviceTypeMapping[serviceType.name]}
        </MenuItem>
      ))
    : [];

  return (
    <div>
      <LoadingBackdrop isOpen={loading && !serviceTypes} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="services-affected-select-label">
              Services Affected
            </InputLabel>
            <Select
              label="Services Affected" // Both label and InputLabel must be present to fix a graphical bug
              key="services-affected-select"
              labelId="services-affected-select-label"
              id="services-affected-select"
              onChange={setSelectField("serviceType")}
              value={
                serviceTypeMenuItems && serviceTypeMenuItems.length > 0
                  ? formFields.serviceType
                  : ""
              }
              error={validationErrors.some((e) => e.field === "serviceType")}
            >
              {serviceTypeMenuItems}
            </Select>
            <FormHelperText error>
              {validationErrors.find((e) => e.field === "serviceType")?.error}
            </FormHelperText>
          </FormControl>
        </Grid>
        {!screenIsSmall && <Grid item xs={12} sm={6} />}
        <Grid item xs={12} sm={6}>
          <DateTextField
            variant={screenIsSmall ? "mobile" : "desktop"}
            label="Start Date"
            onChange={setDateField("startAt")}
            value={formFields.startAt}
            error={validationErrors.some((e) => e.field === "startAt")}
            helperText={
              validationErrors.find((e) => e.field === "startAt")?.error
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTextField
            variant={screenIsSmall ? "mobile" : "desktop"}
            label="End Date"
            onChange={setDateField("endAt")}
            value={formFields.endAt}
            error={validationErrors.some((e) => e.field === "endAt")}
            helperText={
              validationErrors.find((e) => e.field === "endAt")?.error
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Residential Customers Affected"
            InputLabelProps={{ htmlFor: "residential-customers-textfield" }}
            InputProps={{ id: "residential-customers-textfield" }}
            value={formFields.affectedResidentialCustomers ?? ""}
            type="number"
            inputProps={{ min: "0" }}
            onChange={setIntegerField("affectedResidentialCustomers")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Business Customers Affected"
            InputLabelProps={{ htmlFor: "business-customers-textfield" }}
            InputProps={{ id: "business-customers-textfield" }}
            value={formFields.affectedBusinessCustomers ?? ""}
            type="number"
            inputProps={{ min: "0" }}
            onChange={setIntegerField("affectedBusinessCustomers")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Description"
            multiline
            InputLabelProps={{ htmlFor: "description-textfield" }}
            InputProps={{ id: "description-textfield" }}
            value={formFields.description}
            onChange={setField("description")}
            rows={4}
            error={invalidDescription}
            helperText={invalidDescription ? "Invalid description." : ""}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OutageFormFields;
