import React from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";

import { Grid, TextField } from "@mui/material";
import AppAccordion from "../AppAccordion/AppAccordion";
import DateTextField from "sections/_global/components/DateTextField";
import AppSelect, { SelectOption } from "sections/_global/components/AppSelect";
import {
  cableSizes,
  OspInfo,
  SLA,
  splicingTeams,
  typesOfWork,
} from "../../screens/types";
import BooleanSelect from "../BooleanSelect/BooleanSelect";
import LCPSearch from "../LCPSearch/LCPSearch";

export type Props = {
  numActivities: number;
  ospInfo: OspInfo;
  sla: SLA;
  lcp?: string;
  setNumActivities: (val: number) => void;
  setOspInfo: (val: OspInfo) => void;
  setSla: (val: SLA) => void;
  setLcp: (val: string) => void;
  screenIsSmall?: boolean;
  requireLCP?: boolean;
  showLcp?: boolean;
  classes: {
    menuPaper: string;
  };
};

const numActivitiesOptions: SelectOption[] = [];

for (let i = 1; i <= 25; i++) {
  numActivitiesOptions.push({ label: "" + i, value: i });
}

const SplicingDataForm: React.FC<Props> = ({
  numActivities,
  ospInfo,
  sla,
  lcp,
  requireLCP,
  setNumActivities,
  setOspInfo,
  setSla,
  setLcp,
  screenIsSmall,
  showLcp,
  classes,
}: Props) => {
  return (
    <AppAccordion title="Splicing Info" expanded hideExpandIcon>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        marginBottom="0.5em"
      >
        <Grid item md={3} xs={6}>
          <DateTextField
            variant={screenIsSmall ? "mobile" : "desktop"}
            value={sla.start}
            label="SLA Start"
            onChange={(value) => {
              setSla({ ...sla, start: value });
            }}
          ></DateTextField>
        </Grid>
        <Grid item md={3} xs={6}>
          <DateTextField
            variant={screenIsSmall ? "mobile" : "desktop"}
            value={sla.end}
            minDateTime={sla.start ?? undefined}
            label="SLA End"
            onChange={(value) => {
              setSla({ ...sla, end: value });
            }}
          ></DateTextField>
        </Grid>
        <Grid item md={3} xs={6}>
          <AppSelect
            options={numActivitiesOptions}
            label="Activity Copies"
            value={numActivities}
            onChange={(value) => setNumActivities(value as number)}
            useFullWidth
            classes={classes.menuPaper}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <AppSelect
            options={splicingTeams.map((team) => ({
              label: team,
              value: team,
            }))}
            label="Requesting Team"
            value={ospInfo.splicingRequestTeam}
            onChange={(value) =>
              setOspInfo({
                ...ospInfo,
                splicingRequestTeam: value as string,
              })
            }
            required
            useFullWidth
            classes={classes.menuPaper}
          />
        </Grid>
        {showLcp && (
          <Grid item md={3} xs={6}>
            <LCPSearch
              required={requireLCP}
              setLcp={setLcp}
              lcp={lcp}
            ></LCPSearch>
          </Grid>
        )}
        <Grid item md={3} xs={6}>
          <TextField
            id="splicePoint"
            value={ospInfo.splicePoint || ""}
            onChange={(e) =>
              setOspInfo({
                ...ospInfo,
                splicePoint: e.target.value,
              })
            }
            label="Splice Name/Location"
            variant="outlined"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            id="hut"
            value={ospInfo.hutCabinet || ""}
            onChange={(e) =>
              setOspInfo({ ...ospInfo, hutCabinet: e.target.value })
            }
            label="Hut/Cabinet"
            variant="outlined"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            id="ring"
            value={ospInfo.ring || ""}
            onChange={(e) => setOspInfo({ ...ospInfo, ring: e.target.value })}
            label="Ring"
            variant="outlined"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <AppSelect
            options={typesOfWork.map((type) => ({
              label: type,
              value: type,
            }))}
            label="Type of Work"
            value={ospInfo.typeOfWork}
            onChange={(value) =>
              setOspInfo({
                ...ospInfo,
                typeOfWork: value as string,
              })
            }
            useFullWidth
            classes={classes.menuPaper}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <AppSelect
            options={cableSizes.map((size) => ({
              label: size,
              value: size,
            }))}
            label="Cable Size"
            value={ospInfo.cableSize}
            onChange={(value) =>
              setOspInfo({ ...ospInfo, cableSize: value as string })
            }
            useFullWidth
            classes={classes.menuPaper}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <BooleanSelect
            label="Is Cable Placed"
            value={ospInfo.isCablePlaced}
            useFullWidth
            handleChange={(e) =>
              setOspInfo({
                ...ospInfo,
                isCablePlaced: e.target.value == "true",
              })
            }
          ></BooleanSelect>
        </Grid>
      </Grid>
    </AppAccordion>
  );
};

export default withStyles(styles)(SplicingDataForm);
