import React from "react";
import Typography from "@mui/material/Typography";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import PageHeader from "sections/_global/components/PageHeader";

const Overview: React.FC = () => {
  return (
    <div>
      <PageHeader title="External Links" url="/" />

      <Typography variant="h5" marginBottom="0.5em">
        Associate Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "Oracle E-Business Suite (EBS)",
            icon: "language",
            url: "http://ebs1.in.qservco.com:8000",
            linkType: "external",
            titleProps: {
              fontSize: "1.2em",
            },
          },
          {
            title: "SharePoint",
            icon: "web",
            url: "http://sharepoint.ad.qservco.com",
            linkType: "external",
          },
          {
            title: "UltiPro (UKG)",
            icon: "sentiment_satisfied",
            url: "https://qservices.ultipro.com/ ",
            linkType: "external",
          },
          {
            title: "Personal IP Address Lookup",
            icon: "person_pin_circle",
            url: "https://webapps.metronetinc.com/cgi-bin/ip.cgi",
            linkType: "external",
            titleProps: {
              fontSize: "1.2em",
            },
          },
        ]}
      />
      <Typography variant="h5" marginTop="1em" marginBottom="0.5em">
        Field Tech Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "Field Tech Admin (FTA)",
            icon: "build",
            url: "https://webapps.metronetinc.com/fta",
            linkType: "external",
            titleProps: {
              fontSize: "1.3em",
            },
          },
          {
            title: "Oracle Field Services Cloud (OFSC)",
            icon: "cloud",
            url: "https://metronet.etadirect.com/",
            linkType: "external",
            titleProps: {
              fontSize: "1.2em",
            },
          },
        ]}
      />
      <Typography variant="h5" marginTop="1em" marginBottom="0.5em">
        Construction Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "Construction Communication Tool (CCT)",
            icon: "map",
            url: "https://webapps.metronetinc.com/cct",
            linkType: "external",
            titleProps: {
              fontSize: "1em",
            },
          },
          {
            title: "Construction Tracker",
            icon: "apartment",
            url: "https://webapps.metronetinc.com/ospcal",
            linkType: "external",
          },
        ]}
      />
      <Typography variant="h5" marginTop="1em" marginBottom="0.5em">
        Inventory Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "Inventory Manager",
            icon: "storage",
            url: "https://apps.metronetinc.com/inventory-manager/",
            linkType: "external",
            titleProps: {
              fontSize: "1.5em",
            },
          },
          {
            title: "Inventory Scanner",
            icon: "scanner",
            url:
              "https://qmanager.cinergymetronet.com/EquipMgr/ajax/inventory.cgi",
            linkType: "external",
            titleProps: {
              fontSize: "1.5em",
            },
          },
        ]}
      />

      <Typography variant="h5" marginTop="1em" marginBottom="0.5em">
        Billing Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "Intelligent Support Resolution (ISR)",
            icon: "person_search",
            url: "https://webapps.metronetinc.com/isr",
            linkType: "external",
            titleProps: {
              fontSize: "1em",
            },
          },
          {
            title: "Q-Manager",
            icon: "assignment_ind",
            url: "https://qmanager.cinergymetronet.com",
            linkType: "external",
          },
          {
            title: "Quote Tool",
            icon: "request_quote",
            url: "https://webapps.metronetinc.com/quote ",
            linkType: "external",
          },
          {
            title: "Self Sign-Up (SSU)",
            icon: "person_add_alt_1",
            url: "https://signup.metronetinc.com",
            linkType: "external",
          },
          {
            title: "View/Edit Subscriber (VES)",
            icon: "manage_accounts",
            url: "https://webapps.metronetinc.com/ves",
            linkType: "external",
            titleProps: {
              fontSize: "1.2em",
            },
          },
        ]}
      />
    </div>
  );
};

export default Overview;
