type Request = {
  uri: string;
  data?: any;
  headers?: Headers;
};

export const Post = async ({ uri, data }: Request): Promise<any> => {
  return await fetch(uri, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
  });
};

export const Get = async ({ uri, data, headers }: Request): Promise<any> => {
  return await fetch(uri, {
    method: "GET",
    credentials: "include",
  });
};
