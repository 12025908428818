import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  smallScreenContainer: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  headerText: {
    fontWeight: "bold",
  },
  regularText: {
    marginLeft: "0.9rem",
  },
}));

export default useStyles;
