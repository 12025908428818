import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";

import Action, { Props as ActionProps } from "./AppHeaderAction";

export type Props = {
  leftActions?: ActionProps[];
  titleOnClick?: () => void;
  title: string;
  rightActions?: ActionProps[];
};

const AppHeader: React.FC<Props> = ({
  leftActions = [],
  rightActions = [],
  titleOnClick,
  title,
}: Props) => {
  return (
    <AppBar>
      <Toolbar>
        {leftActions.map((action, i) => (
          <Action
            key={`${action.text}${action.icon}`}
            icon={action.icon}
            text={action.text}
            onClick={action.onClick}
            edge={i === 0 ? "start" : undefined}
            label={action.label}
            component={action.component}
          />
        ))}
        <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ cursor: titleOnClick ? "pointer" : "default" }}
            variant="h6"
            component="h1"
            onClick={titleOnClick}
          >
            {title}
          </Typography>
        </Box>
        {rightActions.map((action, i) => (
          <Action
            key={`${action.text}${action.icon}`}
            icon={action.icon}
            text={action.text}
            onClick={action.onClick}
            edge={i === rightActions.length - 1 ? "end" : undefined}
            label={action.label}
            component={action.component}
          />
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
