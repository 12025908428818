import React, { FC } from "react";
import { Alert, AlertColor } from "@mui/material";
import { Slide, Typography, Box, Fade } from "@mui/material";
import { Message } from "sections/_global/types";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import styles from "./styles";

export type Props = {
  /**
   * The messages to be displayed in the snackbar, must have a description and desired color
   */
  messages: Message[];
};

const StatusMessageStack: FC<Props> = ({ messages }: Props) => {
  const dispatch = useMessageDispatch();
  const statusMessageStack: JSX.Element[] = messages.map((message, index) => (
    <Box sx={styles.alert} key={message.id}>
      <Slide
        in={message.isOpen}
        unmountOnExit
        mountOnEnter
        onExited={() => {
          dispatch({
            type: "CLOSE_MESSAGE",
            message: message.id,
          });
        }}
      >
        <Alert
          icon={false}
          severity={message.type as AlertColor}
          variant="filled"
          onClose={() => {
            dispatch({
              type: "TRIGGER_CLOSE",
              message: message.id,
            });
          }}
        >
          {message.description}
        </Alert>
      </Slide>
    </Box>
  ));

  return (
    <Box sx={styles.container}>
      <Fade in={messages.length > 0}>
        <Box sx={styles.container2}>
          <Fade in={messages.length > 1}>
            <Typography
              role="button"
              sx={styles.closeAll}
              onClick={() =>
                dispatch({
                  type: "CLEAR_MESSAGES",
                })
              }
            >
              Close all
            </Typography>
          </Fade>
          <div>{statusMessageStack}</div>
        </Box>
      </Fade>
    </Box>
  );
};

export default StatusMessageStack;
