import React, { useContext, createContext, useReducer, useEffect } from "react";
import { Message } from "../types";
import StatusMessageStack from "../components/StatusMessageStack";
import Watchable from "lib/Watchable";
import useWatchable from "hooks/useWatchable";

export const graphQLErrorMessage = new Watchable<string | null>(null);

type Action =
  | {
      type: "ADD_MESSAGE";
      message: Message;
    }
  | {
      type: "CLEAR_MESSAGES";
    }
  | {
      type: "CLOSE_MESSAGE";
      message: string;
    }
  | {
      type: "TRIGGER_CLOSE";
      message: string;
    };
type Dispatch = (action: Action) => void;
type State = { messages: Message[] };
type Props = {
  children: React.ReactNode;
  initialState?: State;
};

const initialValues = {
  messages: [],
};

// Reducer will take in the case, and then look for the description and isOpen within our current state of the list, and then change that isopen to false
const messageReducer = (state: State, action: Action): State => {
  const { messages } = state;
  switch (action.type) {
    case "CLEAR_MESSAGES":
      return { messages: [] };
    case "CLOSE_MESSAGE":
      return {
        messages: messages.filter(
          (message) => !(message.id === action.message)
        ),
      };
    case "TRIGGER_CLOSE":
      return {
        messages: messages.map((message) =>
          message.id === action.message
            ? {
                description: message.description,
                type: message.type,
                isOpen: false,
                id: message.id,
              }
            : message
        ),
      };

    case "ADD_MESSAGE":
      // Check to see if there is a message with existing variant and description, throw error if there is
      messages.find((message) => message.id === action.message.id)
        ? new Error("There is already an alert with those same values")
        : messages.push(action.message);
      return { messages };
    default:
      throw new Error("Unhandled action type in MessageContext reducer.");
  }
};

const MessageDispatchContext = createContext<Dispatch | undefined>(undefined);
const MessageStateContext = createContext<State | undefined>(undefined);

const useMessageDispatch = (): Dispatch => {
  const context = useContext(MessageDispatchContext);

  if (context === undefined) {
    throw new Error("useMessageDispatch must be used with a MessageProvider.");
  }

  return context;
};

const useMessageState = (): State => {
  const context = useContext(MessageStateContext);

  if (context === undefined) {
    throw new Error("useMessageState must be used with a MessageProvider.");
  }

  return context;
};

const useMessage = (): [State, Dispatch] => {
  return [useMessageState(), useMessageDispatch()];
};

const MessageProvider: React.FC<Props> = ({
  children,
  initialState,
}: Props) => {
  const graphQLError = useWatchable<string | null>(graphQLErrorMessage);
  const [state, dispatch] = useReducer(
    messageReducer,
    initialState ?? initialValues
  );

  useEffect(() => {
    if (graphQLError) {
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: graphQLError,
          type: "error",
          isOpen: true,
          id: "graphql-error",
        },
      });
      graphQLErrorMessage.set(null);
    }
  }, [graphQLError]);

  return (
    <MessageDispatchContext.Provider value={dispatch}>
      <MessageStateContext.Provider value={state}>
        {children}
        <StatusMessageStack messages={state.messages} />
      </MessageStateContext.Provider>
    </MessageDispatchContext.Provider>
  );
};

export { MessageProvider, useMessage, useMessageState, useMessageDispatch };
