import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

type Props = {
  value: Date | null;
  label: string;
  minDate: Date;
  disabled?: boolean;
  handleChange: (str: any) => void;
  handleClear: (str: any) => void;
};

const BasicDatePicker: FC<Props> = ({
  value,
  label,
  minDate,
  disabled,
  handleChange,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        clearable
        disabled={disabled}
        renderInput={(props) => <TextField {...props} sx={{ width: "100%" }} />}
        label={label}
        value={value}
        onChange={(newValue) =>
          handleChange(
            moment(newValue, "MM/DD/YYYY", true).isValid() ? newValue : null
          )
        }
        minDate={minDate}
      />
    </LocalizationProvider>
  );
};

export default withStyles(styles)(BasicDatePicker);
