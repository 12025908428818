import { User } from "../sections/_global/types";
import { Permission } from "../sections/_global/types";

type Can = {
  permissions: Permission[];
  performAction: string | string[];
};

export const can = ({ permissions, performAction }: Can): boolean => {
  let action = performAction;
  if (!Array.isArray(action)) action = [action];

  return permissions.some((permission) => action.includes(permission.action));
};

type UserCan = {
  user: User;
  performAction: string | string[] | ((user: User) => boolean);
};

export const userCan = ({ user, performAction }: UserCan): boolean => {
  if (typeof performAction === "string" || Array.isArray(performAction)) {
    return Boolean(
      user &&
        user.permissions &&
        can({ permissions: user.permissions, performAction })
    );
  }

  return performAction(user);
};
