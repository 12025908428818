import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

export interface Props {
  isOpen: boolean;
  icon?: React.ReactNode;
  title: string;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  okClick: () => void;
  cancelClick: () => void;
}

const ConfirmationDialog: React.FC<Props> = ({
  isOpen,
  icon,
  title,
  children,
  okButtonLabel = "Ok",
  cancelButtonLabel = "Cancel",
  okClick,
  cancelClick,
}: PropsWithChildren<Props>) => {
  return (
    <Dialog open={isOpen} onClose={cancelClick}>
      <DialogTitle>
        {icon}
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="text" onClick={okClick}>
          {okButtonLabel}
        </Button>
        <Button variant="text" onClick={cancelClick}>
          {cancelButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
