// * Supports getting display text for ID + descriptive text for billing model types.
export const getDisplayText = (
  id: number | string | undefined | null,
  value: string | undefined | null,
  alt: string | undefined | null
): string | null | undefined => {
  if (id && value) return `[ ${id} ] ${value}`;
  if ((id ?? "") === "" && value) return `${value}`;
  return alt;
};

export const getDefaultStartDate = (isUserAdmin: boolean): Date => {
  if (isUserAdmin) return new Date();
  return new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
};
