import React, { FC, useState, useEffect } from "react";

import { IMRoutesType, AppGroupEnum } from "sections/inventory-manager/enums";
import OV, {
  SectionType,
  CardType,
} from "sections/_global/components/Overview";

type Props = {
  routes: IMRoutesType[];
};

const Overview: FC<Props> = ({ routes }: Props) => {
  const [isMounted, setIsMounted] = useState<boolean>(true);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const sections: SectionType[] = Object.values(AppGroupEnum)
    .filter((group) => routes.find((app) => app.group === group))
    .map((group) => {
      const apps: CardType[] = routes
        .filter((route) => route.group === group)
        .map((app) => ({
          title: app.name,
          url: app.route,
          active: app.active,
          icon: app?.icon,
          linkType: "internal",
        }));

      return {
        name: group,
        cards: apps,
      };
    });

  return isMounted ? (
    <OV title="Inventory Manager" sections={sections}></OV>
  ) : null;
};

export default Overview;
