import React, { FC } from "react";
import { Button, Icon } from "@mui/material";
import { colors } from "styles/colors";

export type Props = {
  onClick: () => void;
  title?: string;
  className?: string;
};

const SkipButton: FC<Props> = ({ title, onClick, className }: Props) => {
  return (
    <Button
      endIcon={<Icon>fast_forward</Icon>}
      sx={{
        marginBottom: "1rem",
        backgroundColor: colors.brandBlueLight,
        "&:hover": {
          backgroundColor: colors.brandBlue,
        },
      }}
      onClick={() => onClick()}
      className={className}
    >
      {title || "Skip"}
    </Button>
  );
};

export default SkipButton;
