import React from "react";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import { colors } from "../../../../styles/colors";
import { getInitials } from "../../ui";

const styles = {
  root: {
    "&:hover": {
      background: colors.brandBlue,
    },
    width: "3em",
    minWidth: "initial" as const,
    height: "3em",
    background: colors.brandBlue,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
  },
  initials: {
    color: colors.white,
    margin: "auto" as const,
    textTransform: "uppercase" as const,
  },
};

export type Props = {
  classes: {
    root: string;
    initials: string;
  };
  displayName?: string | null;
  styles?: {
    root?: string;
    initials?: string;
  };
};

const UserIcon: React.FC<Props> = ({ styles, displayName, classes }: Props) => {
  return (
    <div className={classnames(classes.root, styles?.root)}>
      <Typography
        sx={{
          color: colors.white,
          margin: "auto" as const,
          textTransform: "uppercase" as const,
        }}
      >
        {getInitials(displayName)}
      </Typography>
    </div>
  );
};

const StyledComponent = withStyles(styles)(UserIcon);

export { UserIcon };

export default StyledComponent;
