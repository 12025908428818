export default {
  page: {
    height: "75vh",
  },
  contactsHeader: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    justifyContent: "space-between",
    alignItems: "center",
  },
  activeSwitch: {
    margin: { xs: "0 5rem", md: "-2.75rem 20% 0 0" },
  },
  contactTable: {
    width: "95%",
  },
} as const;
