import {
  Box,
  Button,
  Grid,
  Icon,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

export interface Props {
  /**
   Array of strings containing the title for each step.
   */
  steps: string[];
  currentStep: number;
  navigationButtons?: "top" | "bottom" | "both";
  /**
   Called when a user clicks one of the two Next buttons.
   */
  onNextStepClick: () => void;
  /**
   Called when a user clicks one of the two Previous buttons.
   */
  onPreviousStepClick: () => void;
}

/**
 A component for displaying multiple steps for a user and providing buttons for moving between steps.
 */
const AppStepper: React.FC<Props> = ({
  steps,
  currentStep,
  navigationButtons = "top",
  onNextStepClick,
  onPreviousStepClick,
  children,
}: PropsWithChildren<Props>) => {
  const renderNav = () => (
    <Grid container>
      <Grid item xs={6}>
        {currentStep > 0 && (
          <Button
            startIcon={<Icon>chevron_left</Icon>}
            sx={{
              paddingLeft: "0.5em",
              marginLeft: "1em",
              marginBottom: "1em",
            }}
            onClick={onPreviousStepClick}
          >
            Previous
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end">
          {currentStep < steps.length - 1 && (
            <Button
              endIcon={<Icon>chevron_right</Icon>}
              sx={{
                paddingRight: "0.5em",
                marginRight: "1em",
                marginBottom: "1em",
              }}
              onClick={onNextStepClick}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Paper>
      <Stepper
        sx={{ paddingTop: "1em", paddingBottom: "1em" }}
        activeStep={currentStep}
      >
        {steps.map((s: string, i: number) => (
          <Step key={i}>
            <StepLabel>{s}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {navigationButtons !== "bottom" && renderNav()}
      <Box padding="1em">{children}</Box>
      {navigationButtons !== "top" && renderNav()}
    </Paper>
  );
};

export default AppStepper;
