import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { Grid, InputAdornment, TextField } from "@mui/material";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import {
  footprintsTicket,
  footprintsTicketVariables,
} from "sections/scheduling-tools/__generated__/footprintsTicket";
import { TicketType, Ticket } from "../../screens/types";

export interface OspTicketData {
  troubleType: string;
  ticketType: string;
  dueDate: string;
  invoiceTaskCode: string;
}

export type Props = {
  ticketNumber?: string | null;
  setFpTicket: (str: Ticket | undefined) => void;
  ospTicketData: OspTicketData | null;
  setOspTicketData: (str: OspTicketData | null) => void;
};

const OspFootprintsData: React.FC<Props> = ({
  ticketNumber,
  setFpTicket,
  ospTicketData,
  setOspTicketData,
}: Props) => {
  const [
    queryFootprintsTicket,
    { loading: footprintsTicketLoading },
  ] = useLazyQuery<footprintsTicket, footprintsTicketVariables>(
    QUERIES.getFootprintsTicket,
    {
      variables: {
        input: {
          ticketNumber: "OSPMC-" + ticketNumber,
          ticketType: "M&C",
          workSpace: "OSP",
        },
      },
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (data?.footprintsTicket?.ticketDetails?.fields?.[0]) {
          setOspTicketData({
            ticketType:
              data.footprintsTicket.ticketDetails.fields.find(
                (field: any) =>
                  field.key.toLowerCase() ==
                  "ticket_data-custom_fields-ticketType".toLowerCase()
              )?.value ?? "",
            troubleType:
              data.footprintsTicket.ticketDetails.fields.find(
                (field: any) =>
                  field.key.toLowerCase() ==
                  "ticket_data-custom_fields-troubletype".toLowerCase()
              )?.value ?? "",
            dueDate:
              data.footprintsTicket.ticketDetails.fields.find(
                (field: any) =>
                  field.key.toLowerCase() ==
                  "ticket_data-custom_fields-duedate".toLowerCase()
              )?.value ?? "",
            invoiceTaskCode:
              data.footprintsTicket.ticketDetails.fields.find(
                (field: any) =>
                  field.key.toLowerCase() ==
                  "ticket_data-custom_fields-invoicingTaskCode".toLowerCase()
              )?.value ?? "",
          });
        }
      },
      onError() {
        setOspTicketData({
          ticketType: "",
          troubleType: "",
          dueDate: "",
          invoiceTaskCode: "",
        });
      },
    }
  );

  useEffect(() => {
    if (!ticketNumber) {
      setOspTicketData({
        ticketType: "",
        troubleType: "",
        dueDate: "",
        invoiceTaskCode: "",
      });
      return;
    }

    queryFootprintsTicket({
      variables: {
        input: {
          ticketNumber: "OSPMC-" + ticketNumber,
          ticketType: "M&C",
          workSpace: "OSP",
        },
      },
    });
  }, [queryFootprintsTicket, setOspTicketData, ticketNumber]);

  const [ticketNumberTemp, setTicketNumberTemp] = useState<string>("");

  React.useEffect(() => {
    setTicketNumberTemp(ticketNumber ?? "");
  }, [ticketNumber]);

  return (
    <>
      <LoadingBackdrop isOpen={footprintsTicketLoading} />
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        marginBottom="1em"
      >
        <Grid item md={4} xs={12}>
          <TextField
            id="footprintsTicket"
            value={ticketNumberTemp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">OSPMC-</InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTicketNumberTemp(e.target.value.replace(/\D/g, ""));
            }}
            onBlur={(e) =>
              setFpTicket(
                ticketNumberTemp
                  ? {
                      ticketNumber: ticketNumberTemp,
                      ticketType: TicketType.FOOTPRINTS,
                      prefix: "OSPMC-",
                    }
                  : undefined
              )
            }
            label="Footprints Ticket"
            variant="outlined"
            type="text"
            style={{ display: "flex" }}
          />
        </Grid>

        {ticketNumber && (
          <>
            <Grid item md={2} xs={12}>
              <TextField
                id="outlined-basic"
                label="Trouble Type"
                disabled
                variant="outlined"
                value={ospTicketData?.troubleType ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                id="outlined-basic"
                label="Ticket Type"
                disabled
                variant="outlined"
                value={ospTicketData?.ticketType ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                id="outlined-basic"
                label="Due Date"
                disabled
                variant="outlined"
                value={ospTicketData?.dueDate ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                id="outlined-basic"
                label="Invoice Task Code"
                disabled
                variant="outlined"
                value={ospTicketData?.invoiceTaskCode ?? ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(OspFootprintsData);
