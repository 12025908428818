import React, { FC } from "react";
import {
  Divider,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { displayOnSeparateLines } from "./SummaryCard";
import { getContactIcon } from "../ContactField/ContactField";
import componentStyles from "./styles";

export type Props = {
  headerInfo?: string;
  mainHeader: string | string[];
  subHeader?: string;
  icon?: number;
  classes?: SxProps<Theme>;
};

const SummaryCardHeader: FC<Props> = ({
  headerInfo,
  mainHeader,
  subHeader,
  icon,
  classes,
}: Props) => {
  const displayIcon = getContactIcon(icon);

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <div>
              <Typography variant="h6" sx={componentStyles.headerInfo}>
                {headerInfo}
              </Typography>
              <Typography variant="h6" sx={componentStyles.mainHeader}>
                {Array.isArray(mainHeader)
                  ? displayOnSeparateLines(
                      mainHeader,
                      componentStyles.separateLineString
                    )
                  : mainHeader}
              </Typography>
              <Typography variant="h6" sx={componentStyles.subHeader}>
                {subHeader}
              </Typography>
            </div>
          }
        />
        <ListItemIcon>{displayIcon}</ListItemIcon>
      </ListItem>
      <Divider />
    </>
  );
};

export default SummaryCardHeader;
