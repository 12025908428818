import { Box, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import BackButton from "sections/_global/components/BackButton";

export interface Props {
  backUrl: string;
  title?: string;
  subtitle?: string;
  onBackBtnClick?: () => void;
}

const BillingModelScreen: React.FC<Props> = ({
  backUrl,
  title,
  subtitle,
  onBackBtnClick,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <>
      <BackButton url={backUrl} onClick={onBackBtnClick} />

      <Box sx={{ marginTop: "0.5em", marginBottom: "1em" }}>
        {title && <Typography variant="h4">{title}</Typography>}
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </Box>

      {children}
    </>
  );
};

export default BillingModelScreen;
