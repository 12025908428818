import React, { FC } from "react";
import { Grid, IconButton, Icon } from "@mui/material";
import EmailTextField from "sections/_global/components/EmailTextField";
import AppSelect from "sections/_global/components/AppSelect";
import PhoneNumberTextField from "sections/_global/components/PhoneNumberTextField";
import ValidatedTextField from "sections/_global/components/ValidatedTextField";
import { useContactDispatch } from "sections/communication-preference/contacts/contexts/ContactContext/ContactContext";
import { Contact } from "sections/communication-preference/contacts/types";
import { numberValidation } from "sections/_global/RegexValidation";
import componentStyles from "./styles";

type Props = {
  contact: Contact;
  oneContact: boolean;
  screenIsSmall: boolean;
};

const ContactInput: FC<Props> = ({
  contact,
  oneContact,
  screenIsSmall,
}: Props) => {
  const contactDispatch = useContactDispatch();

  const { key, value, type } = contact;
  const helperText =
    "Enter contact value and select appropriate contact method";

  /**
   * Function that for our textfields onChange, updates our root object with this inputs values
   * @param value The new value to be set in our parent object
   */
  const textFieldOnChange = (value: string) => {
    contactDispatch({
      type: "UPDATE_CONTACT",
      key,
      update: { text: value, type: "TEXT" },
    });
  };

  /**
   * Function for our app select onChange, sets the type of the input that this app select is next to.
   * @param option The particular
   */
  const selectOnChange = (option: unknown) => {
    contactDispatch({
      type: "UPDATE_CONTACT",
      key,
      update: { text: option as string, type: "TYPE" },
    });
  };

  /**
   * Function which removes this input from the parent object
   */
  const removeInput = () => {
    contactDispatch({
      type: "REMOVE_CONTACT",
      key,
    });
  };

  /**
   * Based on our input type, get the correct input for this type
   * @param type The type of input we are looking to generate
   * @returns An input based on the type we gave
   */
  const getInput = (type: number) => {
    switch (type) {
      case 1:
        return (
          <EmailTextField
            id={key}
            value={value}
            key={key}
            onChange={(email) => textFieldOnChange(email)}
            helperText={helperText}
            classes={componentStyles.fullWidth}
          />
        );
      case 2:
        return (
          <PhoneNumberTextField
            id={key}
            value={value}
            key={key}
            onChange={(phoneNumber) => textFieldOnChange(phoneNumber)}
            helperText={helperText}
            classes={componentStyles.fullWidth}
          />
        );

      default:
        return (
          <ValidatedTextField
            id={key}
            errorText={"Incorrect format"}
            value={value}
            key={key}
            regex={numberValidation}
            onChange={(e) => textFieldOnChange(e.target.value)}
            helperText={helperText}
            classes={componentStyles.fullWidth}
          />
        );
    }
  };

  const input = getInput(type);

  const appSelect = (
    <>
      <Grid item xs={oneContact ? 12 : screenIsSmall ? 9 : 10}>
        <AppSelect
          useFullWidth={true}
          options={[
            { label: "Email", value: 1 },
            { label: "SMS", value: 2 },
          ]}
          label="Contact Method"
          value={type}
          onChange={(contactMethod) => selectOnChange(contactMethod)}
        />
      </Grid>
      {!oneContact && (
        <Grid item xs={1}>
          <IconButton onClick={removeInput}>
            <Icon>delete</Icon>
          </IconButton>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container item spacing={screenIsSmall ? 2 : 5}>
      {screenIsSmall && appSelect}
      <Grid xs={12} sm={8} item>
        {input}
      </Grid>
      <Grid xs={4} container item spacing={oneContact ? 0 : 5}>
        {!screenIsSmall && appSelect}
      </Grid>
    </Grid>
  );
};

export default ContactInput;
