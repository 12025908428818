import { colors } from "styles/colors";

export default {
  inputContainer: {
    display: "flex",
    flexDirection: "column" as const,
  },
  input: {
    margin: "1em",
  },
  paper: {
    padding: "2em",
    maxWidth: "25em",
    margin: "5em auto",
  },
  image: {
    height: "1.5em",
  },
  imageContainer: {
    textAlign: "center" as const,
  },
  title: {
    color: colors.brandGreen,
    fontWeight: "lighter" as const,
    textAlign: "center" as const,
  },
  button: {
    padding: "1em",
    width: "100%",
  },
  buttonContainer: {
    padding: "1em",
  },
};
