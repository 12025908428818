import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERIES } from "../BillingModelRatePlanQueries";
import { BillingModelPeriodTypes } from "../__generated__/BillingModelPeriodTypes";

export interface PeriodTypeSelectData {
  getPeriodTypes: () => void;
  periodTypesResponse: BillingModelPeriodTypes | undefined;
  loading: boolean;
}

function usePeriodTypeSelectQuery(): PeriodTypeSelectData {
  const { refetch, data, loading } = useQuery<BillingModelPeriodTypes>(
    QUERIES.getPeriodTypes,
    {
      fetchPolicy: "cache-first",
    }
  );

  return useMemo(
    () => ({
      getPeriodTypes: () => refetch(),
      periodTypesResponse: data,
      loading,
    }),
    [data, loading, refetch]
  );
}

export default usePeriodTypeSelectQuery;
