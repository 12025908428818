import React, { useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";

import NotificationForm from "./NotificationForm";
import NotificationWarning from "../NotificationWarning";
import { noop } from "sections/_global/ui";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  internalNotificationHasBeenSent: boolean;
  externalNotificationHasBeenSent: boolean;
  sendNotification: ({
    sendExternalNotification,
    sendInternalNotification,
  }: {
    sendExternalNotification: boolean;
    sendInternalNotification: boolean;
  }) => void;
};

const NotifyDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  internalNotificationHasBeenSent,
  externalNotificationHasBeenSent,
  sendNotification,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [sendExternalNotification, setSendExternalNotification] = useState<
    boolean
  >(false);
  const [sendInternalNotification, setSendInternalNotification] = useState<
    boolean
  >(false);
  const notificationFormId = "notification-form-id";
  const warningId = "warning-id";

  const toggleSendExternalNotification = useCallback(() => {
    setSendExternalNotification(!sendExternalNotification);
  }, [setSendExternalNotification, sendExternalNotification]);

  const toggleSendInternalNotification = useCallback(() => {
    setSendInternalNotification(!sendInternalNotification);
  }, [setSendInternalNotification, sendInternalNotification]);

  const nextStep = () => {
    if (
      currentStep === 1 &&
      (sendExternalNotification ||
        (sendInternalNotification && internalNotificationHasBeenSent))
    ) {
      setCurrentStep(currentStep + 1);
    } else {
      sendNotification({ sendExternalNotification, sendInternalNotification });
    }
  };

  const cleanup = () => {
    setSendInternalNotification(false);
    setSendExternalNotification(false);
    setCurrentStep(1);
  };

  return (
    <>
      <Dialog
        open={isOpen && currentStep === 1}
        aria-labelledby={notificationFormId}
        onClose={onClose}
        TransitionProps={{ onExited: !isOpen ? cleanup : noop }}
      >
        <NotificationForm
          id={notificationFormId}
          sendExternalNotification={sendExternalNotification}
          sendInternalNotification={sendInternalNotification}
          onSend={nextStep}
          onClose={onClose}
          toggleSendExternalNotification={toggleSendExternalNotification}
          toggleSendInternalNotification={toggleSendInternalNotification}
        />
      </Dialog>
      <Dialog
        open={isOpen && currentStep === 2}
        aria-labelledby={warningId}
        onClose={onClose}
        TransitionProps={{ onExited: cleanup }}
      >
        <NotificationWarning
          id={warningId}
          onSend={nextStep}
          onClose={onClose}
          sendingExternalNotification={sendExternalNotification}
          sendingInternalNotification={sendInternalNotification}
          externalNotificationHasBeenSent={externalNotificationHasBeenSent}
          internalNotificationHasBeenSent={internalNotificationHasBeenSent}
        />
      </Dialog>
    </>
  );
};

export default NotifyDialog;
