import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import BackButton from "sections/_global/components/BackButton";
import LinkCardsProvider from "sections/_global/components/LinkCards/LinkCardsProvider";
import styles from "./styles";

type Props = {
  classes: {
    title?: string;
  };
};

const Dashboard: FC<Props> = ({ classes }: Props) => {
  return (
    <div>
      <BackButton url="/" />
      <Typography
        variant="h4"
        marginTop="0.5em"
        marginBottom="1em"
        className={classes.title}
      >
        Scheduling Tools
      </Typography>
      <LinkCardsProvider
        cards={[
          {
            title: "OFSC Schedule Activity",
            icon: "event_available",
            url: "/scheduling-tools/ofsc-schedule-activity",
            linkType: "internal",
            titleProps: {
              fontSize: "1.2em",
            },
          },
          {
            title: "OFSC Schedule Activity By Location",
            icon: "map",
            url: "/scheduling-tools/ofsc-schedule-activity-location",
            linkType: "internal",
            titleProps: {
              fontSize: "1.2em",
            },
          },
        ]}
      />
    </div>
  );
};

export default withStyles(styles)(Dashboard);
