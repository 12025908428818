import { colors } from "styles/colors";

export default {
  appbar: {
    background: colors.brandBlueDark,
  },
  icon: {
    color: "white",
  },
  iconButton: {
    marginRight: "0.5em",
  },
  container: {
    marginTop: "1.5em",
  },
};
