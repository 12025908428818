import { colors } from "styles/colors";

export default {
  default: {
    margin: 0,
  },
  fullWidth: {
    width: "100%",
  },
  container: {
    width: "100%",
    overflowX: { xs: "hidden", sm: "auto" },
    overflowY: { xs: "scroll", sm: "hidden" },
    whiteSpace: { xs: "normal", sm: "nowrap" },
  },
  header: {
    fontSize: "1rem",
    fontWeight: 700,
    paddingBottom: "0.75rem",
  },
  shift: {
    width: "87.5%",
    marginLeft: "auto",
  },
  column: {
    padding: { xs: "0.5rem", sm: "0 1rem" },
    width: { xs: "100%", sm: "47%" },
    display: { xs: "block", sm: "inline-table" },
    "&:not(:last-child)": {
      borderRight: { xs: "none", sm: `1px solid ${colors.gray11}` },
      borderBottom: { xs: `0.01rem solid ${colors.gray11}`, sm: "none" },
    },
  },
} as const;
