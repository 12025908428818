import DateAdapter from "@mui/lab/AdapterDateFns";
import { isSameDay, isAfter, isValid } from "date-fns";
import React, { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { TextField } from "@mui/material";
import { getDefaultStartDate } from "sections/billing-model-common/BillingModelUtilities";

export interface Props {
  hasError?: boolean;
  helperText?: string;
  isDisabled?: boolean;
  label: string;
  /**
   If true, the minimum selectable date is 7 days from the current date.
   An error message will appear if a user selects an earlier date.
   */
  limitDates?: boolean;
  useFullWidth?: boolean;
  value: Date | string | undefined | null;
  onFocus?: () => void;
  onChange?: (value: Date | null) => void;
}

/**
 Date Field component for limiting what dates a user can select.
 */
const BillingModelDateField = ({
  hasError,
  helperText,
  isDisabled,
  label,
  limitDates,
  useFullWidth,
  value,
  onChange,
  onFocus,
}: Props): JSX.Element => {
  const [minDateMessage, setMinDateMessage] = useState<string | null>(null);

  // * Only set error or helperText props on picker if hasError or helperText props were passed to this component.

  const handleChange = (value: string | Date | null | undefined) => {
    const message = limitDates
      ? "You must pick a date 7 days from today."
      : "You must pick today or a future date.";
    const date = value ? new Date(value) : null;
    const dateIsValid = isValid(date);
    const initialDate = getDefaultStartDate(limitDates ?? false);
    const isMinDateValid =
      date &&
      initialDate &&
      dateIsValid &&
      (isSameDay(initialDate, date) || isAfter(date, initialDate));

    // * Don't set the minDateMessage if value is the original value.
    // * If we're not limiting dates, don't run the validation.
    if (limitDates) setMinDateMessage(!isMinDateValid ? message : null);
    else setMinDateMessage(null);

    if (onChange) onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopDatePicker
        label={label}
        disabled={isDisabled}
        inputFormat="MM/dd/yyyy"
        value={value}
        onChange={(date, value) => {
          handleChange(date);
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            fullWidth={useFullWidth}
            helperText={minDateMessage || helperText}
            error={minDateMessage !== null || hasError}
            onFocus={onFocus}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default BillingModelDateField;
