import { gql } from "@apollo/client";

const CreateContact = gql`
  mutation CreateContact($input: [CreateContactInput!]!) {
    createContacts(input: $input) {
      contact {
        externalRefTypeId
        externalRefId
        methodId
        value
        isActive
        id
        preferences {
          isOpted
          channelId
          subPreferences {
            isOpted
            channelOptionId
            optionTypeValueId
          }
        }
      }
      message
    }
  }
`;

const GetContactsByExternalRefId = gql`
  query GetContactsByExternalRefId($externalRefId: String!) {
    getContactsByExternalRefId(externalRefId: $externalRefId) {
      externalRefTypeId
      externalRefId
      methodId
      value
      isActive
      preferences {
        isOpted
        channelId
        subPreferences {
          isOpted
          channelOptionId
          optionTypeValueId
        }
      }
      id
    }
  }
`;

const GetContactById = gql`
  query GetContactById($id: String!) {
    getContactById(id: $id) {
      id
      externalRefTypeId
      externalRefId
      methodId
      value
      isActive
    }
  }
`;

const UpdateContact = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      message
      contact {
        externalRefId
        externalRefTypeId
        value
        methodId
        isActive
        id
      }
    }
  }
`;

export {
  CreateContact,
  GetContactsByExternalRefId,
  GetContactById,
  UpdateContact,
};
