import React, { FC, ReactNode } from "react";
import { Button, Icon } from "@mui/material";

export type Props = {
  onClick: () => void;
  children: ReactNode;
  className?: string;
};

const AddButton: FC<Props> = ({ onClick, children, className }: Props) => {
  return (
    <Button
      startIcon={<Icon>add</Icon>}
      sx={{
        marginBottom: "1rem" as const,
      }}
      className={className}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default AddButton;
