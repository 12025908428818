import React from "react";
import { withStyles } from "@mui/styles";

const styles = {
  root: {
    flexGrow: 1,
  },
};

type Props = {
  currentTabIndex: number;
  index: number;
  children: React.ReactNode;
  tabId: string;
  ariaControl: string;
  classes: {
    root: string;
  };
};

const TabPanel: React.FC<Props> = ({
  currentTabIndex,
  index,
  children,
  tabId,
  ariaControl,
  classes,
}: Props) => {
  return (
    <div
      role="tabpanel"
      hidden={currentTabIndex !== index}
      id={`${tabId}-${index}`}
      aria-labelledby={`${ariaControl}-${index}`}
      className={classes.root}
    >
      {currentTabIndex === index && children}
    </div>
  );
};

export default withStyles(styles)(TabPanel);
