import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { BillingModelSearch } from "sections/billing-model-common/BillingModelSearch";
import AppSelect, {
  SelectOption,
} from "sections/_global/components/AppSelect/AppSelect";
import SearchBox from "sections/_global/components/SearchBox";
import theme from "styles/theme";

export interface Props {
  /**
   Label text for the checkbox.
   */
  checkboxLabel?: string;
  /**
   Is the checkbox checked?
   */
  checked?: boolean;
  /**
   Current selected filter.
   */
  filter?: string;
  /**
   Label for the filter select.
   */
  filterLabel?: string;
  /**
   The filter options.
   */
  filterOptions?: string[];
  /**
   Current selected sort order.
   */
  sort?: string;
  /**
   The sort options.
   */
  sortOptions?: string[];
  /**
   Label for the button.
   */
  buttonLabel?: string;
  isButtonDisabled?: boolean;
  onButtonClick?: (() => void) | null;
  /**
   Called whenever the search, filter, sort, or checked values change.
   */
  onSearch: (search: BillingModelSearch) => void;
}

/**
 List header with optional controls for searching, filtering, sorting, and a button.<br />
 This is the base control used in most lists in the billing model tools.
 */
const BillingModelCardList: React.FC<Props> = ({
  children,
  filter,
  filterLabel,
  filterOptions,
  checkboxLabel = "Show Expired",
  checked,
  sort,
  sortOptions,
  buttonLabel = "Add",
  isButtonDisabled = false,
  onButtonClick = null,
  onSearch,
}: React.PropsWithChildren<Props>) => {
  const [search, setSearch] = useState<BillingModelSearch>({
    terms: "",
    filter,
    sort,
    showExpired: checked,
  });

  const getSortOptions = (renderSort: boolean): SelectOption[] => {
    if (sortOptions && renderSort) {
      return sortOptions.map((s) => {
        return {
          label: s,
          value: s,
        };
      });
    }

    return [];
  };

  const getFilterOptions = (renderFilter: boolean): SelectOption[] => {
    if (filterOptions && renderFilter) {
      const options: SelectOption[] = [{ label: "None", value: "" }];
      return options.concat(
        filterOptions.map((f) => {
          return {
            label: f,
            value: f,
          };
        })
      );
    }

    return [];
  };

  const searchChanged = (newSearch: BillingModelSearch) => {
    if (onSearch) {
      onSearch(newSearch);
    }

    setSearch(newSearch);
  };

  const renderFilter = filterOptions && filterOptions.length > 0;
  const renderSort = sortOptions && sortOptions.length > 0;
  const renderCheckbox = checked !== undefined;
  const renderAddButton = onButtonClick !== null;

  return (
    <Box>
      <Grid
        container
        spacing={3}
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          paddingBottom: "0.8rem",
        }}
      >
        <Grid
          item
          xs={12}
          md="auto"
          // * Grow the search box grid item for small displays only
          sx={{ [theme.breakpoints.down("sm")]: { flexGrow: 1 } }}
        >
          <SearchBox
            useFullWidth
            onSearch={(terms) => searchChanged({ ...search, terms })}
          />
        </Grid>

        {filterOptions && renderFilter && (
          <Grid item xs={6} md="auto">
            <AppSelect
              useFullWidth
              label={filterLabel ? filterLabel : "Filter"}
              options={getFilterOptions(renderFilter)}
              value={search.filter}
              onChange={(filter) =>
                searchChanged({
                  ...search,
                  filter: filter as string | undefined,
                })
              }
            />
          </Grid>
        )}

        {sortOptions && renderSort && (
          <Grid item xs={6} md="auto">
            <AppSelect
              useFullWidth
              label="Sort"
              options={getSortOptions(renderSort)}
              value={search.sort}
              onChange={(sort) =>
                searchChanged({ ...search, sort: sort as string | undefined })
              }
            />
          </Grid>
        )}

        {renderCheckbox && (
          <Grid item xs={6} md="auto">
            <FormControlLabel
              checked={search.showExpired}
              control={
                <Checkbox color="secondary" checked={search.showExpired} />
              }
              label={checkboxLabel}
              labelPlacement="end"
              onChange={(event: React.ChangeEvent<any>) =>
                searchChanged({ ...search, showExpired: event.target.checked })
              }
            />
          </Grid>
        )}

        {renderAddButton && onButtonClick && (
          <Grid
            item
            xs="auto"
            sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
          >
            <Button disabled={isButtonDisabled} onClick={() => onButtonClick()}>
              {buttonLabel}
            </Button>
          </Grid>
        )}
      </Grid>

      <Divider />

      {children}
    </Box>
  );
};

export default BillingModelCardList;
