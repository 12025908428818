import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useStyles from "./styles";

type Props = {
  /**
   * The message which will be added to the card if null/undefined is found in either row or column
   */
  message: string;
};

/**
 * Function used to sift through the provided data, and returns a card saying the provided message in props if null is found in any of the rows/columns
 * @param data the data to be looked at for null columns/rows
 */
export const checkData = (data: any[][]): boolean => {
  let invalidTable = false; // This table is invalid if any of the rows are empty.
  if (data.length === 0) {
    invalidTable = true;
  } else {
    for (const subArray of data) {
      if (subArray.length === 0) {
        invalidTable = true;
        break;
      }
    }
  }
  return invalidTable;
};

const InvalidTable: FC<Props> = ({ message }: Props) => {
  const classes = useStyles();
  return (
    <TableRow
      key="empty"
      className={classes.noData}
      aria-invalid
      data-testid="aria-invalid"
    >
      <TableCell>
        <Typography variant="h6">{message}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default InvalidTable;
