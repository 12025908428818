import React, { useCallback, useMemo, useState } from "react";

import { withStyles } from "@mui/styles";

import styles from "./styles";
import { useQuery } from "@apollo/client";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { Grid, IconButton, TextField } from "@mui/material";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import { Project } from "../../screens/types";
import { ebsProjects } from "sections/scheduling-tools/__generated__/ebsProjects";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getProjectMetadata,
  getProjectMetadata_ebsProjectMarkets_projectMarkets as ProjectMarkets,
  getProjectMetadata_ebsProjectStates_projectStates as ProjectStates,
  getProjectMetadata_ebsProjectTypes_projectTypes as ProjectTypes,
} from "sections/scheduling-tools/__generated__/getProjectMetadata";
import Typeahead, {
  TypeaheadOption,
} from "sections/_global/components/Typeahead";
import {
  activityIsType,
  capitalTypes,
  maintenanceTypes,
  warrantyTypes,
} from "../../activityTypes";

const MAINTENANCE_PROJECT_TYPE = "MAINT";
const WARRANTY_ISSUES_PROJECT_TYPE = "WI";

export type Props = {
  required?: boolean;
  project: Project | null;
  setProject: (str: Project | null) => void;
  activityType: string;
};

const ProjectSearch: React.FC<Props> = ({
  project,
  required,
  setProject,
  activityType,
}: Props) => {
  const [projectTypes, setProjectTypes] = useState<ProjectTypes[]>([]);
  const [projectMarkets, setProjectMarkets] = useState<ProjectMarkets[]>([]);
  const [projectStates, setProjectStates] = useState<ProjectStates[]>([]);
  const [projectType, setProjectType] = useState<
    TypeaheadOption | string | null
  >(null);
  const [market, setMarket] = useState<TypeaheadOption | string | null>(null);
  const [state, setState] = useState<TypeaheadOption | string | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectName, setProjectName] = useState<string>("");

  const [
    projectSelected,
    setProjectSelected,
  ] = useState<TypeaheadOption | null>(null);

  const { loading: projectsLoading } = useQuery<ebsProjects>(
    QUERIES.getProjects,
    {
      variables: {
        state: state !== null && typeof state !== "string" ? state.value : null,
        market:
          market !== null && typeof market !== "string" ? market.value : null,
        projectType:
          projectType !== null && typeof projectType !== "string"
            ? projectType.value
            : null,
        projectName,
      },
      onCompleted(data) {
        const projects = data.ebsProjects.projects.map((project) => ({
          projectId: project.projectId ?? 0,
          projectName: project.projectName ?? "",
          clliCode: project.clliCode ?? "",
        }));

        const filteredProjects = activityIsType(activityType, capitalTypes)
          ? projects.filter(
              (p) =>
                !projectTypesToDisable(activityType).some((type) =>
                  p.clliCode.toUpperCase().endsWith(type.toUpperCase())
                )
            )
          : projects;

        setProjects(filteredProjects);
      },
    }
  );

  const { loading: projectMetadataLoading } = useQuery<getProjectMetadata>(
    QUERIES.getProjectMetadata,
    {
      onCompleted(data) {
        const projectMarkets = [
          ...data.ebsProjectMarkets.projectMarkets,
        ].sort((a, b) => (a.market ?? "").localeCompare(b.market ?? ""));
        const projectStates = [
          ...data.ebsProjectStates.projectStates,
        ].sort((a, b) => (a.stateName ?? "").localeCompare(b.stateName ?? ""));
        const projectTypes = [
          ...data.ebsProjectTypes.projectTypes,
        ].sort((a, b) =>
          (a.description ?? "").localeCompare(b.description ?? "")
        );
        setProjectMarkets(projectMarkets);
        setProjectStates(projectStates);
        setProjectTypes(projectTypes);
      },
    }
  );

  const stateOptions: TypeaheadOption[] =
    projectStates.map((state) => ({
      value: state.stateAcronym,
      label: state.stateName ?? "",
    })) ?? [];

  const marketOptions: TypeaheadOption[] =
    projectMarkets.map((market) => ({
      value: market.market,
      label: market.market ?? "",
    })) ?? [];

  //Given an activity type, determines which project type to enforce.
  const projectTypeToEnforce = (activityType: string) => {
    let projectType: string | undefined;
    if (activityIsType(activityType, maintenanceTypes)) {
      projectType = MAINTENANCE_PROJECT_TYPE;
    } else if (activityIsType(activityType, warrantyTypes)) {
      projectType = WARRANTY_ISSUES_PROJECT_TYPE;
    }
    return projectType;
  };

  //Given an activity type, determines which project types to disable.
  const projectTypesToDisable = (activityType: string): string[] => {
    let projectType: string[] = [];
    if (activityIsType(activityType, capitalTypes)) {
      projectType = [MAINTENANCE_PROJECT_TYPE, WARRANTY_ISSUES_PROJECT_TYPE];
    }
    return projectType;
  };

  const projectTypeOptions = useMemo(
    () =>
      projectTypes
        .filter(
          (type) =>
            !projectTypesToDisable(activityType).includes(
              type.projectType?.toUpperCase() ?? ""
            )
        )
        .sort((a, b) =>
          (a.description ?? "").localeCompare(b.description ?? "")
        )
        .map((type) => ({
          value: type.projectType,
          label: type.description ?? "",
        })) ?? [],
    [activityType, projectTypes]
  );

  const projectOptions: TypeaheadOption[] =
    projects.map((project) => ({
      value: project.clliCode,
      label: project.projectName,
    })) ?? [];

  const isOptionEqualToValue = useCallback(
    (option: TypeaheadOption, value: TypeaheadOption) => {
      if (!value || !option) {
        return false;
      }
      return option.value === value.value;
    },
    []
  );

  //If activity is of certain type, set project type filter accordingly.
  React.useEffect(() => {
    if (
      activityIsType(activityType, maintenanceTypes) ||
      activityIsType(activityType, warrantyTypes)
    ) {
      setProjectType(
        projectTypeOptions.find(
          (o) => o.value?.toUpperCase() === projectTypeToEnforce(activityType)
        ) ?? ""
      );
    } else {
      setProjectType(null);
    }
  }, [activityType, projectTypeOptions, setProject]);

  //Clear project selection if it is no longer supported by activity type.
  React.useEffect(() => {
    if (!project) {
      return;
    }

    if (
      !project?.clliCode
        ?.toUpperCase()
        .endsWith(projectTypeToEnforce(activityType) ?? "")
    ) {
      setProject(null);
    }

    if (
      projectTypesToDisable(activityType).some((char) =>
        project?.clliCode?.toUpperCase().endsWith(char)
      )
    ) {
      setProject(null);
    }
  }, [activityType, project, setProject]);

  //Clear project selection if it is no longer supported by project type.
  React.useEffect(() => {
    if (!project) {
      return;
    }

    const projectCode =
      typeof projectType !== "string" ? projectType?.value : "";

    if (!project?.clliCode?.toUpperCase().endsWith(projectCode ?? "")) {
      setProject(null);
    }
  }, [projectType, project, setProject]);

  //If project changes externally, update it here.
  React.useEffect(() => {
    setProjectSelected(
      project
        ? {
            value: project.clliCode,
            label: project.projectName ?? "",
          }
        : null
    );
  }, [project]);

  //Disable project type filter for activity types that enforce project type.
  const isProjectTypeFilterDisabled =
    activityIsType(activityType, maintenanceTypes) ||
    activityIsType(activityType, warrantyTypes);

  return (
    <>
      <LoadingBackdrop isOpen={projectMetadataLoading} />
      <Grid container spacing={1} justifyItems="start">
        <Grid item md={3} xs={6}>
          <TextField
            label="Search"
            value={projectName}
            fullWidth
            onChange={(e) => setProjectName(e.target.value)}
            InputProps={{
              endAdornment: projectName ? (
                <IconButton onClick={() => setProjectName("")}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <></>
              ),
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Typeahead
            label="State"
            options={stateOptions}
            value={state}
            skipAutoSelect
            onChange={(value) => setState(value)}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Typeahead
            label="Market"
            options={marketOptions}
            value={market}
            skipAutoSelect
            onChange={(value) => setMarket(value)}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Typeahead
            label="Project Type"
            options={projectTypeOptions}
            value={projectType}
            skipAutoSelect
            onChange={(value) => setProjectType(value)}
            isDisabled={isProjectTypeFilterDisabled}
          />
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={0} justifyItems="start">
        <Grid item xs={12}>
          <Typeahead
            label="Project"
            options={projectOptions}
            value={projectSelected}
            isLoading={projectsLoading}
            isOptionEqualToValue={isOptionEqualToValue}
            skipAutoSelect
            required={required}
            onChange={(value) => {
              const proj =
                value !== null && typeof value !== "string"
                  ? value.value
                  : null;
              setProject(projects.find((p) => p.clliCode === proj) ?? null);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ProjectSearch);
