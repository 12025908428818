import React from "react";
import Grow from "@mui/material/Grow";
import { FetchResult } from "@apollo/client";

import OutageCard from "./OutageCard";
import { Outage } from "../../ServiceDisruptionTypes";
import { growDelay } from "sections/_global/ui";
import {
  UpdateOutage,
  UpdateOutageVariables,
} from "../../__generated__/UpdateOutage";

export type Props = {
  outages: Outage[];
  refetchOutages: () => void;
  /**
   * This is a mutation hook using Apollo Client's `useMutation` hook.
   */
  updateOutage: ({
    variables,
  }: {
    variables: UpdateOutageVariables;
  }) => Promise<FetchResult<UpdateOutage>>;
};

const OutageCards: React.FC<Props> = ({
  outages,
  refetchOutages,
  updateOutage,
}: Props) => {
  return (
    <div>
      {outages.map((outage, i) => (
        <Grow
          key={i}
          in={true}
          timeout={growDelay({
            componentNumber: i,
            maxNumberOfComponents: 4,
            milliseconds: 300,
          })}
        >
          <div>
            <OutageCard
              id={outage.id}
              serviceType={outage.serviceType}
              serviceTypeName={outage.serviceTypeName}
              startAt={outage.startAt}
              endAt={outage.endAt}
              affectedAreas={outage.affectedAreas}
              isPlanned={outage.isPlanned}
              status={outage.status}
              affectedResidentialCustomers={outage.affectedResidentialCustomers}
              affectedBusinessCustomers={outage.affectedBusinessCustomers}
              refetchOutages={refetchOutages}
              updateOutage={updateOutage}
            />
          </div>
        </Grow>
      ))}
    </div>
  );
};

export default OutageCards;
