import React from "react";

import AppNotice from "../AppNotice";
import useStyles from "./styles";
import { colors } from "styles/colors";

export type Props = {
  email: string;
};

const PermissionDenied: React.FC<Props> = ({ email }: Props) => {
  const classes = useStyles();
  return (
    <AppNotice
      message="User does not have permission to use this feature."
      subMessage={
        <>
          <span>If you believe this to be an error, please contact </span>
          <a href={`mailto: ${email}`} className={classes.emailLink}>
            {email}
          </a>
          .
        </>
      }
      icon="warning"
      color={colors.errorRed}
    />
  );
};

export default PermissionDenied;
