import { makeStyles } from "@mui/styles";
import { colors } from "styles/colors";

export default makeStyles({
  root: {
    textAlign: "center" as const,
    maxWidth: "40em",
    margin: "auto" as const,
    padding: "5em",
  },
  message: {},
  subMessage: {
    color: colors.gray9,
    marginTop: "0.5em",
  },
  iconContainer: {
    opacity: 0.5,
    color: colors.brandGreen,
  },
  icon: {
    marginBottom: "1rem",
    opacity: 0.5,
    fontSize: "10em",
    color: colors.errorRed,
  },
});
