import React, { FC } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { Button, Grid, Icon, IconButton } from "@mui/material";
import ValidatedTextField from "sections/_global/components/ValidatedTextField";
import { anyValidation } from "sections/_global/RegexValidation";

type Props = {
  input: string[];
  setInput: (val: string[]) => void;
  maxFields?: number;
  regex?: RegExp;
  errorText?: string;
  objectName: string;
};

export function format(str: string): string {
  if (str === null || str === "") return "";
  else return str.replace(/\\/g, "/");
}

const MultiInput: FC<Props> = ({
  input,
  setInput,
  maxFields,
  regex,
  errorText,
  objectName,
}: Props) => {
  const handleChange = (idx: number, value: string) => {
    const newArray = input.map((entry, sidx) => {
      if (idx !== sidx) return entry;
      return value;
    });
    setInput(newArray);
  };

  const handleAddRecord = () => {
    setInput(input.concat(""));
  };

  const handleRemoveRecord = (idx: number) => () => {
    setInput(input.filter((s, sidx) => idx !== sidx));
  };

  return (
    <Grid container spacing={1} justifyItems="start">
      <>
        {input.map((item, i) => {
          return (
            <>
              <Grid item md={11} xs={11} key={"multiInputGrid-" + i}>
                <ValidatedTextField
                  onChange={(e) => {
                    handleChange(i, format(e.target.value));
                  }}
                  value={item}
                  id={"multiInput-" + i}
                  type="text"
                  errorText={errorText ?? ""}
                  regex={regex ?? anyValidation}
                  classes={styles}
                />
              </Grid>
              <Grid item md={1} xs={1}>
                <IconButton onClick={handleRemoveRecord(i)}>
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
            </>
          );
        })}
      </>
      <Grid item md={12} xs={12}>
        {maxFields && input.length < maxFields && (
          <Button startIcon={<Icon>add</Icon>} onClick={handleAddRecord}>
            {`Add ${objectName}`}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MultiInput);
