import React, { FC } from "react";
import { Icon, Box } from "@mui/material";

import componentStyles from "./styles";

type Props = {
  disabled: boolean;
  icon: React.ReactElement | string;
};

const LinkCardIcon: FC<Props> = ({ icon, disabled }: Props) => {
  return typeof icon === "string" ? (
    <Icon
      sx={{
        ...componentStyles.root,
        ...(disabled && componentStyles.iconDisabled),
      }}
    >
      {icon}
    </Icon>
  ) : (
    <Box sx={componentStyles.root}>{icon}</Box>
  );
};

export default LinkCardIcon;
