import React, { FC } from "react";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { phoneValidation } from "sections/_global/RegexValidation";
import ValidatedTextField from "../ValidatedTextField";

export interface Props {
  value: string;
  onChange: (str: string) => void;
  helperText: string;
  id: string;
  classes?: SxProps<Theme>;
}

const PhoneNumberTextField: FC<Props> = ({
  value,
  onChange,
  helperText,
  classes,
  id,
}: Props) => {
  const formatted = (pn: string) => {
    if (pn.length >= 7) {
      const first = pn.substring(0, 3);
      const second = pn.substring(3, 6);
      const third = pn.substring(6, 10);
      return `(${first}) ${second}-${third}`;
    }
    if (pn.length >= 4) {
      const first = pn.substring(0, 3);
      const second = pn.substring(3, 6);
      return `(${first}) ${second}`;
    }
    return pn;
  };

  const deformatter = (str: string) => {
    const regex = /[(?<!\s)-]/g;
    const replaced = str.replace(regex, "");

    if (replaced.length > 10) return replaced.substring(0, 10);

    return replaced;
  };

  return (
    <ValidatedTextField
      id={id}
      classes={classes}
      value={formatted(value)}
      onChange={(event) => {
        const value = deformatter(event.target.value);
        const lastCharacterTyped = value[value.length - 1];
        // If this is not a number, then don't change the text box value
        if (
          !isNaN(parseInt(lastCharacterTyped)) ||
          lastCharacterTyped === undefined
        ) {
          event.preventDefault();
          const unMasked = deformatter(value);
          onChange(unMasked);
        }
      }}
      label={"Phone Number"}
      variant={"outlined"}
      regex={phoneValidation}
      deformatter={deformatter}
      errorText={"Phone number is incomplete"}
      type={"text"}
      helperText={helperText}
    />
  );
};

export default PhoneNumberTextField;
