import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getDisplayText } from "sections/billing-model-common/BillingModelUtilities";
import { FormError } from "sections/billing-model-common/BillingModelValidation";
import { v4 as uuidv4 } from "uuid";

import {
  RatePlan,
  RatePlanType,
  RcdGroup,
  RcdGroupType,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import AppFullscreenDialog from "sections/_global/components/AppFullscreenDialog";
import RcdGroupTypeSelect from "../RcdGroupTypeSelect";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import RatePlanGroupNavigation from "./RatePlanGroupNavigation";
import { validateRcdGroup } from "../../validation";
import { useRatePlanDefaultValues } from "sections/billing-model-rateplans/hooks/useRatePlanDefaultValues";
import RcdGroupControls from "../RcdGroupControls";
import { ObjectStatus } from "sections/billing-model-common/BillingModelTypes";
import ExitPromptDialog from "sections/billing-model-common/components/ExitPrompt";
import ConfirmationDialog from "sections/billing-model-common/components/ConfirmationDialog";
import { colors } from "styles/colors";
import { RcdGroupTypeSelectData } from "sections/billing-model-rateplans/hooks/useRcdGroupTypeSelectQuery";
import { TransactionTypeSelectData } from "sections/billing-model-rateplans/hooks/useTransactionTypeSelectQuery";
import { PeriodTypeSelectData } from "sections/billing-model-rateplans/hooks/usePeriodTypeSelectQuery";

interface ActiveRcdGroup {
  index: number;
  group: RatePlanRcdGroup;
}

interface RatePlanRcdGroup {
  ratePlanId: string;
  ratePlanName: string;
  ratePlanDescription: string;
  rcdGroup: RcdGroup;
  status: ObjectStatus;
}

export interface Props {
  isOpen: boolean;
  ratePlanType: RatePlanType;
  ratePlans?: RatePlan[];
  usePeriodTypeSelectQuery: () => PeriodTypeSelectData;
  useTransactionTypeSelectQuery: (search: string) => TransactionTypeSelectData;
  useRcdGroupTypeSelectQuery: (search: string) => RcdGroupTypeSelectData;
  onSubmitClick: (ratePlanType: RatePlanType, ratePlans?: RatePlan[]) => void;
  onCloseClick: () => void;
}

const AddRcdGroupTypeDialog = ({
  ratePlanType,
  ratePlans,
  isOpen,
  usePeriodTypeSelectQuery,
  useTransactionTypeSelectQuery,
  useRcdGroupTypeSelectQuery,
  onSubmitClick,
  onCloseClick,
}: Props): JSX.Element => {
  const dispatch = useMessageDispatch();
  const defaultValues = useRatePlanDefaultValues();
  const [selected, setSelected] = useState<RcdGroupType | null>(null);
  const [rcdGroups, setRcdGroups] = useState<RatePlanRcdGroup[]>([]);
  const [errors, setErrors] = useState<FormError>({});
  const [activeGroup, setActiveGroup] = useState<ActiveRcdGroup | undefined>();
  const [haveChanges, setHaveChanges] = useState<boolean>(false);
  const [isConfirmationPromptOpen, setIsConfirmationPromptOpen] = useState<
    boolean
  >(false);
  const [isExitPromptOpen, setIsExitPromptOpen] = useState<boolean>(false);

  const isRatePlanTypeNew = () => ratePlanType.ratePlanTypeId === "";

  useEffect(() => {
    setHaveChanges(rcdGroups.some((g) => g.status === "changed"));
  }, [rcdGroups]);

  useEffect(() => {
    // * Reset state when the dialog closes / opens
    setSelected(null);
    setActiveGroup(undefined);
    setRcdGroups([]);
    setErrors({});
  }, [isOpen]);

  const displayMessage = (
    id: string,
    message: string,
    type: "error" | "warning" | "success"
  ) => {
    dispatch({
      type: "ADD_MESSAGE",
      message: {
        description: message,
        type: type,
        isOpen: true,
        id,
      },
    });
  };

  const handleGroupTypeChange = useCallback(
    (groupType: RcdGroupType | null) => {
      if (groupType == null) {
        setSelected(groupType);
        setActiveGroup(undefined);
        setRcdGroups([]);

        const newErrors: FormError = {};
        newErrors["rcdGroupType.name"] =
          "Please select the RCD Group Type to add.";

        setErrors(newErrors);
      } else {
        const groups: RatePlanRcdGroup[] | undefined = ratePlans?.map((r) => ({
          ratePlanId: r.ratePlanId,
          ratePlanName: r.name,
          ratePlanDescription: r.description ?? "",
          rcdGroup: {
            __typename: "BamRcdGroup",
            ratePlanId: r.ratePlanId,
            ratingControlGroupId: uuidv4(),
            ratingControlGroupType: groupType,
            active: true,
            class: "",
            startDate: defaultValues.ratePlanTypeStartDate.toISOString(),
            endDate: null,
            periodType: defaultValues.periodType,
            transactionType: defaultValues.transactionType,
            status: "new",
            ratingControlElements:
              groupType.ratingControlElementTypes?.map((et) => ({
                __typename: "BamRcdElement",
                ratingControlElementId: uuidv4(),
                ratingControlElementType: et,
                value: et.defaultValue,
                status: "new",
              })) ?? [],
          },
          status: "new",
        }));

        errors["rcdGroupType.name"] = null;
        setErrors({ ...errors });
        setSelected(groupType);
        setRcdGroups(groups ?? []);
        setActiveGroup(
          groups
            ? {
                group: groups[0],
                index: 0,
              }
            : undefined
        );
      }
    },
    [
      defaultValues.periodType,
      defaultValues.ratePlanTypeStartDate,
      defaultValues.transactionType,
      errors,
      ratePlans,
    ]
  );

  const handleGroupChange = (group: RatePlanRcdGroup, errors: FormError) => {
    const index = rcdGroups.findIndex((g) => g.ratePlanId === group.ratePlanId);
    if (index > -1) {
      const update = [...rcdGroups];
      update.splice(index, 1, group);
      setRcdGroups(update);
      setErrors(errors);

      if (activeGroup) {
        setActiveGroup({ ...activeGroup, group: update[activeGroup.index] });
      }
    }
  };

  const groupHasErrors = (activeGroup: RatePlanRcdGroup | undefined) => {
    if (activeGroup) {
      const errorKey = `rcdGroups.${activeGroup.rcdGroup.ratingControlGroupId}`;
      const periodTypeError = errors[`${errorKey}.periodType`] != null;
      const transactionError = errors[`${errorKey}.transactionType`] != null;
      const startDateError = errors[`${errorKey}.startDate`] != null;
      const endDateError = errors[`${errorKey}.endDate`] != null;
      const elementErrors = activeGroup.rcdGroup.ratingControlElements.some(
        (e) =>
          errors[`${errorKey}.elements.${e.ratingControlElementId}`] != null
      );

      return (
        periodTypeError ||
        transactionError ||
        startDateError ||
        endDateError ||
        elementErrors
      );
    }

    return false;
  };

  const isActiveGroupValid = () => {
    if (activeGroup && activeGroup.group) {
      const [hasErrors, validationErrors] = validateRcdGroup(
        activeGroup.group.rcdGroup,
        errors,
        defaultValues.ratePlanTypeAdmin,
        `rcdGroups.${activeGroup.group.rcdGroup.ratingControlGroupId}.`
      );

      setErrors(validationErrors);

      return !hasErrors;
    }

    return !ratePlans || ratePlans.length === 0;
  };

  const handleSubmit = () => {
    const newOrErrors = rcdGroups.some(
      (g) => g.status === "new" || groupHasErrors(g)
    );

    if (!isActiveGroupValid()) {
      displayMessage(
        "rateplan-error",
        "Please fix any errors and try again.",
        "error"
      );
    } else if (newOrErrors) {
      displayMessage(
        "rateplans-error",
        "Please check that all rate plans are valid and have the required values.",
        "warning"
      );
    } else if (ratePlans && ratePlans.length > 0 && rcdGroups.length === 0) {
      displayMessage("rateplans-empty", "No changes to submit!", "warning");
    } else if (!selected) {
      displayMessage(
        "rateplans-select-rcdgroup",
        "You must first select an RCD Group.",
        "warning"
      );
    } else if (isRatePlanTypeNew() || !ratePlans || ratePlans.length === 0) {
      onSubmitClick(
        {
          ...ratePlanType,
          ratingControlGroupTypes: [
            ...(ratePlanType.ratingControlGroupTypes ?? []),
            selected,
          ],
        },
        ratePlans
      );
    } else {
      setIsConfirmationPromptOpen(true);
    }
  };

  const confirmSave = () => {
    if (selected && ratePlans) {
      const ratePlanTypeUpdate = {
        ...ratePlanType,
        ratingControlGroupTypes: [
          ...(ratePlanType.ratingControlGroupTypes ?? []),
          selected,
        ],
      };

      const ratePlansUpdate = ratePlans?.map((rp) => {
        const newGroup = rcdGroups.find((g) => g.ratePlanId === rp.ratePlanId);
        const groups = [...rp.ratingControlGroups];
        if (newGroup) {
          groups.push({ ...newGroup.rcdGroup, status: "new" });
        }
        return { ...rp, ratingControlGroups: groups };
      });

      setIsConfirmationPromptOpen(false);
      onSubmitClick(ratePlanTypeUpdate, ratePlansUpdate);
    }
  };

  return (
    <>
      <ExitPromptDialog
        isOpen={isExitPromptOpen}
        title="Discard Changes?"
        message="If you continue, your changes for this rate plan type will be lost."
        confirmNavigationClick={() => {
          setIsExitPromptOpen(false);
          onCloseClick();
        }}
        cancelNavigationClick={() => {
          setIsExitPromptOpen(false);
        }}
      />

      <ConfirmationDialog
        isOpen={isConfirmationPromptOpen}
        title="Save Changes?"
        icon={
          <Icon
            sx={{
              color: colors.alertYellow,
              marginRight: "0.4em",
              verticalAlign: "middle",
              paddingBottom: "0.3em",
            }}
          >
            warning
          </Icon>
        }
        okButtonLabel="Yes"
        cancelButtonLabel="No"
        okClick={confirmSave}
        cancelClick={() => setIsConfirmationPromptOpen(false)}
      >
        <Typography variant="body1">
          You are about to modify rate plan type{" "}
          <b>
            {getDisplayText(
              ratePlanType.ratePlanTypeId,
              ratePlanType.name,
              "Unknown"
            )}
          </b>{" "}
          with the following changes:
        </Typography>
        <ul>
          <li>
            <Typography variant="body2">
              Adding{" "}
              <b>
                {getDisplayText(
                  selected?.ratingControlGroupTypeId,
                  selected?.name,
                  "Unknown"
                )}
              </b>{" "}
              group type.
            </Typography>
          </li>
          {ratePlans && (
            <li>
              <Typography variant="body2">
                Updating <b>{ratePlans.length}</b> rate plan
                {ratePlans.length > 1 ? "s" : ""} by adding new groups for the
                selected group type.
              </Typography>
            </li>
          )}
        </ul>
        <Typography variant="body1">
          Are you sure you want to save these changes?
        </Typography>
      </ConfirmationDialog>

      <AppFullscreenDialog
        isOpen={isOpen}
        title="Add RCD Group Type"
        actionText="Submit Changes"
        action={handleSubmit}
        onClose={() => {
          if (haveChanges) setIsExitPromptOpen(true);
          else onCloseClick();
        }}
      >
        <Box sx={{ margin: "3em" }}>
          <Grid container spacing={3} sx={{ padding: "1em" }}>
            <Grid item xs={12}>
              <Box sx={{ padding: "1em 0em 1em 0em" }}>
                <Typography variant="body1">
                  Rate Plan Type:{" "}
                  {getDisplayText(
                    ratePlanType?.ratePlanTypeId,
                    ratePlanType?.name,
                    ratePlanType?.ratePlanTypeId === ""
                      ? "New rate plan type."
                      : "Unknown rate plan type."
                  )}
                </Typography>
                <Typography variant="body1">
                  Affected Rate Plans: {ratePlans?.length ?? 0}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <RcdGroupTypeSelect
                label="RCD Group Type"
                value={selected}
                isDisabled={haveChanges}
                hasError={errors["rcdGroupType.name"] != undefined}
                helperText={errors["rcdGroupType.name"] ?? undefined}
                excludeRcdGroupTypes={ratePlanType.ratingControlGroupTypes}
                useRcdGroupTypeSelectQuery={useRcdGroupTypeSelectQuery}
                onChange={handleGroupTypeChange}
              />
            </Grid>
          </Grid>
          {activeGroup && activeGroup.group && (
            <Stack sx={{ padding: "1em" }}>
              <Box>
                <Typography variant="h5">Update Rate Plans</Typography>
              </Box>

              <Stack sx={{ margin: "1em 0em 1em 0em" }} spacing={1}>
                <Typography variant="h6">
                  {activeGroup &&
                    getDisplayText(
                      activeGroup.group.ratePlanId,
                      activeGroup.group.ratePlanName,
                      "Unknown Rate Plan"
                    )}
                </Typography>
                <Typography variant="body1">
                  {activeGroup.group.ratePlanDescription}
                </Typography>
              </Stack>

              <RcdGroupControls
                original={activeGroup.group.rcdGroup}
                rcdGroup={activeGroup.group.rcdGroup}
                isUserAdmin={defaultValues.ratePlanTypeAdmin}
                errors={errors}
                usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
                useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
                onChange={(group, errs) =>
                  handleGroupChange(
                    {
                      ...activeGroup.group,
                      rcdGroup: group,
                      status: "changed",
                    },
                    errs
                  )
                }
              />

              <RatePlanGroupNavigation
                activeIndex={activeGroup.index}
                count={rcdGroups.length}
                hasError={groupHasErrors(activeGroup.group)}
                onNextClicked={() => {
                  const allowMove =
                    rcdGroups.length > 0 && activeGroup && isActiveGroupValid();
                  if (allowMove) {
                    const index = activeGroup
                      ? Math.min(activeGroup.index + 1, rcdGroups.length)
                      : 0;
                    setActiveGroup({ group: rcdGroups[index], index });
                  }
                }}
                onPreviousClicked={() => {
                  if (
                    rcdGroups.length > 0 &&
                    activeGroup &&
                    isActiveGroupValid()
                  ) {
                    const index = activeGroup
                      ? Math.max(activeGroup.index - 1, 0)
                      : 0;
                    setActiveGroup({ group: rcdGroups[index], index });
                  }
                }}
              />
            </Stack>
          )}
        </Box>
      </AppFullscreenDialog>
    </>
  );
};

export default AddRcdGroupTypeDialog;
