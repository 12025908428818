import React, { FC } from "react";
import { ContactProvider } from "../contexts/ContactContext/ContactContext";
import AddContact from "./AddContact";

const AddContactProvider: FC = () => {
  return (
    <ContactProvider>
      <AddContact />
    </ContactProvider>
  );
};

export default AddContactProvider;
