import React, { FC } from "react";

import {
  quotaActivityDates,
  quotaActivityDatesVariables,
  quotaActivityDates_quotaActivityDates_dates as QuotaActivityDate,
} from "../../../__generated__/quotaActivityDates";
import QuotaPicker from "./QuotaPicker";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { quotaActivityTypes } from "../../activityTypes";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";

export type Props = {
  quota?: QuotaActivityDate[];
  quotaOffset: number;
  selected: string;
  required?: boolean;
  handleChange: (e: any) => void;
  handleQuotaChange: (e: QuotaActivityDate[]) => void;
  decrementQuotaOffset: (e: any) => void;
  incrementQuotaOffset: (e: any) => void;
  subscriberId: string;
  activityType: string;
  duration: number;
  isEmergency: boolean;
};

const QuotaPickerProvider: FC<Props> = ({
  quota = [],
  quotaOffset,
  selected,
  required,
  handleChange,
  handleQuotaChange,
  decrementQuotaOffset,
  incrementQuotaOffset,
  subscriberId,
  activityType,
  duration,
  isEmergency,
}: Props) => {
  const [queryQuota, { loading: quotaLoading }] = useLazyQuery<
    quotaActivityDates,
    quotaActivityDatesVariables
  >(QUERIES.getActivityDates, {
    variables: {
      subId: subscriberId ?? "",
      activityType,
      duration: duration,
      dayOffset: quotaOffset,
      isEmergency: isEmergency,
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (data?.quotaActivityDates?.dates) {
        handleQuotaChange(
          data.quotaActivityDates.dates.filter(
            (date) => date !== null
          ) as QuotaActivityDate[]
        );
      } else {
        handleQuotaChange([]);
      }
    },
  });

  React.useEffect(() => {
    if (
      quotaActivityTypes.filter((activity) => activity.id == activityType)
        .length > 0
    ) {
      queryQuota({});
    }
  }, [
    subscriberId,
    activityType,
    duration,
    quotaOffset,
    isEmergency,
    queryQuota,
  ]);

  return (
    <>
      <LoadingBackdrop isOpen={quotaLoading} />
      <QuotaPicker
        quota={quota}
        quotaOffset={quotaOffset}
        selected={selected}
        required={required}
        handleChange={handleChange}
        incrementQuotaOffset={incrementQuotaOffset}
        decrementQuotaOffset={decrementQuotaOffset}
      />
    </>
  );
};

export default QuotaPickerProvider;
