export default {
  addButtonContainer: {
    width: { xs: "75%", sm: "50%" },
    display: "flex",
    justifyContent: {
      xs: "center",
      sm: "right",
    },
  },
  removeButtonContainer: {
    marginTop: "2rem",
    width: "100%",
    display: "flex",
    justifyContent: {
      xs: "center",
      sm: "right",
    },
  },
} as const;
