import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import Button from "@mui/material/Button";

import SelectableItem from "./SelectableItem";
import { Market } from "../../ServiceDisruptionTypes";

import styles from "./styles";

export type Props = {
  classes: {
    search: string;
    selectAllButton: string;
    selectAllContainer: string;
  };
  selectedMarkets: Market[];
  setSelectedMarkets: (markets: Market[]) => void;
  getMarkets: () => void;
  markets: Market[];
};

const cloneData = (data: any) => JSON.parse(JSON.stringify(data));

const AddMarketStep: React.FC<Props> = ({
  classes,
  selectedMarkets,
  setSelectedMarkets,
  getMarkets,
  markets,
}: Props) => {
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchFilter(e.target.value);
  };

  const regex = new RegExp(searchFilter, "i");

  const isSelected = ({ city, state }: Market) =>
    selectedMarkets.some(
      (market) => market.city === city && market.state === state
    );

  const onSelect = ({ city, state }: Market) => {
    let newSelectedMarkets = cloneData(selectedMarkets);

    if (
      newSelectedMarkets.some(
        (market: Market) => market.city === city && market.state === state
      )
    ) {
      newSelectedMarkets = newSelectedMarkets.filter(
        (market: Market) => !(market.city === city && market.state === state)
      );
    } else {
      newSelectedMarkets.push({ city, state });
    }
    setSelectedMarkets(newSelectedMarkets);
  };

  const onSelectAll = () => {
    let newSelectedMarkets: Market[] = [];

    if (!allSelected) {
      newSelectedMarkets = markets.slice().map((market) => ({
        city: market.city,
        state: market.state,
      }));
    }

    setAllSelected(!allSelected);
    setSelectedMarkets(newSelectedMarkets);
  };

  useEffect(() => {
    getMarkets();
  }, [getMarkets]);

  return (
    <div>
      <Typography variant="h6" marginBottom="1em">
        Markets
      </Typography>
      <TextField
        classes={{ root: classes.search }}
        label="Search"
        value={searchFilter}
        onChange={(e) => onChange(e)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.selectAllContainer}>
        <Button
          color={allSelected ? "error" : "primary"}
          onClick={onSelectAll}
          variant="text"
        >
          {allSelected ? "Remove All" : "Select All"}
        </Button>
      </div>
      {markets &&
        markets
          .slice()
          .sort((a, b) => (a.city > b.city ? 1 : -1))
          .filter(
            (market: Market) =>
              Boolean(!searchFilter) ||
              (market && market.city && market.city.match(regex))
          )
          .map((market: Market) => (
            <SelectableItem
              key={`${market.city}, ${market.state}`}
              label={`${market.city}, ${market.state}`}
              isSelected={isSelected(market)}
              onSelect={() => onSelect(market)}
            />
          ))}
    </div>
  );
};

const StyledComponent = withStyles(styles)(AddMarketStep);

export { AddMarketStep };

export default StyledComponent;
