import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import styles from "./styles";
import { useLazyQuery } from "@apollo/client";
import { QUERIES } from "sections/scheduling-tools/ScheduleActivityQueries";
import { TextField } from "@mui/material";
import LoadingBackdrop from "sections/_global/components/LoadingBackdrop";
import {
  Address,
  Coordinates,
  OspInfo,
  Project,
  SLA,
  Ticket,
} from "../../screens/types";

import { OfscTicketInput } from "../../../../../../__generated__/globalTypes";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import { isBefore } from "date-fns";
import {
  getActivity,
  getActivityVariables,
} from "sections/scheduling-tools/__generated__/getActivity";
import { convertActivityToTicket } from "../../screens/helpers";

export type Props = {
  activityId: string | null;
  setActivityId: (val: string) => void;
  setActivityType: (val: string) => void;
  setAddress: (val: Address) => void;
  setResourceId: (val: string) => void;
  setCoordinates: (val: Coordinates) => void;
  setDate: (val: Date | null) => void;
  setDescription: (val: string) => void;
  setDuration: (val: string) => void;
  setFpTicket: (val: Ticket | undefined) => void;
  setLcp: (val: string) => void;
  setOspInfo: (val: OspInfo) => void;
  setProject: (val: Project | null) => void;
  setSla: (val: SLA) => void;
  setZdTicket: (val: Ticket | undefined) => void;
};

function stringToDate(input?: string | null) {
  if (!input) {
    return;
  }
  const validFormat = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
  if (!validFormat.test(input)) {
    return;
  }
  const [year, month, day] = input.split("-");
  const date = new Date(+year, +month - 1, +day);
  return date;
}

function filterPastDate(date?: Date): Date | null {
  if (!date) {
    return null;
  } else if (isBefore(date, new Date())) {
    return null;
  }
  return date;
}

const ActivityCopy: React.FC<Props> = ({
  activityId,
  setActivityId,
  setActivityType,
  setAddress,
  setResourceId,
  setCoordinates,
  setDate,
  setDescription,
  setDuration,
  setFpTicket,
  setLcp,
  setOspInfo,
  setProject,
  setSla,
  setZdTicket,
}: Props) => {
  const dispatch = useMessageDispatch();

  const [queryActivity, { loading: activityLoading }] = useLazyQuery<
    getActivity,
    getActivityVariables
  >(QUERIES.getActivity, {
    variables: {
      ofscActivityId: activityId ?? "",
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.ofscActivity.__typename === "OfscActivitiesSuccess" &&
        data.ofscActivity.activities &&
        data.ofscActivity.activities.length > 0
      ) {
        const activity = data.ofscActivity.activities[0];
        setActivityType(activity?.activityType ?? "");
        setDuration(((activity?.duration as unknown) as string) ?? "");
        setDate(filterPastDate(stringToDate(activity?.date)));
        setResourceId(activity?.resourceId ?? "");
        setCoordinates(activity?.coordinates ?? {});
        setAddress(
          activity?.address
            ? {
                streetAddress1: activity.address.address1 ?? undefined,
                streetAddress2: activity.address.address2 ?? undefined,
                city: activity.address.city,
                state: activity.address.state,
                postalCode: activity.address.postalCode,
              }
            : {}
        );
        setProject({
          projectId: 0,
          projectName: activity?.ospInfo?.projectName ?? "",
          clliCode: activity?.ospInfo?.projectCode ?? "",
        });

        const ospInfo = activity?.ospInfo;

        setOspInfo({
          isCablePlaced: ospInfo?.cablePlaced ?? undefined,
          cableSize: ospInfo?.cableSize ?? undefined,
          emailsToNotify:
            ospInfo?.emailNotifications?.map((link) => link ?? "") ?? undefined,
          hutCabinet: ospInfo?.hutCabinet ?? undefined,
          links: ospInfo?.links?.map((link) => link ?? "") ?? undefined,
          ring: ospInfo?.ring ?? undefined,
          splicePoint: ospInfo?.splicePoint ?? undefined,
          splicingRequestTeam: ospInfo?.splicingRequestTeam ?? undefined,
          typeOfWork: ospInfo?.typeOfWork ?? undefined,
        });
        setSla({
          start: activity?.slaWindowStart
            ? new Date(activity?.slaWindowStart)
            : null,
          end: activity?.slaWindowEnd ? new Date(activity?.slaWindowEnd) : null,
        });

        const fpTemp = activity?.tickets
          ?.filter(
            (ticket) =>
              ticket?.ticketType ===
              ("FOOTPRINTS" as OfscTicketInput["ticketType"])
          )
          .filter((ticket) => ticket != null)[0];

        setFpTicket(convertActivityToTicket(fpTemp));

        const zdTemp = activity?.tickets
          ?.filter(
            (ticket) =>
              ticket?.ticketType ===
              ("ZENDESK" as OfscTicketInput["ticketType"])
          )
          .filter((ticket) => ticket != null)[0];

        setZdTicket(convertActivityToTicket(zdTemp));

        setLcp(activity?.terminal ?? "");

        setDescription(activity?.notes ?? "");
      } else {
        dispatch({
          type: "ADD_MESSAGE",
          message: {
            type: "error",
            description: `Activity could not be copied.`,
            isOpen: true,
            id: "search",
          },
        });
      }
    },
  });

  useEffect(() => {
    if (!activityId) {
      return;
    }

    queryActivity({
      variables: {
        ofscActivityId: activityId ?? "",
      },
    });
  }, [queryActivity, activityId]);

  const [activityIdTemp, setActivityIdTemp] = useState<string>("");

  React.useEffect(() => {
    setActivityIdTemp(activityId ?? "");
  }, [activityId]);

  return (
    <>
      <LoadingBackdrop isOpen={activityLoading} />
      <TextField
        id="activityLookup"
        value={activityIdTemp}
        onChange={(e) => {
          setActivityIdTemp(e.target.value.replace(/\D/g, ""));
        }}
        onBlur={(e) => setActivityId(activityIdTemp ?? null)}
        label="Activity to Copy"
        variant="outlined"
        type="text"
        style={{ display: "flex" }}
        fullWidth
      />
    </>
  );
};

export default withStyles(styles)(ActivityCopy);
