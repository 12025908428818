import React from "react";
import { useQuery } from "@apollo/client";
import {
  CreateOutageFormFields,
  UpdateOutageFormFields,
  ValidationError,
} from "../../ServiceDisruptionTypes";
import { QUERIES as SERVICE_DISRUPTION_QUERIES } from "../../ServiceDisruptionQueries";
import { ServiceTypes } from "../../__generated__/ServiceTypes";
import OutageFormFields from "./OutageFormFields";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {
  formFields: CreateOutageFormFields | UpdateOutageFormFields;
  setFormFields: (
    fields: CreateOutageFormFields | UpdateOutageFormFields
  ) => void;
  setValidationErrors: (errors: ValidationError[]) => void;
  clearValidationError: (name: string) => void;
  validationErrors: ValidationError[];
};

const OutageFormFieldsProvider: React.FC<Props> = ({
  formFields,
  setFormFields,
  setValidationErrors,
  clearValidationError,
  validationErrors,
}: Props) => {
  const { data: serviceTypesData, loading } = useQuery<ServiceTypes>(
    SERVICE_DISRUPTION_QUERIES.getServiceTypes
  );
  const theme = useTheme();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const serviceTypes = serviceTypesData?.serviceTypes;

  return (
    <OutageFormFields
      serviceTypes={serviceTypes || []}
      setFormFields={setFormFields}
      formFields={formFields}
      setValidationErrors={setValidationErrors}
      clearValidationError={clearValidationError}
      validationErrors={validationErrors}
      loading={loading}
      screenIsSmall={screenIsSmall || false}
    />
  );
};

export default OutageFormFieldsProvider;
