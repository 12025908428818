import React from "react";
import {
  RatePlan,
  RatePlanType,
  RcdGroup,
} from "sections/billing-model-rateplans/BillingModelRatePlanTypes";
import useRatePlanTypeSelectQuery from "sections/billing-model-rateplans/hooks/useRatePlanTypeSelectQuery";
import SelectRcdGroups from "./SelectRcdGroups";
import useRatePlanTypeDetailsQuery from "sections/billing-model-rateplans/hooks/useRatePlanTypeDetailsQuery";

export interface Props {
  ratePlan: RatePlan | null;
  ratePlanType: RatePlanType | null;
  selected: RcdGroup[];
  onChange: (rpt: RatePlanType | null, groups: RcdGroup[]) => void;
}

const SelectRcdGroupsProvider = ({
  ratePlan,
  ratePlanType,
  selected,
  onChange,
}: Props): JSX.Element => {
  return (
    <SelectRcdGroups
      ratePlan={ratePlan}
      ratePlanType={ratePlanType}
      selected={selected}
      useRatePlanTypeDetailsQuery={useRatePlanTypeDetailsQuery}
      useRatePlanTypeSelectQuery={useRatePlanTypeSelectQuery}
      onChange={(rpt, groups) => {
        if (onChange) {
          onChange(rpt, groups);
        }
      }}
    />
  );
};

export default SelectRcdGroupsProvider;
