import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import BillingModelScreen from "sections/billing-model-common/components/BillingModelScreen";
import RouteExitGuard from "sections/billing-model-common/components/RouteExitGuard";
import { useMessageDispatch } from "sections/_global/contexts/MessageContext";
import { BamCreateRatePlanTypeInput } from "../../../../__generated__/globalTypes";
import {
  MUTATIONS,
  QUERIES,
} from "../../billing-model-rateplans/BillingModelRatePlanQueries";
import RatePlanTypeForm from "../components/RatePlanTypeForm";
import { BillingModelCreateRatePlanType } from "../../billing-model-rateplans/__generated__/BillingModelCreateRatePlanType";
import {
  BillingModelRatePlanType,
  BillingModelRatePlanTypeVariables,
} from "../../billing-model-rateplans/__generated__/BillingModelRatePlanType";
import { RatePlan, RatePlanType } from "../BillingModelRatePlanTypes";
import AddRcdGroupTypeDialog from "../components/AddRcdGroupTypeDialog";
import usePeriodTypeSelectQuery from "../hooks/usePeriodTypeSelectQuery";
import useTransactionTypeSelectQuery from "../hooks/useTransactionTypeSelectQuery";
import useRcdGroupTypeSelectQuery from "../hooks/useRcdGroupTypeSelectQuery";

interface Params {
  id: string;
}

const CreateRatePlanType = (): JSX.Element => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const dispatch = useMessageDispatch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [haveChanges, setHaveChanges] = useState<boolean>(false);
  const [ratePlanType, setRatePlanType] = useState<RatePlanType>({
    __typename: "BamRatePlanType",
    ratePlanTypeId: "",
    name: "",
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
    ratingControlGroupTypes: [],
  });

  useQuery<BillingModelRatePlanType, BillingModelRatePlanTypeVariables>(
    QUERIES.getRatePlanType,
    {
      fetchPolicy: "network-only",
      skip: id === "" || id === null || id === undefined,
      variables: { id: id },
      onCompleted: (response) => {
        if (response.bamRatePlanType) {
          const rpt: RatePlanType = {
            ...(response.bamRatePlanType as RatePlanType),
            ratePlanTypeId: "",
            name: `Copy of ${response.bamRatePlanType.name}`,
          };
          setRatePlanType(rpt);
        }
      },
    }
  );

  const [createRatePlanType, { loading: submitting }] = useMutation<
    BillingModelCreateRatePlanType
  >(MUTATIONS.createRatePlanType);

  const handleChanges = (rpt: RatePlanType) => {
    setHaveChanges(true);
    setRatePlanType(rpt);
  };

  const handleAddRcdGroup = async (
    ratePlanTypeUpdate: RatePlanType,
    ratePlansUpdate?: RatePlan[]
  ): Promise<boolean> => {
    setDialogOpen(false);
    handleChanges(ratePlanTypeUpdate);
    return true;
  };

  const handleSubmit = async () => {
    const dispatchError = () =>
      dispatch({
        type: "ADD_MESSAGE",
        message: {
          description: "An error occurred while creating the Rate Plan Type.",
          type: "error",
          isOpen: true,
          id: "create-rateplantype-msg",
        },
      });
    const input: BamCreateRatePlanTypeInput = {
      name: ratePlanType.name ?? "",
      ratingControlGroupTypes:
        ratePlanType.ratingControlGroupTypes?.map((rgt) => ({
          ratingControlGroupTypeId: rgt.ratingControlGroupTypeId,
        })) ?? [],
    };

    createRatePlanType({ variables: { input } })
      .then((response) => {
        if (!response.data || !response.data.bamCreateRatePlanType) {
          dispatchError();
        } else {
          dispatch({
            type: "ADD_MESSAGE",
            message: {
              description: "Rate plan type created.",
              type: "success",
              isOpen: true,
              id: "create-rateplantype-msg",
            },
          });
          setHaveChanges(false);
          history.push("/billing-model/rate-types/list");
        }
      })
      .catch(() => {
        dispatchError();
      });
  };

  return (
    <BillingModelScreen
      backUrl="/billing-model/rate-types/list"
      title="Create Rate Plan Type"
    >
      <RouteExitGuard showPrompt={haveChanges} />
      <AddRcdGroupTypeDialog
        isOpen={dialogOpen}
        ratePlanType={ratePlanType}
        usePeriodTypeSelectQuery={usePeriodTypeSelectQuery}
        useTransactionTypeSelectQuery={useTransactionTypeSelectQuery}
        useRcdGroupTypeSelectQuery={useRcdGroupTypeSelectQuery}
        onSubmitClick={handleAddRcdGroup}
        onCloseClick={() => setDialogOpen(false)}
      />
      <RatePlanTypeForm
        loading={submitting}
        ratePlanType={ratePlanType}
        onAddRcdGroupType={() => setDialogOpen(true)}
        onChange={handleChanges}
        onSubmit={handleSubmit}
      />
    </BillingModelScreen>
  );
};

export default CreateRatePlanType;
